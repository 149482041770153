import React from "react";
import { useTheme } from "@mui/material/styles";
import { Box, Button, Typography, useMediaQuery } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";

export default function Title(props) {
  const { component } = props;
  const theme = useTheme();
  const {
    slug = null,
    url = null,
    textMaxWidthDesktop = null,
    textMaxWidthMobile = null,
  } = props;

  const view = useMediaQuery(theme.breakpoints.up("md")) ? "desktop" : "mobile";

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        textAlign: "center",
        pt: 12,
        bgcolor: "#fff",
      }}
    >
      <Box sx={{ position: "relative", width: "100%", mb: 1 }}>
        {!component.trans(`${slug}-title_${view}`).includes(`_${view}`) && (
          <Box sx={{ position: "relative", width: "100%", px: 2 }}>
            <Typography
              component="h2"
              align="center"
              sx={{
                ...theme.custom.titles.title,
                ...{
                  position: "relative",
                  zIndex: 1,
                  borderTop: { xs: "2px solid #808080", md: "none" },
                  pt: { xs: 4, md: 0 },
                  mb: 2,
                  "&::before": {
                    display: { xs: "none", md: "block" },
                    content: '""',
                    position: "absolute",
                    borderTop: "2px solid #808080",
                    margin: "0 auto",
                    top: "50%",
                    left: 0,
                    right: 0,
                    bottom: 0,
                    width: "95%",
                    zIndex: -1,
                  },
                  "& span": {
                    bgcolor: "#fff",
                    px: { xs: 0, md: 4 },
                  },
                },
              }}
            >
              <span>{component.trans(`${slug}-title_${view}`)}</span>
            </Typography>
          </Box>
        )}
      </Box>
      {!component.trans(`${slug}-description_${view}`).includes(`_${view}`) && (
        <Typography
          component="h3"
          sx={{
            ...theme.custom.titles.description,
            ...{
              textAlign: "center",
              maxWidth: {
                xs: textMaxWidthMobile ? textMaxWidthMobile : "unset",
                md: textMaxWidthDesktop ? textMaxWidthDesktop : "unset",
              },
              px: 1,
              mb: 1,
            },
          }}
        >
          {component.trans(`${slug}-description_${view}`)}
        </Typography>
      )}
      {!component.trans(`${slug}-button_${view}`).includes(`_${view}`) &&
        url && (
          <Button
            sx={{
              ...theme.custom.buttons.button1,
              ...{
                mx: "auto",
                mt: 1,
                mb: 6,
              },
            }}
            component={RouterLink}
            to={url}
            disableRipple
          >
            {component.trans(`${slug}-button_${view}`)}
          </Button>
        )}
    </Box>
  );
}
