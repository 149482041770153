import React from "react";
import { Box } from "@mui/material";
import NavBar from "../../../Components/Widgets/Navbar";
import MenuDrawer from "../../../Components/Widgets/MenuDrawer";
import SubMenuDrawer from "../../../Components/Widgets/SubMenuDrawer";
import TopBannersSlider2 from "../../../Components/Widgets/Sliders/TopBannersSlider2";
import SpecialColSlider from "../../../Components/Widgets/Sliders/SpecialColSlider";
import NewCollections from "../widgets/sliders/new_collections";
import CollectionsSlider from "../../default/widgets/sliders/collections_slider";
import AllCollections from "../../default/widgets/sliders/all_collections";
import Footer from "../widgets/footer";

const ExportDefault = (props) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        minHeight: "100vh",
      }}
    >
      <NavBar
        {...{
          ...props,
          ...{
            bg_color: "transparent",
          },
        }}
      />
      <MenuDrawer {...props} />
      <SubMenuDrawer {...props} />
      <TopBannersSlider2
        {...{
          ...props,
          ...{
            fscope: "men-collection-top-banner",
            lazyLoading: true,
          },
        }}
      />
      <NewCollections
        {...{
          ...props,
          ...{
            sliders: ["zodiac", "alchemy", "laconia"],
          },
        }}
      />
      <SpecialColSlider
        {...{
          ...props,
          ...{
            fscope: "men-special-col-slider",
            textMaxWidthDesktop: "420px",
            textMaxWidthMobile: "350px",
          },
        }}
      />
      <CollectionsSlider
        {...{
          ...props,
          ...{
            sliders: ["orion", "perseus", "kavafi", "ark"],
          },
        }}
      />
      <AllCollections
        {...{
          ...props,
          ...{
            gender: "men",
          },
        }}
      />
      <Footer {...props} />
    </Box>
  );
};

export default ExportDefault;
