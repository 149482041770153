import React from "react";
import { styled } from "@mui/material/styles";
import {
  Container,
  Typography,
  Grid,
  Box,
  CircularProgress,
  Link,
  Autocomplete,
  TextField,
} from "@mui/material";
import { autocompleteClasses } from "@mui/material/Autocomplete";
const Title = styled("div")(({ theme }) => ({
  fontFamily: "Poppins-Regular",
  color: "#fff",
  fontSize: "16px",
  lineHeight: "16px",
}));

const StyledImg = styled("img")`
  width: 100vw;
  height: 100vh;
  object-fit: cover;
  position: fixed;
  top: 0;
  left: 0;
  z-index: -2;
`;

const MyAutocomplete = styled(Autocomplete)(({ theme }) => ({
  color: "#fff",
  [`& .${autocompleteClasses.popupIndicator}`]: {
    transform: "none",
  },
  "& .MuiFormControl-root": {
    "& .MuiFormLabel-root": {
      color: "#fff",
      fontSize: "18px",
      fontFamily: "Poppins-Regular",
    },
    "& .MuiInputBase-root": {
      borderColor: "#fff",
      color: "#fff",
    },
    "& .MuiInputBase-root:after": {
      display: "none",
    },

    "& .MuiInput-underline:before": {
      display: "none",
    },
    "& .MuiButtonBase-root.MuiAutocomplete-clearIndicator": {
      color: "#fff",
      visibility: "visible",
    },
  },
}));

export default function Locations(props) {
  const { component } = props;
  const devHelper = component.getHelpers("dev");
  const filesUrl = component.getHelpers("env").get("files_url");
  const img_version = component.getHelpers("value").getValue("images-version");

  const country = component.getCountry();
  const {
    locations = [],
    stores = [],
    states = [],
    store = null,
    state = null,
  } = component.getData("default", {});

  const changeState = (newValue) => {
    component.setData({ "default.state": newValue });
    component.setLocations();
  };

  const changeStore = (newValue) => {
    component.setData({ "default.store": newValue });
    component.setLocations();
  };

  return (
    <Container
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        px: 4,
      }}
      disableGutters
      maxWidth="xl"
    >
      <StyledImg
        component="img"
        src={`${filesUrl}/konstantino/assets/locations-backround.jpg?v=${img_version}`}
        alt="background image"
      />
      <Box sx={{ textAlign: "center", mt: "150px" }}>
        <Typography
          sx={{
            fontSize: "26px",
            fontFamily: "Poppins-Regular",
            color: "#fff",
            textTransform: "uppercase",
          }}
        >
          {component.trans(country)}
        </Typography>
      </Box>
      <Grid
        container
        sx={{
          flexGrow: 1,
          alignItems: "center",
          mt: 3,
        }}
        direction="row"
        rowSpacing={1}
        columnSpacing={{ xs: 0, sm: "4px" }}
      >
        <Grid
          item
          xs={12}
          sm={6}
          justifycontent="center"
          sx={{
            textAlign: "center",
          }}
        >
          <MyAutocomplete
            value={state}
            onChange={(event, newValue) => {
              changeState(newValue);
            }}
            options={states}
            popupIcon={
              <i
                className="fa-light fa-location-dot"
                style={{ color: "#fff", fontSize: "22px" }}
              ></i>
            }
            renderInput={(params) => (
              <TextField
                {...params}
                label="FIND BY LOCATION"
                variant="standard"
                sx={{
                  m: 1,
                  maxWidth: "200px",
                  fontSize: "18px",
                  fontFamily: "Poppins-Regular",
                }}
              />
            )}
          />
        </Grid>
        <Grid
          item
          xs={12}
          sm={6}
          justifycontent="center"
          sx={{
            textAlign: "center",
          }}
        >
          <MyAutocomplete
            value={store}
            onChange={(event, newValue) => {
              changeStore(newValue);
            }}
            options={stores}
            popupIcon={
              <i
                className="fa-light fa-shop"
                style={{ color: "#fff", fontSize: "22px" }}
              ></i>
            }
            renderInput={(params) => (
              <TextField
                {...params}
                label="FIND BY STORE"
                variant="standard"
                sx={{
                  m: 1,
                  maxWidth: "200px",
                  fontSize: "18px",
                  fontFamily: "Poppins-Regular",
                }}
              />
            )}
          />
        </Grid>
      </Grid>
      <Box
        sx={{
          width: "100%",
          my: 3,
          borderBottom: "1px solid #fff",
          borderTop: "1px solid #fff",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          minHeight: "142.69px",
        }}
      >
        {!locations ? (
          <CircularProgress
            sx={{
              color: "#fff",
            }}
          />
        ) : (
          <>
            {locations.length !== 0 ? (
              <Grid
                container
                sx={{
                  flexGrow: 1,
                  alignItems: "stretch",
                  m: 0,
                  py: 3,
                }}
                direction="row"
                rowSpacing={2}
                columnSpacing={1}
              >
                {locations.map((loc, index) => {
                  const {
                    slug,
                    name,
                    address_text_1 = null,
                    address_text_2 = null,
                    url = null,
                    phone = "",
                    email = null,
                    email_text = null,
                  } = devHelper.getObjectValue(loc, "_values");

                  var new_phone = "",
                    number = "",
                    country_num = "";

                  if (phone.trim().length >= 10) {
                    number = phone.slice(-10);
                    country_num = phone.slice(0, -10);
                    new_phone =
                      (country_num && country_num + " ") +
                      "(" +
                      number.slice(0, 3) +
                      ")" +
                      number.slice(3, 6) +
                      "-" +
                      number.slice(-4);
                  }

                  return (
                    <Grid
                      item
                      xs={12}
                      sm={6}
                      md={4}
                      justifycontent="center"
                      sx={{
                        textAlign: "center",
                        pb: 2,
                      }}
                      key={"location-" + slug}
                    >
                      <Title>{name}</Title>
                      {url && address_text_1 && (
                        <Link
                          href={url}
                          rel="noreferrer"
                          target="_blank"
                          underline="none"
                          sx={{
                            fontFamily: "Spartan-Medium",
                            color: "#fff",
                            fontSize: "12px",
                            lineHeight: "14px",
                            display: "inline-block",
                            mt: "6px",
                          }}
                        >
                          {address_text_1 && address_text_1}
                          <br />
                          {address_text_2 && address_text_2}
                        </Link>
                      )}
                      <Box sx={{ mt: -1 }}>
                        {phone && (
                          <span
                            style={{
                              color: "#fff",
                              fontSize: "12px",
                              fontFamily: "Spartan-SemiBold",
                              marginRight: "4px",
                            }}
                          >
                            Tel:
                            <Link
                              href={"tel:" + phone}
                              underline="none"
                              sx={{
                                fontFamily: "Spartan-SemiBold",
                                color: "#fff",
                                fontSize: "12px",
                                pl: "4px",
                              }}
                            >
                              {new_phone}
                            </Link>
                          </span>
                        )}
                        {email && (
                          <span
                            style={{
                              color: "#fff",
                              fontSize: "12px",
                              fontFamily: "Spartan-SemiBold",
                            }}
                          >
                            Email:
                            <Link
                              href={"mailto:" + email}
                              target="_blank"
                              underline="none"
                              sx={{
                                fontFamily: "Spartan-SemiBold",
                                color: "#fff",
                                fontSize: "12px",
                                pl: "4px",
                              }}
                            >
                              {email_text}
                            </Link>
                          </span>
                        )}
                      </Box>
                    </Grid>
                  );
                })}
              </Grid>
            ) : (
              <Box
                sx={{
                  textAlign: "center",
                }}
              >
                <Typography sx={{ color: "#fff" }}>
                  NO LOCATIONS FOUND
                </Typography>
              </Box>
            )}
          </>
        )}
      </Box>
    </Container>
  );
}
