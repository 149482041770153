import { Component } from "../../../modules/Core/Component";
import templateDefault from "../../templates/default/widgets/login";
import Services from "../../Services/Services";

export default class Login extends Component {
  template = templateDefault;

  googleAuthLoaded = false;

  onLoad(data) {
    super.onLoad(data);

    // Services.get("socialLogin", "test").then(([socialLogin]) => {
    //   socialLogin.loadFB({ window, document, page: this });
    // });

    // Services.get("socialLogin", "test").then(([socialLogin]) => {
    //   socialLogin.loadGoogle({ window, document, page: this });
    // });
  }

  loginFB() {
    this.deleteOrder();
    Services.get("socialLogin", "test").then(([socialLogin]) => {
      socialLogin.loginFB({ window, document, page: this });
    });
  }

  login(e) {
    e.preventDefault();
    // this.deleteOrder();

    const { username, password } = this.getData("default.fields.auth", {});
    const error = {};

    if (!username) {
      error["username"] = this.ucfirst("email-helpertext");
    } else {
      if (!this.getHelpers("validate").email(username, {})) {
        error["username"] = this.ucfirst("invalid-email");
      }
    }

    if (!password) {
      error["password"] = this.ucfirst("password-helpertext");
    }

    this.setData({
      "error.fields.auth": error,
      // "error.message.auth": "",
    });

    if (Object.keys(error).length === 0) {
      Services.get("auth").then(([authService]) => {
        authService
          .login({ username, password })
          .then((authService) => {
            this.deleteData("error.auth.message");
            this.getHelpers("Auth").set(
              authService.getData("_response").getData()
            );
            this.getPage().redirectToRedirect("/");
          })
          .catch((authService) => {
            this.getComponents()
              .findById("main-message")
              .first()
              .setData({
                "error-message": authService.getData("_response").getMessage(),
              });
          });
      });
    }
  }

  requestPassword(e) {
    if (e) e.preventDefault();

    const { username } = this.getData("default.fields.request", {});
    const error = {};

    if (!username) {
      error["username"] = "";
    } else {
      if (!this.getHelpers("validate").email(username, {})) {
        error["username"] = this.ucfirst("invalid-email");
      }
    }

    this.setData({
      "error.fields.request": error,
    });

    if (Object.keys(error).length === 0) {
      Services.get("auth").then(([authService]) => {
        authService
          .passwordRequest({ username })
          .then((authService) => {
            this.getHelpers("Auth").set(
              authService.getData("_response").getData()
            );
            this.getPage().redirectToRedirect("/");
          })
          .catch((authService) => {
            this.getComponents()
              .findById("main-message")
              .first()
              .setData({
                "error-message": authService.getData("_response").getMessage(),
              });
          });
      });
    }
  }

  resetPassword(e) {
    if (e) e.preventDefault();

    const { password, verify } = this.getData("default.fields.reset", {});
    const key = this.getPage().getQuery("resetPassword", "");
    const error = {};

    if (!password) {
      error["password"] = "";
    }

    if (!verify) {
      error["verify"] = "";
    }

    if (!key) {
      error["key"] = "";
    }

    this.setData({
      "error.fields.reset": error,
    });

    if (Object.keys(error).length === 0) {
      Services.get("auth", "test").then(([authService]) => {
        authService
          .passwordReset({ password, verify, key })
          .then((authService) => {
            this.getHelpers("Auth").set(
              authService.getData("_response").getData()
            );
            this.getPage().redirectToRedirect("/");
          })
          .catch((authService) => {
            this.getComponents()
              .findById("main-message")
              .first()
              .setData({
                "error-message": authService.getData("_response").getMessage(),
              });
          });
      });
    }
  }
}
