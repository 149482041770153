import React from "react";
import { useTheme } from "@mui/material/styles";
import {
  Button,
  Box,
  CardMedia,
  Link,
  Container,
  useMediaQuery,
  Typography,
  Fade,
} from "@mui/material";
import { Link as RouterLink } from "react-router-dom";

export default function CurationSlider(props) {
  const { component } = props;
  const theme = useTheme();
  const devHelper = component.getHelpers("dev");
  const filesUrl = component.getHelpers("env").get("files_url");
  const img_version = component.getHelpers("value").getValue("images-version");

  const sliders = component.getData("default.sliders", []);
  const view = useMediaQuery(theme.breakpoints.up("md")) ? "desktop" : "mobile";
  const activeSlide = component.getData("default.active_slide", 0);

  const [timeout, setTimeoutt] = React.useState(0);

  React.useEffect(() => {
    setTimeout(() => {
      setTimeoutt(4000);
    }, 400);
  }, []);

  const {
    titleMaxWidthDesktop = null,
    titleMaxWidthMobile = null,
    textMaxWidthDesktop = null,
    textMaxWidthMobile = null,
  } = component.props;

  const text = (slider, f_color, view) => {
    const { slug = null, url = null } = devHelper.getObjectValue(
      slider,
      "_values"
    );

    return (
      <Box
        sx={{
          backgroundColor: "transparent",
          display: "flex",
          flexDirection: "column",
          px: { xs: 2, md: 0 },
        }}
      >
        {!component
          .trans(`${slug}-small-title_${view}`)
          .includes(`_${view}`) && (
          <Button
            sx={{
              ...theme.custom.titles.subtitle,
              ...{
                color: f_color,
                textAlign: "center",
                mx: "auto",
              },
            }}
            component={RouterLink}
            to={url}
            disableRipple
          >
            {component.trans(`${slug}-small-title_${view}`)}
          </Button>
        )}
        {!component.trans(`${slug}-title_${view}`).includes(`_${view}`) && (
          <Button
            sx={{
              ...theme.custom.titles.title,
              ...{
                color: f_color,
                textAlign: "center",
                maxWidth: {
                  xs: titleMaxWidthMobile ? titleMaxWidthMobile : "unset",
                  md: titleMaxWidthDesktop ? titleMaxWidthDesktop : "unset",
                },
                mx: "auto",
              },
            }}
            component={RouterLink}
            to={url}
            disableRipple
          >
            {component.trans(`${slug}-title_${view}`)}
          </Button>
        )}
        {!component
          .trans(`${slug}-description_${view}`)
          .includes(`_${view}`) && (
          <Typography
            component="h2"
            sx={{
              ...theme.custom.titles.description,
              ...{
                color: f_color,
                textAlign: "center",
                maxWidth: {
                  xs: textMaxWidthMobile ? textMaxWidthMobile : "unset",
                  md: textMaxWidthDesktop ? textMaxWidthDesktop : "unset",
                },
                mx: "auto",
              },
            }}
          >
            {component.trans(`${slug}-description_${view}`)}
          </Typography>
        )}
        {!component.trans(`${slug}-button_${view}`).includes(`_${view}`) && (
          <Button
            sx={{
              ...theme.custom.buttons.button1,
              ...{
                color: f_color,
                border: "1px solid " + f_color,
                mx: "auto",
                mt: 2,
              },
            }}
            component={RouterLink}
            to={url}
            disableRipple
          >
            {component.trans(`${slug}-button_${view}`)}
          </Button>
        )}
      </Box>
    );
  };

  return (
    <Box sx={{ bgcolor: "#fff" }}>
      {sliders.length !== 0 && (
        <Container
          maxWidth="xl"
          disableGutters
          sx={{
            px: 2,
          }}
        >
          <Box
            sx={{
              py: 6,
              bgcolor: "#F6F6F7",
            }}
          >
            <Box sx={{ px: { xs: 2, md: 10 }, mb: 3 }}>
              <Box sx={{ position: "relative" }}>
                {sliders.map((slider, index) => {
                  const { slug, url } = devHelper.getObjectValue(
                    slider,
                    "_values"
                  );

                  return (
                    <Fade
                      key={slug}
                      in={activeSlide === index}
                      timeout={timeout}
                    >
                      <Link component={RouterLink} to={url}>
                        <CardMedia
                          component="img"
                          sx={{
                            position: index === 0 ? "relative" : "absolute",
                            top: 0,
                            left: 0,
                            zIndex: 0,
                          }}
                          image={`${filesUrl}/konstantino/sliders/${view}/${slug}.jpg?v=${img_version}`}
                          alt={component.trans(
                            `${devHelper.getObjectValue(
                              slider,
                              "_values.slug"
                            )} image`
                          )}
                          onLoad={() =>
                            component.setData({
                              [`default.imageReady-${index}`]: true,
                            })
                          }
                        />
                      </Link>
                    </Fade>
                  );
                })}
              </Box>
            </Box>
            {text(sliders[0], "#000", view)}
          </Box>
        </Container>
      )}
    </Box>
  );
}
