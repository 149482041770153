import React from "react";
import { useTheme } from "@mui/material/styles";
import {
  Box,
  CardMedia,
  Button,
  Link,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import Slider from "react-slick";
import { collections } from "./collections";

function SampleNextArrow(props) {
  const { className, onClick } = props;
  return (
    <Box
      className={className}
      sx={{
        background: "#eaeae8",
        right: 20,
        zIndex: 1,
        top: "50%",
        webkitTransform: "translate(0,-50%)",
        transform: "translate(0,-50%)",
        color: "#000",
        width: "30px",
        height: "30px",
        borderRadius: "50%",
        display: { xs: "none", md: "flex" },
        justifyContent: "center",
        alignItems: "center",
        "&:hover": {
          background: "#eaeae8",
          color: "#000",
        },
      }}
      onClick={onClick}
    >
      <i
        className="fa-sharp fa-light fa-chevron-right"
        style={{ fontSize: "20px" }}
      ></i>
    </Box>
  );
}

function SamplePrevArrow(props) {
  const { className, onClick } = props;
  return (
    <Box
      className={className}
      sx={{
        background: "#eaeae8",
        left: 20,
        zIndex: 1,
        top: "50%",
        webkitTransform: "translate(0,-50%)",
        transform: "translate(0,-50%)",
        color: "#000",
        width: "30px",
        height: "30px",
        borderRadius: "50%",
        display: { xs: "none", md: "flex" },
        justifyContent: "center",
        alignItems: "center",
        "&:hover": {
          background: "#eaeae8",
          color: "#000",
        },
      }}
      onClick={onClick}
    >
      <i
        className="fa-sharp fa-light fa-chevron-left"
        style={{ fontSize: "20px" }}
      ></i>
    </Box>
  );
}

export default function NewCollections(props) {
  const { component, sliders = [] } = props;
  const theme = useTheme();
  const view = useMediaQuery(theme.breakpoints.up("md")) ? "desktop" : "mobile";

  const devHelper = component.getHelpers("dev");
  const filesUrl = component.getHelpers("env").get("files_url");
  const img_version = component.getHelpers("value").getValue("images-version");

  const settings = {
    className: "center",
    dots: false,
    slidesToShow: 3,
    slidesToScroll: 1,
    centerPadding: "15%",
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    appendDots: (dots) => (
      <div
        style={{
          height: "15px",
        }}
      >
        <ul style={{ margin: "0px" }}> {dots} </ul>
      </div>
    ),
    customPaging: (i) => (
      <div
        className="dot"
        style={{
          width: "15px",
          height: "15px",
          border: "1px solid #000",
          borderRadius: "50%",
          backgroundColor: "#000",
        }}
      ></div>
    ),
    responsive: [
      {
        breakpoint: 900,
        settings: {
          centerMode: true,
          infinite: true,
          slidesToShow: 1,
          centerPadding: "15%",
        },
      },
      {
        breakpoint: 600,
        settings: {
          centerMode: true,
          infinite: true,
          slidesToShow: 1,
          centerPadding: "12%",
        },
      },
      {
        breakpoint: 400,
        settings: {
          centerMode: true,
          infinite: true,
          slidesToShow: 1,
          centerPadding: "8%",
        },
      },
    ],
  };

  return (
    <Box sx={{ py: 9, bgcolor: "#fff" }}>
      <Box sx={{ textAlign: "center" }}>
        <Typography
          component="h1"
          align="center"
          sx={{
            ...theme.custom.titles.title,
            ...{ mb: 2 },
          }}
        >
          Shop by Collection
        </Typography>
      </Box>
      <Box sx={{ mb: { xs: "40px", md: 0 } }}>
        <Slider {...settings}>
          {sliders.map((slider) => {
            return (
              <Box
                key={slider}
                sx={{
                  p: 1,
                  my: 1,
                  textAlign: "center",
                  "&:hover": {
                    "& > div > a > img": {
                      transform: "scale(1.3)",
                      webkitTransform: "scale(1.3)",
                    },
                  },
                }}
              >
                <Box
                  sx={{
                    overflow: "hidden",
                    position: "relative",
                  }}
                >
                  <Button
                    disabled
                    sx={{
                      position: "absolute",
                      background: "transparent",
                      zIndex: "1",
                      padding: 0,
                      minWidth: 0,
                      color: "#000",
                      textTransform: "none",
                      fontFamily: "Spartan-SemiBold",
                      "&:disabled": {
                        pointerEvents: "auto",
                        color: "#000",
                      },
                      "&:hover, &:focus": {
                        background: "transparent",
                      },
                      top: { xs: 10, sm: 12 },
                      right: { xs: 10, sm: 15 },
                      fontSize: { xs: "12px", sm: "14px" },
                    }}
                  >
                    {devHelper.getObjectValue(collections, `${slider}.tag`)}
                  </Button>
                  <Link
                    component={RouterLink}
                    to={devHelper.getObjectValue(collections, `${slider}.url`)}
                  >
                    <CardMedia
                      sx={{
                        maxWidth: "100%",
                        transition: "transform .5s ease",
                        webkitTransition: "transform .5s ease",
                      }}
                      component="img"
                      alt={`${slider}-${view}`}
                      image={`${filesUrl}/konstantino/sliders/collections/${slider}.jpg?v=r${img_version}`}
                    />
                  </Link>
                </Box>
                <Box
                  sx={{
                    backgroundColor: "transparent",
                    display: "flex",
                    flexDirection: "column",
                    mt: 2,
                  }}
                >
                  <Typography
                    sx={{
                      ...theme.custom.titles.title,
                      ...{},
                    }}
                  >
                    {devHelper.getObjectValue(collections, `${slider}.name`)}
                  </Typography>
                  <Typography
                    variant="body2"
                    component="h3"
                    sx={{
                      ...theme.custom.titles.description,
                      ...{
                        textAlign: "center",
                        maxWidth: { xs: "400px", md: "500px" },
                        mx: "auto",
                      },
                    }}
                  >
                    {devHelper.getObjectValue(
                      collections,
                      `${slider}.blurb.${view}`
                    )}
                  </Typography>
                  <Typography
                    sx={{
                      ...theme.custom.buttons.button1,
                      ...{
                        m: "auto",
                        mt: 2,
                      },
                    }}
                  >
                    Explore
                  </Typography>
                </Box>
              </Box>
            );
          })}
        </Slider>
      </Box>
    </Box>
  );
}
