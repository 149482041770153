import React from "react";
import { Container, Typography } from "@mui/material";

export default function OrderComplete(props) {
  const { component } = props;

  return (
    <Container
      component="main"
      maxWidth="xs"
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        my: 2,
        minHeight: "600px",
      }}
    >
      <Typography variant="h4">
        {component.ucfirst("thank you for your order")}
      </Typography>
    </Container>
  );
}
