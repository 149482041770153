import Page from "../../../modules/Core/Components/Page";
import Pages from "../Pages";
import Services from "../../Services/Services";

export default class Redirect extends Page {
  template = (cprops) => {
    const { component } = cprops;
    const { props } = component;
    const pageName = component.getData("default.page", "");
    const pageParams = component.getData("default.params", {});

    let Page = null;

    if (pageName) {
      Page = Pages[pageName] || "";
      props.match.params = pageParams;
    }

    return Page ? <Page {...props} /> : "";
  };

  async onLoad(data) {
    super.onLoad(data);

    let [service] = await Services.get("url");

    service = await service.getAll({
      params: {
        furl: window.location.pathname,
      },
    });

    const { rows = [] } = service.getData("urls");

    if (rows.length) {
      this.setPage(rows[0]);
    } else {
      this.doSomething();
    }
  }

  setPage(page) {
    const { _values = {} } = page;
    const { url_params, page_template } = _values;
    const params = {};

    url_params.forEach((param) => {
      const { slug, value } = param;
      params[slug] = value;
    });

    this.setData({
      default: {
        page: page_template,
        params: params,
      },
    });

    return this;
  }

  doSomething() {
    const skuLength = 3;
    const pathname = window.location.pathname.split("/");
    let sku = pathname.pop();

    if (sku) {
      sku = sku.split("-").reverse();
      const skus = [];

      for (let i = 0; i < sku.length && i < skuLength; i++) {
        skus.push(
          sku
            .slice(0, i + 1)
            .reverse()
            .join("-")
        );
      }

      if (skus.length) {
        this.findProduct(skus);
      }
    }
  }

  async findProduct(skus) {
    skus = skus.reverse();
    let [service] = await Services.get("product");

    service = await service.getAll({
      params: {
        "fsearch-sku": skus.join(" "),
        fsku: skus.join(" "),
        condition: "or",
      },
    });

    const { rows = [] } = service.getData("products");

    if (rows.length) {
      let product = null;

      skus.every((sku) => {
        product = rows.find((product) => {
          const { combinations = [] } = product._values;

          return (
            product._values.sku === sku ||
            combinations.find((combination) => combination.sku === sku)
          );
        });

        return product ? false : true;
      });

      if (!product && rows.length) {
        product = rows[0];
      }

      if (product) {
        const { _values = {} } = product;
        const { slug, sku } = _values;

        if (slug && sku) {
          this.redirect(`/product/${slug}/${sku}`);
        }
      }
    } else {
      this.redirect("/");
    }
  }
}
