import React from "react";
import { useTheme } from "@mui/material/styles";
import {
  Box,
  CardMedia,
  Button,
  useMediaQuery,
  Typography,
} from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import Slider from "react-slick";

export default function JustIn(props) {
  const { component } = props;
  const theme = useTheme();
  const {
    slug = null,
    url = null,
    textMaxWidthDesktop = null,
    textMaxWidthMobile = null,
  } = component.props;

  const view = useMediaQuery(theme.breakpoints.up("md")) ? "desktop" : "mobile";
  const devHelper = component.getHelpers("dev");
  const filesUrl = component.getHelpers("env").get("files_url");
  const img_version = component.getHelpers("value").getValue("images-version");

  const sliders = component.getData("default.sliders", []);

  const settings = {
    className: "center",
    centerMode: true,
    infinite: true,
    dots: false,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    speed: 1500,
    autoplaySpeed: 2500,
    centerPadding: "10%",
    nextArrow: <></>,
    prevArrow: <></>,
    pauseOnHover: false,
    responsive: [
      {
        breakpoint: 900,
        settings: {
          slidesToShow: 3,
          centerPadding: "12.5%",
        },
      },
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 2,
          centerPadding: "17.5%",
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          centerPadding: "25%",
        },
      },
    ],
  };

  const info = () => {
    return (
      <Box>
        {!component.trans(`${slug}-title_${view}`).includes(`_${view}`) && (
          <Typography
            component="h1"
            sx={{
              ...theme.custom.titles.title,
              ...{ mt: 2 },
            }}
          >
            {component.trans(`${slug}-title_${view}`)}
          </Typography>
        )}
        {!component
          .trans(`${slug}-description_${view}`)
          .includes(`_${view}`) && (
          <Typography
            component="h3"
            sx={{
              ...theme.custom.titles.description,
              ...{
                textAlign: "center",
                maxWidth: {
                  xs: textMaxWidthMobile ? textMaxWidthMobile : "unset",
                  md: textMaxWidthDesktop ? textMaxWidthDesktop : "unset",
                },
                px: 1,
                mt: 2,
                mb: 1,
              },
            }}
          >
            {component.trans(`${slug}-description_${view}`)}
          </Typography>
        )}
        {!component.trans(`${slug}-button_${view}`).includes(`_${view}`) &&
          url && (
            <Button
              sx={{
                ...theme.custom.buttons.button1,
                ...{
                  mx: "auto",
                  mt: 2,
                },
              }}
              component={RouterLink}
              to={url}
              disableRipple
            >
              {component.trans(`${slug}-button_${view}`)}
            </Button>
          )}
      </Box>
    );
  };

  return (
    <Box sx={{ mt: 5 }}>
      <Box
        sx={{
          display: { xs: "none", md: "flex" },
          flexDirection: "column",
          alignItems: "center",
          textAlign: "center",
          mb: 2,
        }}
      >
        {info()}
      </Box>
      {sliders.length !== 0 && (
        <Slider {...settings}>
          {sliders.map((slider, index) => (
            <Box
              key={devHelper.getObjectValue(slider, "_values.slug")}
              sx={{
                p: "3px",
              }}
              component={RouterLink}
              to={devHelper.getObjectValue(slider, "_values.url")}
            >
              <CardMedia
                sx={{
                  maxWidth: "100%",
                }}
                component="img"
                image={`${filesUrl}/konstantino/sliders/desktop/${devHelper.getObjectValue(
                  slider,
                  "_values.slug"
                )}.jpg?v=${img_version}`}
                alt={devHelper.getObjectValue(slider, "_values.slug")}
              />
            </Box>
          ))}
        </Slider>
      )}
      <Box
        sx={{
          display: { xs: "flex", md: "none" },
          flexDirection: "column",
          alignItems: "center",
          textAlign: "center",
          mb: 2,
        }}
      >
        {info()}
      </Box>
    </Box>
  );
}
