import { Component } from "../../../modules/Core/Component";
import templateDefault from "../../templates/default/widgets/cart/cart_popper";
import Services from "../../Services/Services";

export default class CartPopper extends Component {
  id = "cart-popper";
  group = "order,cart-popper";

  template = templateDefault;

  onLoad(data) {
    super.onLoad(data);
    this.getOrder();
  }

  getOrder() {
    Services.get("order").then(([orderService]) => {
      orderService
        .fetchOrder()
        .then((orderService) => {
          const order = orderService.getData("order");
          this.setData({ "default.order": order });
        })
        .catch((orderService) => {
          this.checkOrderError(orderService);
        });
    });
  }

  checkOrderError(service) {
    if (service && service.getMessage && service.getError) {
      const type = service.getMessage() || service.getError();

      switch (type) {
        case "order-missing":
        case "order-expired":
        case "order-placed": {
          this.setData({ "default.order": {} });
          break;
        }
        default:
          break;
      }
    }

    return this;
  }

  setPopperOpen(boolean) {
    this.setData({ "default.opened": boolean });
  }
}
