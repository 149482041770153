import React from "react";
import { useTheme, styled } from "@mui/material/styles";
import {
  Container,
  Grid,
  Radio,
  RadioGroup,
  FormControlLabel,
  useMediaQuery,
  Box,
} from "@mui/material";
import ProductListView from "../../../Components/Widgets/ProductListView";
import SwipeableViews from "react-swipeable-views";
import { autoPlay } from "react-swipeable-views-utils";
const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

const BpIcon = styled("span")(({ theme }) => ({
  borderRadius: "50%",
  width: "15px",
  height: "15px",
  backgroundColor: "#000",
  "&:before": {
    border: "1px solid #fff",
    borderRadius: "50%",
    display: "block",
    width: "15px",
    height: "15px",
    backgroundImage: "radial-gradient(#000,#000 28%,transparent 32%)",
    content: '""',
  },
}));

const BpCheckedIcon = styled(BpIcon)({
  width: "15px",
  height: "15px",
  backgroundColor: "#000",
  "&:before": {
    border: "1px solid #fff",
    borderRadius: "50%",
    display: "block",
    width: "15px",
    height: "15px",
    backgroundImage: "radial-gradient(#fff,#fff 50%,transparent 30%)",
    content: '""',
  },
});

function CustomRadio(props) {
  return (
    <Radio
      sx={{
        "&:hover": {
          backgroundColor: "transparent",
        },
      }}
      disableRipple
      color="default"
      checkedIcon={<BpCheckedIcon />}
      icon={<BpIcon />}
      {...props}
    />
  );
}

export default function RecentlyViewed(props) {
  const { component } = props;
  const theme = useTheme();
  const items_per_view = useMediaQuery(theme.breakpoints.up("md")) ? 3 : 2;
  const [activeView, setActiveView] = React.useState(0);
  const products = component.getData("default.viewed", []);
  const devHelper = component.getHelpers("dev");

  const handleViewChange = (step) => {
    setActiveView(step);
  };

  return (
    <>
      {products.length !== 0 && (
        <>
          <Box
            sx={{
              width: "100%",
              color: "#ffffff",
              height: "30px",
              backgroundColor: "#000",
            }}
          ></Box>
          <Container
            sx={{ px: "20px", position: "relative" }}
            disableGutters
            maxWidth="xl"
          >
            <Box
              sx={{
                writingMode: "vertical-rl",
                position: "absolute",
                borderRadius: 0,
                borderLeft: "1px solid #000",
                top: "50%",
                left: 0,
                transform: "translateY(-50%) scale(-1)",
                fontSize: "12px",
              }}
            >
              Recently Viewed
            </Box>

            <AutoPlaySwipeableViews
              axis={theme.direction === "rtl" ? "x-reverse" : "x"}
              index={activeView}
              onChangeIndex={handleViewChange}
              enableMouseEvents
              interval={5000}
            >
              {Array.from(
                {
                  length:
                    products.length % items_per_view === 0
                      ? products.length / items_per_view
                      : products.length / items_per_view + 1,
                },
                (_, index) => (
                  <Grid
                    key={"grid-" + index}
                    container
                    sx={{
                      alignItems: "flex-start",
                    }}
                    justifycontent="center"
                    direction="row"
                    spacing={"4px"}
                  >
                    {products
                      .slice(
                        index * items_per_view,
                        (index + 1) * items_per_view
                      )
                      .map((product, i) => (
                        <ProductListView
                          key={devHelper.getObjectValue(product, "_id")}
                          {...{
                            ...props,
                            ...{
                              product,
                              grid: 4,
                            },
                          }}
                        />
                      ))}
                  </Grid>
                )
              )}
            </AutoPlaySwipeableViews>
          </Container>
          <Box
            sx={{
              width: "100%",
              color: "#ffffff",
              height: "30px",
              backgroundColor: "#000",
              textAlign: "center",
              position: "relative",
            }}
          >
            <RadioGroup
              row
              value={activeView}
              sx={{ display: "block", height: "30px" }}
            >
              {Array.from(
                {
                  length:
                    products.length % items_per_view === 0
                      ? products.length / items_per_view
                      : products.length / items_per_view + 1,
                },
                (_, index) => (
                  <FormControlLabel
                    key={"desktop_key-" + index}
                    value={index}
                    control={<CustomRadio />}
                    onClick={(e) => {
                      handleViewChange(index);
                    }}
                    id={"radio-" + index}
                    sx={{ height: "30px" }}
                  />
                )
              )}
            </RadioGroup>
          </Box>
        </>
      )}
    </>
  );
}
