import React from "react";
import { Box } from "@mui/material";
import NavBar from "../../../Components/Widgets/Navbar";
import MenuDrawer from "../../../Components/Widgets/MenuDrawer";
import SubMenuDrawer from "../../../Components/Widgets/SubMenuDrawer";
import SingleBanner from "../../default/widgets/sliders/single_banner";
import Grid6Banners from "../../../Components/Widgets/Sliders/Grid6Banners";
import Grid4Banners from "../../../Components/Widgets/Sliders/Grid4Banners";
import WomenMenGold from "../../../Components/Widgets/Sliders/WomenMenGold";
import ProductsSlider from "../../../Components/Widgets/Sliders/ProductsSlider";
import Title from "../../default/widgets/title";
import CollectionsSlider from "../../default/widgets/sliders/collections_slider";
import Footer from "../widgets/footer";

const ExportDefault = (props) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        minHeight: "100vh",
      }}
    >
      <NavBar
        {...{
          ...props,
          ...{
            bg_color: "transparent",
          },
        }}
      />
      <MenuDrawer {...props} />
      <SubMenuDrawer {...props} />
      <SingleBanner
        {...{
          ...props,
          ...{
            fscope: "home-top-banner",
            bottom: "30%",
            left: "3%",
            textMaxWidthDesktop: "450px",
            texts: {
              desktop: {
                name: "KARIA COLLECTION",
                title: "Ornate Beauty",
                blurb:
                  "Unveil new designs that exude transcendent elegance, with striking chiseled details, voluminous silhouettes, and majestic stones.",
                button: "Shop Karia",
                url: "/women/collection/karia",
              },
              mobile: {
                name: "KARIA COLLECTION",
                title: "Ornate Beauty",
                blurb:
                  "Unveil new elegant designs with striking chiseled details, voluminous silhouettes, and majestic stones.",
                button: "Shop Karia",
                url: "/women/collection/karia",
              },
            },
          },
        }}
      />
      <ProductsSlider
        {...{
          ...props,
          ...{
            fscope: "home-product-slider",
          },
        }}
      />
      <Grid6Banners
        {...{
          ...props,
          ...{
            fscope: "home-gender-banner",
          },
        }}
      />
      <Grid4Banners
        {...{
          ...props,
          ...{
            fscope: "home-women-types",
          },
        }}
      />
      <WomenMenGold
        {...{
          ...props,
          ...{
            fscope: "home-women-men-gold",
            textMaxWidthDesktop: "450px",
            textMaxWidthMobile: "300px",
          },
        }}
      />
      {/* <SingleBannerWithEffect
        {...{
          ...props,
          ...{
            fscope: "men-collections-single-banner",
            text_color: "#fff",
            top: "50%",
            right: "9%",
            center: "0,-50%",
            titleMaxWidthDesktop: "600px",
            textMaxWidthDesktop: "500px",
            textMaxWidthMobile: "280px",
            top_right_text: "New",
            m_bottom: "5%",
          },
        }}
      /> */}
      <Grid4Banners
        {...{
          ...props,
          ...{
            fscope: "home-men-types",
          },
        }}
      />
      <Title
        {...{
          ...props,
          ...{
            slug: "home-slider-title",
            titleMaxWidthDesktop: "300px",
          },
        }}
      />
      <CollectionsSlider
        {...{
          ...props,
          ...{
            sliders: ["kyma", "karia", "zodiac", "alchemy", "anthos"],
          },
        }}
      />
      <Footer {...props} />
    </Box>
  );
};

export default ExportDefault;
