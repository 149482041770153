import Hook from "./Hook";
import Wishlist from "./Wishlist";
import RecentlyViewed from "./RecentlyViewed";
import Address from "./Address";
import Menu from "./Menu";
import Product from "./Product";
import Order from "./Order";
import User from "./User";
import Auth from "./Auth";
import Url from "./Url";
import Coupon from "./Coupon";

const ExportDefault = {
  Hook,
  Address,
  Menu,
  Wishlist,
  RecentlyViewed,
  Product,
  Order,
  User,
  Auth,
  Url,
  Coupon,
};

export default ExportDefault;
