import Helpers from "../../../modules/Core/Helpers/Helpers";
import MainApi from "../../Api/Main";
import Service from "../Service";

class Address extends Service {
  getAddresses(options) {
    const {
      account = Helpers.Env.getDefaultAccount(),
      repository = Helpers.Env.getDefaultRepository(),
    } = options || {};

    return new Promise((resolve, reject) => {
      MainApi.address.collection(account, repository).then((r) => {
        const result = this.evaluate(r, "addresses");

        if (result) {
          resolve(this);
        } else {
          reject(this);
        }
      });
    });
  }

  getAddress(addressId, options) {
    const {
      account = Helpers.Env.getDefaultAccount(),
      repository = Helpers.Env.getDefaultRepository(),
    } = options || {};

    return new Promise((resolve, reject) => {
      MainApi.address.one(account, repository, addressId).then((r) => {
        const result = this.evaluate(r, "address");

        if (result) {
          resolve(this);
        } else {
          reject(this);
        }
      });
    });
  }

  createAddress(data, options) {
    const {
      account = Helpers.Env.getDefaultAccount(),
      repository = Helpers.Env.getDefaultRepository(),
    } = options || {};

    return new Promise((resolve, reject) => {
      MainApi.address.create(account, repository, data).then((r) => {
        const result = this.evaluate(r);

        if (result) {
          resolve(this);
        } else {
          reject(this);
        }
      });
    });
  }

  updateAddress(addressId, data, options) {
    const {
      account = Helpers.Env.getDefaultAccount(),
      repository = Helpers.Env.getDefaultRepository(),
    } = options || {};

    return new Promise((resolve, reject) => {
      MainApi.address.update(account, repository, addressId, data).then((r) => {
        const result = this.evaluate(r);

        if (result) {
          resolve(this);
        } else {
          reject(this);
        }
      });
    });
  }

  deleteAddress(addressId, options) {
    const {
      account = Helpers.Env.getDefaultAccount(),
      repository = Helpers.Env.getDefaultRepository(),
    } = options || {};

    return new Promise((resolve, reject) => {
      MainApi.address.delete(account, repository, addressId).then((r) => {
        const result = this.evaluate(r, "addresses");

        if (result) {
          resolve(this);
        } else {
          reject(this);
        }
      });
    });
  }
}

export default new Address();
