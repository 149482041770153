import React from "react";
import { styled, useTheme } from "@mui/material/styles";
import {
  Fade,
  CardMedia,
  Radio,
  RadioGroup,
  FormControlLabel,
  Box,
  Link,
  Button,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { Link as RouterLink } from "react-router-dom";

const BpIcon = styled("span")(({ theme }) => ({
  width: "50px",
  height: "2px",
  backgroundColor: "#fff",
  [theme.breakpoints.down("sm")]: {
    width: "35px",
  },
  "&:before": {
    display: "block",
    width: "12px",
    height: "12px",
    backgroundImage:
      "linear-gradient(180deg,hsla(0,0%,100%,.05),hsla(0,0%,100%,0))",
    content: '""',
  },
}));

const BpCheckedIcon = styled(BpIcon)(({ theme }) => ({
  width: "50px",
  height: "4px",
  backgroundColor: "#fff",
  [theme.breakpoints.down("sm")]: {
    width: "35px",
  },
}));

function MyRadio(props) {
  return (
    <Radio
      sx={{
        px: "2px",
        "&:hover": {
          bgcolor: "transparent",
        },
      }}
      disableRipple
      color="default"
      checkedIcon={<BpCheckedIcon />}
      icon={<BpIcon />}
      {...props}
    />
  );
}

export default function TopBannersSlider(props) {
  const { component } = props;
  const theme = useTheme();
  const view = useMediaQuery(theme.breakpoints.up("md")) ? "desktop" : "mobile";

  const devHelper = component.getHelpers("dev");
  const filesUrl = component.getHelpers("env").get("files_url");
  const img_version = component.getHelpers("value").getValue("images-version");
  const sliders = component.getData("default.sliders", []);
  const activeSlide = component.getData("default.active_slide", 0);

  const [timeout, setTimeoutt] = React.useState(0);

  React.useEffect(() => {
    setTimeout(() => {
      setTimeoutt(4000);
    }, 400);
  }, []);

  const {
    top = null,
    bottom = null,
    left = null,
    right = null,
    center = null,
    text_color = "#fff",
    titleMaxWidthDesktop = null,
    titleMaxWidthMobile = null,
    textMaxWidthDesktop = null,
    textMaxWidthMobile = null,
    lazyLoading = null,
  } = component.props;

  const text = (slider, f_color, view) => {
    const { slug = null, url = null } = devHelper.getObjectValue(
      slider,
      "_values"
    );

    return (
      <Box
        sx={{
          backgroundColor: "transparent",
          display: "flex",
          flexDirection: "column",
          px: { xs: 2, md: 0 },
        }}
      >
        {!component
          .trans(`${slug}-small-title_${view}`)
          .includes(`_${view}`) && (
          <Button
            sx={{
              ...theme.custom.titles.subtitle,
              ...{
                color: f_color,
                textAlign: "center",
                mx: "auto",
              },
            }}
            component={RouterLink}
            to={url}
            disableRipple
          >
            {component.trans(`${slug}-small-title_${view}`)}
          </Button>
        )}
        {!component.trans(`${slug}-title_${view}`).includes(`_${view}`) && (
          <Button
            sx={{
              ...theme.custom.titles.title,
              ...{
                color: f_color,
                textAlign: "center",
                maxWidth: {
                  xs: titleMaxWidthMobile ? titleMaxWidthMobile : "unset",
                  md: titleMaxWidthDesktop ? titleMaxWidthDesktop : "unset",
                },
                mx: "auto",
              },
            }}
            component={RouterLink}
            to={url}
            disableRipple
          >
            {component.trans(`${slug}-title_${view}`)}
          </Button>
        )}
        {!component
          .trans(`${slug}-description_${view}`)
          .includes(`_${view}`) && (
          <Typography
            component="h2"
            sx={{
              ...theme.custom.titles.description,
              ...{
                color: f_color,
                textAlign: "center",
                maxWidth: {
                  xs: textMaxWidthMobile ? textMaxWidthMobile : "unset",
                  md: textMaxWidthDesktop ? textMaxWidthDesktop : "unset",
                },
                mx: "auto",
              },
            }}
          >
            {component.trans(`${slug}-description_${view}`)}
          </Typography>
        )}
        {!component.trans(`${slug}-button_${view}`).includes(`_${view}`) && (
          <Button
            sx={{
              ...theme.custom.buttons.button1,
              ...{
                color: f_color,
                border: "1px solid " + f_color,
                mx: "auto",
                mt: 2,
              },
            }}
            component={RouterLink}
            to={url}
            disableRipple
          >
            {component.trans(`${slug}-button_${view}`)}
          </Button>
        )}
      </Box>
    );
  };

  return (
    <Box>
      {!component.getData("default.imageReady-0", false) && lazyLoading && (
        <Box
          sx={{
            width: "100vw",
            height: "100vh",
            position: "fixed",
            top: 0,
            left: 0,
            zIndex: 1200,
            bgcolor: "#000",
          }}
        />
      )}
      <Box
        sx={{
          position: "relative",
          height: "inherit",
        }}
      >
        {sliders.map((slider, index) => {
          const { slug, url } = devHelper.getObjectValue(slider, "_values");

          return (
            <Fade key={slug} in={activeSlide === index} timeout={timeout}>
              <Box>
                <Link component={RouterLink} to={url}>
                  <CardMedia
                    component="img"
                    sx={{
                      position: index === 0 ? "relative" : "absolute",
                      top: 0,
                      left: 0,
                      zIndex: 0,
                    }}
                    image={`${filesUrl}/konstantino/sliders/${view}/${slug}.jpg?v=${img_version}`}
                    alt={component.trans(
                      `${devHelper.getObjectValue(
                        slider,
                        "_values.slug"
                      )} image`
                    )}
                    onLoad={() =>
                      component.setData({
                        [`default.imageReady-${index}`]: true,
                      })
                    }
                  />
                </Link>
                <Box
                  sx={{
                    position: "absolute",
                    display: { xs: "none", md: "block" },
                    top: top ? top : "unset",
                    bottom: bottom ? bottom : "unset",
                    left: left ? left : "unset",
                    right: right ? right : "unset",
                    transform: center ? `translate(${center})` : "unset",
                  }}
                >
                  {text(slider, text_color, "desktop")}
                </Box>
                <Box
                  sx={{
                    position: "absolute",
                    display: { xs: "block", md: "none" },
                    bottom: "5%",
                    left: "5%",
                  }}
                >
                  {!component
                    .trans(`${slug}-title_${view}`)
                    .includes(`_${view}`) && (
                    <Button
                      sx={{
                        fontFamily: "Poppins-Regular",
                        color: "#fff",
                        fontSize: { xs: 18, md: 45 },
                        textTransform: "none",
                        lineHeight: 1,
                        textAlign: "center",
                        px: 0,
                        pt: 0,
                        pb: 1,
                        maxWidth: {
                          xs: titleMaxWidthMobile
                            ? titleMaxWidthMobile
                            : "unset",
                          md: titleMaxWidthDesktop
                            ? titleMaxWidthDesktop
                            : "unset",
                        },
                        mx: "auto",
                        "&:hover": {
                          backgroundColor: "transparent",
                        },
                      }}
                      component={RouterLink}
                      to={url}
                      disableRipple
                    >
                      {component.trans(`${slug}-title_${view}`)}
                    </Button>
                  )}
                </Box>
              </Box>
            </Fade>
          );
        })}
        <Box
          sx={{
            backgroundColor: "transparent",
            position: "absolute",
            bottom: { xs: 0, md: 5 },
            left: { xs: 0, md: 20 },
            width: { xs: "100%", md: "unset" },
            textAlign: { xs: "center", md: "unset" },
          }}
        >
          {sliders.length !== 0 && (
            <RadioGroup row value={activeSlide} style={{ display: "block" }}>
              {sliders.map((slider, index) => (
                <FormControlLabel
                  key={"key-" + index}
                  value={index}
                  control={<MyRadio />}
                  onClick={(e) => {
                    component.startInterval(sliders, index);
                  }}
                  id={"radio-" + index}
                  sx={{ mx: 0 }}
                />
              ))}
            </RadioGroup>
          )}
        </Box>
      </Box>
    </Box>
  );
}
