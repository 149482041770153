import Page from "../../../modules/Core/Components/Page";
import templateDefault from "../../templates/default/pages/boutiques";

export default class Boutiques extends Page {
  template = templateDefault;

  onAuth() {
    this.refresh();
  }

  onLoad(data) {
    super.onLoad(data);
    this.getApp().updateWindowTitle(
      `${this.ucfirst("Boutiques")} | ${this.ucfirst("window-title")}`
    );
    this.scrollToTop();
  }
}
