import React from "react";
import { styled } from "@mui/material/styles";
import { Container, Typography, Grid, Box, Link, Button } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";

const Title = styled(Typography)(({ theme }) => ({
  fontFamily: "Poppins-Regular",
  color: "#fff",
  fontSize: "16px",
  lineHeight: "24px",
}));

const StyledImg = styled("img")`
  width: 100vw;
  height: 100vh;
  object-fit: cover;
  position: fixed;
  top: 0;
  left: 0;
  z-index: -2;
`;

export default function Boutiques(props) {
  const { component } = props;
  const filesUrl = component.getHelpers("env").get("files_url");
  const img_version = component.getHelpers("value").getValue("images-version");

  const mainMenu = component.getData("default.mainMenu", []);
  const klocations = mainMenu.find((menu) => menu.slug === "locations");

  return (
    <Container
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        px: 4,
      }}
      disableGutters
      maxWidth="xl"
    >
      <StyledImg
        component="img"
        src={`${filesUrl}/konstantino/assets/locations-backround.jpg?v=${img_version}`}
        alt="background image"
      />
      <Box sx={{ textAlign: "center", mt: "150px" }}>
        <Title variant="h1" sx={{ fontSize: "26px" }}>
          WHERE YOU CAN FIND US
        </Title>
        <Typography
          variant="h6"
          sx={{
            mt: 2,
            mb: 4,
            color: "#fff",
            fontFamily: "Spartan-Medium",
            fontSize: "12px",
          }}
        >
          Tap the county of your choice below to find us in a store near you!
        </Typography>
      </Box>
      <Container
        sx={{
          py: 6,
          borderBottom: "1px solid #fff",
          borderTop: "1px solid #fff",
        }}
        maxWidth="md"
      >
        <Grid
          container
          justifycontent="center"
          direction="row"
          justify="center"
          spacing={{ xs: 1, lg: 2 }}
        >
          {klocations &&
            klocations.subMenu.location &&
            klocations.subMenu.location.map((data) => {
              const { slug, title, url } = data;

              return (
                <Grid
                  key={"klocations-" + slug}
                  item
                  sm={3}
                  xs={6}
                  justifycontent="center"
                >
                  <div
                    style={{
                      position: "relative",
                      width: "100%",
                      height: 0,
                      paddingBottom: "100%",
                    }}
                  >
                    <div
                      style={{
                        position: "absolute",
                        left: 0,
                        right: 0,
                        top: 0,
                        bottom: 0,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <Button
                        component={RouterLink}
                        to={url}
                        sx={{
                          width: "100%",
                          height: "100%",
                          color: "#000",
                          backgroundColor: "#fff",
                          textAlign: "center",
                          border: "2px solid #000",
                          borderRadius: "0px",
                          fontSize: "15px",
                          fontWeight: "600",
                          "&:hover,&:focus": {
                            color: "#fff",
                            backgroundColor: "#000",
                          },
                        }}
                      >
                        {title}
                      </Button>
                    </div>
                  </div>
                </Grid>
              );
            })}
        </Grid>
      </Container>
      <Box sx={{ textAlign: "center", mt: 4 }}>
        <Title variant="h1" sx={{ fontSize: "26px" }}>
          OUR BOUTIQUES &amp; CONTACT INFORMATION
        </Title>
        <Typography
          variant="h6"
          sx={{
            mt: 2,
            mb: 4,
            mx: "auto",
            color: "#fff",
            fontFamily: "Spartan-Medium",
            fontSize: "12px",
            maxWidth: "400px",
          }}
        >
          We invite you to shop our jewelry creations at one of our Greek
          boutiques in Athens and Mykonos.
        </Typography>
      </Box>
      <Grid
        container
        justifycontent="center"
        direction="row"
        justify="center"
        sx={{ px: { xs: 0, md: 8 }, mb: 4 }}
        rowSpacing={{ xs: 3, md: 0 }}
      >
        <Grid
          item
          md={4}
          xs={12}
          justifycontent="center"
          sx={{
            textAlign: "center",
          }}
        >
          <Title sx={{ color: "#fff", lineHeight: "16px" }}>
            KONSTANTINO ATHENS (Flagship Store)
          </Title>
          <Link
            href="https://www.google.com/maps/place/KONSTANTINO+JEWELRY+-+ATHENS/@37.9756587,23.7276433,19.63z/data=!4m5!3m4!1s0x14a1bd3d320b57ad:0x62993108934b74eb!8m2!3d37.9756326!4d23.7278967"
            rel="noreferrer"
            target="_blank"
            underline="none"
            sx={{
              fontFamily: "Spartan-Medium",
              color: "#fff",
              fontSize: "12px",
              lineHeight: "16px",
              display: "inline-block",
              mt: "6px",
            }}
          >
            20, Pandrossou Str. Plaka
            <br />
            ATHENS, GREECE
          </Link>
          <Box sx={{ mt: -1 }}>
            <span
              style={{
                color: "#fff",
                fontSize: "12px",
                fontFamily: "Spartan-SemiBold",
                marginRight: "4px",
              }}
            >
              Tel:
              <Link
                href="tel:+302103222701"
                underline="none"
                sx={{
                  fontFamily: "Spartan-SemiBold",
                  color: "#fff",
                  fontSize: "12px",
                  pl: "4px",
                }}
              >
                +30 210.322.2701
              </Link>
            </span>
            <span
              style={{
                color: "#fff",
                fontSize: "12px",
                fontFamily: "Spartan-SemiBold",
              }}
            >
              Email:
              <Link
                href="mailto:konstantino.gr@gmail.com"
                target="_blank"
                underline="none"
                sx={{
                  fontFamily: "Spartan-SemiBold",
                  color: "#fff",
                  fontSize: "12px",
                  pl: "4px",
                }}
              >
                K-Athens
              </Link>
            </span>
          </Box>
        </Grid>
        <Grid
          item
          md={4}
          xs={12}
          justifycontent="center"
          sx={{
            textAlign: "center",
          }}
        >
          <Title sx={{ color: "#fff", lineHeight: "16px" }}>
            KONSTANTINO MYKONOS
          </Title>
          <Link
            href="https://www.google.com/maps/place/KONSTANTINO+JEWELRY+-+MYKONOS/@37.444966,25.3255058,17z/data=!3m1!4b1!4m5!3m4!1s0x14a2bf0c301477df:0x7070d7c505b2b4a!8m2!3d37.4449618!4d25.3276945"
            rel="noreferrer"
            target="_blank"
            underline="none"
            sx={{
              fontFamily: "Spartan-Medium",
              color: "#fff",
              fontSize: "12px",
              lineHeight: "14px",
              display: "inline-block",
              mt: "6px",
            }}
          >
            Enoplon Dynameon Str.
            <br />
            MYKONOS, GREECE
          </Link>
          <Box sx={{ mt: -1 }}>
            <span
              style={{
                color: "#fff",
                fontSize: "12px",
                fontFamily: "Spartan-SemiBold",
                marginRight: "4px",
              }}
            >
              Tel:
              <Link
                href="tel:+302289023762"
                underline="none"
                sx={{
                  fontFamily: "Spartan-SemiBold",
                  color: "#fff",
                  fontSize: "12px",
                  pl: "4px",
                }}
              >
                +30 2289.023.762
              </Link>
            </span>
            <span
              style={{
                color: "#fff",
                fontSize: "12px",
                fontFamily: "Spartan-SemiBold",
              }}
            >
              Email:
              <Link
                href="mailto:mykonos@konstantino-jewelry.com"
                target="_blank"
                underline="none"
                sx={{
                  fontFamily: "Spartan-SemiBold",
                  color: "#fff",
                  fontSize: "12px",
                  pl: "4px",
                }}
              >
                K-Mykonos
              </Link>
            </span>
          </Box>
        </Grid>
        <Grid
          item
          md={4}
          xs={12}
          justifycontent="center"
          sx={{
            textAlign: "center",
          }}
        >
          <Title sx={{ color: "#fff", lineHeight: "16px" }}>
            KONSTANTINO US Contact Direct
          </Title>
          {/* <span
            style={{
              color: "#fff",
              fontSize: "12px",
              fontFamily: "Spartan-SemiBold",
              marginRight: "4px",
            }}
          >
            Tel:
            <Link
              href="tel:+14144532857"
              underline="none"
              sx={{
                fontFamily: "Spartan-SemiBold",
                color: "#fff",
                fontSize: "12px",
                pl: "4px",
              }}
            >
              +1 (414) 453-2857
            </Link>
          </span> */}
          <span
            style={{
              color: "#fff",
              fontSize: "12px",
              fontFamily: "Spartan-SemiBold",
            }}
          >
            Email:
            <Link
              href="mailto:info@konstantino.com"
              underline="none"
              target="_blank"
              sx={{
                fontFamily: "Spartan-SemiBold",
                color: "#fff",
                fontSize: "12px",
                pl: "4px",
              }}
            >
              K-U.S.A
            </Link>
          </span>
        </Grid>
      </Grid>
    </Container>
  );
}
