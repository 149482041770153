import { Component } from "../../../modules/Core/Component";
import templateDefault from "../../templates/default/widgets/collection/collection";
import Services from "../../Services/Services";

export default class Collection extends Component {
  id = "collection";
  group = "order";
  pageAmount = 12;

  template = templateDefault;

  loadCollection(clear) {
    const { type, value } = this.getPage().getParams();

    const pageGender = this.getGender();
    const term = this.getSearchTerm();
    this.getApp().updateWindowTitle(
      this.ucfirst(
        term
          ? `Search Results For ${decodeURIComponent(term)} | ${this.ucfirst(
              "window-title"
            )}`
          : `${this.ucfirst(
              `${pageGender}-${type}-${value}-title_desktop`
            )} | ${this.ucfirst("window-title")}`
      )
    );

    const collectionAmount =
      this.getHelpers("value").getValue("collection-amount");

    if (collectionAmount) {
      this.pageAmount = parseInt(collectionAmount);
    }

    Services.get("hook").then(async ([hookService]) => {
      const types = await hookService.exec("types");
      const gems = await hookService.exec("gems");
      const sizes = await hookService.exec("sizes");
      const collections = await hookService.exec("collections");
      const metals = await hookService.exec("metals");
      const curations = await hookService.exec("curations");
      const prices = await hookService.exec("price-ranges");

      this.setData({
        "default.collections": collections.getData() || [],
        "default.types": types.getData() || [],
        "default.gems": gems.getData() || [],
        "default.sizes": sizes.getData() || [],
        "default.metals": metals.getData() || [],
        "default.curations": curations.getData() || [],
        "default.price_ranges": prices.getData() || [],
        "default.disabledFilters": [],
      });
    });

    if (clear) {
      this.setData({
        "default.products": [],
        // "default.fetchable": false,
      });
    }

    this.disableFilters();
    this.getOrder();
    this.loadFilters();
    this.execFetchProducts();

    this.setData({
      "default.pageType": type,
      "default.pageValue": value,
      "default.layouts": [
        {
          slug: "products",
          items: 3,
          img: "",
        },
        {
          slug: "products",
          items: 3,
          img: "",
        },
        // {
        //   slug: "full_bannner",
        //   items: 0,
        //   img: "/images/delos/Delos-Selection-Assets-11.jpg",
        // },
        {
          slug: "products",
          items: 200,
          img: "",
        },
      ],
    });

    if (clear) {
      const saveScrollPosition = localStorage.getItem("saveScrollPosition") * 1;

      if (!saveScrollPosition) {
        window.scrollTo({ top: 0 });
      }
    }
  }

  loadFilters() {
    if (window.location.search) {
      const {
        pageAmount,
        pageNumber = 0,
        sortBy,
        // type,
        gender,
        ...search
      } = this.queryParamsToObject(window.location.search);

      for (let i in search) {
        search[i] = search[i].split(",");
      }

      if (sortBy) {
        search["sortBy"] = sortBy.split(",");
      }

      this.setData({
        "default.activeFilters": search,
        "default.params": {
          pageAmount,
          pageNumber,
        },
      });
    } else {
      this.setData({
        "default.activeFilters": {},
        "default.params": {
          pageAmount: this.pageAmount,
          pageNumber: 0,
        },
      });
    }
  }

  disableFilters() {
    let { type } = this.getPage().getParams();

    if (type === "gift") {
      type = "price";
    }

    this.setData({ "default.disabledFilters": [type] });
    return this;
  }

  prepareFilters(filters) {
    const buildFilters = [];
    const disabledFilters = this.getData("default.disabledFilters", []);

    for (let type in filters) {
      const disabled = disabledFilters.find((filter) => filter === type)
        ? true
        : false;

      if (!disabled && filters[type].length) {
        buildFilters.push({
          type,
          values: filters[type],
        });
      }
    }

    return buildFilters;
  }

  getOrder() {
    Services.get("order").then(([orderService]) => {
      orderService
        .fetchOrder()
        .then((orderService) => {
          const order = orderService.getData("order");
          this.setData({ "default.order": order });
        })
        .catch((orderService) => {});
    });
  }

  allTypes() {
    this.clearFiltersAndFetch();
    return this;
  }

  showTypes() {
    const { type } = this.getPage().getParams();
    return type !== "type";
  }

  getGender() {
    const pathname = document.location.pathname.split("/");

    pathname.shift();

    if (pathname.length) {
      return pathname.shift();
    }

    return null;
  }

  isOnType(type) {
    return this.getData("default.activeFilters.type", []).find(
      (filter) => filter === type
    )
      ? true
      : false;
  }

  sortBy(sortBy) {
    this.setData({
      "default.activeFilters.sortBy": sortBy.split("-"),
    });
    this.fetchProducts();
    return this;
  }

  selectType(type) {
    this.clearFilters();
    this.setData({ "default.activeFilters.type": [type] });
    this.fetchProducts();
    return this;
  }

  fetchProducts(more) {
    const params = this.buildParams(more);

    this.getPage().redirect(
      [window.location.pathname, this.objectToQueryParams(params)].join("?")
    );
  }

  buildParams(more) {
    const params = this.prepareParams(more);
    const activeFilters = this.getData("default.activeFilters", {});

    if (this.getSearchTerm()) {
      params["term"] = this.getSearchTerm();
    }

    for (let type in activeFilters) {
      if (activeFilters[type]) {
        const value = activeFilters[type];
        params[type] = Array.isArray(value) ? value.join(",") : value;
      }
    }

    return params;
  }

  execFetchProducts(more) {
    const params = this.buildParams(more);
    // const { pageNumber } = params;
    // const { sy = 0 } = this.queryParamsToObject(window.location.search);

    this.setData({ "default.priceRange.value": [] });
    this.setData(
      { "default.loading": true },
      {
        cb: () => {
          Services.get("hook").then(async ([hookService]) => {
            const products = await hookService.exec("products", {
              params,
            });

            let {
              data = [],
              filters = [],
              total = 0,
            } = products.getData() || {};

            // if (pageNumber * 1 > 0) {
            // data = this.getData("default.products", []).concat(data);
            // }

            this.setData(
              {
                "default.products": data,
                "default.filters": this.prepareFilters(filters),
                "default.total": total,
                "default.loading": false,
              },
              {
                cb: () => {
                  const saveScrollPosition =
                    localStorage.getItem("saveScrollPosition") * 1;

                  const scrollList = localStorage.getItem("scrollList");
                  const scrollProduct = localStorage.getItem("scrollProduct");

                  if (saveScrollPosition) {
                    if (scrollList === scrollProduct) {
                      setTimeout(() => {
                        window.scrollTo(0, saveScrollPosition);
                      }, 50);
                    }
                  }

                  localStorage.removeItem("saveScrollPosition");
                  localStorage.removeItem("scrollList");
                  localStorage.removeItem("scrollProduct");
                },
              }
            );
          });
        },
      }
    );

    return this;
  }

  objectToQueryParams(obj) {
    return Object.keys(obj)
      .map(
        // (key) => `${encodeURIComponent(key)}=${encodeURIComponent(obj[key])}`
        (key) =>
          `${encodeURIComponent(key)}=${
            Array.isArray(obj[key]) ? obj[key].join(",") : obj[key]
          }`
      )
      .join("&");
  }

  queryParamsToObject(queryString) {
    const pairs = (
      queryString[0] === "?" ? queryString.substr(1) : queryString
    ).split("&");
    return pairs.reduce((accumulator, pair) => {
      const [key, value] = pair.split("=").map(decodeURIComponent);
      accumulator[key] = value;
      return accumulator;
    }, {});
  }

  getSearchTerm() {
    return this.getPage().getQuery("term", "");
  }

  prepareParams() {
    // prepareParams(more) {
    let pageAmount = this.pageAmount;
    // console.log(this.getData("default.params.pageNumber", 0));
    let pageNumber = this.getData("default.params.pageNumber", 0);
    const { type, value } = this.getPage().getParams();

    // if (!more) {
    //   this.setData({ "default.params.pageNumber": 0 });
    //   pageNumber = 0;
    // }

    // pageNumber += 1;

    const gender = this.getGender();

    if (gender !== "search") {
    }

    const persistentFilters = {};

    if (gender !== "search") {
      persistentFilters["default.activeFilters.gender"] = [gender];
    }

    if (type === "gift") {
      persistentFilters["default.activeFilters.gift"] = [1];
      persistentFilters["default.activeFilters.price"] = [
        `0,${value.split("-").pop()}`,
      ];
    } else if (type) {
      persistentFilters[`default.activeFilters.${type}`] = [value];
    }

    this.setData(persistentFilters);

    return { pageAmount, pageNumber };
  }

  fetchMore() {
    const pageNumber = this.getData("default.params.pageNumber", 0) * 1;
    this.setData({ "default.params.pageNumber": pageNumber + 1 });
    this.fetchProducts(true);
  }

  canFetchMore() {
    const pageNumber = this.getData("default.params.pageNumber", 0) * 1;
    const total = this.getData("default.total", 0);
    // const fetchable = this.getData("default.fetchable", false);
    // return fetchable && pageNumber * this.pageAmount + this.pageAmount < total;
    return pageNumber * this.pageAmount + this.pageAmount < total;
  }

  getMoreAmount() {
    const pageNumber = this.getData("default.params.pageNumber", 0) * 1;
    const total = this.getData("default.total", 0);
    const more = total - (pageNumber * this.pageAmount + this.pageAmount);

    return more < this.pageAmount ? more : this.pageAmount;
  }

  toggleFilter(type, item) {
    const { _values = {} } = item;
    const { slug } = _values;
    const activeFilters = this.getData("default.activeFilters", {});
    let activeFilter = null;

    if (!activeFilters[type]) {
      activeFilters[type] = [];
    }

    activeFilter = activeFilters[type];

    if (activeFilter.find((filter) => filter === slug)) {
      activeFilters[type] = activeFilter.filter((filter) => filter !== slug);
    } else {
      activeFilter.push(slug);
    }

    this.setData({ "default.activeFilters": activeFilters });

    this.fetchProducts();
  }

  getFilterAmount(type, item) {
    const { _values = {} } = item;
    const filter = this.getData("default.filters", []).find(
      (filter) => filter.type === type
    );

    let count = 0;

    if (filter) {
      const { values } = filter;
      const filterValue = values.find((value) => value._id === _values.slug);

      if (filterValue) {
        count = filterValue.count;
      }
    }

    return count;
  }

  clearFilters() {
    this.setData({ "default.activeFilters": {} });
    return this;
  }

  clearFiltersAndFetch() {
    this.clearFilters().fetchProducts();
    return this;
  }

  isFilterSelected(type, item) {
    const { _values = {} } = item;
    const { slug } = _values;
    let selected = false;

    const activeFilters = this.getData("default.activeFilters", {});

    if (activeFilters[type]) {
      selected = activeFilters[type].find((filter) => filter === slug)
        ? true
        : false;
    }

    return selected;
  }

  getPriceRange(filter) {
    const { values = [] } = filter;
    const filtered = this.getData("default.priceRange.value", []);

    if (filtered.length) {
      return [filtered[0], filtered[1]];
    }

    return values.length ? [values[0].minPrice, values[0].maxPrice] : [0, 0];
  }

  getMinPrice(filter) {
    const { values = [] } = filter;
    return values.length ? values[0].minPrice : 0;
  }

  getMaxPrice(filter) {
    const { values = [] } = filter;
    return values.length ? values[0].maxPrice : 0;
  }

  changePriceRange(priceRange, doFilter) {
    this.setData({
      "default.activeFilters.price": priceRange,
      "default.priceRange.value": priceRange,
    });

    if (doFilter) {
      this.fetchProducts();
    }
  }

  typeIsVisible(type) {
    const { _values = {} } = type;
    const { values = [] } =
      this.getData("default.filters", []).find(({ type }) => type === "type") ||
      {};

    return values.find(({ _id }) => _id === _values.slug);
  }

  showBanners() {
    const { activeFilters = {} } = this.getData("default", {});

    return Object.keys(activeFilters).length <= 2;
  }
}
