import React, { useLayoutEffect, useState, useRef, useEffect } from "react";
import { styled, useTheme } from "@mui/material/styles";
import {
  Button,
  Container,
  Box,
  CardMedia,
  Typography,
  FormControl,
  Select,
  Radio,
  RadioGroup,
  FormControlLabel,
  Skeleton,
  InputLabel,
  MenuItem,
  Grid,
  Link,
  Accordion as MuiAccordion,
  AccordionSummary as MuiAccordionSummary,
  AccordionDetails as MuiAccordionDetails,
} from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import SwipeableViews from "react-swipeable-views";
import NotifyMe from "../../../../Components/Widgets/Dialogs/NotifyMe";
import Inquire from "../../../../Components/Widgets/Dialogs/Inquire";
import Zoom from "../../../../Components/Widgets/Zoom";
import { autoPlay } from "react-swipeable-views-utils";
import Translate from "../../../../../modules/Core/Components/Translate";

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

const BpIcon = styled("span")(({ theme }) => ({
  width: "40px",
  height: "2px",
  backgroundColor: "#fff",
  [theme.breakpoints.down("sm")]: {
    width: "30px",
  },
  "&:before": {
    display: "block",
    width: "12px",
    height: "12px",
    backgroundImage:
      "linear-gradient(180deg,hsla(0,0%,100%,.05),hsla(0,0%,100%,0))",
    content: '""',
  },
}));

const BpCheckedIcon = styled(BpIcon)(({ theme }) => ({
  width: "40px",
  height: "2px",
  backgroundColor: "#000",
  [theme.breakpoints.down("sm")]: {
    width: "30px",
  },
}));

function MyRadio(props) {
  return (
    <Radio
      sx={{
        px: "2px",
        "&:hover": {
          bgcolor: "transparent",
        },
      }}
      disableRipple
      color="default"
      checkedIcon={<BpCheckedIcon />}
      icon={<BpIcon />}
      {...props}
    />
  );
}

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  "&:not(:last-child)": {
    borderBottom: 0,
    m: 0,
  },
  "&:before": {
    display: "none",
  },
  "& .MuiButtonBase-root": { padding: 0 },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={
      <i
        className="fa-regular fa-chevron-down"
        style={{ fontSize: "18px", color: "#000" }}
      ></i>
    }
    {...props}
  />
))(({ theme }) => ({
  border: "none",
  p: 0,
  fontFamily: "Spartan-SemiBold",
  fontSize: "15px",
  color: "#000",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(-90deg)",
    border: "none",
    p: 0,
  },
  "& .MuiAccordionSummary-content": {
    border: "none",
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: "0 16px 16px",
}));

export default function FirstSection(props) {
  const { component } = props;
  const theme = useTheme();

  const devHelper = component.getHelpers("dev");
  const filesUrl = component.getHelpers("env").get("files_url");

  const product = component.getData("default.product", null);

  const [notifyMeOpen, setNotifyMeOpen] = React.useState(false);
  const [inquireOpen, setInquireOpen] = React.useState(false);

  const [activeImage, setActiveImage] = React.useState(0);

  const images = component.getData("default.images", []);

  const wishlist = component.getData("default.wishlist", false);
  const in_stock = devHelper.getObjectValue(product, "in_stock");
  const gems = devHelper.getObjectValue(product, "_values.gems", []);
  const sizes = devHelper.getObjectValue(product, "_values.sizes", []);
  const slug = devHelper.getObjectValue(product, "_values.slug");
  const collections = devHelper.getObjectValue(
    product,
    "_values.collections",
    []
  );
  const genders = devHelper.getObjectValue(product, "_values.genders", []);

  var collection = collections[0] !== undefined ? collections[0] : null;
  var gender = genders[0] !== undefined ? genders[0] : null;

  //Add or Remove product from Wishlist
  const updateWishList = (boolean) => {
    boolean
      ? component.addToWishList(slug)
      : component.removeFromWishList(slug);

    component.setData("default.wishlist", true);
  };

  //Change product
  const changeProduct = (gem) => {
    var gemValues = gem.item._values;

    var new_sku = gemValues.sizes.length
      ? gemValues.sizes[0].sku
      : gemValues.default.sku;

    setActiveImage(0);

    component.getPage().redirect(`/product/${slug}/${new_sku}`);
  };

  //Change slider image
  const handleImageChange = (step) => {
    setActiveImage(step);
  };

  const targetRef = useRef();

  function SetImageHeight() {
    const [height, setHeight] = useState(0);
    useLayoutEffect(() => {
      function updateSize() {
        if (targetRef.current) {
          setHeight(900 * (targetRef.current.clientWidth / 720));
        }
      }
      window.addEventListener("resize", updateSize);
      updateSize();
      return () => window.removeEventListener("resize", updateSize);
    }, []);
    return height;
  }

  const img_height = SetImageHeight();

  const ready = component.getData("default.ready", false);

  useEffect(() => {
    if (product) {
      const images = document.getElementById("images");
      const pw = document.getElementById("product-info-wrapper");
      const ph = document.getElementById("product-info-holder");
      const pi = document.getElementById("product-info");
      const marginTop = 133;
      let prevScroll = 0;
      let doInnerScroll = true;

      const onScroll = () => {
        const isOnTop = window.scrollY === 0;

        if (
          window.scrollY + window.innerHeight >=
          images.offsetHeight + marginTop
        ) {
          pw.style.top = `${
            images.offsetHeight + marginTop - pw.offsetHeight
          }px`;
          pw.style.position = "absolute";
          doInnerScroll = false;
        } else {
          pw.style.top = `${marginTop}px`;
          pw.style.position = "fixed";
          doInnerScroll = true;
        }

        const newScroll = window.scrollY - prevScroll;
        const downwards = newScroll > 0;
        prevScroll = window.scrollY;

        const piTop = Math.abs(parseInt(pi.style.top || 0));

        doInnerScroll =
          doInnerScroll && (pi.offsetHeight > ph.offsetHeight || piTop !== 0);

        if (doInnerScroll) {
          if (
            (downwards &&
              piTop < Math.abs(pi.offsetHeight - ph.offsetHeight)) ||
            (!downwards && piTop > 0)
          ) {
            pi.style.top = isOnTop ? 0 : `-${piTop + newScroll}px`;
          }
        }
      };

      const onResize = () => {
        pw.style.width = `${images.offsetWidth}px`;

        if (window.innerWidth > 600) {
          pw.style.height = `${window.innerHeight - marginTop}px`;
          pw.style.position = `fixed`;
          ph.style.position = `relarive`;
          pi.style.position = `absolute`;
          pw.style.overflow = `hidden`;

          window.removeEventListener("scroll", onScroll, false);
          window.addEventListener("scroll", onScroll);
          onScroll();
        } else {
          pw.style.width = `initial`;
          pw.style.height = `initial`;
          pw.style.position = `initial`;
          ph.style.position = `initial`;
          pi.style.position = `initial`;
          window.removeEventListener("scroll", onScroll, false);
        }
      };

      window.addEventListener("resize", onResize);

      onResize();

      return () => {
        localStorage.setItem("scrollProduct", window.location.pathname);

        window.removeEventListener("scroll", onScroll, false);
        window.removeEventListener("resize", onResize, false);
      };
    }
  }, [product]);

  return (
    <Container
      maxWidth="xl"
      disableGutters
      sx={{ px: { xs: 0, sm: 4 }, py: { xs: 0, sm: 4 } }}
    >
      <Grid
        container
        sx={{
          display: "flex",
        }}
        rowSpacing={{ xs: 2, sm: 4 }}
        columnSpacing={{ xs: 0, sm: 4 }}
      >
        <Grid item sm={6} xs={12} justifycontent="center">
          <Box ref={targetRef}>
            {img_height > 0 && (
              <Box id="images">
                <Box
                  sx={{
                    position: "relative",
                    height: img_height,
                    width: "100%",
                  }}
                >
                  {!ready && (
                    <Skeleton
                      variant="rectangular"
                      width="100%"
                      height="100%"
                      sx={{ position: "absolute", top: 0, left: 0 }}
                    />
                  )}
                  {images.length !== 0 && (
                    <Box
                      sx={{
                        position: "absolute",
                        bottom: 0,
                        left: 0,
                        zIndex: 1,
                        width: "100%",
                        textAlign: "center",
                      }}
                    >
                      <RadioGroup
                        row
                        value={activeImage}
                        sx={{ display: "block" }}
                      >
                        {images.map((image, index) => (
                          <FormControlLabel
                            key={`radio-btn-${devHelper.getObjectValue(
                              image,
                              "name"
                            )}`}
                            value={index}
                            control={<MyRadio />}
                            onClick={(e) => {
                              handleImageChange(index);
                            }}
                            sx={{ m: 0 }}
                            aria-label={`Button for image ${index + 1}`}
                          />
                        ))}
                      </RadioGroup>
                    </Box>
                  )}
                  <AutoPlaySwipeableViews
                    axis={theme.direction === "rtl" ? "x-reverse" : "x"}
                    index={activeImage}
                    onChangeIndex={handleImageChange}
                    enableMouseEvents
                    interval={3000}
                  >
                    {images.length !== 0 ? (
                      images.map((image, index) => (
                        <Box
                          key={"product-img-" + index}
                          sx={{ height: "100%" }}
                        >
                          <CardMedia
                            style={{
                              maxWidth: "100%",
                              cursor: "pointer",
                            }}
                            component="img"
                            image={
                              component.getData(
                                `default.img-error-product-${index}`,
                                false
                              )
                                ? filesUrl.concat(
                                    `/konstantino/assets/product-not-found.jpg`
                                  )
                                : filesUrl.concat(
                                    `/konstantino/products/${devHelper.getObjectValue(
                                      image,
                                      "name"
                                    )}`
                                  )
                            }
                            alt={devHelper.getObjectValue(image, "name")}
                            onLoad={() => {
                              component.setData({ "default.ready": true });
                            }}
                            onError={() => {
                              component.setData({
                                [`default.img-error-product-${index}`]: true,
                              });
                            }}
                            onClick={() =>
                              component.toggleZoom(images, activeImage)
                            }
                          />
                        </Box>
                      ))
                    ) : (
                      <Box sx={{ height: "100%" }}>
                        <CardMedia
                          style={{
                            maxWidth: "100%",
                            cursor: "pointer",
                          }}
                          component="img"
                          image={filesUrl.concat(
                            `/konstantino/assets/product-not-found.jpg`
                          )}
                          alt="product-not-found"
                          onLoad={() => {
                            component.setData({ "default.ready": true });
                          }}
                          onClick={() =>
                            component.toggleZoom(images, activeImage)
                          }
                        />
                      </Box>
                    )}
                  </AutoPlaySwipeableViews>
                </Box>
                <Box
                  sx={{
                    display: { xs: "none", sm: "flex" },
                  }}
                  className="images_list"
                >
                  <Box
                    sx={{
                      position: "relative",
                    }}
                    className="images_list"
                  >
                    {images.length !== 0 && (
                      <Box
                        sx={{
                          position: "absolute",
                          left: 30,
                          top: 30,
                          color: "#000",
                          zIndex: "1",
                          fontFamily: "Spartan-SemiBold",
                          fontSize: "15px",
                        }}
                      >
                        {"1 of " + images.length}
                      </Box>
                    )}
                    {images.length !== 0 &&
                      images.map((image, index) => (
                        <Box
                          key={"menu_img-" + index}
                          sx={{
                            position: "relative",
                            height: img_height,
                            width: "100%",
                            cursor: "pointer",
                            mt: 1,
                          }}
                        >
                          {!component.getData(
                            `default.ready-side-product-${index}`,
                            false
                          ) && (
                            <Skeleton
                              variant="rectangular"
                              width="100%"
                              height="100%"
                              sx={{ position: "absolute", top: 0, left: 0 }}
                            />
                          )}
                          <CardMedia
                            component="img"
                            image={
                              component.getData(
                                `default.img-error-side-product-${index}`,
                                false
                              )
                                ? filesUrl.concat(
                                    `/konstantino/assets/product-not-found.jpg`
                                  )
                                : filesUrl.concat(
                                    `/konstantino/products/${devHelper.getObjectValue(
                                      image,
                                      "name"
                                    )}`
                                  )
                            }
                            alt={devHelper.getObjectValue(image, "name")}
                            style={{
                              maxWidth: "100%",
                              height: "100%",
                            }}
                            onClick={(e) => {
                              handleImageChange(index);
                            }}
                            onLoad={() => {
                              component.setData({
                                [`default.ready-side-product-${index}`]: true,
                              });
                            }}
                            onError={() => {
                              component.setData({
                                [`default.img-error-side-product-${index}`]: true,
                              });
                            }}
                          />
                        </Box>
                      ))}
                  </Box>
                </Box>
              </Box>
            )}
          </Box>
        </Grid>
        <Grid
          item
          sm={6}
          xs={12}
          justifycontent="center"
          sx={{ px: { xs: 1, sm: 0 } }}
        >
          {product && (
            <Box
              id="product-info-wrapper"
              style={{
                position: "relative",
                overflow: "auto",
              }}
            >
              <Box
                id="product-info-holder"
                style={{ position: "relative", width: "100%", height: "100%" }}
              >
                <Box
                  id="product-info"
                  style={{ position: "absolute", width: "100%" }}
                >
                  <Box
                    sx={{
                      width: "100%",
                      textAlign: "center",
                      borderBottom: "5px solid #000",
                      px: 1,
                    }}
                  >
                    {collection && (
                      <Button
                        sx={{
                          fontFamily: "Spartan-SemiBold",
                          fontSize: { xs: 14, md: 14 },
                          padding: 0,
                          "&:hover, &:focus": {
                            background: "transparent",
                          },
                        }}
                        component={RouterLink}
                        to={
                          gender
                            ? "/" +
                              devHelper.getObjectValue(
                                gender,
                                "item._values.slug"
                              ) +
                              "/collection/" +
                              devHelper.getObjectValue(
                                collection,
                                "item._values.slug"
                              )
                            : "/"
                        }
                        disableRipple
                      >
                        {devHelper.getObjectValue(
                          collection,
                          "item._values.name"
                        )}
                      </Button>
                    )}
                    <Typography
                      sx={{
                        fontFamily: "Poppins-Regular",
                        fontSize: { xs: 22, md: 25 },
                        textTransform: "none",
                        padding: 0,
                        mb: "10px",
                        color: "#000",
                        lineHeight: { xs: "26px", md: "26px" },
                      }}
                    >
                      {devHelper.getObjectValue(product, "_values.name")}
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      mt: 1,
                      px: 1,
                    }}
                  >
                    <Typography
                      sx={{
                        fontFamily: "Poppins-Regular",
                        fontSize: { xs: 22, md: 25 },
                        color: "#000",
                      }}
                    >
                      {component
                        .getApp()
                        .priceFormater(
                          devHelper.getObjectValue(product, "_values.price")
                        )}
                    </Typography>
                    <Button
                      disableRipple
                      id="heart"
                      onClick={(e) => {
                        updateWishList(!wishlist);
                      }}
                      sx={{
                        fontSize: { xs: "25px", md: "25px" },
                        "&:hover": {
                          background: "transparent",
                        },
                      }}
                      aria-label="Add to Wishlist"
                    >
                      <i
                        className={
                          wishlist ? "fa-solid fa-heart" : "fa-light fa-heart"
                        }
                      ></i>
                    </Button>
                  </Box>
                  {/* Gemstones */}
                  {gems.length !== 0 && !product.simple && (
                    <Box sx={{ mt: 1, px: 1 }}>
                      <Typography
                        sx={{
                          fontFamily: "Spartan-SemiBold",
                          fontSize: { xs: 12, md: 14 },
                          mb: 1,
                          color: "#000",
                        }}
                      >
                        {devHelper.getObjectValue(product, "_values.colorized")
                          ? "COLOR"
                          : "GEMSTONE"}
                      </Typography>
                      <Box sx={{ mb: 1 }}>
                        {gems.map((gem, index) => (
                          <Box
                            key={"gem-" + slug + "-" + index}
                            sx={{
                              position: "relative",
                              cursor: "pointer",
                              width: "35px",
                              height: "35px",
                              display: "inline-block",
                              margin: "0px 3px",
                              borderRadius: "50px",
                              padding: "3px",
                              border:
                                devHelper.getObjectValue(
                                  product,
                                  "active_gem"
                                ) === index
                                  ? "1px solid #000"
                                  : "1px solid transparent",
                            }}
                          >
                            {!component.getData(
                              `default.ready-gem-${index}`,
                              false
                            ) && (
                              <Skeleton
                                variant="rectangular"
                                width="100%"
                                height="100%"
                                sx={{
                                  position: "absolute",
                                  top: 0,
                                  left: 0,
                                  borderRadius: "50px",
                                }}
                              />
                            )}
                            <CardMedia
                              component="img"
                              image={
                                component.getData(
                                  `default.img-error-gem-${index}`,
                                  false
                                )
                                  ? filesUrl.concat(
                                      `/konstantino/assets/gem-not-found.png`
                                    )
                                  : filesUrl.concat(
                                      `/konstantino/gems/${devHelper.getObjectValue(
                                        gem,
                                        "item._values.slug"
                                      )}.png`
                                    )
                              }
                              title={devHelper.getObjectValue(
                                gem,
                                "item._values.name"
                              )}
                              onClick={() => {
                                changeProduct(gem);
                              }}
                              onLoad={() => {
                                component.setData({
                                  [`default.ready-gem-${index}`]: true,
                                });
                              }}
                              onError={() => {
                                component.setData({
                                  [`default.img-error-gem-${index}`]: true,
                                });
                              }}
                            />
                          </Box>
                        ))}
                      </Box>
                    </Box>
                  )}
                  {/* Sizes */}
                  {sizes.length !== 0 && product.has_size && (
                    <Box sx={{ mt: 1, px: 1 }}>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                          mb: 1,
                        }}
                      >
                        <Typography
                          sx={{
                            fontFamily: "Spartan-SemiBold",
                            fontSize: { xs: 12, md: 14 },
                            color: "#000",
                          }}
                        >
                          SIZE
                        </Typography>
                        <Button
                          sx={{
                            visibility: "hidden",
                            fontFamily: "Spartan-SemiBold",
                            fontSize: { xs: 12, md: 14 },
                            color: "#000",
                            "&:hover, &:focus": {
                              background: "transparent",
                            },
                          }}
                          disableRipple
                          endIcon={
                            <i
                              className="fa-regular fa-chevron-right"
                              style={{ marginTop: "-4px", fontSize: "18px" }}
                            ></i>
                          }
                        >
                          size chart
                        </Button>
                      </Box>
                      <FormControl
                        fullWidth
                        variant="standard"
                        sx={{
                          "& .MuiSelect-select": {
                            "&:focus,&:hover": {
                              backgroundColor: "transparent !important",
                            },
                          },
                        }}
                      >
                        <InputLabel
                          sx={{
                            fontFamily: "Spartan-SemiBold",
                            fontSize: { xs: 12, md: 14 },
                            color: "#000",
                          }}
                        >
                          Choose an option
                        </InputLabel>
                        <Select
                          label="Choose an option"
                          onChange={(e) =>
                            component.changeSize(product, gems, e.target.value)
                          }
                          value={component.getData("default.fields.size", "")}
                          error={component.dataExists("error.fields.size")}
                          IconComponent={(props) => (
                            <i
                              {...props}
                              className={`material-icons ${props.className} fa-regular fa-chevron-down`}
                              style={{
                                fontSize: "18px",
                                color: "#000",
                              }}
                            ></i>
                          )}
                        >
                          {sizes.map((size) => (
                            <MenuItem
                              key={`size-${devHelper.getObjectValue(
                                size,
                                "item._values.slug"
                              )}`}
                              value={devHelper.getObjectValue(
                                size,
                                "item._values.slug"
                              )}
                            >
                              {devHelper.getObjectValue(
                                size,
                                "item._values.name"
                              )}
                            </MenuItem>
                          ))}
                          <MenuItem
                            onClick={() => {
                              component.prepareInquire(product);
                              setInquireOpen(true);
                            }}
                            style={{
                              justifyContent: "center",
                            }}
                          >
                            {component.ucfirst("Can't find your size? Inquire")}
                          </MenuItem>
                        </Select>
                      </FormControl>
                    </Box>
                  )}
                  <Box
                    sx={{
                      width: "100%",
                      textAlign: "center",
                      borderBottom: "5px solid #000",
                      mt: 3,
                      px: 1,
                    }}
                  >
                    <Button
                      sx={{
                        borderRadius: 0,
                        color: "#fff",
                        backgroundColor: "#000",
                        fontSize: { xs: 14, md: 18 },
                        py: 1,
                        "&:hover": {
                          background: "#000",
                        },
                      }}
                      fullWidth
                      onClick={
                        in_stock
                          ? () => {
                              component.updateCart(
                                product,
                                "combinations.options",
                                product.selected_option,
                                1
                              );
                            }
                          : () => {
                              component.notifyMe(product);
                              setNotifyMeOpen(true);
                            }
                      }
                    >
                      {in_stock ? "add to cart" : "notify me"}
                    </Button>
                    <Typography
                      sx={{
                        fontFamily: "Spartan-SemiBold",
                        fontSize: { xs: 11, md: 14 },
                        color: "#000",
                        mt: 2,
                        mb: 1,
                      }}
                    >
                      AVERAGE DELIVERY TIME: 1-3 WORKING DAYS
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      width: "100%",
                      borderBottom: "5px solid #000",
                      mt: 2,
                      px: 1,
                    }}
                  >
                    <Typography
                      sx={{
                        fontFamily: "Spartan-SemiBold",
                        fontSize: { xs: 13, md: 14 },
                        color: "#000",
                      }}
                    >
                      PRODUCT DETAILS
                    </Typography>
                    {devHelper.getObjectValue(
                      product,
                      "_values.gems_description"
                    ) && (
                      <>
                        <Typography
                          sx={{
                            fontFamily: "Spartan-SemiBold",
                            fontSize: { xs: 13, md: 14 },
                            color: "#000",
                            mt: 2,
                          }}
                        >
                          {devHelper.getObjectValue(
                            product,
                            "_values.colorized"
                          )
                            ? "Colors"
                            : "Gemstones"}
                        </Typography>
                        <Typography
                          sx={{
                            fontFamily: "Spartan-Regular",
                            fontSize: { xs: 12, md: 13 },
                            color: "#000",
                          }}
                        >
                          {devHelper.getObjectValue(
                            product,
                            "_values.gems_description"
                          )}
                        </Typography>
                      </>
                    )}
                    {devHelper.getObjectValue(
                      product,
                      "_values.metal_description"
                    ) && (
                      <>
                        <Typography
                          sx={{
                            fontFamily: "Spartan-SemiBold",
                            fontSize: { xs: 13, md: 14 },
                            color: "#000",
                            mt: 2,
                          }}
                        >
                          Materials
                        </Typography>
                        <Typography
                          sx={{
                            fontFamily: "Spartan-Regular",
                            fontSize: { xs: 12, md: 13 },
                            color: "#000",
                          }}
                        >
                          {devHelper.getObjectValue(
                            product,
                            "_values.metal_description"
                          )}
                        </Typography>
                      </>
                    )}
                    {devHelper.getObjectValue(
                      product,
                      "_values.size_description"
                    ) && (
                      <>
                        <Typography
                          sx={{
                            fontFamily: "Spartan-SemiBold",
                            fontSize: { xs: 13, md: 14 },
                            color: "#000",
                            mt: 2,
                          }}
                        >
                          Size
                        </Typography>
                        <Typography
                          sx={{
                            fontFamily: "Spartan-Regular",
                            fontSize: { xs: 12, md: 13 },
                            color: "#000",
                          }}
                        >
                          {devHelper.getObjectValue(
                            product,
                            "_values.size_description"
                          )}
                        </Typography>
                      </>
                    )}
                    {devHelper.getObjectValue(product, "_values.code") && (
                      <Typography
                        sx={{
                          fontFamily: "Spartan-SemiBold",
                          fontSize: { xs: 13, md: 14 },
                          color: "#000",
                          mt: 2,
                        }}
                      >
                        {`Style Number: ${devHelper.getObjectValue(
                          product,
                          "_values.code"
                        )}`}
                      </Typography>
                    )}
                    {devHelper.getObjectValue(product, "_values.upc") && (
                      <Typography
                        sx={{
                          fontFamily: "Spartan-SemiBold",
                          fontSize: { xs: 13, md: 14 },
                          color: "#000",
                          mb: 2,
                        }}
                      >
                        {`UPC: ${devHelper.getObjectValue(
                          product,
                          "_values.upc"
                        )}`}
                      </Typography>
                    )}
                    <Box
                      sx={{
                        textAlign: "center",
                        mt: 1,
                        mb: 2,
                      }}
                    >
                      <Link
                        component={Button}
                        sx={{
                          borderRight: "1px solid #000",
                          fontFamily: "Spartan-SemiBold",
                          color: "#000",
                          fontSize: "14px",
                          px: 4,
                          py: 1,
                          cursor: "pointer",
                          textTransform: "none",
                          borderRadius: 0,
                          "&:hover": {
                            bgcolor: "transparent",
                          },
                        }}
                        underline="always"
                        disableRipple
                        onClick={() => {
                          window.print();
                          return false;
                        }}
                      >
                        Print
                      </Link>
                      <Link
                        component={Button}
                        sx={{
                          fontFamily: "Spartan-SemiBold",
                          color: "#000",
                          fontSize: "14px",
                          px: 4,
                          py: 1,
                          cursor: "pointer",
                          textTransform: "none",
                          "&:hover": {
                            bgcolor: "transparent",
                          },
                        }}
                        underline="always"
                        disableRipple
                        onClick={() => {
                          component.shareProduct();
                        }}
                      >
                        Share
                      </Link>
                    </Box>
                  </Box>
                  <Box
                    sx={{
                      width: "100%",
                      borderBottom: "5px solid #000",
                      mt: 2,
                      px: 1,
                    }}
                  >
                    <Accordion elevation={0} disableGutters defaultExpanded>
                      <AccordionSummary sx={{ fontSize: { xs: 13, md: 14 } }}>
                        COLLECTION DETAILS
                      </AccordionSummary>
                      <AccordionDetails sx={{ fontSize: { xs: 13, md: 14 } }}>
                        <Translate
                          entity="item"
                          eslug={devHelper.getObjectValue(
                            collection,
                            "item._values.slug"
                          )}
                          scope="default"
                          slug="info"
                          auto
                        />
                      </AccordionDetails>
                    </Accordion>
                    <Typography
                      sx={{
                        fontFamily: "Spartan-SemiBold",
                        fontSize: { xs: 12, md: 13 },
                        color: "#000",
                        my: 1,
                      }}
                    >
                      <Translate
                        entity="item"
                        eslug={devHelper.getObjectValue(
                          collection,
                          "item._values.slug"
                        )}
                        scope="default"
                        slug="extra_info"
                        auto
                      />
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      width: "100%",
                      mt: 2,
                      px: 1,
                    }}
                  >
                    <Accordion elevation={0} disableGutters defaultExpanded>
                      <AccordionSummary sx={{ fontSize: { xs: 13, md: 14 } }}>
                        CARING FOR YOUR K-JEWELRY
                      </AccordionSummary>
                      <AccordionDetails sx={{ fontSize: { xs: 13, md: 14 } }}>
                        <Translate
                          entity="literal"
                          eslug="all-products-info"
                          scope="default"
                          slug="default"
                          auto
                        />
                      </AccordionDetails>
                    </Accordion>
                  </Box>
                </Box>
              </Box>
            </Box>
          )}
        </Grid>
      </Grid>
      <Zoom
        {...{
          ...props,
          ...{
            activeImage,
          },
        }}
      />
      <NotifyMe
        {...{
          ...props,
          ...{
            open: notifyMeOpen,
            setOpen: (boolean) => setNotifyMeOpen(boolean),
          },
        }}
      />
      <Inquire
        {...{
          ...props,
          ...{
            open: inquireOpen,
            setOpen: (boolean) => setInquireOpen(boolean),
          },
        }}
      />
    </Container>
  );
}
