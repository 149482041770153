import * as React from "react";
import { styled } from "@mui/material/styles";
import {
  Box,
  Typography,
  Link,
  Container,
  OutlinedInput,
  InputAdornment,
  FormControl,
  Button,
} from "@mui/material";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import ContactUs from "../../../Components/Widgets/Dialogs/ContactUs";
import { isMobile } from "react-device-detect";
import { Link as RouterLink } from "react-router-dom";

const FooterTitle = styled(Typography)(({ theme }) => ({
  color: "#000",
  fontFamily: "Poppins-SemiBold",
  fontSize: 15,
  marginBottom: 2,
  [theme.breakpoints.down("md")]: {
    fontSize: 15,
  },
}));

const FooterLink = styled(Link)(({ theme }) => ({
  textDecoration: "none",
  color: "#000",
  marginBottom: "3px",
  fontSize: 13,
  display: "block",
  fontFamily: "Spartan-SemiBold",
  [theme.breakpoints.down("md")]: {
    marginBottom: "12px",
    fontSize: 11,
  },
}));

const ContactUsLink = styled("span")(({ theme }) => ({
  textDecoration: "none",
  color: "#000",
  cursor: "pointer",
  marginBottom: "3px",
  fontSize: 13,
  display: "block",
  fontFamily: "Spartan-SemiBold",
  [theme.breakpoints.down("md")]: {
    marginBottom: "12px",
    fontSize: 11,
  },
}));

const SocialLink = styled(Link)(({ theme }) => ({
  textDecoration: "none",
  color: "#000",
  fontSize: "14px",
}));

export default function StickyFooter(props) {
  const { component } = props;
  const handleClick = (event) => {
    const anchor = (event.target.ownerDocument || document).querySelector(
      "#back-to-top-anchor"
    );

    if (anchor) {
      anchor.scrollIntoView({ behavior: "smooth", block: "center" });
    }
  };
  const [contactUsOpen, setContactUsOpen] = React.useState(false);

  return (
    <Box
      id="page-footer"
      component="footer"
      sx={{
        mt: "auto",
        bgcolor: "#F6F6F7",
      }}
    >
      <Container
        maxWidth="xl"
        disableGutters
        sx={{
          py: 4,
          px: { xs: 2, sm: 10 },
          position: "relative",
          textAlign: { xs: "center", md: "left" },
        }}
      >
        <Box
          sx={{
            position: { xs: "relative", md: "absolute" },
            textAlign: "center",
            top: { xs: 0, md: 32 },
            right: { xs: 0, md: 80 },
            mb: { xs: 2, md: 0 },
          }}
        >
          <Button
            variant="text"
            endIcon={
              <Box
                sx={{
                  backgroundColor: "#000",
                  color: "#fff",
                  width: "30px",
                  height: "30px",
                  pt: "3px",
                  borderRadius: "50%",
                  fontSize: "20px",
                }}
              >
                <KeyboardArrowUpIcon />
              </Box>
            }
            sx={{
              color: "#000",
              textTransform: "none",
              "&:hover": { backgroundColor: "transparent" },
            }}
            onClick={handleClick}
            disableRipple
          >
            Back to top
          </Button>
        </Box>
        <FooterTitle>STAY IN TOUCH</FooterTitle>
        <Typography
          sx={{
            color: "#000",
            fontSize: { xs: 11, md: 13 },
            mb: 2,
            fontFamily: "Spartan-SemiBold",
          }}
        >
          Be the first to hear about new designs, campaigns, and offers.
        </Typography>
        <Box
          component="form"
          onSubmit={(e) => {
            e.preventDefault();

            component
              .getApp()
              .newsletterSubscribe(
                component.getData("default.subscribe.email")
              );

            component.setData({ "default.subscribe.email": "" });
          }}
          sx={{
            display: { xs: "block", md: "inline-block" },
            pr: { xs: 0, md: 2 },
            mb: { xs: 2, md: 0 },
            textAlign: { xs: "center", md: "left" },
          }}
        >
          <FooterTitle>Subscribe To Our Newsletter</FooterTitle>
          <FormControl
            variant="outlined"
            focused={false}
            hiddenLabel={true}
            sx={{
              backgroundColor: "#fff",
              verticalAlign: "middle",
              "& label.Mui-focused": {
                color: "#000",
                borderRadius: 0,
              },
              "& .MuiInput-underline:after": {
                borderBottomColor: "#000",
                borderRadius: 0,
              },
              "& .MuiOutlinedInput-input": {
                px: 2,
                py: 1,
                fontSize: "12px",
                borderColor: "#000",
              },
              "& .MuiOutlinedInput-root": {
                p: 0,
                "& fieldset": {
                  borderColor: "#000",
                  borderRadius: 0,
                },
                "&:hover fieldset": {
                  borderColor: "#000",
                  borderRadius: 0,
                },
                "&.Mui-focused fieldset": {
                  borderColor: "#000",
                  borderRadius: 0,
                },
              },
            }}
          >
            <OutlinedInput
              type="text"
              placeholder="E-Mail Address"
              sx={{
                "& .MuiInputBase-input": {
                  fontSize: 13,
                  color: "#000",
                },
              }}
              onChange={(e) =>
                component.setData({ "default.subscribe.email": e.target.value })
              }
              value={component.getData("default.subscribe.email", "")}
              endAdornment={
                <InputAdornment position="end">
                  <Button
                    disableRipple
                    sx={{
                      textTransform: "none",
                      fontSize: 14,
                      borderLeft: "1px solid #000",
                      borderRadius: 0,
                      height: "33px",
                      backgroundColor: "#fff",
                      px: 2,
                    }}
                    type="submit"
                  >
                    Next
                    <i
                      className="fa-light fa-arrow-right-long"
                      style={{ marginLeft: "5px" }}
                    ></i>
                  </Button>
                </InputAdornment>
              }
            />
          </FormControl>
        </Box>
        <Box
          sx={{
            display: { xs: "block", md: "inline-block" },
            pr: { xs: 0, md: 2 },
            mb: { xs: 2, md: 0 },
            textAlign: { xs: "center", md: "left" },
          }}
        >
          <FooterTitle>K-Boutiques</FooterTitle>
          <Button
            disableRipple
            sx={{
              textTransform: "none",
              fontSize: 14,
              border: "1px solid #000",
              borderRadius: 0,
              height: "33px",
              backgroundColor: "#fff",
              px: 2,
              "&:hover": { backgroundColor: "transparent" },
            }}
            component={RouterLink}
            to="/boutiques"
          >
            Our Stores &amp; Locations
            <i
              className="fa-light fa-location-dot"
              style={{ marginLeft: "5px" }}
            ></i>
          </Button>
        </Box>
        <Box
          sx={{
            display: { xs: "block", md: "inline-block" },
            pr: { xs: 0, md: 2 },
            textAlign: { xs: "center", md: "left" },
          }}
        >
          <FooterTitle>Up Coming Events</FooterTitle>
          <Button
            disableRipple
            sx={{
              textTransform: "none",
              fontSize: 14,
              border: "1px solid #000",
              borderRadius: 0,
              height: "33px",
              backgroundColor: "#fff",
              px: 2,
              "&:hover": { backgroundColor: "transparent" },
            }}
            component={RouterLink}
            to="/trunk-shows"
          >
            Trunk Shows
            <i
              className="fa-light fa-window-maximize"
              style={{ marginLeft: "5px" }}
            ></i>
          </Button>
        </Box>
      </Container>
      <Box
        sx={{
          borderTop: "1px solid #000",
        }}
      ></Box>
      <Container
        maxWidth="xl"
        disableGutters
        sx={{ py: 4, px: { xs: 2, sm: 10 }, position: "relative" }}
      >
        <Box
          sx={{
            display: { xs: "block", md: "inline-block" },
            pr: { xs: 0, md: 3 },
            mb: { xs: 2, md: 0 },
            textAlign: { xs: "center", md: "left" },
            verticalAlign: "top",
          }}
        >
          <FooterTitle sx={{ mb: 2 }}>CLIENT SERVICES</FooterTitle>
          <FooterLink component={RouterLink} to="/signin">
            Login to your account
          </FooterLink>
          <FooterLink component={RouterLink} to="/trunk-shows">
            Trunk Shows
          </FooterLink>
          <ContactUsLink onClick={() => setContactUsOpen(true)}>
            Contact Us
          </ContactUsLink>
          <FooterLink component={RouterLink} to="/assistance/care-tips">
            Care Tips
          </FooterLink>
          <FooterLink component={RouterLink} to="/assistance/shipping">
            Shipping
          </FooterLink>
          <FooterLink component={RouterLink} to="/assistance/returns">
            Returns
          </FooterLink>
          <FooterLink component={RouterLink} to="/assistance/faqs">
            FAQs
          </FooterLink>
        </Box>
        <Box
          sx={{
            display: { xs: "block", md: "inline-block" },
            pr: { xs: 0, md: 3 },
            mb: { xs: 2, md: 0 },
            textAlign: { xs: "center", md: "left" },
            verticalAlign: "top",
          }}
        >
          <FooterTitle sx={{ mb: 2 }}>LEGAL &amp; PRIVACY</FooterTitle>
          <FooterLink
            component={RouterLink}
            to="/assistance/privacy-policy-and-cookies"
          >
            Privacy Policy & Cookie Policy
          </FooterLink>
          <FooterLink component={RouterLink} to="/assistance/warranty">
            Warranty
          </FooterLink>
        </Box>
        <Box
          sx={{
            display: { xs: "block", md: "inline-block" },
            pr: { xs: 0, md: 3 },
            mb: { xs: 2, md: 0 },
            textAlign: { xs: "center", md: "left" },
            verticalAlign: "top",
          }}
        >
          <FooterTitle sx={{ mb: 2 }}>CORPORATE</FooterTitle>
          <FooterLink component={RouterLink} to="/boutiques">
            Store Locator
          </FooterLink>
        </Box>
        <Box
          sx={{
            display: { xs: "block", md: "inline-block" },
            pr: { xs: 0, md: 3 },
            mb: { xs: 2, md: 0 },
            textAlign: { xs: "center", md: "left" },
            verticalAlign: "top",
            cursor: "pointer",
          }}
          onClick={() => {
            const el = document.getElementById("userwayAccessibilityIcon");

            if (el) {
              if (!isMobile) {
                el.click();
              } else {
                var event1 = new Event("touchstart");
                var event2 = new Event("touchend");
                el.dispatchEvent(event1);
                el.dispatchEvent(event2);
              }
            }
          }}
        >
          <img
            // id="myAccessibilityIcon"
            width="40"
            height="40"
            alt="Accessibility Widget"
            src="https://cdn.userway.org/widgetapp/images/body_bl.svg"
            data-uw-rm-ima-original="accessibility widget"
          ></img>
        </Box>
        <Box
          sx={{
            display: { xs: "block", md: "inline-block" },
            pr: { xs: 0, md: 3 },
            mb: { xs: 2, md: 0 },
            textAlign: { xs: "center", md: "left" },
            width: "100%",
          }}
        >
          <FooterTitle sx={{ mb: 1, mt: 4 }}>Follow Us</FooterTitle>
          <SocialLink
            href="https://www.instagram.com/konstantinojewelry/"
            target="_blank"
            sx={{ mr: { xs: 1, md: 3 }, ml: { xs: 1, md: 0 } }}
            aria-label="instagram"
          >
            <i className="fa-brands fa-instagram"></i>
          </SocialLink>
          <SocialLink
            href="https://www.facebook.com/konstantinojewelry/"
            target="_blank"
            sx={{ mr: { xs: 1, md: 3 }, ml: { xs: 1, md: 0 } }}
            aria-label="facebook"
          >
            <i className="fa-brands fa-facebook-f"></i>
          </SocialLink>
          <SocialLink
            href="https://twitter.com/KONSTANTINO__"
            target="_blank"
            sx={{ mr: { xs: 1, md: 3 }, ml: { xs: 1, md: 0 } }}
            aria-label="twitter"
          >
            <i className="fa-brands fa-twitter"></i>
          </SocialLink>

          <SocialLink
            href="https://www.youtube.com/c/Konstantino"
            target="_blank"
            sx={{ mr: { xs: 1, md: 3 }, ml: { xs: 1, md: 0 } }}
            aria-label="youtube"
          >
            <i className="fa-brands fa-youtube"></i>
          </SocialLink>
          {/* <SocialLink
            href="https://gr.pinterest.com/KONSTANTINO_/_created/"
            target="_blank"
          >
            <i className="fa-brands fa-pinterest-p"></i>
          </SocialLink> */}
          {/* <SocialLink
            href="https://vimeo.com/konstantinojewelry"
            target="_blank"
          >
            <i className="fa-brands fa-vimeo-v"></i>
          </SocialLink> */}
        </Box>
        <Box
          sx={{
            position: { xs: "relative", md: "absolute" },
            textAlign: "center",
            bottom: { xs: 0, md: 32 },
            right: { xs: 0, md: 80 },
            mt: { xs: 3, md: 0 },
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Box
            sx={{
              display: "flex",
              width: { xs: "280px", md: "380px" },
            }}
            component={RouterLink}
            to="/"
          >
            <img
              alt="logo konstantino"
              src="/images/logos/footer-logo.png"
              title="logo"
              style={{
                maxWidth: "100%",
              }}
            />
          </Box>
        </Box>
      </Container>
      <ContactUs
        {...{
          ...props,
          ...{
            open: contactUsOpen,
            setOpen: (boolean) => setContactUsOpen(boolean),
          },
        }}
      />
    </Box>
  );
}
