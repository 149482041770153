import { Component } from "../../../../modules/Core/Component";
import templateDefault from "../../../templates/default/widgets/sliders/curation_slider";
import Services from "../../../Services/Services";

export default class CurationSlider extends Component {
  template = templateDefault;

  onLoad(data) {
    super.onLoad(data);

    this.getSliders();
  }

  getSliders() {
    Services.get("hook").then(async ([hookService]) => {
      try {
        var response = await hookService.exec("sliders", {
          params: { scope: this.props.fscope },
        });

        const sliders = response.getData() || [];

        this.startInterval(sliders, 0);

        this.setData({
          "default.sliders": sliders,
        });
      } catch (e) {
        console.log(e);
      }
    });
  }

  startInterval(sliders, activeSlide) {
    clearInterval(this.interval);
    this.setData({
      "default.active_slide": activeSlide,
    });
    this.interval = setInterval(() => {
      this.setData({
        "default.active_slide": ++activeSlide % sliders.length,
      });
    }, 6000);
  }
}
