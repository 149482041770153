import React from "react";
import { Button, Box, CardMedia, Link, Grid, Container } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import Slider from "react-slick";

export default function Grid6Banners(props) {
  const { component } = props;
  const devHelper = component.getHelpers("dev");
  const filesUrl = component.getHelpers("env").get("files_url");
  const img_version = component.getHelpers("value").getValue("images-version");
  const sliders = component.getData("default.sliders", []);

  const settings = {
    className: "center",
    centerMode: true,
    centerPadding: "20%",
    infinite: true,
    dots: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    swipeToSlide: true,
    autoplaySpeed: 3500,
    nextArrow: <></>,
    prevArrow: <></>,
    arrows: false,
    pauseOnHover: false,
    appendDots: (dots) => (
      <div
        style={{
          height: "15px",
        }}
      >
        <ul style={{ margin: "16px" }}> {dots} </ul>
      </div>
    ),
    customPaging: (i) => (
      <div
        className="dot"
        style={{
          width: "15px",
          height: "15px",
          border: "1px solid #000",
          borderRadius: "50%",
          backgroundColor: "#000",
        }}
      ></div>
    ),
    responsive: [
      {
        breakpoint: 900,
        settings: {
          slidesToShow: 1,
          centerPadding: "0%",
          dots: true,
          autoplay: false,
        },
      },
    ],
  };

  return (
    <Box sx={{ bgcolor: "#fff" }}>
      <Container
        maxWidth="xl"
        disableGutters
        sx={{
          px: 2,
        }}
      >
        <Box
          sx={{
            pt: 6,
            pb: { xs: 12, md: 6 },
            bgcolor: "#F6F6F7",
          }}
        >
          <Grid
            container
            direction="row"
            rowSpacing={{ xs: 3, md: 8 }}
            columnSpacing={{ xs: 3, md: 2 }}
            sx={{
              px: { xs: 5, md: 5, lg: 10 },
              display: { xs: "none", md: "flex" },
            }}
          >
            {sliders.map((slider) => {
              const { slug, url, name, button_text } = devHelper.getObjectValue(
                slider,
                "_values"
              );
              return (
                <Grid
                  item
                  xs={12}
                  sm={4}
                  key={slug}
                  sx={{ textAlign: "center" }}
                >
                  <Link component={RouterLink} to={url}>
                    <CardMedia
                      component="img"
                      image={`${filesUrl}/konstantino/sliders/desktop/${slug}.jpg?v=${img_version}`}
                      alt={name}
                    />
                  </Link>
                  <Button
                    component={RouterLink}
                    to={url}
                    sx={{
                      fontFamily: "Spartan-Bold",
                      color: "#000",
                      lineHeight: 1.2,
                      fontSize: 15,
                      textTransform: "none",
                      border: "1px solid #000",
                      p: "16px 32px",
                      borderRadius: 0,
                      minWidth: 200,
                      mt: 3,
                      "&:hover": {
                        backgroundColor: "transparent",
                      },
                    }}
                    disableRipple
                  >
                    {button_text}
                  </Button>
                </Grid>
              );
            })}
          </Grid>
          <Box
            sx={{
              px: { xs: 5, md: 5, lg: 10 },
              display: { xs: "block", md: "none" },
            }}
          >
            <Slider
              {...settings}
              className="side_slider"
              swipe={true}
              swipeToSlide={true}
            >
              {sliders.map((slider) => {
                const { slug, url, name, button_text } =
                  devHelper.getObjectValue(slider, "_values");
                return (
                  <Box
                    key={slug}
                    sx={{
                      textAlign: "center",
                    }}
                  >
                    <Box sx={{ px: { xs: 2, sm: 4 } }}>
                      <Link
                        component={RouterLink}
                        to={devHelper.getObjectValue(slider, "_values.url")}
                      >
                        <CardMedia
                          sx={{
                            maxWidth: "100%",
                          }}
                          component="img"
                          image={`${filesUrl}/konstantino/sliders/desktop/${slug}.jpg?v=${img_version}`}
                          alt={name}
                        />
                      </Link>
                    </Box>
                    <Button
                      component={RouterLink}
                      to={url}
                      sx={{
                        fontFamily: "Spartan-Bold",
                        color: "#000",
                        lineHeight: 1.2,
                        fontSize: 15,
                        textTransform: "none",
                        border: "1px solid #000",
                        p: "16px 32px",
                        borderRadius: 0,
                        minWidth: 200,
                        mt: 3,
                        "&:hover": {
                          backgroundColor: "transparent",
                        },
                      }}
                      disableRipple
                    >
                      {button_text}
                    </Button>
                  </Box>
                );
              })}
            </Slider>
          </Box>
        </Box>
      </Container>
    </Box>
  );
}
