import { Component } from "../../../modules/Core/Component";
import templateDefault from "../../templates/default/widgets/navbar/main_appbar";
import Services from "../../Services/Services";

export default class Navbar extends Component {
  id = "navbar";
  group = "wishlist,order";

  template = templateDefault;

  onLoad(data) {
    super.onLoad(data);

    this.getOrder();
    this.getMenu();
    this.checkWishlist();
    if (this.isAuthenticated()) {
      this.retrieveCoupons();
    }

    const errorOrderMessage = this.getHelpers("const").get(
      "error-order-message"
    );

    if (errorOrderMessage) {
      this.getHelpers("const").set("error-order-message", "");
      this.setData({ "error.order-message": errorOrderMessage });
    }
  }

  getMenu() {
    Services.get("menu").then(([menuService]) => {
      menuService
        .getAll()
        .then((menuService) => {
          this.setData({ "default.mainMenu": menuService.getData("menu", []) });
        })
        .catch((menuService) => {});
    });
  }

  getOrder() {
    Services.get("order").then(([orderService]) => {
      orderService
        .fetchOrder()
        .then((orderService) => {
          const order = orderService.getData("order");
          this.setData({ "default.order": order });
        })
        .catch((orderService) => {});
    });
  }

  checkWishlist() {
    Services.get("wishlist").then(([wishlistService]) => {
      var wishlist = wishlistService.getWishlist();
      this.setData({ "default.wishlist": wishlist });
    });
  }

  retrieveCoupons() {
    Services.get("coupon").then(([couponService]) => {
      couponService
        .getCoupons()
        .then((couponService) => {
          const coupons = couponService.getData("coupons", []);

          this.setData({
            "default.coupons": coupons,
          });
        })
        .catch((err) => {
          console.log("regvfcdinj");
          console.log("error", err);
        });
    });
  }

  toggleMenuDrawer() {
    this.getComponents()
      .findById("menu-drawer")
      .forEach((drawer) => {
        drawer.toggle();
      });
  }

  toggleCartPopper(boolean) {
    this.getComponents().findById("cart-popper").first().setPopperOpen(boolean);
  }

  checkSearch(e) {
    e.preventDefault();
    const { search_term } = this.getData("default", null);
    const devHelper = this.getHelpers("env").getPublicUrl();
    if (search_term && search_term.length > 1) {
      window.location.href = devHelper + "/search/results?term=" + search_term;
    }
  }
}
