import Products from "./layout_products";
import FullBanner from "./layout_banner_full_width";
import SideBanner from "./layout_side_banner";

const ExportDefault = {
  products: Products,
  full_bannner: FullBanner,
  side_bannner: SideBanner,
};

export default ExportDefault;
