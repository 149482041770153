import { Component } from "../../../modules/Core/Component";
import templateDefault from "../../templates/default/widgets/trunk_shows/trunk_shows";
import Services from "../../Services/Services";

export default class TrunkShows extends Component {
  id = "trunk-shows";
  template = templateDefault;

  onAuth() {
    this.refresh();
  }

  onLoad(data) {
    super.onLoad(data);

    Services.get("hook").then(([service]) => {
      service.exec("trunkshows").then((response) => {
        const trunkshows = response.getData() || {};
        var stores = [],
          locations = [];
        trunkshows.forEach((show) => {
          const { name = null, location = null } = show._values;

          if (name) {
            stores.push(name);
          }
          if (location) {
            locations.push(location);
          }
        });

        stores = [...new Set(stores)].sort();
        locations = [...new Set(locations)].sort();

        this.setData({
          "default.trunkshows": trunkshows,
          "default.stores": stores,
          "default.locations": locations,
        });
      });
    });
  }

  setTrunkShows() {
    const {
      date = "",
      location = "",
      store = "",
    } = this.getData("default", {});

    Services.get("hook").then(([service]) => {
      service
        .exec("trunkshows", {
          params: {
            selectedDate: date ? date : "",
            location: location ? location : "",
            name: store ? store : "",
          },
        })
        .then((response) => {
          const trunkshows = response.getData() || {};

          this.setData({
            "default.trunkshows": trunkshows,
          });
        });
    });

    return this;
  }
}
