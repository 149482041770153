import Helpers from "../../../modules/Core/Helpers/Helpers";
import MainApi from "../../Api/Main";
import Service from "../Service";

class Auth extends Service {
  //
  login(data) {
    const { username, password } = data;
    const account = Helpers.Env.getDefaultAccount();
    const repository = Helpers.Env.getDefaultRepository();

    return new Promise((resolve, reject) => {
      MainApi.auth
        .login(account, repository, {
          username,
          password,
        })
        .then((r) => {
          if (this.evaluate(r)) {
            resolve(this);
          } else {
            this.setError(this.getData("_response").getData());
            reject(this);
          }
        });
    });
  }

  // loginFB(data) {
  //   const { username, name } = data;
  //   const account = Helpers.Env.getDefaultAccount();
  //   const repository = Helpers.Env.getDefaultRepository();

  //   return new Promise((resolve, reject) => {
  //     MainApi.auth
  //       .loginFB(account, repository, {
  //         username,
  //         name,
  //       })
  //       .then((r) => {
  //         if (this.evaluate(r)) {
  //           resolve(this);
  //         } else {
  //           reject(this);
  //         }
  //       });
  //   });
  // }

  // loginGoogle(data) {
  //   const { username, name } = data;
  //   const account = Helpers.Env.getDefaultAccount();
  //   const repository = Helpers.Env.getDefaultRepository();

  //   return new Promise((resolve, reject) => {
  //     MainApi.auth
  //       .loginGoogle(account, repository, {
  //         username,
  //         name,
  //       })
  //       .then((r) => {
  //         if (this.evaluate(r)) {
  //           resolve(this);
  //         } else {
  //           reject(this);
  //         }
  //       });
  //   });
  // }

  register(data) {
    const { name, username, password, verify } = data;
    const account = Helpers.Env.getDefaultAccount();
    const repository = Helpers.Env.getDefaultRepository();

    return new Promise((resolve, reject) => {
      MainApi.auth
        .register(account, repository, {
          name,
          username,
          password,
          verify,
        })
        .then((r) => {
          if (this.evaluate(r)) {
            resolve(this);
          } else {
            reject(this);
          }
        });
    });
  }

  passwordRequest(data) {
    const { username } = data;
    const account = Helpers.Env.getDefaultAccount();
    const repository = Helpers.Env.getDefaultRepository();

    return new Promise((resolve, reject) => {
      MainApi.auth.password
        .request(account, repository, {
          username,
        })
        .then((r) => {
          if (this.evaluate(r)) {
            resolve(this);
          } else {
            reject(this);
          }
        });
    });
  }

  passwordReset(data) {
    const { password, verify, key } = data;
    const account = Helpers.Env.getDefaultAccount();
    const repository = Helpers.Env.getDefaultRepository();

    return new Promise((resolve, reject) => {
      MainApi.auth.password
        .reset(account, repository, {
          password,
          verify,
          key,
        })
        .then((r) => {
          if (this.evaluate(r)) {
            resolve(this);
          } else {
            reject(this);
          }
        });
    });
  }

  updateProfile(data) {
    const { name, password, verify } = data;
    const account = Helpers.Env.getDefaultAccount();
    const repository = Helpers.Env.getDefaultRepository();

    return new Promise((resolve, reject) => {
      MainApi.auth.profile
        .update(account, repository, {
          name,
          password,
          verify,
        })
        .then((r) => {
          if (this.evaluate(r)) {
            resolve(this);
          } else {
            reject(this);
          }
        });
    });
  }

  retrieveProfile() {
    const account = Helpers.Env.getDefaultAccount();
    const repository = Helpers.Env.getDefaultRepository();

    return new Promise((resolve, reject) => {
      MainApi.auth.profile.retrieve(account, repository).then((r) => {
        if (this.evaluate(r, "profile")) {
          resolve(this);
        } else {
          reject(this);
        }
      });
    });
  }
}

export default new Auth();
