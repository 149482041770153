import { Component } from "../../../modules/Core/Component";
import templateDefault from "../../templates/default/widgets/quick_view";
import Services from "../../Services/Services";

export default class QuickView extends Component {
  id = "quick-view";
  group = "wishlist";

  template = templateDefault;

  toggle() {
    this.toggleData("default.opened", {
      defaultValue: true,
    });
    this.setData({
      "default.fields.size": "",
      "error.fields": {},
    });

    if (this.getData("default.opened")) {
      this.getApp().sendToGA4(this.getData("default.product"), "view_item");
    }
  }

  filterImages(images, sku) {
    var filtered_images = images.filter((img) =>
      img.name.includes(sku.toLowerCase())
    );

    this.setData({
      "default.images": filtered_images,
    });

    return this;
  }

  checkWishlist() {
    Services.get("wishlist").then(([wishlistService]) => {
      var wishlist = wishlistService.getWishlist();
      var product = this.getData("default.product", null);
      if (product) {
        this.setData({
          "default.wishlist": wishlist.some(
            (el) => el === product._values.slug
          ),
        });
      }
    });
  }

  addToWishList(slug) {
    Services.get("wishlist").then(([wishlistService]) => {
      wishlistService.addToWishList(slug, this);
    });
  }

  removeFromWishList(slug) {
    Services.get("wishlist").then(([wishlistService]) => {
      wishlistService.removeFromWishList(slug, this);
    });
  }

  updateCart(product, skuPath, sku, amount) {
    const error = {};
    if (sku === "") {
      const { size } = this.getData("default.fields", "");

      if (!size) {
        error["size"] = "";
      }

      this.setData({
        "error.fields": error,
      });
    }

    if (Object.keys(error).length === 0) {
      Services.get("order").then(async ([orderService]) => {
        const { _id, _values } = product;
        const { combinations } = _values;
        const { upc } =
          combinations.find(({ options }) => options === sku) || {};

        sku = typeof sku === "string" ? sku : sku.join(",");
        amount = this.getProductAmount(product, skuPath, sku) + amount;

        await orderService.updateItem({
          itemId: _id,
          itemType: "product",
          skuStock: upc,
          sku: skuPath ? `${skuPath}:${sku}` : sku,
          amount,
        });

        this.getComponents()
          .findByGroup("order")
          .forEach((order) => {
            order.setData({ "default.order": orderService.getOrder() });
          });

        if (this.getProductAmount(product, skuPath, sku) !== amount) {
          product.in_stock = false;
          this.setData({ "default.product": product });
        }
      });

      this.getComponents()
        .findById("main-message")
        .first()
        .setData({
          "message-duration": 700,
          "success-message": this.trans("product-added-to-cart-message"),
        });

      this.getApp().sendToGA4(product, "add_to_cart", amount);
    }
  }

  getProductAmount(product, skuPath, sku) {
    const { items = [] } =
      this.getComponents()
        .findById("collection")
        .first()
        .getData("default.order", {}) || {};

    const { amount = 0 } =
      items.find((orderItem) => {
        return (
          product._id === orderItem.item.id &&
          (!sku || orderItem.sku === `${skuPath}:${sku}`)
        );
      }) || {};

    return amount;
  }

  notifyMe(product) {
    const { selected_option } = product;
    const { slug, sizes = [], combinations = [] } = product._values;
    const combination = combinations.find(
      ({ options }) => options === selected_option
    );

    const { size } = this.getData("default.fields", "");

    var size_value;

    if (size) {
      size_value = sizes.find((item) => item.item._values.slug === size).item
        ._values.name;
    }

    if (combination) {
      const { upc, code } = combination;
      const productInfo = {
        slug,
        combination: selected_option,
        upc,
        code,
      };

      if (size) {
        productInfo.size = size_value;
      }

      this.getPage().setData({ "default.notify": productInfo });
    }
  }

  prepareInquire(product) {
    const { slug, name, sku } = product._values;

    const link =
      this.getHelpers("env").getPublicUrl() + "/product/" + slug + "/" + sku;

    const productInfo = {
      product: name,
      slug,
      sku,
      link,
    };

    this.getPage().setData({ "default.inquire": productInfo });
  }
}
