import React, { useRef } from "react";
import { styled } from "@mui/material/styles";
import {
  Toolbar,
  Link,
  CardMedia,
  Button,
  Box,
  InputBase,
  ClickAwayListener,
  Typography,
  Grid,
  Grow,
  Paper,
  Popper,
  MenuItem,
  MenuList,
  Badge,
  Container,
  Fade,
  Stack,
} from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import { useTheme } from "@mui/material/styles";

const MyLi = styled("li")(({ theme }) => ({}));

const MyBadge = styled(Badge)(({ theme }) => ({
  "& .MuiBadge-badge": {
    color: "#000",
    backgroundColor: "#fff",
    minWidth: "16px",
    height: "16px",
    fontSize: "10px",
    p: 0,
  },
}));

const MyLink = styled(Link)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  fontFamily: "Spartan-Medium",
  color: "#000",
  fontSize: "13px",
  textTransform: "none",
  padding: 0,
  textDecoration: "none",
  "&:hover": {
    backgroundColor: "transparent",
  },
}));

const Title = styled("div")(({ theme }) => ({
  fontFamily: "Poppins-SemiBold",
  color: "#000",
  fontSize: "16px",
}));

const TopMenu = styled(Box)(({ theme }) => ({
  position: "absolute",
  left: 0,
  zIndex: 3000,
  width: "100%",
  // backgroundColor: "rgb(234, 234, 232,0.95)",
  backgroundColor: "rgb(246, 246, 247,0.95)",
  boxShadow: "0px 15px 10px -15px rgb(0 0 0 / 12%)",
}));

const RightNavBtn = styled(Button)(({ theme }) => ({
  paddingLeft: "4px",
  minWidth: "unset",
  fontSize: "18px",
  color: "#fff",
  "&:hover": {
    backgroundColor: "transparent",
  },
}));

const SearchIconWrapper = styled(Button)(({ theme }) => ({
  padding: theme.spacing(0, 1),
  position: "absolute",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  minWidth: "unset",
  margin: "7px 0px",
  fontSize: "16px",
  color: "#fff",
  zIndex: "1",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: "4px 4px 4px 32px",
    color: "#fff",
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "20ch",
    },
  },
}));

export default function MainAppbar(props) {
  const { component } = props;

  const {
    wishlist = [],
    order = null,
    mainMenu = [],
    coupons = [],
  } = component.getData("default", {});

  const isAuth = component.isAuthenticated();

  const theme = useTheme();

  const devHelper = component.getHelpers("dev");
  const filesUrl = component.getHelpers("env").get("files_url");
  const img_version = component.getHelpers("value").getValue("images-version");

  const anchorRef = React.useRef(null);
  const [open, setOpen] = React.useState(false);
  const [searchForm, setSearchForm] = React.useState(false);
  const inputRef = useRef(null);

  const [openMenu, setOpenMenu] = React.useState(false);
  const [menu, setMenu] = React.useState("womens");

  const [menuEffect, setMenuEffect] = React.useState(false);

  const kgifts = mainMenu.find((menu) => menu.slug === "gifts");
  const kwomens = mainMenu.find((menu) => menu.slug === "womens");
  const kmens = mainMenu.find((menu) => menu.slug === "mens");
  const kworld = mainMenu.find((menu) => menu.slug === "world");
  const klocations = mainMenu.find((menu) => menu.slug === "locations");

  const handleToggle = (boolean, new_menu) => {
    setOpenMenu(boolean);
    if (new_menu !== undefined) {
      setMenu(new_menu);
    }
  };

  const handleToggleAccountInfo = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  function handleListKeyDown(event) {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpen(false);
    }
  }

  const prevOpen = React.useRef(open);

  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }
    prevOpen.current = open;
  }, [open]);

  React.useEffect(() => {
    if (mainMenu.length) {
      setTimeout(() => {
        setMenuEffect(true);
      }, 500);
    }
  }, [mainMenu.length]);

  const handleSearchClick = () => {
    setSearchForm(!searchForm);
  };
  return (
    <>
      <Toolbar disableGutters>
        <Container
          maxWidth="xl"
          disableGutters
          sx={{
            display: "flex",
            px: { xs: 2, md: 3 },
            justifyContent: "space-between",
            height: {
              xs: theme.appbar_height.mobile,
              md: theme.appbar_height.desktop,
            },
          }}
        >
          <Box sx={{ display: { xs: "flex", md: "none" }, width: "81.41px" }}>
            <RightNavBtn
              disableRipple
              onClick={() => {
                component.toggleMenuDrawer();
              }}
              sx={{ fontSize: "22px" }}
              aria-label="Mobile Menu"
            >
              <i className="fa-light fa-bars-sort"></i>
            </RightNavBtn>
            <RightNavBtn
              disableRipple
              onClick={() => handleSearchClick()}
              sx={{
                visibility: searchForm ? "hidden" : "visible",
                display: { xs: "block", md: "none" },
              }}
              aria-label="Search"
            >
              <i className="fa-light fa-magnifying-glass"></i>
            </RightNavBtn>
          </Box>
          <Button
            sx={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              width: { xs: "75px", md: "105px" },
              outline: "none",
              p: 0,
              "&:hover": {
                bgcolor: "transparent",
              },
            }}
            disableRipple
            component={RouterLink}
            to="/"
          >
            <CardMedia
              component="img"
              image="/images/logos/navbar-logo.png"
              alt="Konstantino Logo"
              title="Konstantino Home"
              sx={{
                maxWidth: "100%",
                width: "inherit",
              }}
            />
          </Button>
          <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}>
            {mainMenu.map((menu) => {
              const { slug, name, url, menu_template } = menu;
              return (
                <Box
                  key={`menu-btn-${slug}`}
                  onMouseEnter={() => {
                    if (menuEffect) {
                      handleToggle(true, menu_template);
                    }
                  }}
                  onMouseLeave={() => handleToggle(false, menu_template)}
                  onClick={() => handleToggle(false, menu_template)}
                  sx={{
                    height: theme.appbar_height.desktop,
                    display: "flex",
                    alignItems: "center",
                    "&:hover,&:focus": {
                      "& a": {
                        borderBottom: "2px solid #fff",
                      },
                    },
                  }}
                >
                  <Button
                    to={url}
                    component={RouterLink}
                    key={`main-menu-root-${slug}`}
                    sx={{
                      color: "#fff",
                      textTransform: "none",
                      fontSize: "16px",
                      lineHeight: "16px",
                      mt: "5px",
                      p: 0,
                      ml: 5,
                      fontFamily: "Spartan-SemiBold",
                      borderBottom: "2px solid transparent",
                      borderRadius: 0,
                      "&:hover,&:focus": {
                        backgroundColor: "transparent",
                      },
                    }}
                    disableRipple
                  >
                    {name}
                  </Button>
                </Box>
              );
            })}
          </Box>
          <Box sx={{ display: "flex" }}>
            <RightNavBtn
              disableRipple
              onClick={() => handleSearchClick()}
              sx={{
                visibility: searchForm ? "hidden" : "visible",
                display: { xs: "none", md: "block" },
              }}
              aria-label="Search"
            >
              <i className="fa-light fa-magnifying-glass"></i>
            </RightNavBtn>
            {!isAuth ? (
              <RightNavBtn
                component={RouterLink}
                to="/signin"
                disableRipple
                aria-label="User Account"
              >
                <i className="fa-light fa-user"></i>
              </RightNavBtn>
            ) : (
              <>
                <RightNavBtn
                  ref={anchorRef}
                  aria-controls={open ? "menu-list-grow" : undefined}
                  aria-haspopup="true"
                  onClick={handleToggleAccountInfo}
                  disableRipple
                  aria-label="User Account"
                >
                  <MyBadge
                    badgeContent={coupons.length}
                    showZero
                    sx={{
                      "& .MuiBadge-badge": {
                        visibility: coupons.length === 0 ? "hidden" : "visible",
                      },
                    }}
                  >
                    <i className="fa-light fa-user"></i>
                  </MyBadge>
                </RightNavBtn>
                <Popper
                  open={open}
                  anchorEl={anchorRef.current}
                  role={undefined}
                  transition
                >
                  {({ TransitionProps, placement }) => (
                    <Grow
                      {...TransitionProps}
                      style={{
                        transformOrigin:
                          placement === "bottom"
                            ? "center top"
                            : "center bottom",
                      }}
                    >
                      <Paper square>
                        <ClickAwayListener onClickAway={handleClose}>
                          <MenuList
                            autoFocusItem={open}
                            id="menu-list-grow"
                            onKeyDown={handleListKeyDown}
                          >
                            <MenuItem
                              component={RouterLink}
                              to="/profile"
                              onClick={handleClose}
                              sx={{
                                fontFamily:
                                  "founders-grotesk,Helvetica,sans-serif",
                                zIndex: 1000000,
                              }}
                            >
                              {component.ucfirst("k-profile")}
                            </MenuItem>
                            <MenuItem
                              component={RouterLink}
                              to="/addresses"
                              onClick={handleClose}
                              sx={{
                                fontFamily:
                                  "founders-grotesk,Helvetica,sans-serif",
                              }}
                            >
                              {component.ucfirst("k-addresses")}
                            </MenuItem>
                            <MenuItem
                              component={RouterLink}
                              to="/orders"
                              onClick={handleClose}
                              sx={{
                                fontFamily:
                                  "founders-grotesk,Helvetica,sans-serif",
                              }}
                            >
                              {component.ucfirst("k-orders")}
                            </MenuItem>
                            <MenuItem
                              component={RouterLink}
                              to="/coupons"
                              onClick={handleClose}
                              sx={{
                                fontFamily:
                                  "founders-grotesk,Helvetica,sans-serif",
                              }}
                            >
                              <MyBadge
                                badgeContent={coupons.length}
                                sx={{
                                  "& .MuiBadge-badge": {
                                    right: -15,
                                    top: 12,
                                    background: "#000",
                                    color: "#fff",
                                  },
                                }}
                              >
                                {component.ucfirst("k-coupons")}
                              </MyBadge>
                            </MenuItem>
                            <MenuItem
                              onClick={() => component.getPage().logout()}
                              sx={{
                                fontFamily:
                                  "founders-grotesk,Helvetica,sans-serif",
                              }}
                            >
                              {component.ucfirst("logout")}
                            </MenuItem>
                          </MenuList>
                        </ClickAwayListener>
                      </Paper>
                    </Grow>
                  )}
                </Popper>
              </>
            )}
            <RightNavBtn
              disableRipple
              component={RouterLink}
              to="/wishlist"
              aria-label="Wishlist"
            >
              <MyBadge
                badgeContent={wishlist.length}
                showZero
                sx={{
                  "& .MuiBadge-badge": {
                    visibility: wishlist.length === 0 ? "hidden" : "visible",
                  },
                }}
              >
                <i className="fa-light fa-heart" />
              </MyBadge>
            </RightNavBtn>
            <RightNavBtn
              disableRipple
              component={RouterLink}
              to="/cart"
              onMouseEnter={() => component.toggleCartPopper(true)}
              onMouseLeave={() => component.toggleCartPopper(false)}
              aria-label="Cart"
            >
              <MyBadge
                badgeContent={devHelper.getObjectValue(
                  order,
                  "amounts.items",
                  0
                )}
                showZero
                sx={{
                  "& .MuiBadge-badge": {
                    visibility:
                      devHelper.getObjectValue(order, "amounts.items", 0) === 0
                        ? "hidden"
                        : "visible",
                  },
                }}
              >
                <i className="fa-light fa-bag-shopping"></i>
              </MyBadge>
            </RightNavBtn>
          </Box>
        </Container>
      </Toolbar>
      {searchForm && (
        <ClickAwayListener onClickAway={() => setSearchForm(false)}>
          <Container
            maxWidth="xl"
            disableGutters
            sx={{ backgroundColor: "transparent", mb: 1 }}
          >
            <form
              onSubmit={(e) => component.checkSearch(e)}
              method="get"
              style={{
                width: "100%",
                padding: "0px 10px",
                position: "relative",
              }}
            >
              <Stack direction="row">
                <SearchIconWrapper
                  type="submit"
                  sx={{
                    left: { xs: "15px", md: "23px" },
                    "&:hover": { backgroundColor: "transparent" },
                  }}
                  disableRipple
                  aria-label="Search"
                >
                  <i className="fa-light fa-magnifying-glass"></i>
                </SearchIconWrapper>
                <StyledInputBase
                  sx={{ marginLeft: { xs: "15px", md: "23px" } }}
                  placeholder="Search…"
                  value={component.getData("default.search_term", "")}
                  onChange={(e) => {
                    component.setData({
                      "default.search_term": e.target.value,
                    });
                  }}
                  autoFocus
                  inputRef={inputRef}
                  inputProps={{ "aria-label": "search" }}
                />
                <Button
                  type="submit"
                  sx={{
                    color: "#fff",
                    border: "1px solid #fff",
                    px: 1.5,
                    py: 0,
                    minWidth: 0,
                    minHeight: 0,
                    fontSize: 12,
                    "&:hover": { border: "1px solid #fff" },
                  }}
                  variant="outlined"
                >
                  Explore
                </Button>
              </Stack>
            </form>
          </Container>
        </ClickAwayListener>
      )}

      <Fade in={openMenu} timeout={{ enter: 500, exit: 0 }}>
        <Box sx={{ position: "relative" }}>
          {menu === "womens" && (
            <TopMenu
              onMouseEnter={() => {
                handleToggle(true);
              }}
              onMouseLeave={() => {
                handleToggle(false);
              }}
            >
              <Container maxWidth="xl" disableGutters>
                <Grid
                  container
                  justifycontent="center"
                  direction="row"
                  justify="center"
                  sx={{ py: 2, px: 4, borderBottom: "1px solid #a6a6a6" }}
                >
                  <Grid item xs={2} justifycontent="center">
                    <Title sx={{ mt: "20%" }}>Shop Women's</Title>
                    <ul
                      style={{
                        margin: "0px",
                        padding: "0px",
                        listStyleType: "none",
                      }}
                    >
                      {kwomens &&
                        kwomens.subMenu.women &&
                        kwomens.subMenu.women.map((data, index) => {
                          const { slug, title, url } = data;

                          return (
                            <MyLi key={`kwomens-women-${slug}`}>
                              <MyLink
                                component={RouterLink}
                                to={url}
                                sx={{ mt: index === 0 ? "4px" : 1 }}
                                onClick={() => handleToggle(false)}
                              >
                                {title}
                              </MyLink>
                            </MyLi>
                          );
                        })}
                    </ul>
                  </Grid>
                  <Grid item xs={10} justifycontent="center">
                    <Grid
                      container
                      justifycontent="center"
                      direction="row"
                      justify="center"
                      columnSpacing={3}
                    >
                      {kwomens &&
                        kwomens.subMenu.kwomen &&
                        kwomens.subMenu.kwomen.map((data) => {
                          const { slug, title, url } = data;

                          return (
                            <Grid
                              key={`kwomens-kwomen-${slug}`}
                              item
                              xs={2}
                              justifycontent="center"
                            >
                              <Box
                                key={slug}
                                sx={{
                                  textAlign: "center",
                                  "&:hover": {
                                    "& > div > a > img": {
                                      transform: "scale(1.3)",
                                      webkitTransform: "scale(1.3)",
                                    },
                                  },
                                }}
                              >
                                <Box
                                  className="box-img-zoomed"
                                  sx={{
                                    overflow: "hidden",
                                    borderRadius: "50%",
                                    border: "1px solid #eaeae8",
                                  }}
                                >
                                  <Link
                                    component={RouterLink}
                                    to={url}
                                    onClick={() => handleToggle(false)}
                                  >
                                    <CardMedia
                                      sx={{
                                        maxWidth: "100%",
                                        transition: "transform .5s ease",
                                        webkitTransition: "transform .5s ease",
                                      }}
                                      component="img"
                                      image={`${filesUrl}/konstantino/menu/${slug}.jpg?v=${img_version}`}
                                      alt={slug + " image"}
                                    />
                                  </Link>
                                </Box>
                                <Button
                                  variant="text"
                                  sx={{
                                    fontFamily: "Spartan-Bold",
                                    color: "#000",
                                    fontSize: "13px",
                                    textTransform: "none",
                                    p: 0,
                                    minWidth: 0,
                                    mt: 2,
                                    "&:hover": {
                                      backgroundColor: "transparent",
                                    },
                                  }}
                                  component={RouterLink}
                                  to={url}
                                  disableRipple
                                  onClick={() => handleToggle(false)}
                                >
                                  {title}
                                </Button>
                              </Box>
                            </Grid>
                          );
                        })}
                    </Grid>
                  </Grid>
                </Grid>
                <Grid
                  container
                  justifycontent="center"
                  direction="row"
                  justify="center"
                  sx={{ py: 2, px: 4 }}
                >
                  <Grid item xs={7} justifycontent="center">
                    <Grid
                      container
                      justifycontent="center"
                      direction="row"
                      justify="center"
                    >
                      <Grid item xs={4} justifycontent="center">
                        <Title>Shop By Collection</Title>
                        <ul
                          style={{
                            margin: "0px",
                            padding: "0px",
                            listStyleType: "none",
                          }}
                        >
                          {kwomens &&
                            kwomens.subMenu.collections &&
                            kwomens.subMenu.collections.map((data, index) => {
                              const { slug, title, url } = data;

                              return (
                                <MyLi
                                  key={`kwomens-collections-${slug}`}
                                  sx={{ mt: index === 0 ? "4px" : 1 }}
                                >
                                  <MyLink
                                    component={RouterLink}
                                    to={url}
                                    onClick={() => handleToggle(false)}
                                  >
                                    {title}
                                  </MyLink>
                                </MyLi>
                              );
                            })}
                        </ul>
                      </Grid>
                      <Grid item xs={4} justifycontent="center">
                        <Title>Shop By Curation</Title>
                        <ul
                          style={{
                            margin: "0px",
                            padding: "0px",
                            listStyleType: "none",
                          }}
                        >
                          {kwomens &&
                            kwomens.subMenu.curation &&
                            kwomens.subMenu.curation.map((data, index) => {
                              const { slug, title, url } = data;

                              return (
                                <MyLi key={`kwomens-curation-${slug}`}>
                                  <MyLink
                                    component={RouterLink}
                                    to={url}
                                    sx={{ mt: index === 0 ? "4px" : 1 }}
                                    onClick={() => handleToggle(false)}
                                  >
                                    {title}
                                  </MyLink>
                                </MyLi>
                              );
                            })}
                        </ul>
                      </Grid>
                      <Grid item xs={4} justifycontent="center">
                        <Title>Shop By Metal</Title>
                        <ul
                          style={{
                            margin: "0px",
                            padding: "0px",
                            listStyleType: "none",
                          }}
                        >
                          {kwomens &&
                            kwomens.subMenu.metals &&
                            kwomens.subMenu.metals.map((data, index) => {
                              const { slug, title, url } = data;

                              return (
                                <MyLi key={`kwomens-metals-${slug}`}>
                                  <MyLink
                                    component={RouterLink}
                                    to={url}
                                    sx={{ mt: index === 0 ? "4px" : 1 }}
                                    onClick={() => handleToggle(false)}
                                  >
                                    {title}
                                  </MyLink>
                                </MyLi>
                              );
                            })}
                        </ul>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={5} justifycontent="center">
                    <Title sx={{ mb: "4px" }}>Shop By Stone Color</Title>
                    <Grid
                      container
                      justifycontent="center"
                      direction="row"
                      justify="center"
                      columnSpacing={2}
                      rowSpacing={1}
                    >
                      {kwomens &&
                        kwomens.subMenu.colors &&
                        kwomens.subMenu.colors.map((data, index) => {
                          const { slug, title, url } = data;

                          return (
                            <Grid
                              key={`kwomens-colors-${slug}`}
                              item
                              xs={4}
                              justifycontent="center"
                            >
                              <Link
                                underline="none"
                                sx={{
                                  fontFamily: "Spartan-Medium",
                                  color: "#000",
                                  fontSize: "13px",
                                  p: 0,
                                  display: "flex",
                                  alignItems: "center",
                                  // justifyContent: "space-between",
                                }}
                                component={RouterLink}
                                to={url}
                                onClick={() => handleToggle(false)}
                              >
                                <CardMedia
                                  component="img"
                                  image={`${filesUrl}/konstantino/menu/${slug}.png?v=${img_version}`}
                                  alt={slug + " image"}
                                  sx={{
                                    width: "20px",
                                    height: "20px",
                                    mt: "-4px",
                                    mr: 1,
                                  }}
                                ></CardMedia>
                                {title}
                              </Link>
                            </Grid>
                          );
                        })}
                    </Grid>
                  </Grid>
                </Grid>
              </Container>
            </TopMenu>
          )}
          {menu === "mens" && (
            <TopMenu
              onMouseEnter={() => {
                handleToggle(true);
              }}
              onMouseLeave={() => {
                handleToggle(false);
              }}
            >
              <Container maxWidth="xl" disableGutters>
                <Grid
                  container
                  justifycontent="center"
                  direction="row"
                  justify="center"
                  sx={{ py: 2, px: 4, borderBottom: "1px solid #a6a6a6" }}
                >
                  <Grid item xs={2} justifycontent="center">
                    <Title sx={{ mt: "20%" }}>Shop Men's</Title>
                    <ul
                      style={{
                        margin: "0px",
                        padding: "0px",
                        listStyleType: "none",
                      }}
                    >
                      {kmens &&
                        kmens.subMenu.men &&
                        kmens.subMenu.men.map((data, index) => {
                          const { slug, title, url } = data;

                          return (
                            <MyLi key={`kmens-men-${slug}`}>
                              <MyLink
                                component={RouterLink}
                                to={url}
                                sx={{ mt: index === 0 ? "4px" : 1 }}
                                onClick={() => handleToggle(false)}
                              >
                                {title}
                              </MyLink>
                            </MyLi>
                          );
                        })}
                    </ul>
                  </Grid>
                  <Grid item xs={10} justifycontent="center">
                    <Grid
                      container
                      justifycontent="center"
                      direction="row"
                      justify="center"
                      columnSpacing={3}
                    >
                      {kmens &&
                        kmens.subMenu.kmen &&
                        kmens.subMenu.kmen.map((data) => {
                          const { slug, title, url } = data;

                          return (
                            <Grid
                              key={`kmens-kmen-${slug}`}
                              item
                              xs={2}
                              justifycontent="center"
                              sx={{ textAlign: "center" }}
                            >
                              <Box
                                key={slug}
                                sx={{
                                  textAlign: "center",
                                  "&:hover": {
                                    "& > div > a > img": {
                                      transform: "scale(1.3)",
                                      webkitTransform: "scale(1.3)",
                                    },
                                  },
                                }}
                              >
                                <Box
                                  className="box-img-zoomed"
                                  sx={{
                                    overflow: "hidden",
                                    borderRadius: "50%",
                                    border: "1px solid #eaeae8",
                                  }}
                                >
                                  <Link
                                    component={RouterLink}
                                    to={url}
                                    onClick={() => handleToggle(false)}
                                  >
                                    <CardMedia
                                      sx={{
                                        maxWidth: "100%",
                                        transition: "transform .5s ease",
                                        webkitTransition: "transform .5s ease",
                                      }}
                                      component="img"
                                      image={`${filesUrl}/konstantino/menu/${slug}.jpg?v=${img_version}`}
                                      alt={slug + " image"}
                                    />
                                  </Link>
                                </Box>
                                <Button
                                  variant="text"
                                  sx={{
                                    fontFamily: "Spartan-Bold",
                                    color: "#000",
                                    fontSize: "13px",
                                    textTransform: "none",
                                    p: 0,
                                    minWidth: 0,
                                    mt: 2,
                                    "&:hover": {
                                      backgroundColor: "transparent",
                                    },
                                  }}
                                  component={RouterLink}
                                  to={url}
                                  disableRipple
                                  onClick={() => handleToggle(false)}
                                >
                                  {title}
                                </Button>
                              </Box>
                            </Grid>
                          );
                        })}
                    </Grid>
                  </Grid>
                </Grid>
                <Grid
                  container
                  justifycontent="center"
                  direction="row"
                  justify="center"
                  sx={{ py: 2, px: 4 }}
                >
                  <Grid item xs={7} justifycontent="center">
                    <Grid
                      container
                      justifycontent="center"
                      direction="row"
                      justify="center"
                    >
                      <Grid item xs={4} justifycontent="center">
                        <Title>Shop By Collection</Title>
                        <ul
                          style={{
                            margin: "0px",
                            padding: "0px",
                            listStyleType: "none",
                          }}
                        >
                          {kmens &&
                            kmens.subMenu.collections &&
                            kmens.subMenu.collections.map((data, index) => {
                              const { slug, title, url } = data;

                              return (
                                <MyLi
                                  key={`kmens-collections-${slug}`}
                                  sx={{ mt: index === 0 ? "4px" : 1 }}
                                >
                                  <MyLink
                                    component={RouterLink}
                                    to={url}
                                    onClick={() => handleToggle(false)}
                                  >
                                    {title}
                                  </MyLink>
                                </MyLi>
                              );
                            })}
                        </ul>
                      </Grid>
                      <Grid item xs={4} justifycontent="center">
                        <Title>Shop By Curation</Title>
                        <ul
                          style={{
                            margin: "0px",
                            padding: "0px",
                            listStyleType: "none",
                          }}
                        >
                          {kmens &&
                            kmens.subMenu.curation &&
                            kmens.subMenu.curation.map((data, index) => {
                              const { slug, title, url } = data;

                              return (
                                <MyLi key={`kmens-curation-${slug}`}>
                                  <MyLink
                                    component={RouterLink}
                                    to={url}
                                    sx={{ mt: index === 0 ? "4px" : 1 }}
                                    onClick={() => handleToggle(false)}
                                  >
                                    {title}
                                  </MyLink>
                                </MyLi>
                              );
                            })}
                        </ul>
                      </Grid>
                      <Grid item xs={4} justifycontent="center">
                        <Title>Shop By Metal</Title>
                        <ul
                          style={{
                            margin: "0px",
                            padding: "0px",
                            listStyleType: "none",
                          }}
                        >
                          {kmens &&
                            kmens.subMenu.metals &&
                            kmens.subMenu.metals.map((data, index) => {
                              const { slug, title, url } = data;

                              return (
                                <MyLi key={`kmens-metals-${slug}`}>
                                  <MyLink
                                    component={RouterLink}
                                    to={url}
                                    sx={{ mt: index === 0 ? "4px" : 1 }}
                                    onClick={() => handleToggle(false)}
                                  >
                                    {title}
                                  </MyLink>
                                </MyLi>
                              );
                            })}
                        </ul>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={5} xl={4} justifycontent="center">
                    <Title sx={{ mb: "4px" }}>Shop By Color</Title>
                    <Grid
                      container
                      justifycontent="center"
                      direction="row"
                      justify="center"
                      columnSpacing={2}
                      rowSpacing={1}
                    >
                      {kmens &&
                        kmens.subMenu.colors &&
                        kmens.subMenu.colors.map((data, index) => {
                          const { slug, title, url } = data;

                          return (
                            <Grid
                              key={`kmens-colors-${slug}`}
                              item
                              xs={4}
                              justifycontent="center"
                            >
                              <Link
                                underline="none"
                                sx={{
                                  fontFamily: "Spartan-Medium",
                                  color: "#000",
                                  fontSize: "13px",
                                  p: 0,
                                  display: "flex",
                                  alignItems: "center",
                                  // justifyContent: "space-between",
                                }}
                                component={RouterLink}
                                to={url}
                                onClick={() => handleToggle(false)}
                              >
                                <CardMedia
                                  component="img"
                                  image={`${filesUrl}/konstantino/menu/${slug}.png?v=${img_version}`}
                                  alt={slug + " image"}
                                  sx={{
                                    width: "20px",
                                    height: "20px",
                                    mt: "-4px",
                                    mr: 1,
                                  }}
                                ></CardMedia>
                                {title}
                              </Link>
                            </Grid>
                          );
                        })}
                    </Grid>
                  </Grid>
                </Grid>
              </Container>
            </TopMenu>
          )}
          {menu === "gifts" && (
            <TopMenu
              onMouseEnter={() => {
                handleToggle(true);
              }}
              onMouseLeave={() => {
                handleToggle(false);
              }}
            >
              <Container maxWidth="xl" disableGutters>
                <Box sx={{ float: "right", my: 1 }}>
                  <Typography
                    sx={{
                      fontFamily: "Spartan-Medium",
                      color: "#000",
                      float: "left",
                      fontSize: "13px",
                    }}
                  >
                    Take advatage of free two day ground shipping with in the
                    US.
                  </Typography>
                  <Link
                    sx={{
                      fontFamily: "Spartan-Bold",
                      color: "#000",
                      fontSize: "13px",
                      float: "right",
                      textDecoration: "none",
                      ml: 2,
                      mr: 4,
                    }}
                  >
                    View Details
                  </Link>
                </Box>
                <Grid
                  container
                  justifycontent="center"
                  direction="row"
                  justify="center"
                  sx={{ pb: 2, px: 4 }}
                >
                  <Grid item xs={6} justifycontent="center">
                    <Grid
                      container
                      justifycontent="center"
                      direction="row"
                      alignItems="center"
                      columnSpacing={1}
                    >
                      <Grid item xs={7} justifycontent="center">
                        {/* <Link component={RouterLink} to={giftsOfHerLink}> */}
                        <CardMedia
                          component="img"
                          image={`${filesUrl}/konstantino/menu/womens-gifts.jpg?v=${img_version}`}
                          alt={"womens gifts image"}
                        />
                        {/* </Link> */}
                      </Grid>
                      <Grid
                        item
                        xs={5}
                        justifycontent="center"
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <Box>
                          <Title>Shop Women's Gifts</Title>
                          <ul
                            style={{
                              margin: "0px",
                              padding: "0px",
                              listStyleType: "none",
                            }}
                          >
                            {kgifts &&
                              kgifts.subMenu.womensgifts &&
                              kgifts.subMenu.womensgifts.map((data, index) => {
                                const { slug, title, url } = data;

                                return (
                                  <MyLi key={`kgifts-giftsforher-${slug}`}>
                                    <MyLink
                                      component={RouterLink}
                                      to={url}
                                      sx={{ mt: index === 0 ? "4px" : 1 }}
                                      onClick={() => handleToggle(false)}
                                    >
                                      {title}
                                    </MyLink>
                                  </MyLi>
                                );
                              })}
                          </ul>
                        </Box>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid
                    item
                    xs={6}
                    sx={{ padding: "0px 3px 3px 0px" }}
                    justifycontent="center"
                  >
                    <Grid
                      container
                      justifycontent="center"
                      direction="row"
                      alignItems="center"
                      columnSpacing={1}
                    >
                      <Grid item xs={7} justifycontent="center">
                        {/* <Link component={RouterLink} to={giftsOfHimLink}> */}
                        <CardMedia
                          component="img"
                          image={`${filesUrl}/konstantino/menu/mens-gifts.jpg?v=${img_version}`}
                          alt={"mens gifts image"}
                        />
                        {/* </Link> */}
                      </Grid>
                      <Grid
                        item
                        xs={5}
                        justifycontent="center"
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <Box>
                          <Title>Shop Men's Gifts</Title>
                          <ul
                            style={{
                              margin: "0px",
                              padding: "0px",
                              listStyleType: "none",
                            }}
                          >
                            {kgifts &&
                              kgifts.subMenu.mensgifts &&
                              kgifts.subMenu.mensgifts.map((data, index) => {
                                const { slug, title, url } = data;

                                return (
                                  <MyLi key={`kgifts-giftsforhim-${slug}`}>
                                    <MyLink
                                      component={RouterLink}
                                      to={url}
                                      sx={{ mt: index === 0 ? "4px" : 1 }}
                                      onClick={() => handleToggle(false)}
                                    >
                                      {title}
                                    </MyLink>
                                  </MyLi>
                                );
                              })}
                          </ul>
                        </Box>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Container>
            </TopMenu>
          )}
          {menu === "locations" && (
            <TopMenu
              onMouseEnter={() => {
                handleToggle(true);
              }}
              onMouseLeave={() => {
                handleToggle(false);
              }}
            >
              <Container maxWidth="xl" disableGutters>
                <Grid
                  container
                  justifycontent="center"
                  direction="row"
                  justify="center"
                  sx={{ py: 3, px: 4 }}
                >
                  <Grid
                    item
                    xs={5}
                    justifycontent="center"
                    sx={{
                      textAlign: "center",
                    }}
                  >
                    <Title sx={{ fontSize: "26px" }}>OUR LOCATIONS</Title>
                    <Title sx={{ color: "#000", lineHeight: "18px", mt: 4 }}>
                      KONSTANTINO US Contact Direct
                    </Title>
                    <span
                      style={{
                        color: "#000",
                        fontSize: "12px",
                        fontFamily: "Spartan-SemiBold",
                      }}
                    >
                      Email:
                      <Link
                        href="mailto:info@konstantino.com"
                        underline="none"
                        target="_blank"
                        sx={{
                          fontFamily: "Spartan-SemiBold",
                          color: "#000",
                          fontSize: "12px",
                          pl: "4px",
                        }}
                      >
                        K-U.S.A
                      </Link>
                    </span>
                    <Title sx={{ color: "#000", mt: 4, lineHeight: "18px" }}>
                      KONSTANTINO ATHENS (Flagship Store)
                    </Title>
                    <Link
                      href="https://www.google.com/maps/place/KONSTANTINO+JEWELRY+-+ATHENS/@37.9756587,23.7276433,19.63z/data=!4m5!3m4!1s0x14a1bd3d320b57ad:0x62993108934b74eb!8m2!3d37.9756326!4d23.7278967"
                      rel="noreferrer"
                      target="_blank"
                      underline="none"
                      sx={{
                        fontFamily: "Spartan-Medium",
                        color: "#000",
                        fontSize: "12px",
                        lineHeight: "14px",
                        display: "inline-block",
                        mt: "6px",
                      }}
                    >
                      20, Pandrossou Str. Plaka
                      <br />
                      ATHENS, GREECE
                    </Link>
                    <Box sx={{ mt: -1 }}>
                      <span
                        style={{
                          color: "#000",
                          fontSize: "12px",
                          fontFamily: "Spartan-SemiBold",
                          marginRight: "4px",
                        }}
                      >
                        Tel:
                        <Link
                          href="tel:+302103222701"
                          underline="none"
                          sx={{
                            fontFamily: "Spartan-SemiBold",
                            color: "#000",
                            fontSize: "12px",
                            pl: "4px",
                          }}
                        >
                          +30 (210)322-2701
                        </Link>
                      </span>
                      <span
                        style={{
                          color: "#000",
                          fontSize: "12px",
                          fontFamily: "Spartan-SemiBold",
                        }}
                      >
                        Email:
                        <Link
                          href="mailto:konstantino.gr@gmail.com"
                          target="_blank"
                          underline="none"
                          sx={{
                            fontFamily: "Spartan-SemiBold",
                            color: "#000",
                            fontSize: "12px",
                            pl: "4px",
                          }}
                        >
                          K-Athens
                        </Link>
                      </span>
                    </Box>
                    <Title sx={{ color: "#000", mt: 4, lineHeight: "18px" }}>
                      KONSTANTINO MYKONOS
                    </Title>
                    <Link
                      href="https://www.google.com/maps/place/KONSTANTINO+JEWELRY+-+MYKONOS/@37.444966,25.3255058,17z/data=!3m1!4b1!4m5!3m4!1s0x14a2bf0c301477df:0x7070d7c505b2b4a!8m2!3d37.4449618!4d25.3276945"
                      rel="noreferrer"
                      target="_blank"
                      underline="none"
                      sx={{
                        fontFamily: "Spartan-Medium",
                        color: "#000",
                        fontSize: "12px",
                        lineHeight: "14px",
                        display: "inline-block",
                        mt: "6px",
                      }}
                    >
                      Enoplon Dynameon Str.
                      <br />
                      MYKONOS, GREECE
                    </Link>
                    <Box sx={{ mt: -1 }}>
                      <span
                        style={{
                          color: "#000",
                          fontSize: "12px",
                          fontFamily: "Spartan-SemiBold",
                          marginRight: "4px",
                        }}
                      >
                        Tel:
                        <Link
                          href="tel:+302289023762"
                          underline="none"
                          sx={{
                            fontFamily: "Spartan-SemiBold",
                            color: "#000",
                            fontSize: "12px",
                            pl: "4px",
                          }}
                        >
                          +30 (228)902-3762
                        </Link>
                      </span>
                      <span
                        style={{
                          color: "#000",
                          fontSize: "12px",
                          fontFamily: "Spartan-SemiBold",
                        }}
                      >
                        Email:
                        <Link
                          href="mailto:mykonos@konstantino-jewelry.com"
                          target="_blank"
                          underline="none"
                          sx={{
                            fontFamily: "Spartan-SemiBold",
                            color: "#000",
                            fontSize: "12px",
                            pl: "4px",
                          }}
                        >
                          K-Mykonos
                        </Link>
                      </span>
                    </Box>
                  </Grid>
                  <Grid
                    item
                    xs={7}
                    justifycontent="center"
                    sx={{
                      textAlign: "center",
                    }}
                  >
                    <Container
                      disableGutters
                      sx={{ maxWidth: "800px !important" }}
                    >
                      <Title sx={{ fontSize: "26px", mb: 4 }}>
                        WHERE YOU CAN FIND US
                      </Title>
                      <Grid
                        container
                        justifycontent="center"
                        direction="row"
                        justify="center"
                        sx={{ px: { xs: 1, md: 2, lg: 10 } }}
                        spacing={{ xs: 1, lg: 2 }}
                      >
                        {klocations &&
                          klocations.subMenu.location &&
                          klocations.subMenu.location.map((data) => {
                            const { slug, title, url } = data;

                            return (
                              <Grid
                                key={"klocations-" + slug}
                                item
                                xs={3}
                                justifycontent="center"
                              >
                                <div
                                  style={{
                                    position: "relative",
                                    width: "100%",
                                    height: 0,
                                    paddingBottom: "100%",
                                  }}
                                >
                                  <div
                                    style={{
                                      position: "absolute",
                                      left: 0,
                                      right: 0,
                                      top: 0,
                                      bottom: 0,
                                      display: "flex",
                                      justifyContent: "center",
                                      alignItems: "center",
                                    }}
                                  >
                                    <Button
                                      component={RouterLink}
                                      to={url}
                                      sx={{
                                        width: "100%",
                                        height: "100%",
                                        color: "#000",
                                        backgroundColor: "#ffffff",
                                        textAlign: "center",
                                        border: "2px solid #000",
                                        borderRadius: "0px",
                                        fontSize: "15px",
                                        fontWeight: "600",
                                        "&:hover,&:focus": {
                                          color: "#ffffff",
                                          backgroundColor: "#000",
                                        },
                                      }}
                                      onClick={() => handleToggle(false)}
                                    >
                                      {title}
                                    </Button>
                                  </div>
                                </div>
                              </Grid>
                            );
                          })}
                      </Grid>
                    </Container>
                  </Grid>
                </Grid>
              </Container>
            </TopMenu>
          )}
          {menu === "world" && (
            <TopMenu
              onMouseEnter={() => {
                handleToggle(true);
              }}
              onMouseLeave={() => {
                handleToggle(false);
              }}
            >
              <Container maxWidth="xl" disableGutters sx={{ p: 3 }}>
                <Grid
                  container
                  justifycontent="center"
                  direction="row"
                  justify="center"
                  columnSpacing={0}
                  rowSpacing={0}
                >
                  {kworld &&
                    kworld.subMenu.world &&
                    kworld.subMenu.world.map((data) => {
                      const { slug, title, url } = data;

                      return (
                        <Grid
                          key={`kworld-kworld-${slug}`}
                          item
                          xs={2.4}
                          justifycontent="center"
                          sx={{ textAlign: "center", p: 1 }}
                        >
                          <Link
                            component={RouterLink}
                            to={url}
                            onClick={() => handleToggle(false)}
                          >
                            <Box
                              className="image_shadow"
                              sx={{
                                position: "relative",
                                width: "100%",
                              }}
                            >
                              <Box
                                sx={{
                                  position: "absolute",
                                  top: "50%",
                                  width: "100%",
                                  textAlign: "center",
                                  transform: "translate(0,-50%)",
                                  zIndex: 2,
                                  fontSize: "20px",
                                  color: "#fff",
                                  fontFamily: "Spartan-SemiBold",
                                }}
                              >
                                {title}
                              </Box>
                              <CardMedia
                                sx={{
                                  maxWidth: "100%",
                                }}
                                component="img"
                                image={`${filesUrl}/konstantino/menu/${slug}.jpg?v=${img_version}`}
                                alt={slug + " image"}
                              />
                            </Box>
                          </Link>
                        </Grid>
                      );
                    })}
                </Grid>
              </Container>
            </TopMenu>
          )}
        </Box>
      </Fade>
    </>
  );
}
