import Page from "../../../../modules/Core/Components/Page";
import templateDefault from "../../../templates/default/pages/world/films";

export default class Films extends Page {
  template = templateDefault;

  onAuth() {
    this.refresh();
  }

  onLoad(data) {
    super.onLoad(data);
    this.getApp().updateWindowTitle(
      `${this.ucfirst("Films")} | ${this.ucfirst("window-title")}`
    );
    this.scrollToTop();
  }
}
