import React from "react";
import { styled } from "@mui/material/styles";
import {
  Container,
  Typography,
  Grid,
  Box,
  CircularProgress,
  Link,
  Autocomplete,
  InputAdornment,
  TextField,
} from "@mui/material";
import { autocompleteClasses } from "@mui/material/Autocomplete";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import ClearIcon from "@mui/icons-material/Clear";

const StyledImg = styled("img")`
  width: 100vw;
  height: 100vh;
  object-fit: cover;
  position: fixed;
  top: 0;
  left: 0;
  z-index: -2;
`;

const MyAutocomplete = styled(Autocomplete)(({ theme }) => ({
  color: "#fff",
  [`& .${autocompleteClasses.popupIndicator}`]: {
    transform: "none",
  },
  "& .MuiFormControl-root": {
    "& .MuiFormLabel-root": {
      color: "#fff",
      fontSize: "18px",
      fontFamily: "Poppins-Regular",
    },
    "& .MuiInputBase-root": {
      borderColor: "#fff",
      color: "#fff",
    },
    "& .MuiInputBase-root:after": {
      display: "none",
    },

    "& .MuiInput-underline:before": {
      display: "none",
    },
    "& .MuiButtonBase-root.MuiAutocomplete-clearIndicator": {
      color: "#fff",
      visibility: "visible",
    },
  },
}));

const MyMobileDatePicker = styled(MobileDatePicker)(({ theme }) => ({
  "& .MuiFormLabel-root": {
    color: "#fff",
    fontSize: "18px",
    fontFamily: "Poppins-Regular",
  },
  "& .MuiInputBase-root": {
    borderColor: "#fff",
    color: "#fff",
  },
  "& .MuiInputBase-root:after": {
    display: "none",
  },

  "& .MuiInput-underline:before": {
    display: "none",
  },
  "& .MuiButtonBase-root.MuiAutocomplete-clearIndicator": {
    color: "#fff",
    visibility: "visible",
  },
  "& label.Mui-focused": {
    color: "#fff",
  },
}));
export default function TrunkShows(props) {
  const { component } = props;
  const devHelper = component.getHelpers("dev");
  const filesUrl = component.getHelpers("env").get("files_url");
  const img_version = component.getHelpers("value").getValue("images-version");

  const {
    trunkshows = null,
    stores = [],
    locations = [],
    date = null,
    location = null,
    store = null,
  } = component.getData("default", {});

  const months = [
    component.ucfirst("January"),
    component.ucfirst("February"),
    component.ucfirst("March"),
    component.ucfirst("April"),
    component.ucfirst("May"),
    component.ucfirst("June"),
    component.ucfirst("July"),
    component.ucfirst("August"),
    component.ucfirst("September"),
    component.ucfirst("October"),
    component.ucfirst("November"),
    component.ucfirst("December"),
  ];

  const changeDate = (newValue) => {
    component.setData({
      "default.date": component
        .getHelpers("date-time")
        .createDate(new Date(newValue)),
    });
    component.setTrunkShows();
  };

  const changeLocation = (newValue) => {
    component.setData({ "default.location": newValue });
    component.setTrunkShows();
  };

  const changeStore = (newValue) => {
    component.setData({ "default.store": newValue });
    component.setTrunkShows();
  };

  return (
    <Container
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        px: 4,
      }}
      disableGutters
      maxWidth="xl"
    >
      <StyledImg
        component="img"
        src={`${filesUrl}/konstantino/assets/locations-backround.jpg?v=${img_version}`}
        alt="background image"
      />
      <Box sx={{ textAlign: "center", mt: "150px" }}>
        <Typography
          sx={{
            fontSize: "26px",
            fontFamily: "Poppins-Regular",
            color: "#fff",
          }}
        >
          TRUNK SHOW ANNOUNCEMENTS
        </Typography>
        <Typography
          sx={{
            fontSize: "14px",
            fontFamily: "Spartan-Medium",
            color: "#fff",
            maxWidth: "370px",
            mx: "auto",
            mt: "4px",
          }}
        >
          All the designs are handpicked, piece by piece, by founder and creator
          Konstantino.
        </Typography>
      </Box>
      <Grid
        container
        sx={{
          flexGrow: 1,
          alignItems: "center",
          mt: 3,
        }}
        direction="row"
        rowSpacing={1}
        columnSpacing={{ xs: 0, sm: "4px" }}
      >
        <Grid
          item
          xs={12}
          sm={4}
          justifycontent="center"
          sx={{
            textAlign: "center",
          }}
        >
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <MyMobileDatePicker
              label="FIND BY DATE"
              inputFormat="MM/DD/YYYY"
              value={date}
              onChange={changeDate}
              popupIcon={
                <i
                  className="fa-light fa-location-dot"
                  style={{ color: "#fff" }}
                ></i>
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="standard"
                  sx={{
                    m: 1,
                    maxWidth: "200px",
                    fontSize: "18px",
                    fontFamily: "Poppins-Regular",
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <>
                          <ClearIcon
                            sx={{
                              color: "#fff",
                              visibility: date ? "visible" : "hidden",
                              cursor: date ? "pointer" : "none",
                              mr: "4px",
                            }}
                            fontSize="small"
                            onClick={() => {
                              component.setData({ "default.date": null });
                              component.setTrunkShows();
                            }}
                          />
                          <i
                            className="fa-light fa-calendar-days"
                            style={{
                              color: "#fff",
                              fontSize: "22px",
                              cursor: "pointer",
                            }}
                          ></i>
                        </>
                      </InputAdornment>
                    ),
                  }}
                />
              )}
            />
          </LocalizationProvider>
        </Grid>
        <Grid
          item
          xs={12}
          sm={4}
          justifycontent="center"
          sx={{
            textAlign: "center",
          }}
        >
          <MyAutocomplete
            value={location}
            onChange={(event, newValue) => {
              changeLocation(newValue);
            }}
            options={locations}
            popupIcon={
              <i
                className="fa-light fa-location-dot"
                style={{ color: "#fff", fontSize: "22px" }}
              ></i>
            }
            renderInput={(params) => (
              <TextField
                {...params}
                label="FIND BY LOCATION"
                variant="standard"
                sx={{
                  m: 1,
                  maxWidth: "200px",
                  fontSize: "18px",
                  fontFamily: "Poppins-Regular",
                }}
              />
            )}
          />
        </Grid>
        <Grid
          item
          xs={12}
          sm={4}
          justifycontent="center"
          sx={{
            textAlign: "center",
          }}
        >
          <MyAutocomplete
            value={store}
            onChange={(event, newValue) => {
              changeStore(newValue);
            }}
            options={stores}
            popupIcon={
              <i
                className="fa-light fa-shop"
                style={{ color: "#fff", fontSize: "22px" }}
              ></i>
            }
            renderInput={(params) => (
              <TextField
                {...params}
                label="FIND BY STORE"
                variant="standard"
                sx={{
                  m: 1,
                  maxWidth: "200px",
                  fontSize: "18px",
                  fontFamily: "Poppins-Regular",
                }}
              />
            )}
          />
        </Grid>
      </Grid>
      <Box
        sx={{
          width: "100%",
          mb: 3,
          borderBottom:
            trunkshows && trunkshows.length !== 0 ? "none" : "1px solid #fff",
          borderTop: "1px solid #fff",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          minHeight: "142.69px",
        }}
      >
        {!trunkshows ? (
          <CircularProgress
            sx={{
              color: "#fff",
            }}
          />
        ) : (
          <>
            {trunkshows.length !== 0 ? (
              <Grid
                container
                sx={{
                  flexGrow: 1,
                  alignItems: "stretch",
                  m: 0,
                }}
                direction="row"
                rowSpacing={3}
                columnSpacing={0}
              >
                {trunkshows.map((show) => {
                  const {
                    slug,
                    name,
                    address_text_1 = null,
                    address_text_2 = null,
                    url = null,
                    phone = "",
                    title = null,
                    date_from = null,
                    date_to = null,
                    city = null,
                    extra_info = null,
                  } = devHelper.getObjectValue(show, "_values");

                  var new_phone = "",
                    number = "",
                    country_num = "";

                  if (phone.trim().length >= 10) {
                    number = phone.slice(-10);
                    country_num = phone.slice(0, -10);
                    new_phone =
                      (country_num && country_num + " ") +
                      "(" +
                      number.slice(0, 3) +
                      ")" +
                      number.slice(3, 6) +
                      "-" +
                      number.slice(-4);
                  }

                  return (
                    <Grid
                      item
                      xs={12}
                      justifycontent="center"
                      sx={{
                        textAlign: "center",
                        pb: 3,
                        borderBottom: "1px solid #fff",
                      }}
                      key={"show-" + slug}
                    >
                      <Typography
                        sx={{
                          fontFamily: "Poppins-Regular",
                          color: "#fff",
                          fontSize: "16px",
                          lineHeight: "16px",
                          mt: "4px",
                        }}
                      >
                        {name + " - " + city}
                      </Typography>
                      {title && (
                        <Typography
                          sx={{
                            fontFamily: "Spartan-Medium",
                            color: "#fff",
                            fontSize: "12px",
                            lineHeight: "16px",
                          }}
                        >
                          {title}
                        </Typography>
                      )}
                      {date_from && (
                        <Typography
                          sx={{
                            fontFamily: "Spartan-Medium",
                            color: "#fff",
                            fontSize: "12px",
                            lineHeight: "16px",
                          }}
                        >
                          {`${months[date_from.split("-")[1] - 1]} ${
                            date_from.split("-")[2]
                          }`}
                          {date_from !== date_to &&
                            ` -  ${date_to.split("-")[2]}`}
                        </Typography>
                      )}
                      {extra_info && (
                        <Typography
                          sx={{
                            fontFamily: "Spartan-Medium",
                            color: "#fff",
                            fontSize: "12px",
                            lineHeight: "16px",
                          }}
                        >
                          {extra_info}
                        </Typography>
                      )}
                      {url ? (
                        <Link
                          href={url}
                          rel="noreferrer"
                          target="_blank"
                          underline="none"
                          sx={{
                            fontFamily: "Spartan-Bold",
                            color: "#fff",
                            fontSize: "12px",
                            lineHeight: "14px",
                            display: "inline-block",
                            mt: 2,
                          }}
                        >
                          {address_text_1 && address_text_1}
                          <br />
                          {address_text_2 && address_text_2}
                        </Link>
                      ) : (
                        <Link
                          underline="none"
                          sx={{
                            fontFamily: "Spartan-Bold",
                            color: "#fff",
                            fontSize: "12px",
                            lineHeight: "14px",
                            display: "inline-block",
                            mt: 2,
                          }}
                        >
                          {address_text_1 && address_text_1}
                          <br />
                          {address_text_2 && address_text_2}
                        </Link>
                      )}
                      {phone && (
                        <Box sx={{ mt: 1 }}>
                          <span
                            style={{
                              color: "#fff",
                              fontSize: "12px",
                              fontFamily: "Spartan-SemiBold",
                              marginRight: "4px",
                            }}
                          >
                            Tel:
                            <Link
                              href={"tel:" + phone}
                              underline="none"
                              sx={{
                                fontFamily: "Spartan-SemiBold",
                                color: "#fff",
                                fontSize: "12px",
                                pl: "4px",
                              }}
                            >
                              {new_phone}
                            </Link>
                          </span>
                        </Box>
                      )}
                    </Grid>
                  );
                })}
              </Grid>
            ) : (
              <Box
                sx={{
                  textAlign: "center",
                }}
              >
                <Typography sx={{ color: "#fff" }}>
                  NO TRUNK SHOW FOUND
                </Typography>
              </Box>
            )}
          </>
        )}
      </Box>
    </Container>
  );
}
