import { Component } from "../../../../modules/Core/Component";
import templateDefault from "../../../templates/default/widgets/user/coupons";
import Services from "../../../Services/Services";

export default class Coupons extends Component {
  template = templateDefault;

  onLoad(data) {
    super.onLoad(data);
    this.retrieveCoupons();
  }

  retrieveCoupons() {
    Services.get("coupon").then(([couponService]) => {
      couponService
        .getCoupons()
        .then((couponService) => {
          const coupons = couponService.getData("coupons");
          this.setData({
            "default.coupons": coupons,
          });
        })
        .catch((err) => {
          console.log("error", err);
        });
    });
  }
}
