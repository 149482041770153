import React from "react";
import { Box } from "@mui/material";
import NavBar from "../../../Components/Widgets/Navbar";
import MenuDrawer from "../../../Components/Widgets/MenuDrawer";
import SubMenuDrawer from "../../../Components/Widgets/SubMenuDrawer";
import TopBannersSlider from "../../../Components/Widgets/Sliders/TopBannersSlider";
import SimpleBanner from "../../../Components/Widgets/Sliders/SimpleBanner";
import SlickSlider3 from "../../../Components/Widgets/Sliders/SlickSlider3";
import CurationSlider from "../../../Components/Widgets/Sliders/CurationSlider";
import JustIn from "../../../Components/Widgets/Sliders/JustIn";
import ShopByDesignMetal from "../../../Components/Widgets/Sliders/ShopByDesignMetal";
import Title from "../../default/widgets/title";
import CollectionsSlider from "../../default/widgets/sliders/collections_slider";
import Footer from "../widgets/footer";

const ExportDefault = (props) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        minHeight: "100vh",
      }}
    >
      <NavBar
        {...{
          ...props,
          ...{
            bg_color: "transparent",
          },
        }}
      />
      <MenuDrawer {...props} />
      <SubMenuDrawer {...props} />
      <TopBannersSlider
        {...{
          ...props,
          ...{
            fscope: "women-top-banner",
            bottom: "35%",
            left: "3%",
            text_color: "#fff",
            textMaxWidthDesktop: "500px",
            textMaxWidthMobile: "500px",
            lazyLoading: true,
          },
        }}
      />
      <ShopByDesignMetal
        {...{
          ...props,
          ...{
            fscope: "women-types",
            fscope2: "women-metals",
          },
        }}
      />
      <SlickSlider3
        {...{
          ...props,
          ...{
            fscope: "women-types",
          },
        }}
      />
      <SimpleBanner
        {...{
          ...props,
          ...{
            fscope: "women-gold",
            textMaxWidthMobile: "300px",
          },
        }}
      />
      <SlickSlider3
        {...{
          ...props,
          ...{
            fscope: "women-metals",
          },
        }}
      />
      <CurationSlider
        {...{
          ...props,
          ...{
            fscope: "women-curation-slider",
            textMaxWidthDesktop: "530px",
            textMaxWidthMobile: "360px",
          },
        }}
      />
      <JustIn
        {...{
          ...props,
          ...{
            fscope: "women-product-slider",
            slug: "women-product-slider",
            url: "/women/curation/new-arrivals",
          },
        }}
      />
      <Title
        {...{
          ...props,
          ...{
            slug: "women-slider-title",
            titleMaxWidthDesktop: "500px",
            textMaxWidthDesktop: "500px",
            url: "/women/collections",
          },
        }}
      />
      <CollectionsSlider
        {...{
          ...props,
          ...{
            sliders: ["gen-k", "kyma", "karia", "zodiac"],
          },
        }}
      />
      <Footer {...props} />
    </Box>
  );
};

export default ExportDefault;
