import React from "react";
import { Typography, Grid, Box, Button, Container } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import { useTheme } from "@mui/material/styles";
import { collections } from "./collections";

export default function AllCollections(props) {
  const { component, gender } = props;
  const devHelper = component.getHelpers("dev");
  const theme = useTheme();

  return (
    <Box sx={{ bgcolor: "#fff" }}>
      <Container
        maxWidth="xl"
        disableGutters
        sx={{
          px: 2,
          mb: 6,
        }}
      >
        <Box
          sx={{
            py: 6,
            bgcolor: "#F6F6F7",
          }}
        >
          <Box sx={{ px: { xs: 2, md: 5 } }}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Box sx={{ position: "relative", width: "100%" }}>
                <Typography
                  component="h2"
                  align="center"
                  sx={{
                    ...theme.custom.titles.title,
                    ...{
                      position: "relative",
                      zIndex: 1,
                      borderTop: { xs: "2px solid #808080", md: "none" },
                      pt: { xs: 4, md: 0 },
                      mb: 2,
                      "&::before": {
                        display: { xs: "none", md: "block" },
                        content: '""',
                        position: "absolute",
                        borderTop: "2px solid #808080",
                        margin: "0 auto",
                        top: "50%",
                        left: 0,
                        right: 0,
                        bottom: 0,
                        width: "95%",
                        zIndex: -1,
                      },
                      "& span": {
                        bgcolor: "#F6F6F7",
                        px: { xs: 0, md: 4 },
                      },
                    },
                  }}
                >
                  <span>All K-Collections</span>
                </Typography>
              </Box>
              <Typography
                component="h3"
                align="center"
                sx={{
                  ...theme.custom.titles.description,
                  ...{
                    px: 1,
                    mb: 4,
                  },
                }}
              >
                View each collection by tapping the link below.
              </Typography>
              <Grid
                container
                sx={{
                  flexGrow: 1,
                  alignItems: "flex-start",
                  justifyContent: "center",
                }}
                direction="row"
                columnSpacing={0}
                rowSpacing={4}
              >
                {Object.keys(collections)
                  .sort()
                  .filter((col) => {
                    const url = devHelper.getObjectValue(
                      collections[col],
                      "url"
                    );
                    return url.split("/").includes(gender);
                  })
                  .map((col) => {
                    const url = devHelper.getObjectValue(
                      collections[col],
                      "url"
                    );
                    const name = devHelper.getObjectValue(
                      collections[col],
                      "name"
                    );

                    return (
                      <Grid
                        item
                        md={3}
                        sm={4}
                        xs={4}
                        justifycontent="center"
                        sx={{ textAlign: "center" }}
                        key={col}
                      >
                        <Button
                          variant="text"
                          sx={{
                            fontFamily: "Poppins-Regular",
                            color: "#000",
                            fontSize: { xs: "15px", sm: "22px", md: "24px" },
                            textTransform: "none",
                            minWidth: 0,
                            "&:hover": {
                              backgroundColor: "transparent",
                            },
                          }}
                          component={RouterLink}
                          to={url}
                          disableRipple
                          fullWidth
                        >
                          {name.toUpperCase()}
                        </Button>
                      </Grid>
                    );
                  })}
              </Grid>
            </Box>
          </Box>
        </Box>
      </Container>
    </Box>
  );
}
