import React from "react";
import { Link as RouterLink } from "react-router-dom";
import { styled } from "@mui/material/styles";
import PropTypes from "prop-types";
import {
  Button,
  TextField,
  Link,
  Typography,
  IconButton,
  OutlinedInput,
  Container,
  InputLabel,
  InputAdornment,
  FormControl,
  Dialog,
  DialogTitle,
  DialogContent,
  FormHelperText,
} from "@mui/material";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import CloseIcon from "@mui/icons-material/Close";
import ForgotPasswordDialog from "../../../Components/Widgets/Dialogs/ForgotPasswordDialog";

const LoginRegisterBtn = styled(Button)(({ theme }) => ({
  margin: theme.spacing(2, 0),
  padding: "12px 14px",
  background: "#000",
  borderRadius: 0,
  border: "1px solid #000",
  color: "#ffffff",
  "&:hover": {
    background: "#000",
    opacity: 0.8,
  },
}));

const MyTextField = styled(TextField)(({ theme }) => ({
  "& label.Mui-focused": {
    color: "#000",
    borderRadius: 0,
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "#000",
    borderRadius: 0,
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "#000",
      borderRadius: 0,
    },
    "&:hover fieldset": {
      borderColor: "#000",
      borderRadius: 0,
    },
    "&.Mui-focused fieldset": {
      borderColor: "#000",
      borderRadius: 0,
    },
  },
}));

const PasswordField = styled(FormControl)(({ theme }) => ({
  marginTop: "16px",
  marginBottom: "8px",
  width: "100%",
  "& label.Mui-focused": {
    color: "#000",
    borderRadius: 0,
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "#000",
    borderRadius: 0,
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "#000",
      borderRadius: 0,
    },
    "&:hover fieldset": {
      borderColor: "#000",
      borderRadius: 0,
    },
    "&.Mui-focused fieldset": {
      borderColor: "#000",
      borderRadius: 0,
    },
  },
}));

const MyDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": { padding: theme.spacing(2) },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const MyDialogTitle = (props) => {
  const { children, onClose, ...other } = props;
  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

MyDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

export default function LogIn(props) {
  const { component } = props;

  const [values, setValues] = React.useState({
    password: "",
    showPassword: false,
  });

  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleLogin = (event) => {
    component.login(event);
  };

  const [open, setOpen] = React.useState(false);

  const redirect = component.getPage().getQuery("redirect");

  return (
    <Container
      component="main"
      maxWidth="xs"
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        py: 3,
      }}
    >
      <Typography variant="h4" component="h1">
        {component.ucfirst("sign-in")}
      </Typography>
      <form style={{ width: "100%", marginTop: 8 }} onSubmit={handleLogin}>
        <MyTextField
          variant="outlined"
          margin="normal"
          fullWidth
          id="email"
          label={component.ucfirst("email-textfield")}
          name="email"
          autoComplete="email"
          helperText={component.getData("error.fields.auth.username")}
          value={component.getData("default.fields.auth.username", "")}
          onChange={(e) => {
            component.setData({
              "default.fields.auth.username": e.target.value,
            });
            component.deleteData("error.fields.auth.username");
          }}
          error={component.dataExists("error.fields.auth.username")}
        />
        <PasswordField
          variant="outlined"
          error={component.dataExists("error.fields.auth.password")}
        >
          <InputLabel>{component.ucfirst("password-textfield")}</InputLabel>
          <OutlinedInput
            type={values.showPassword ? "text" : "password"}
            value={component.getData("default.fields.auth.password", "")}
            onChange={(e) => {
              component.setData({
                "default.fields.auth.password": e.target.value,
              });
              component.deleteData("error.fields.auth.password");
            }}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                  edge="end"
                  disableRipple
                  sx={{
                    "&:hover, &:focus": { backgroundColor: "transparent" },
                  }}
                >
                  {values.showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            }
            label={component.ucfirst("password-textfield")}
          />
          {component.dataExists("error.fields.auth.password") && (
            <FormHelperText error style={{ margin: "3px 14px 0" }}>
              {component.getData("error.fields.auth.password")}
            </FormHelperText>
          )}
        </PasswordField>

        <LoginRegisterBtn type="submit" fullWidth variant="contained">
          {component.ucfirst("sign-in-btn")}
        </LoginRegisterBtn>
        <Link
          variant="body2"
          component="button"
          underline="hover"
          type="button"
          onClick={() => setOpen(true)}
          style={{
            color: "#7d7d7d",
          }}
        >
          {component.ucfirst("forgot-password")}
        </Link>
      </form>

      <div
        style={{
          marginTop: 16,
          paddingTop: 16,
          borderTop: "1px solid rgba(0,0,0,.1)",
          width: "100%",
          textAlign: "center",
        }}
      >
        <Typography variant="body1">
          {component.ucfirst("no-account-text")}
        </Typography>
      </div>

      <LoginRegisterBtn
        type="submit"
        fullWidth
        variant="contained"
        component={RouterLink}
        to={`/register` + (redirect ? `?redirect=${redirect}` : "")}
      >
        {component.ucfirst("register-btn")}
      </LoginRegisterBtn>
      <ForgotPasswordDialog
        {...{
          ...props,
          ...{
            open,
            setOpen: (boolean) => setOpen(boolean),
          },
        }}
      />
      <MyDialog
        onClose={() => component.getPage().redirect("/signin")}
        aria-labelledby="customized-dialog-title"
        open={
          component.getPage().getQuery("resetPassword", false) ? true : false
        }
        // open={true}
      >
        <MyDialogTitle
          id="customized-dialog-title"
          onClose={() => component.getPage().redirect("/signin")}
        >
          {component.trans("reset-password-title")}
        </MyDialogTitle>

        <DialogContent dividers>
          <Typography variant="body2">
            {component.trans("reset-password-text-2")}
          </Typography>
          <form onSubmit={(e) => component.resetPassword(e)}>
            <PasswordField
              variant="outlined"
              error={component.dataExists("error.fields.reset.password")}
            >
              <InputLabel>{component.ucfirst("password-textfield")}</InputLabel>
              <OutlinedInput
                type={values.showPassword ? "text" : "password"}
                value={component.getData("default.fields.reset.password", "")}
                onChange={(e) => {
                  component.setData({
                    "default.fields.reset.password": e.target.value,
                  });
                }}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                      disableRipple
                      sx={{
                        "&:hover, &:focus": { backgroundColor: "transparent" },
                      }}
                    >
                      {values.showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                }
                label={component.ucfirst("password-textfield")}
              />
              {component.dataExists("error.fields.password") && (
                <FormHelperText error style={{ margin: "3px 14px 0" }}>
                  {component.getData("error.fields.password")}
                </FormHelperText>
              )}
            </PasswordField>
            <PasswordField
              variant="outlined"
              error={component.dataExists("error.fields.reset.password")}
            >
              <InputLabel>
                {component.ucfirst("confirm-password-textfield")}
              </InputLabel>
              <OutlinedInput
                type={values.showPassword ? "text" : "password"}
                value={component.getData("default.fields.reset.verify", "")}
                onChange={(e) => {
                  component.setData({
                    "default.fields.reset.verify": e.target.value,
                  });
                }}
                label={component.ucfirst("confirm-password-textfield")}
              />
              {component.dataExists("error.fields.verify") && (
                <FormHelperText error style={{ margin: "3px 14px 0" }}>
                  {component.getData("error.fields.verify")}
                </FormHelperText>
              )}
            </PasswordField>
            <LoginRegisterBtn type="submit" fullWidth variant="contained">
              {component.trans("submit-btn")}
            </LoginRegisterBtn>
          </form>
        </DialogContent>
      </MyDialog>
    </Container>
  );
}
