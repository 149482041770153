import { Component } from "../../../modules/Core/Component";
import templateDefault from "../../templates/default/widgets/assistance/assistance";

export default class Assistance extends Component {
  id = "assistance";

  template = templateDefault;

  onLoad(data) {
    super.onLoad(data);

    this.setSection();
  }

  setSection() {
    this.getApp().updateWindowTitle(
      `Assistance | ${this.ucfirst("window-title")}`
    );
    this.setData({
      "default.section": this.getPage().getParam("section", ""),
    });
  }
}
