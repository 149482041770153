import { Component } from "../../../../modules/Core/Component";
import templateDefault from "../../../templates/default/widgets/dialogs/contact_us";
import Services from "../../../Services/Services";

export default class ContactUs extends Component {
  id = "contact-us";

  template = templateDefault;

  onLoad(data) {
    super.onLoad(data);
    var open = this.props.open;

    this.setData({
      "default.open": open,
      "default.fields": {
        name: "",
        email: "",
        phone: "",
        message: "",
      },
    });
  }

  sendRequest(e) {
    e.preventDefault();

    const { fields } = this.getData("default", {
      name: "",
      email: "",
      phone: "",
      message: "",
    });

    const error = {
      fields: { message: "", error: false },
    };

    const { name, email, phone, message } = this.getData("default.fields", {});

    if (!name) {
      error.fields["name"] = "Please enter your Name";
      error.fields.error = true;
    }

    if (!email) {
      error.fields["email"] = "Please enter your Email";
      error.fields.error = true;
    } else {
      if (!this.getHelpers("validate").email(email)) {
        error.fields["email"] = this.ucfirst("Invalid Email");
        error.fields.error = true;
      }
    }

    if (phone && phone.length !== 10) {
      error.fields["phone"] = "The total numbers allowed is 10";
      error.fields.error = true;
    }

    if (!message) {
      error.fields["message"] = "Please enter a Message";
      error.fields.error = true;
    }

    this.setData({
      error,
    });

    if (!error.fields.error) {
      Services.get("hook").then(async ([hookService]) => {
        await hookService.post("contact-us", fields);

        this.setData({
          "default.fields": {
            name: "",
            email: "",
            phone: "",
            message: "",
          },
          error: null,
        });

        this.props.setOpen(false);

        this.getComponents()
          .findById("main-message")
          .first()
          .setData({
            "message-duration": 2000,
            "success-message": this.trans("message-send"),
          });
      });
    }
  }
}
