import Api from "../../modules/Core/Helpers/Api";
import Date from "../../modules/Core/Helpers/DateTime";

const api = Api.create("eshop");
const contentApi = Api.create("content");
const shareApi = Api.create("share");

function prepareOptions(options) {
  options = options || {};
  const { params = {} } = options;
  params.date = Date.getCurrentDate();
  options.params = params;
  return options;
}

const ExportDefault = {
  auth: {
    login: function (account, repository, options) {
      return api.post(
        `account/${account}/repository/${repository}/auth/login`,
        options
      );
    },
    // loginFB: function (account, repository, options) {
    //   return api.post(
    //     `account/${account}/repository/${repository}/auth/login/fb`,
    //     options
    //   );
    // },
    // loginGoogle: function (account, repository, options) {
    //   return api.post(
    //     `account/${account}/repository/${repository}/auth/login/google`,
    //     options
    //   );
    // },
    register: function (account, repository, options) {
      return api.post(
        `account/${account}/repository/${repository}/auth/register`,
        options
      );
    },
    password: {
      request: function (account, repository, options) {
        return api.post(
          `account/${account}/repository/${repository}/auth/password/request`,
          options
        );
      },
      reset: function (account, repository, options) {
        return api.post(
          `account/${account}/repository/${repository}/auth/password/reset`,
          options
        );
      },
    },
    profile: {
      update: function (account, repository, options) {
        return api.put(
          `account/${account}/repository/${repository}/auth/profile/update`,
          options
        );
      },
      retrieve: function (account, repository, options) {
        return api.get(
          `account/${account}/repository/${repository}/auth/profile`,
          options
        );
      },
    },
  },
  address: {
    collection: function (account, repository, options) {
      return api.get(
        `account/${account}/repository/${repository}/address`,
        options
      );
    },
    one: function (account, repository, id, options) {
      return api.get(
        `account/${account}/repository/${repository}/address/${id}`,
        options
      );
    },
    create: function (account, repository, options) {
      return api.post(
        `account/${account}/repository/${repository}/address`,
        options
      );
    },
    update: function (account, repository, id, options) {
      return api.put(
        `account/${account}/repository/${repository}/address/${id}`,
        options
      );
    },
    // select: function (account, repository, id, options) {
    //   return api.put(
    //     `account/${account}/repository/${repository}/address/${id}`,
    //     options
    //   );
    // },
    delete: function (account, repository, id, options) {
      return api.delete(
        `account/${account}/repository/${repository}/address/${id}`,
        options
      );
    },
  },
  coupon: {
    collection: function (account, repository, options) {
      return api.get(
        `account/${account}/repository/${repository}/coupon`,
        options
      );
    },
    create: function (account, repository, options) {
      return api.post(
        `account/${account}/repository/${repository}/coupon`,
        options
      );
    },
    delete: function (account, repository, id, options) {
      return api.delete(
        `account/${account}/repository/${repository}/coupon/${id}`,
        options
      );
    },
  },
  user: {
    order: {
      collection: function (account, repository, options) {
        return api.get(
          `account/${account}/repository/${repository}/user/order`,
          options
        );
      },
      one: function (account, repository, id, options) {
        return api.get(
          `account/${account}/repository/${repository}/user/order/${id}`,
          options
        );
      },
    },
  },
  order: {
    retrieve: function (account, repository, options) {
      return api.get(
        `account/${account}/repository/${repository}/order`,
        options
      );
    },
    // create: function (account, repository, options) {
    //   return api.post(
    //     `account/${account}/repository/${repository}/order`,
    //     options
    //   );
    // },
    action: function (account, repository, action, type, options) {
      return api.put(
        `account/${account}/repository/${repository}/action/${action}/type/${type}`,
        options
      );
    },
  },
  business: {
    request: function (account, repository, options) {
      return api.post(
        `account/${account}/repository/${repository}/business/request`,
        options
      );
    },
  },
  /**
   *
   * Shared
   *
   */
  hook: function (account, repository, hook, version, options) {
    options = prepareOptions(options);

    return shareApi.get(
      `content/account/${account}/repository/${repository}/hook/${hook}/${version}`,
      options
    );
  },
  hookPost: function (account, repository, hook, version, options) {
    return contentApi.post(
      `account/${account}/repository/${repository}/hook/${hook}/${version}`,
      options
    );
  },
  languages: function (account, repository, options) {
    options = prepareOptions(options);

    return shareApi.get(
      `content/account/${account}/repository/${repository}/languages`,
      options
    );
  },
  items: function (account, repository, set, options) {
    options = prepareOptions(options);

    // set/item/items/collection?lang=el&page=0&amount=1&condition=and&accessKey=5UjXNsDUjXNDNdsHVZ6NxcpC8cpCdsH6e3m
    return shareApi.get(
      `content/account/${account}/repository/${repository}/set/${set}/items/collection`,
      options
    );
  },
  item: function (account, repository, set, slug, options) {
    options = prepareOptions(options);

    // set/item/items/collection?lang=el&page=0&amount=1&condition=and&accessKey=5UjXNsDUjXNDNdsHVZ6NxcpC8cpCdsH6e3m
    return shareApi.get(
      `content/account/${account}/repository/${repository}/set/${set}/item/${slug}`,
      options
    );
  },
  paymentTypes: function (account, repository, options) {
    options = prepareOptions(options);

    return shareApi.get(
      `eshop/account/${account}/repository/${repository}/payment-types`,
      options
    );
  },
  // categories: function (account, repository, options) {
  //   options = prepareOptions(options);

  //   return shareApi.get(
  //     `content/account/${account}/repository/${repository}/categories`,
  //     options
  //   );
  // },
  // plans: function (account, repository, spot, period, options) {
  //   options = prepareOptions(options);

  //   return shareApi.get(
  //     `coozein/account/${account}/repository/${repository}/spot/${spot}/period/${period}/plans`,
  //     options
  //   );
  // },
  // menus: function (account, repository, spot, period, options) {
  //   options = prepareOptions(options);

  //   return shareApi.get(
  //     `coozein/account/${account}/repository/${repository}/spot/${spot}/period/${period}/menus`,
  //     options
  //   );
  // },
  // menu: {
  //   items: function (account, repository, spot, period, id, options) {
  //     options = prepareOptions(options);

  //     return shareApi.get(
  //       `coozein/account/${account}/repository/${repository}/spot/${spot}/period/${period}/menu/${id}/items`,
  //       options
  //     );
  //   },
  // },
  inquiry: function (account, repository, options) {
    return contentApi.post(
      `account/${account}/repository/${repository}/email/inquiry`,
      options
    );
  },
};

export default ExportDefault;
