import React from "react";
import { CardMedia, Grid } from "@mui/material";

export default function LayoutBannerFullWidth(props) {
  const { image } = props;

  return (
    <Grid item xs={12}>
      <CardMedia
        component="img"
        alt={"banner"}
        image={image}
        sx={{ backgroundColor: "transparent", border: "2px solid #fff" }}
      />
    </Grid>
  );
}
