import Helpers from "../../../modules/Core/Helpers/Helpers";
import MainApi from "../../Api/Main";
import Service from "../Service";

class Url extends Service {
  getAll(options) {
    const {
      account = Helpers.Env.getDefaultAccount(),
      repository = Helpers.Env.getDefaultRepository(),
      params = {},
    } = options || {};

    return new Promise((resolve, reject) => {
      MainApi.items(account, repository, "url", {
        params,
      }).then((r) => {
        if (this.evaluate(r, "urls")) {
          resolve(this);
        } else {
          reject(this);
        }
      });
    });
  }
}

export default new Url();
