import React from "react";
import { useTheme } from "@mui/material/styles";
import {
  Button,
  Box,
  CardMedia,
  Link,
  Container,
  useMediaQuery,
  Typography,
  Fade,
  Grid,
} from "@mui/material";
import { Link as RouterLink } from "react-router-dom";

export default function CurationSlider(props) {
  const { component } = props;
  const theme = useTheme();
  const devHelper = component.getHelpers("dev");
  const filesUrl = component.getHelpers("env").get("files_url");
  const img_version = component.getHelpers("value").getValue("images-version");

  const sliders = component.getData("default.sliders", []);
  const view = useMediaQuery(theme.breakpoints.up("md")) ? "desktop" : "mobile";
  const activeSlide = component.getData("default.active_slide", 0);

  const [timeout, setTimeoutt] = React.useState(0);

  React.useEffect(() => {
    setTimeout(() => {
      setTimeoutt(4000);
    }, 400);
  }, []);

  const {
    titleMaxWidthDesktop = null,
    titleMaxWidthMobile = null,
    textMaxWidthDesktop = null,
    textMaxWidthMobile = null,
  } = component.props;

  const text = (slider, f_color, view) => {
    const { slug = null, url = null } = devHelper.getObjectValue(
      slider,
      "_values"
    );

    return (
      <Box
        sx={{
          backgroundColor: "transparent",
          display: "flex",
          flexDirection: "column",
          px: { xs: 2, md: 0 },
        }}
      >
        {!component
          .trans(`${slug}-small-title_${view}`)
          .includes(`_${view}`) && (
          <Button
            sx={{
              fontFamily: "Spartan-SemiBold",
              color: f_color,
              fontSize: { xs: 12, md: 16 },
              lineHeight: 1,
              mb: 1,
              textTransform: "none",
              p: 0,
              textAlign: "center",
              mx: "auto",
              "&:hover": {
                backgroundColor: "transparent",
              },
            }}
            component={RouterLink}
            to={url}
            disableRipple
          >
            {component.trans(`${slug}-small-title_${view}`)}
          </Button>
        )}
        {!component.trans(`${slug}-title_${view}`).includes(`_${view}`) && (
          <Button
            sx={{
              fontFamily: "Poppins-Regular",
              color: f_color,
              fontSize: { xs: 30, md: 37 },
              textTransform: "none",
              lineHeight: 1,
              textAlign: "center",
              px: 0,
              pt: 0,
              pb: 1,
              maxWidth: {
                xs: titleMaxWidthMobile ? titleMaxWidthMobile : "unset",
                md: titleMaxWidthDesktop ? titleMaxWidthDesktop : "unset",
              },
              mx: "auto",
              "&:hover": {
                backgroundColor: "transparent",
              },
            }}
            component={RouterLink}
            to={url}
            disableRipple
          >
            {component.trans(`${slug}-title_${view}`)}
          </Button>
        )}
        {!component
          .trans(`${slug}-description_${view}`)
          .includes(`_${view}`) && (
          <Typography
            variant="body2"
            component="h2"
            sx={{
              fontFamily: "Spartan-Medium",
              color: f_color,
              fontSize: { xs: 12, md: 15 },
              textTransform: "none",
              textAlign: "center",
              p: 0,
              lineHeight: "22px",
              maxWidth: {
                xs: textMaxWidthMobile ? textMaxWidthMobile : "unset",
                md: textMaxWidthDesktop ? textMaxWidthDesktop : "unset",
              },
              mx: "auto",
              "&:hover": {
                backgroundColor: "transparent",
              },
            }}
          >
            {component.trans(`${slug}-description_${view}`)}
          </Typography>
        )}
        {!component.trans(`${slug}-button_${view}`).includes(`_${view}`) && (
          <Button
            sx={{
              fontFamily: "Spartan-Bold",
              color: f_color,
              lineHeight: 1.2,
              fontSize: 15,
              textTransform: "none",
              border: "1px solid",
              p: "16px 32px",
              borderRadius: 0,
              mx: "auto",
              mt: {
                xs: 4,
                md: 2,
              },
              mb: {
                xs: 3,
                md: 0,
              },
              "&:hover": {
                backgroundColor: "transparent",
              },
            }}
            component={RouterLink}
            to={url}
            disableRipple
          >
            {component.trans(`${slug}-button_${view}`)}
          </Button>
        )}
      </Box>
    );
  };

  return (
    <Box sx={{ bgcolor: "#fff" }}>
      {sliders.length !== 0 && (
        <Container
          maxWidth="xl"
          disableGutters
          sx={{
            px: 2,
          }}
        >
          <Box
            sx={{
              py: 6,
              bgcolor: "#F6F6F7",
            }}
          >
            <Box sx={{ px: { xs: 2, md: 5 } }}>
              <Grid container sx={{ alignItems: "center" }}>
                <Grid item xs={12} sm={5}>
                  <Box sx={{ position: "relative" }}>
                    {sliders.map((slider, index) => {
                      const { slug = null, url = null } =
                        devHelper.getObjectValue(slider, "_values");
                      return (
                        <Fade
                          key={slug}
                          in={activeSlide === index}
                          timeout={timeout}
                        >
                          <Link component={RouterLink} to={url}>
                            <CardMedia
                              component="img"
                              sx={{
                                position: index === 0 ? "relative" : "absolute",
                                top: 0,
                                left: 0,
                                zIndex: 0,
                              }}
                              image={`${filesUrl}/konstantino/sliders/desktop/${slug}.jpg?v=${img_version}`}
                              alt={component.trans(
                                `${devHelper.getObjectValue(
                                  slider,
                                  "_values.slug"
                                )} image`
                              )}
                              onLoad={() =>
                                component.setData({
                                  [`default.imageReady-${index}`]: true,
                                })
                              }
                            />
                          </Link>
                        </Fade>
                      );
                    })}
                  </Box>
                </Grid>
                <Grid item xs={12} sm={7}>
                  <Box
                    sx={{
                      bgcolor: { xs: "none", sm: "#ECECEC" },
                      mt: { xs: 6, sm: 0 },
                      py: { xs: 0, sm: 6, md: 10, lg: 18 },
                    }}
                  >
                    {text(sliders[0], "#000", view)}
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Container>
      )}
    </Box>
  );
}
