import React from "react";
import { Box, Typography, Grid, Chip, Button, TextField } from "@mui/material";
import { styled } from "@mui/material/styles";

const MyTextField = styled(TextField)(({ theme }) => ({
  "& label.Mui-focused": {
    color: "#000",
    borderRadius: 0,
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "#000",
    borderRadius: 0,
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "#000",
      borderRadius: 0,
    },
    "&:hover fieldset": {
      borderColor: "#000",
      borderRadius: 0,
    },
    "&.Mui-focused fieldset": {
      borderColor: "#000",
      borderRadius: 0,
    },
  },
}));

export default function CouponsSection(props) {
  const { component, order } = props;
  const devHelper = component.getHelpers("dev");

  const coupons = component.getData("default.coupons", []);

  return (
    <Box
      sx={{
        px: 2,
      }}
    >
      <Box>
        {(coupons.length > 0 || devHelper.getObjectValue(order, "coupon")) && (
          <Typography
            sx={{
              fontSize: 16,
              mb: 1,
            }}
          >
            {component.trans(
              devHelper.getObjectValue(order, "coupon")
                ? "active-coupon"
                : "my-coupons"
            )}
          </Typography>
        )}
        <Grid container spacing={1}>
          {devHelper.getObjectValue(order, "coupon") ? (
            <Grid item>
              <Chip
                label={devHelper
                  .getObjectValue(order, "coupon.name")
                  .toUpperCase()}
                variant="filled"
                color="secondary"
                onDelete={() => component.releaseCoupon()}
              />
            </Grid>
          ) : (
            <>
              {coupons.map((c) => {
                const { name, _id } = c;

                return (
                  <Grid item key={_id}>
                    <Chip
                      label={name.toUpperCase()}
                      onClick={() => component.activateCoupon(_id)}
                      variant={
                        devHelper.getObjectValue(order, "coupon._id") !== _id
                          ? "outlined"
                          : "filled"
                      }
                      color="secondary"
                      onDelete={
                        devHelper.getObjectValue(order, "coupon._id") !== _id
                          ? undefined
                          : () => component.releaseCoupon()
                      }
                    />
                  </Grid>
                );
              })}
            </>
          )}
        </Grid>
      </Box>
      <Box sx={{ mt: 2 }}>
        <Typography
          sx={{
            fontSize: 16,
          }}
        >
          {component.ucfirst("checkout-subtitle-2-1")}
        </Typography>
        <form onSubmit={(e) => component.updateCouponByCode(e)}>
          <Grid container spacing={1}>
            <Grid item>
              <MyTextField
                margin="normal"
                fullWidth
                name="couponCode"
                label={component.trans("promo-code")}
                type="text"
                id="couponCode"
                placeholder={component.trans("promo-holder")}
                helperText={component.getData("error.couponCode")}
                value={component.getData("default.couponCode", "")}
                onChange={(e) => {
                  component.setData({
                    "default.couponCode": e.target.value,
                  });
                }}
                error={component.dataExists("error.couponCode")}
                sx={{ borderRadius: 0 }}
              />
            </Grid>
            <Grid item>
              <Button
                fullWidth
                type="submit"
                sx={{
                  mt: 2,
                  height: "56px",
                  px: 2.5,
                  bgcolor: "#000",
                  borderRadius: 0,
                  border: "1px solid #000",
                  color: "#fff",
                  "&:hover": {
                    bgcolor: "#000",
                  },
                }}
              >
                {component.trans("apply-btn")}
              </Button>
            </Grid>
          </Grid>
        </form>
      </Box>
    </Box>
  );
}
