import Helpers from "../../../modules/Core/Helpers/Helpers";
import Service from "../Service";

class RecentlyViewed extends Service {
  getRecentlyViewed() {
    const viewed = Helpers.State.get("viewed");
    if (viewed) {
      var array = viewed.split(",").reverse();
      return array;
    } else {
      return [];
    }
  }

  addToRecentlyViewed(slug) {
    const Env = Helpers.Env;
    const stateHelper = Helpers.State;
    // stateHelper.remove("viewed", {
    //   domain: Env.get("domain"),
    //   path: "/",
    // });

    const start = Date.now() + 86400000;
    var array = [];

    var viewed = stateHelper.get("viewed", null);
    if (viewed) {
      array = viewed.split(",");
      if (!array.includes(slug)) {
        if (array.length === 8) {
          array.shift();
        }
        array.push(slug);
      }
      stateHelper.set("viewed", array.join(","), {
        domain: Env.get("domain"),
        expires: new Date(start),
        path: "/",
      });
    } else {
      array.push(slug);
      stateHelper.set("viewed", array.join(","), {
        domain: Env.get("domain"),
        expires: new Date(start),
        path: "/",
      });
    }
  }
}

export default new RecentlyViewed();
