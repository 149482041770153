import { Component } from "../../../modules/Core/Component";
import templateDefault from "../../templates/default/widgets/boutiques/boutiques";
import Services from "../../Services/Services";

export default class Boutiques extends Component {
  id = "boutiques";
  template = templateDefault;

  onAuth() {
    this.refresh();
  }

  onLoad(data) {
    super.onLoad(data);

    this.getMenu();
  }

  getMenu() {
    Services.get("menu").then(([menuService]) => {
      menuService
        .getAll()
        .then((menuService) => {
          // const { rows = [] } = menuService.getData("menu", {}) || {};
          this.setData({ "default.mainMenu": menuService.getData("menu", []) });
        })
        .catch((menuService) => {});
    });
  }
}
