import { Component } from "../../../modules/Core/Component";
import templateDefault from "../../templates/default/widgets/product_list_view";
import Services from "../../Services/Services";

export default class ProductListView extends Component {
  id = "procut-list-view";
  group = "wishlist";

  template = templateDefault;

  onLoad(data) {
    super.onLoad(data);

    this.setProduct();

    this.checkWishlist();
  }

  setProduct() {
    var product = { ...this.props.product };

    var { combinations = [], gems = [] } = product._values;

    product.simple = this.simpleProduct(combinations);

    var final_values;

    var has_size = false;

    if (product.simple) {
      var i = combinations.findIndex(
        (comb) => comb.options === "g-default,s-default"
      );

      final_values = combinations[i];
    } else {
      // Add to each gem its sizes
      gems = gems.map((gem) => {
        var values = gem.item._values;
        values.sizes = [];
        var has_combination = false;

        combinations.forEach((combination) => {
          var { options = "" } = combination;

          options = options.split(",");

          if (values.slug === options[0] && combination.active) {
            has_combination = true;
            if (options.length === 2) {
              values.sizes.push(combination);
            } else {
              values.default = combination;
            }
          }
        });
        if (has_combination) {
          //Sort gem's sizes by price
          values.sizes.sort(function (a, b) {
            return a.price - b.price;
          });
          return gem;
        }
        return null;
      });

      gems = gems.filter((gem) => gem !== null);

      //Sort gems by price
      gems.sort(function (a, b) {
        var gemA = a.item._values;
        var gemB = b.item._values;

        var priceA = gemA.sizes.length
          ? gemA.sizes[0].price
          : gemA.default.price;

        var priceB = gemB.sizes.length
          ? gemB.sizes[0].price
          : gemB.default.price;

        return priceA - priceB;
      });

      product._values.gems = gems;

      const { type, value } = this.getPage().getParams();

      if (gems.length) {
        var lastGem = gems.slice(-1)[0].item._values;

        product.maxPrice = lastGem.sizes.length
          ? lastGem.sizes.slice(-1)[0].price
          : lastGem.default.price;

        var firstGem = product._values.gems[0].item._values;

        has_size = firstGem.sizes.length ? true : false;

        final_values = has_size ? firstGem.sizes[0] : firstGem.default;

        product.active_gem = -1;

        if (type === "color" && value) {
          product.active_gem = gems.findIndex((gem) => {
            var values = gem.item._values;
            return values.sizes.length
              ? values.sizes[0].color === value
              : values.default.color === value;
          });
          if (product.active_gem !== -1) {
            has_size = gems[product.active_gem].item._values.sizes.length;
            final_values = has_size
              ? gems[product.active_gem].item._values.sizes[0]
              : gems[product.active_gem].item._values.default;
          }
        }
      } else {
        has_size = true;
        if (combinations.length) {
          final_values = combinations[0];
        }
      }
    }

    this.setProductValues(product, final_values, has_size);
  }

  filterImages(images, sku) {
    var filtered_images = images.filter((img) =>
      img.name.includes(sku.toLowerCase())
    );

    this.setData({
      "default.images": filtered_images,
    });

    return this;
  }

  simpleProduct(combinations) {
    return combinations.some((comb) => comb.options === "g-default,s-default");
  }

  setProductValues(product, values, has_size) {
    const { sku, price, options, stock } = values;

    product._values.sku = sku;
    product._values.price = price;
    product.selected_option = has_size ? "" : options;
    product.has_size = has_size;
    product.in_stock = has_size ? true : stock;

    this.filterImages(product._values.images, sku);

    this.setData({
      "default.product": product,
      "default.fields.size": "",
    });

    return this;
  }

  toggleQuickView(list_product, wishlist) {
    var product = { ...list_product };
    var images = this.getData("default.images");
    this.getComponents()
      .findById("quick-view")
      .forEach((quick_view) => {
        quick_view.setData({ "default.images": images });

        if (product.active_gem === -1) {
          product.active_gem = 0;
        }
        quick_view.setData({ "default.wishlist": wishlist });
        quick_view.setData({ "default.product": product });
        quick_view.toggle();
      });

    list_product.active_gem = -1;
    this.setData({ "default.product": list_product });
  }

  checkWishlist() {
    Services.get("wishlist").then(([wishlistService]) => {
      var wishlist = wishlistService.getWishlist();
      var product = this.getData("default.product");
      this.setData({
        "default.wishlist": wishlist.some((el) => el === product._values.slug),
      });
    });
  }

  addToWishList(slug) {
    Services.get("wishlist").then(([wishlistService]) => {
      wishlistService.addToWishList(slug, this);
    });
  }

  removeFromWishList(slug) {
    Services.get("wishlist").then(([wishlistService]) => {
      wishlistService.removeFromWishList(slug, this);
    });
  }
}
