import { Component } from "../../../modules/Core/Component";
import templateDefault from "../../templates/default/widgets/product/first_section";
import { isMobile } from "react-device-detect";
import Services from "../../Services/Services";

export default class Product extends Component {
  template = templateDefault;
  id = "product";
  group = "wishlist,order";

  onLoad(data) {
    super.onLoad(data);

    this.setProduct();
    this.checkWishlist();
    this.getOrder();
  }

  updateProduct() {
    this.setProduct();
    this.checkWishlist();
    this.getOrder();
  }

  setProduct() {
    this.setData({
      "default.fields.size": "",
    });

    //Get product
    Services.get("product").then(([productService]) => {
      productService
        .getOne({ slug: this.getPage().getParam("slug", "") })
        .then((productService) => {
          productService = productService.service;

          var product = productService.getData("product");

          //Set window title
          this.getApp().updateWindowTitle(
            `${this.ucfirst(
              this.getHelpers("dev").getObjectValue(product, "_values.name")
            )} | ${this.ucfirst("window-title")}`
          );

          var {
            combinations = [],
            gems = [],
            slug,
            sku,
            images = [],
          } = product._values;

          var product_sku = this.getPage().getParam("sku", null);

          if (!product_sku) {
            product_sku = sku;
            this.getPage().redirect(`/product/${slug}/${product_sku}`);
            return;
          }

          this.filterImages(images, product_sku);

          product.simple = this.simpleProduct(combinations);

          var final_values;

          var has_size = false;

          if (product.simple) {
            var i = combinations.findIndex(
              (comb) => comb.options === "g-default,s-default"
            );

            final_values = combinations[i];
          } else {
            //Add to each gem its sizes
            gems = gems.map((gem) => {
              var values = gem.item._values;
              values.sizes = [];
              var has_combination = false;

              combinations.forEach((combination) => {
                var { options = "" } = combination;

                options = options.split(",");

                if (values.slug === options[0] && combination.active) {
                  has_combination = true;
                  if (options.length === 2) {
                    values.sizes.push(combination);
                  } else {
                    values.default = combination;
                  }
                }
              });
              if (has_combination) {
                //Sort gem's sizes by price
                values.sizes.sort(function (a, b) {
                  return a.price - b.price;
                });
                return gem;
              }
              return null;
            });

            gems = gems.filter((gem) => gem !== null);

            //Sort gems by price
            gems.sort(function (a, b) {
              var gemA = a.item._values;
              var gemB = b.item._values;

              var priceA = gemA.sizes.length
                ? gemA.sizes[0].price
                : gemA.default.price;

              var priceB = gemB.sizes.length
                ? gemB.sizes[0].price
                : gemB.default.price;

              return priceA - priceB;
            });

            product._values.gems = gems;

            var index = gems.findIndex((gem) => {
              var gemValues = gem.item._values;

              var temp_sku = gemValues.sizes.length
                ? gemValues.sizes[0].sku
                : gemValues.default.sku;

              return temp_sku === product_sku;
            });

            if (index !== -1) {
              var gemValues = gems[index].item._values;

              has_size = gemValues.sizes.length ? true : false;

              final_values = has_size ? gemValues.sizes[0] : gemValues.default;

              product.active_gem = index;
            } else {
              has_size = true;
              if (combinations.length) {
                final_values = combinations[0];
              }
            }
          }

          this.setProductValues(product, final_values, has_size);
        })
        .catch((productService) => {});
    });
  }

  filterImages = (images, sku) => {
    var filtered_images = images.filter((img) =>
      img.name.includes(sku.toLowerCase())
    );

    this.setData({
      "default.images": filtered_images,
    });

    return this;
  };

  simpleProduct(combinations) {
    return combinations.some((comb) => comb.options === "g-default,s-default");
  }

  setProductValues(product, values, has_size) {
    const { options, stock } = values;

    product._values = { ...product._values, ...values };
    product.selected_option = has_size ? "" : options;
    product.has_size = has_size;
    product.in_stock = has_size ? true : stock;

    this.setData({
      "default.product": product,
    });

    this.getApp().sendToGA4(product, "view_item");

    return this;
  }

  //Change size of product
  changeSize(product, gems, size_slug) {
    var active_gem = product.active_gem;

    this.setData({
      "default.fields.size": size_slug,
      "error.fields": {},
    });

    var size = null;

    if (active_gem !== undefined) {
      size = gems[active_gem].item._values.sizes.find(
        (size) => size.options.split(",")[1] === size_slug
      );
    } else {
      size = product._values.combinations.find(
        (combination) => combination.options === size_slug
      );
    }

    if (size) {
      product._values = { ...product._values, ...size };
      product.selected_option = size.options;
    }

    product.in_stock = size ? size.stock : false;

    this.setData({ "default.product": product });
  }

  getOrder() {
    Services.get("order").then(([orderService]) => {
      orderService
        .fetchOrder()
        .then((orderService) => {
          const order = orderService.getData("order");
          this.setData({ "default.order": order });
        })
        .catch((orderService) => {});
    });
  }

  checkWishlist() {
    Services.get("wishlist").then(([wishlistService]) => {
      var wishlist = wishlistService.getWishlist();
      var product_slug = this.getPage().getParam("slug", null);
      this.setData({
        "default.wishlist": wishlist.some((el) => el === product_slug),
      });
    });
  }

  addToWishList(slug) {
    Services.get("wishlist").then(([wishlistService]) => {
      wishlistService.addToWishList(slug, this);
    });
  }

  removeFromWishList(slug) {
    Services.get("wishlist").then(([wishlistService]) => {
      wishlistService.removeFromWishList(slug, this);
    });
  }

  toggleZoom(images, activeImage) {
    this.getComponents()
      .findById("zoom")
      .forEach((zoom) => {
        zoom.setData({
          "default.images": images,
          "default.activeImage": activeImage,
        });
        zoom.toggle();
      });
  }

  updateCart(product, skuPath, sku, amount) {
    const error = {};
    if (sku === "") {
      const { size } = this.getData("default.fields", "");

      if (!size) {
        error["size"] = "";
      }

      this.setData({
        "error.fields": error,
      });
    }

    if (Object.keys(error).length === 0) {
      Services.get("order").then(async ([orderService]) => {
        const { _id, _values } = product;
        const { combinations } = _values;
        const { upc } =
          combinations.find(({ options }) => options === sku) || {};

        sku = typeof sku === "string" ? sku : sku.join(",");
        amount = this.getProductAmount(product, skuPath, sku) + amount;

        await orderService.updateItem({
          itemId: _id,
          itemType: "product",
          // sku: upc,
          skuStock: upc,
          sku: skuPath ? `${skuPath}:${sku}` : sku,
          amount,
        });

        this.getComponents()
          .findByGroup("order")
          .forEach((order) => {
            order.setData({ "default.order": orderService.getOrder() });
          });

        if (this.getProductAmount(product, skuPath, sku) !== amount) {
          product.in_stock = false;
          this.setData({ "default.product": product });
        } else {
          this.getApp().sendToGA4(product, "add_to_cart", amount);
        }
      });

      if (isMobile) {
        this.getComponents()
          .findById("main-message")
          .first()
          .setData({
            "message-duration": 700,
            "success-message": this.trans("product-added-to-cart-message"),
          });
      } else {
        this.getComponents()
          .findById("cart-popper")
          .first()
          .setPopperOpen(true);

        setTimeout(() => {
          var popper = this.getComponents().findById("cart-popper").first();
          if (popper) {
            popper.setPopperOpen(false);
          }
        }, 2000);
      }
    }
  }

  getProductAmount(product, skuPath, sku) {
    const { items = [] } = this.getData("default.order", {}) || {};

    const { amount = 0 } =
      items.find((orderItem) => {
        return (
          product._id === orderItem.item.id &&
          (!sku || orderItem.sku === `${skuPath}:${sku}`)
        );
      }) || {};

    return amount;
  }

  notifyMe(product) {
    const { selected_option } = product;
    const { slug, sizes = [], combinations = [] } = product._values;
    const combination = combinations.find(
      ({ options }) => options === selected_option
    );

    const { size } = this.getData("default.fields", "");

    var size_value;

    if (size) {
      size_value = sizes.find((item) => item.item._values.slug === size).item
        ._values.name;
    }

    if (combination) {
      const { upc, code } = combination;
      const productInfo = {
        slug,
        combination: selected_option,
        upc,
        code,
      };

      if (size) {
        productInfo.size = size_value;
      }

      this.getPage().setData({ "default.notify": productInfo });
    }
  }

  prepareInquire(product) {
    // const { selected_option } = product;
    const { slug, name, sku } = product._values;

    const link =
      this.getHelpers("env").getPublicUrl() + "/product/" + slug + "/" + sku;

    const productInfo = {
      product: name,
      slug,
      sku,
      link,
    };

    this.getPage().setData({ "default.inquire": productInfo });
  }

  // notifyMe(product) {
  //   const { selected_option } = product;
  //   const { slug, name, sku, sizes = [] } = product._values;

  //   const { size } = this.getData("default.fields", "");

  //   var size_value;
  //   if (size) {
  //     size_value = sizes.find((item) => item.item._values.slug === size).item
  //       ._values.name;
  //   }

  //   const productInfo = {
  //     name,
  //     sku,
  //     combination: selected_option,
  //     link:
  //       this.getHelpers("env").getPublicUrl() + "/product/" + slug + "/" + sku,
  //   };

  //   if (size) {
  //     productInfo.size = size_value;
  //   }

  //   this.getPage().setData({ "default.notify": productInfo });

  //   // this.getComponents()
  //   //   .findById("notify-me")
  //   //   .forEach((dialog) => {
  //   //     dialog.setData({
  //   //       "default.product.name": name,
  //   //       "default.product.sku": sku,
  //   //       "default.product.link":
  //   //         this.getHelpers("env").getPublicUrl() +
  //   //         "/product/" +
  //   //         slug +
  //   //         "/" +
  //   //         sku,
  //   //     });

  //   //     if (size) {
  //   //       dialog.setData({
  //   //         "default.product.size": size_value,
  //   //       });
  //   //     }
  //   //   });
  // }

  shareProduct() {
    const product = this.getData("default.product._values");

    if (product && navigator.canShare) {
      navigator.share({
        title: this.getHelpers("title"),
        text: product.name,
        url: document.location.href,
      });
    }

    return this;
  }
}
