import Page from "../../../modules/Core/Components/Page";
import templateDefault from "../../templates/default/pages/login";

export default class Login extends Page {
  title = "login";

  template = templateDefault;

  onAuth() {
    this.refresh();
  }

  onLoad(data) {
    super.onLoad(data);
    this.getApp().updateWindowTitle(
      `${this.ucfirst("Sign In")} | ${this.ucfirst("window-title")}`
    );
    this.scrollToTop();
  }
}
