import React from "react";
import { Link as RouterLink } from "react-router-dom";
import { styled } from "@mui/material/styles";
import {
  Button,
  TextField,
  Typography,
  IconButton,
  OutlinedInput,
  Container,
  InputLabel,
  InputAdornment,
  FormControl,
  FormHelperText,
  Link,
} from "@mui/material";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

const LoginRegisterBtn = styled(Button)(({ theme }) => ({
  margin: theme.spacing(2, 0),
  padding: "12px 14px",
  background: "#000",
  borderRadius: 0,
  border: "1px solid #000",
  color: "#ffffff",
  "&:hover": {
    opacity: "0.8",
    background: "#000",
  },
}));

const MyTextField = styled(TextField)(({ theme }) => ({
  "& label.Mui-focused": {
    color: "#000",
    borderRadius: 0,
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "#000",
    borderRadius: 0,
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "#000",
      borderRadius: 0,
    },
    "&:hover fieldset": {
      borderColor: "#000",
      borderRadius: 0,
    },
    "&.Mui-focused fieldset": {
      borderColor: "#000",
      borderRadius: 0,
    },
  },
}));

const PasswordField = styled(FormControl)(({ theme }) => ({
  marginTop: "16px",
  marginBottom: "8px",
  width: "100%",
  "& label.Mui-focused": {
    color: "#000",
    borderRadius: 0,
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "#000",
    borderRadius: 0,
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "#000",
      borderRadius: 0,
    },
    "&:hover fieldset": {
      borderColor: "#000",
      borderRadius: 0,
    },
    "&.Mui-focused fieldset": {
      borderColor: "#000",
      borderRadius: 0,
    },
  },
}));

export default function Register(props) {
  const { component } = props;
  const [values, setValues] = React.useState({
    password: "",
    confirm_password: "",
    showPassword: false,
  });

  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleRegister = (event) => {
    component.register(event);
  };

  return (
    <Container
      component="main"
      maxWidth="xs"
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        py: 3,
      }}
    >
      <Typography variant="h4" component="h1">
        {component.ucfirst("register")}
      </Typography>
      <form style={{ width: "100%", marginTop: 8 }} onSubmit={handleRegister}>
        <MyTextField
          variant="outlined"
          margin="normal"
          fullWidth
          id="name"
          label={component.ucfirst("fullname-textfield")}
          name="name"
          autoComplete="name"
          helperText={component.getData("error.fields.name")}
          value={component.getData("default.fields.name", "")}
          onChange={(e) => {
            component.setData({ "default.fields.name": e.target.value });
            component.deleteData("error.fields.name");
          }}
          error={component.dataExists("error.fields.name")}
        />

        <MyTextField
          variant="outlined"
          margin="normal"
          fullWidth
          id="email"
          label={component.ucfirst("email-textfield")}
          name="email"
          autoComplete="email"
          helperText={component.getData("error.fields.username")}
          value={component.getData("default.fields.username", "")}
          onChange={(e) => {
            component.setData({ "default.fields.username": e.target.value });
            component.deleteData("error.fields.username");
          }}
          error={component.dataExists("error.fields.username")}
        />
        <PasswordField
          variant="outlined"
          error={component.dataExists("error.fields.password")}
        >
          <InputLabel htmlFor="outlined-adornment-password">
            {component.ucfirst("password-textfield")}
          </InputLabel>
          <OutlinedInput
            type={values.showPassword ? "text" : "password"}
            value={component.getData("default.fields.password", "")}
            onChange={(e) => {
              component.setData({
                "default.fields.password": e.target.value,
              });
              component.deleteData("error.fields.password");
            }}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                  edge="end"
                  disableRipple
                  sx={{
                    "&:hover, &:focus": { backgroundColor: "transparent" },
                  }}
                >
                  {values.showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            }
            label={component.ucfirst("password-textfield")}
          />
          {component.dataExists("error.fields.password") && (
            <FormHelperText error style={{ margin: "3px 14px 0" }}>
              {component.getData("error.fields.password")}
            </FormHelperText>
          )}
        </PasswordField>

        <PasswordField
          variant="outlined"
          error={component.dataExists("error.fields.verify")}
        >
          <InputLabel htmlFor="outlined-adornment-password">
            {component.ucfirst("confirm-password-textfield")}
          </InputLabel>
          <OutlinedInput
            type={values.showPassword ? "text" : "password"}
            value={component.getData("default.fields.verify", "")}
            onChange={(e) => {
              component.setData({
                "default.fields.verify": e.target.value,
              });
              component.deleteData("error.fields.verify");
            }}
            label={component.ucfirst("confirm-password-textfield")}
          />
          {component.dataExists("error.fields.verify") && (
            <FormHelperText error style={{ margin: "3px 14px 0" }}>
              {component.getData("error.fields.verify")}
            </FormHelperText>
          )}
        </PasswordField>
        <LoginRegisterBtn type="submit" fullWidth variant="contained">
          {component.ucfirst("register-btn")}
        </LoginRegisterBtn>
        <div
          style={{ textAlign: "center", width: "100%", marginBottom: "16px" }}
        >
          <Link
            underline="hover"
            type="button"
            component={RouterLink}
            to="/signin"
            sx={{
              color: "#000",
            }}
          >
            {component.ucfirst("back-to-signin")}
          </Link>
        </div>
      </form>
    </Container>
  );
}
