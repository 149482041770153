export const collections = {
  1993: {
    name: "1993",
    url: "/women/collection/1993",
    blurb: {
      desktop:
        "Experience the timeless elegance and sophistication of Konstantino’s iconic mixed metal designs adorned with intricate engravings.",
      mobile:
        "Experience iconic heritage designs with mixed metals and intricate engravings.",
    },
  },
  alchemy: {
    name: "Alchemy",
    url: "/men/collection/alchemy",
    blurb: {
      desktop:
        "Experience the captivating allure of intricately designed serpentine patterns that exude a unique and irresistible charm.",
      mobile:
        "Experience the captivating allure of intricately designed serpentine patterns.",
    },
  },
  muse: {
    name: "Muse",
    url: "/women/collection/muse",
    blurb: {
      desktop:
        "Explore the divine beauty of our Muse Collection, inspired by the timeless elegance of Ancient Greece.",
      mobile:
        "Explore the divine beauty of our Muse Collection, inspired by the timeless elegance of Ancient Greece.",
    },
  },
  laconia: {
    name: "Laconia",
    url: "/men/collection/laconia",
    blurb: {
      desktop:
        "Explore a fresh wave of men’s designs inspired by ancient Spartan armor through emblematic engravings and motifs.",
      mobile:
        "Explore a fresh wave of men’s designs inspired by ancient Spartan armor through emblematic engravings and motifs.",
    },
  },
  anthos: {
    name: "Anthos",
    url: "/women/collection/anthos",
    blurb: {
      desktop:
        "Explore reinvigorating creations designed that transcend and encapsulate the glamorous age of rock and roll.",
      mobile:
        "Explore reinvigorating creations designed that encapsulate the glamorous age of rock.",
    },
  },
  "delos-2": {
    name: "Delos 2.0",
    url: "/women/collection/delos-2",
    blurb: {
      desktop:
        "Embraces your individuality with designs that allows you to construct alternative looks.",
      mobile:
        "Embraces your individuality with designs that allows you to construct alternative looks.",
    },
  },
  birthstone: {
    name: "Birthstone",
    url: "/women/collection/birthstone",
    blurb: {
      desktop:
        "Grant yourself or a loved one protection with an evil eye birthstone talisman.",
      mobile:
        "Grant yourself or a loved one protection with an evil eye birthstone talisman.",
    },
  },
  "gen-k": {
    name: "Gen-K",
    url: "/women/collection/gen-k",
    blurb: {
      desktop:
        "Discover our revitalized Gen-K collection, featuring new vibrant colors and reimagined designs perfect for mixing and matching with your existing pieces.",
      mobile:
        "Discover our revitalized Gen-K collection, featuring new vibrant colors and reimagined designs.",
    },
    tag: "New",
  },
  angelic: {
    name: "Angelic",
    url: "/women/collection/angelic",
    blurb: {
      desktop:
        "View a heavenly assortment of jewelry that symbolizes hope, guidance, and love.",
      mobile:
        "View a heavenly assortment of jewelry that symbolizes hope, guidance, and love.",
    },
  },
  ultra: {
    name: "Ultra",
    url: "/women/collection/ultra",
    blurb: {
      desktop:
        "View a chic new wave of sterling silver jewelry centered around smokey matte stones of Opal and Mother of Pearl doublets .",
      mobile:
        "View a chic new wave of sterling silver jewelry centered around smokey matte stones of Opal and Mother of Pearl doublets .",
    },
  },
  pythia: {
    name: "Pythia",
    url: "/women/collection/pythia",
    blurb: {
      desktop:
        "Rediscover our Pythia Collection. Now you can shop some of your favorite designs in new refreshing stone colors of Green and Purple Amethyst.",
      mobile:
        "Rediscover Pythia designs in new refreshing stone colors of Green and Purple Amethyst.",
    },
  },
  dome: {
    name: "Dome",
    url: "/women/collection/dome",
    blurb: {
      desktop:
        "Inspired by Byzantine ceilings, our new Dome Collection centers itself around exquisite custom-cut stones and arresting architectural features.",
      mobile:
        "Discover exquisite Dome shaped designs that reflect Byzantine features and stone engraving.",
    },
  },
  astria: {
    name: "Astria",
    url: "/women/collection/astria",
    blurb: {
      desktop:
        "Shop from galactic designs that display all shapes and sizes of voluminous structures.",
      mobile:
        "Shop from galactic designs that display all shapes and sizes of voluminous structures.",
    },
  },
  crosses: {
    name: "Crosses",
    url: "/women/collection/cross",
    blurb: {
      desktop:
        "A timeless compliment that you can have to cherish and hold. Shop from a stunning selection of crosses that pay tribute to older decades of heritage designs.",
      mobile:
        "Discover beautiful cross pendants with timeless and stylish characteristics.",
    },
  },
  paradise: {
    name: "Paradise",
    url: "/women/collection/paradise",
    blurb: {
      desktop:
        "Explore a line of whimsical jewelry that depicts wildlife found on the islands of Greece.",
      mobile:
        "Explore a line of whimsical jewelry that depicts wildlife found on the islands of Greece.",
    },
  },
  callas: {
    name: "Callas",
    url: "/women/collection/callas",
    blurb: {
      desktop:
        "View designs crafted in silky hammered gold with elements that transcend the spirit of Maria Callas.",
      mobile:
        "View designs crafted in hammered gold with elements that transcend Maria Callas.",
    },
  },
  orion: {
    name: "Orion",
    url: "/men/collection/orion",
    blurb: {
      desktop:
        "Create the ultimate layered look with a bold set of detailed designs inspired by the Orion constellation.",
      mobile:
        "Create the ultimate layered with designs inspired by the Orion constellation.",
    },
  },
  perseus: {
    name: "Perseus",
    url: "/men/collection/perseus",
    blurb: {
      desktop:
        "View a rhythmic set of classical creations contrasted by electrifying contemporary elements that elevate each design feature in a fresh and invigorating way.",
      mobile:
        "View a rhythmic set of creations contrasted that play with contemporary elements.",
    },
  },
  kavafi: {
    name: "Kavafi",
    url: "/men/collection/kavafi",
    blurb: {
      desktop:
        "Explore stylish creations inspired by warrior armor from the Archaic and Hellenistic periods designed in 18k gold with precious embedded stones.",
      mobile: "Stylish armor inspired by the Archaic and Hellenistic periods.",
    },
  },
  ark: {
    name: "Ark",
    url: "/men/collection/ark",
    blurb: {
      desktop:
        "Shop from a selection of designs that imbue historical animal characters imprinted on Corundum and Blue Agate stones.",
      mobile:
        "Shop from a selection of designs that imbue historical animal characters imprinted on Corundum and Blue Agate stones.",
    },
  },
  zodiac: {
    name: "Zodiac",
    url: "/women/collection/zodiac",
    blurb: {
      desktop:
        "Choose from 12 enchanting zodiac characters, each masterfully crafted with exquisite detailing that unveils the unique essence of every sign.",
      mobile:
        "Choose from 12 enchanting zodiac characters, each intricately designed to reveal the unique essence of every sign.",
    },
    tag: "New",
  },
  // moon: {
  //   name: "Moon",
  //   url: "/men/collection/moon",
  //   blurb: {
  //     desktop:
  //       "Captivating and bold, each design is illuminated by two distinct stones that symbolize the full moon’s transformation in the midnight sky.",
  //     mobile:
  //       "Captivating and bold, each design features two distinct stones that symbolize the full moon's transformation in the midnight sky.",
  //   },
  //   tag: "New",
  // },
  karia: {
    name: "Karia",
    url: "/women/collection/karia",
    blurb: {
      desktop:
        "Unveil new designs that exude transcendent elegance, with striking chilled details, voluminous silhouettes, and majestic stones.",
      mobile:
        "Unveil new elegant designs with striking chilled details, voluminous silhouettes, and majestic stones.",
    },
    tag: "New",
  },
  kyma: {
    name: "Kyma",
    url: "/women/collection/kyma",
    blurb: {
      desktop:
        "Meticulously crafted to capture the graceful movements and ethereal allure of Aegean waves through clean lines, smooth curves, and sculptural forms.",
      mobile:
        "Meticulously crafted to capture the movements of Aegean waves through clean lines, smooth curves, and sculptural forms.",
    },
    tag: "New",
  },
};
