import React from "react";
import { styled } from "@mui/material/styles";
import {
  Typography,
  Container,
  AccordionDetails as MuiAccordionDetails,
  AccordionSummary as MuiAccordionSummary,
  Accordion as MuiAccordion,
} from "@mui/material";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import Translate from "../../../../../modules/Core/Components/Translate";

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  borderTop: `1px solid ${theme.palette.divider}`,
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&:before": {
    display: "none",
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor: "transparent",
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(0, 2),
}));

const MainTitle = styled(Typography)(({ theme }) => ({
  fontSize: "24px",
  color: "#000",
  fontWeight: "600",
  marginBottom: "15px",
}));

const Title = styled(Typography)(({ theme }) => ({
  fontSize: "16px",
  color: "#000",
  fontWeight: "500",
  "& i": {
    fontSize: "20px",
    marginRight: "8px",
  },
}));

export default function Assistance(props) {
  const { component } = props;

  const sections = [
    { slug: "care-tips" },
    { slug: "shipping" },
    { slug: "returns" },
    { slug: "faqs" },
    { slug: "privacy-policy-and-cookies" },
    { slug: "warranty" },
  ];

  const section = component.getData("default.section", "");

  const handleChange = (new_section) => {
    if (section === new_section) {
      component.setData({
        "default.section": false,
      });
    } else {
      component.getPage().redirect(`/assistance/${new_section}`);
      component.setData({
        "default.section": new_section,
      });
    }
  };

  return (
    <Container
      sx={{
        padding: "0px 20px",
        my: 3,
      }}
      maxWidth="lg"
    >
      <MainTitle variant="h1">CLIENT SERVICES</MainTitle>

      {sections.map((sec, index) => {
        const { slug } = sec;
        return (
          <Accordion
            key={slug}
            expanded={section === slug}
            onChange={() => handleChange(slug)}
          >
            <AccordionSummary>
              <Title variant="h3">
                {component.trans("footer-subtitle-" + (index + 1))}
              </Title>
            </AccordionSummary>
            <AccordionDetails id="assistance-section">
              <Translate
                entity="literal"
                eslug={"assistance-section-" + (index + 1)}
                scope="default"
                slug="default"
                auto
              />
            </AccordionDetails>
          </Accordion>
        );
      })}
    </Container>
  );
}
