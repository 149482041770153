import React from "react";
import { styled } from "@mui/material/styles";
import { Link as RouterLink } from "react-router-dom";
import {
  Typography,
  Container,
  Grid,
  Paper,
  Link,
  CircularProgress,
  Box,
} from "@mui/material";

const MyTypography = styled(Typography)(({ theme }) => ({
  fontFamily: "founders-grotesk,Helvetica,sans-serif",
  whiteSpace: "nowrap",
  width: "100%",
  overflow: "hidden",
  textOverflow: "ellipsis",
}));

const ActionBtn = styled(Link)(({ theme }) => ({
  color: "#000",
  fontSize: "13px",
  fontWeight: "400",
  cursor: "pointer",
  borderBottom: "1px solid #000",
  "&:hover": {
    borderBottom: "1px solid transparent",
  },
}));

export default function Address(props) {
  const { component } = props;
  const devHelper = component.getHelpers("dev");
  const orders = component.getData("default.orders", null);

  const months = [
    component.ucfirst("jan"),
    component.ucfirst("feb"),
    component.ucfirst("mar"),
    component.ucfirst("apr"),
    component.ucfirst("may"),
    component.ucfirst("jun"),
    component.ucfirst("jul"),
    component.ucfirst("aug"),
    component.ucfirst("sep"),
    component.ucfirst("oct"),
    component.ucfirst("nov"),
    component.ucfirst("dec"),
  ];
  const days = [
    component.ucfirst("sunday"),
    component.ucfirst("monday"),
    component.ucfirst("tuesday"),
    component.ucfirst("wednesday"),
    component.ucfirst("thursday"),
    component.ucfirst("friday"),
    component.ucfirst("saturday"),
  ];

  return (
    <Container
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
      maxWidth="xl"
    >
      <Box
        sx={{
          borderTop: "2px solid #000",
          width: "100%",
          textAlign: "center",
          position: "relative",
          zIndex: -1,
          my: 6,
        }}
      >
        <Box
          sx={{
            fontFamily: "Poppins-Regular",
            color: "#000",
            lineHeight: 1,
            mx: "auto",
            textAlign: "center",
            position: "absolute",
            top: "0px",
            left: "50%",
            transform: "translate(-50%, -50%)",
            bgcolor: "#fff",
            px: 5,
            fontSize: "34px",
          }}
        >
          {component.ucfirst("orders")}
        </Box>
      </Box>
      {!orders ? (
        <CircularProgress
          style={{
            margin: "200px 0",
            color: "#000",
          }}
        />
      ) : (
        <>
          {orders.total > 0 ? (
            <Grid
              container
              direction="row"
              alignItems="center"
              rowSpacing={3}
              sx={{ my: "30px" }}
              columnSpacing={{ xs: 1, sm: 2 }}
            >
              {orders.rows.map((order) => {
                const date = devHelper
                  .getObjectValue(order, "_updated_at")
                  .split(" ")
                  .splice(0, 1)
                  .join("");

                const time = devHelper
                  .getObjectValue(order, "_updated_at")
                  .split(" ")
                  .splice(-1)
                  .join("");

                return (
                  <Grid
                    item
                    lg={3}
                    md={4}
                    sm={6}
                    xs={12}
                    key={devHelper.getObjectValue(order, "id")}
                  >
                    <Paper
                      sx={{
                        border: "1px solid #dedede",
                        padding: "20px",
                        margin: "5px 0",
                        backgroundColor: "#ffffff",
                      }}
                      square
                      elevation={2}
                    >
                      <MyTypography variant="subtitle1">
                        <b>{component.ucfirst("order")}:</b>{" "}
                        {devHelper.getObjectValue(order, "id").slice(-8)}
                      </MyTypography>
                      <MyTypography variant="subtitle1">
                        {`${days[new Date(date).getUTCDay()]}, ${
                          months[new Date(date).getMonth()]
                        } ${new Date(date).getDate()} ${new Date(
                          date
                        ).getFullYear()} ${time}`}
                      </MyTypography>
                      <MyTypography variant="subtitle1">
                        <b> {component.ucfirst("items")}:</b>{" "}
                        {devHelper.getObjectValue(order, "amounts.items")}
                      </MyTypography>
                      <MyTypography variant="subtitle1">
                        <b> {component.ucfirst("total")}:</b>{" "}
                        {devHelper.getObjectValue(order, "totals.grand")}{" "}
                        {component.ucfirst("currency-symbol")}
                      </MyTypography>
                      <ActionBtn
                        underline="none"
                        component={RouterLink}
                        to={"/order/" + devHelper.getObjectValue(order, "id")}
                      >
                        {component.ucfirst("view-details-btn")}
                      </ActionBtn>
                    </Paper>
                  </Grid>
                );
              })}
            </Grid>
          ) : (
            <div
              style={{
                textAlign: "center",
                margin: "175px 0",
              }}
            >
              <Typography>{component.ucfirst("zero-orders")}</Typography>
            </div>
          )}
        </>
      )}
    </Container>
  );
}
