import { Component } from "../../../modules/Core/Component";
import templateDefault from "../../templates/default/widgets/locations/locations";
import Services from "../../Services/Services";

export default class Locations extends Component {
  id = "locations";
  template = templateDefault;

  onAuth() {
    this.refresh();
  }

  onLoad(data) {
    super.onLoad(data);

    this.getLocations();
  }

  getLocations() {
    this.getApp().updateWindowTitle(
      this.ucfirst(
        `Location ${this.ucfirst(this.getCountry())} | ${this.ucfirst(
          "window-title"
        )}`
      )
    );

    Services.get("hook").then(([service]) => {
      service
        .exec("location", {
          params: { scope: this.getCountry() },
        })
        .then((response) => {
          const locations = response.getData() || [];
          var stores = [],
            states = [];
          locations.forEach((loc) => {
            const { name = null, location = null } = loc._values;

            if (name) {
              stores.push(name);
            }
            if (location) {
              states.push(location);
            }
          });

          stores = [...new Set(stores)].sort();
          states = [...new Set(states)].sort();

          this.setData({
            "default.locations": locations,
            "default.stores": stores,
            "default.states": states,
            "default.store": null,
            "default.state": null,
          });
        });
    });
  }

  getCountry() {
    return this.getPage().getParam("country", "");
  }

  setLocations() {
    const { state = "", store = "" } = this.getData("default", {});

    Services.get("hook").then(([service]) => {
      service
        .exec("location", {
          params: {
            scope: this.getCountry(),
            location: state ? state : "",
            name: store ? store : "",
          },
        })
        .then((response) => {
          const locations = response.getData() || [];

          this.setData({
            "default.locations": locations,
          });
        });
    });

    return this;
  }
}
