import React from "react";
import { Container, Grid, Typography, CardMedia, Box } from "@mui/material";
import { styled } from "@mui/material/styles";

const Title = styled(Typography)(({ theme }) => ({
  fontFamily: "Poppins-Regular",
  color: "#000",
  fontSize: "34px",
  lineHeight: 1,
}));

const Paragraph = styled(Typography)(({ theme }) => ({
  fontFamily: "Spartan-Medium",
  color: "#000",
  fontSize: "16px",
  marginTop: "16px",
  textAlign: "left",
}));

export default function Films(props) {
  const { component } = props;
  const filesUrl = component.getHelpers("env").get("files_url");
  const img_version = component.getHelpers("value").getValue("images-version");

  return (
    <Container
      disableGutters
      maxWidth="xl"
      sx={{ px: { xs: 3, md: 14 }, mb: 9, py: 10 }}
    >
      <Grid
        container
        sx={{
          alignItems: "center",
          justifyContent: "center",
          mb: { xs: 6, md: 12 },
        }}
        rowSpacing={{ xs: 6, md: 12 }}
        columnSpacing={6}
      >
        {/* text-section-1 */}
        <Grid
          item
          xs={12}
          sx={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Paragraph
            component="h3"
            align="center"
            sx={{
              fontSize: 20,
              maxWidth: 950,
              mx: "auto",
            }}
          >
            “Our designs are expressed through the revival and reinterpretation
            of ancient mythologies, aesthetics and techniques, curated with
            great respect, mastery and risk.”
            <Box
              component="span"
              sx={{
                mt: 4,
                fontSize: 20,
                float: "right",
              }}
            >
              - Konstantino
            </Box>
          </Paragraph>
        </Grid>
        {/* about-image-1 */}
        <Grid
          item
          xs={12}
          md={6}
          sx={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <CardMedia
            component="img"
            alt="about-image-1"
            image={`${filesUrl}/konstantino/about/about-image-1.jpg?v=${img_version}`}
          />
        </Grid>
        <Grid
          item
          xs={12}
          md={6}
          sx={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Title component="h2">4,000 BC</Title>
          <Paragraph component="h3">
            In the heart of the Mediterranean, generations of alchemists,
            artisans and goldsmiths explored the depths of earth's most valued
            materials. Gold, silver, bronze and precious stones inspired the
            creation of fine jewelry, a creative expression of human
            intelligence.
          </Paragraph>
          <Paragraph component="h3">
            Through the ages, Greek goldsmiths have established an exceptionally
            unique presence in the history of fine jewelry, evident across the
            Mediterranean, spanning through the Archaic, Classical, Hellenistic
            and Byzantine eras of Greece. The indestructible nature of fine
            jewelry exudes God-like qualities, a timelessness that surpasses the
            ages of style and trend. The Konstantino style emerged from the
            roots of these traditions, paving the way for its own unique style,
            one re-defining the blueprint of the classical Greek identity.
          </Paragraph>
        </Grid>
        {/* about-image-2 */}
        <Grid
          item
          xs={12}
          md={6}
          sx={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <CardMedia
            component="img"
            alt="about-image-2"
            image={`${filesUrl}/konstantino/about/about-image-2.jpg?v=${img_version}`}
          />
        </Grid>
        <Grid
          item
          xs={12}
          md={6}
          sx={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Title component="h2">1958: Birth of an artist</Title>
          <Paragraph component="h3">
            Konstantino was born in Filiadona, a small village located in the
            heart of Greece, where the Ancient city of Melitea once stood.
            There, he watched his father closely as he worked on the farm,
            building machines and tools of his own, developing a great
            appreciation for the art of hand craftsmanship, history and
            archaeology. As a young boy, Konstantino would uncover broken
            ancient ceramics, coins and marbles in the fields, sparking a
            profound connection to his heritage.
          </Paragraph>
        </Grid>
      </Grid>
      {/* reverse 1 */}
      <Grid
        container
        direction={{ xs: "row", md: "row-reverse" }}
        sx={{
          alignItems: "center",
          justifyContent: "center",
          mb: { xs: 6, md: 12 },
        }}
        rowSpacing={{ xs: 6, md: 12 }}
        columnSpacing={6}
      >
        {/* about-image-3 */}
        <Grid
          item
          xs={12}
          md={6}
          sx={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <CardMedia
            component="img"
            alt="about-image-3"
            image={`${filesUrl}/konstantino/about/about-image-3.jpg?v=${img_version}`}
          />
        </Grid>
        <Grid
          item
          xs={12}
          md={6}
          sx={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Title component="h2">1978: A musical influence</Title>
          <Paragraph component="h3">
            Following his love for music, Konstantino became a disc jockey in
            the city of Athens, collecting everything from jazz, blues and rock
            ‘n roll, to folk music from around the globe. Musical legends and
            artists inspired a stylistic fluidity that introduced an essence of
            glam rock to the scene. Konstantino began curating his own aesthetic
            identity as a creative mind, artist and style icon. Influencing what
            would become the rule breaking spirit that drives the design team at
            Konstantino to this day.
          </Paragraph>
        </Grid>
      </Grid>
      {/* reverse 2 */}
      <Grid
        container
        direction={{ xs: "row", md: "row-reverse" }}
        sx={{
          alignItems: "center",
          justifyContent: "center",
          mb: { xs: 6, md: 12 },
        }}
        rowSpacing={{ xs: 6, md: 12 }}
        columnSpacing={6}
      >
        {/* about-image-4 */}
        <Grid
          item
          xs={12}
          md={6}
          sx={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <CardMedia
            component="img"
            alt="about-image-4"
            image={`${filesUrl}/konstantino/about/about-image-4.jpg?v=${img_version}`}
          />
        </Grid>
        <Grid
          item
          xs={12}
          md={6}
          sx={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Title component="h2">1983: Jewelry saved my life</Title>
          <Paragraph component="h3">
            Konstantino fled the music scene of Athens in search of an oasis, he
            took a weekend trip to the island of Hydra where he ended up living
            for the next 15 years. It was on this magical island that he fell in
            love with jewelry, collecting and curating collections of his own,
            whereby in 1988 the first Konstantino store opened.
          </Paragraph>
        </Grid>
      </Grid>
      <Grid
        container
        sx={{
          alignItems: "center",
          justifyContent: "center",
          mb: { xs: 6, md: 12 },
        }}
        rowSpacing={{ xs: 6, md: 12 }}
        columnSpacing={6}
      >
        {/* about-image-5 */}
        <Grid
          item
          xs={12}
          md={6}
          sx={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <CardMedia
            component="img"
            alt="about-image-2"
            image={`${filesUrl}/konstantino/about/about-image-5.jpg?v=${img_version}`}
          />
        </Grid>
        <Grid
          item
          xs={12}
          md={6}
          sx={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Title component="h2">1991: Annette</Title>
          <Paragraph component="h3">
            Hydra is not only the birthplace of Konstantino’s love for Jewelry
            but is also where Konstantino met his soon to be wife and creative
            partner, Annette. Together, from the ground up, Konstantino Jewelry
            soon established its presence in the United States and global
            market.
          </Paragraph>
        </Grid>
        {/* about-image-6 */}
        <Grid
          item
          xs={12}
          md={6}
          sx={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <CardMedia
            component="img"
            alt="about-image-6"
            image={`${filesUrl}/konstantino/about/about-image-6.jpg?v=${img_version}`}
          />
        </Grid>
        <Grid
          item
          xs={12}
          md={6}
          sx={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Title component="h2">1998: Global Impact</Title>
          <Paragraph component="h3">
            As the admiration and desire for the Konstantino brand spread, the
            dream continued to grow, where now our designs can be found in many
            countries globally. With the addition of a longstanding presence in
            stores across the United States, Konstantino is honored to represent
            an evolution of the Grecian style, to the world of fine jewelry
            lovers.
          </Paragraph>
        </Grid>
      </Grid>
      {/* reverse 3 */}
      <Grid
        container
        direction={{ xs: "row", md: "row-reverse" }}
        sx={{
          alignItems: "center",
          justifyContent: "center",
          mb: { xs: 6, md: 12 },
        }}
        rowSpacing={{ xs: 6, md: 12 }}
        columnSpacing={6}
      >
        {/* about-image-7 */}
        <Grid
          item
          xs={12}
          md={6}
          sx={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <CardMedia
            component="img"
            alt="about-image-7"
            image={`${filesUrl}/konstantino/about/about-image-7.jpg?v=${img_version}`}
          />
        </Grid>
        <Grid
          item
          xs={12}
          md={6}
          sx={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Title component="h2">2016: A Family Future</Title>
          <Paragraph component="h3">
            Konstantino and Annette’s children, Julian and Phaedra joined the
            team, further continuing the legacy of passion and risk for the
            craft of gold smithery and design. This new generation leads with
            knowledge, humility and a natural instinct for the dynamic of the
            Konstantino brand. Preserving the brand’s core philosophies and
            expanding on its already established style, is a challenge that’s
            been taken on with confidence.
          </Paragraph>
        </Grid>
      </Grid>
      <Grid
        container
        sx={{
          alignItems: "center",
          justifyContent: "center",
        }}
        rowSpacing={{ xs: 6, md: 12 }}
        columnSpacing={6}
      >
        {/* text-section-2 */}
        <Grid
          item
          xs={12}
          sx={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Paragraph
            component="h3"
            align="center"
            sx={{
              fontSize: 20,
              maxWidth: 860,
              mx: "auto",
            }}
          >
            “Our master etchers and model makers are so connected to their art,
            they build their own personalized tools to facilitate their unique
            approach to the process.”
            <Box
              component="span"
              sx={{
                mt: 4,
                fontSize: 20,
                float: "right",
              }}
            >
              - Konstantino
            </Box>
          </Paragraph>
        </Grid>
        {/* about-image-8 */}
        <Grid
          item
          xs={12}
          md={6}
          sx={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <CardMedia
            component="img"
            alt="about-image-8"
            image={`${filesUrl}/konstantino/about/about-image-8.jpg?v=${img_version}`}
          />
        </Grid>
        {/* about-image-9 */}
        <Grid
          item
          xs={12}
          md={6}
          sx={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <CardMedia
            component="img"
            alt="about-image-9"
            image={`${filesUrl}/konstantino/about/about-image-9.jpg?v=${img_version}`}
          />
        </Grid>
        {/* text-section-3 */}
        <Grid
          item
          xs={12}
          sx={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Title component="h2">ATELIER</Title>
          <Paragraph component="h3">
            The Konstantino Atelier is located in the heart of the Athenian
            Metropolis, Syntagma Square. Our atelier pays homage to the age-old
            techniques when applying filigree, hand hammering, granulation,
            repousse, etching, sculpting and model making. The Konstantino team
            has evolved, maintaining long lasting relationships of over 20 years
            with its core goldsmiths. By fusing age-old techniques with new
            approaches to the art form, the Konstantino style stands unique in
            its re-approach to these traditions, indisputably Grecian and
            nothing like you’ve seen before.
          </Paragraph>
        </Grid>
        {/* text-section-4 */}
        <Grid
          item
          xs={12}
          sx={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Title component="h2">VISION & PROMISE</Title>
          <Paragraph component="h3">
            The Konstantino brand is committed to upholding its core
            philosophies as it evolves through the ages. Our atelier promises a
            high level of quality control, ensuring our product always exceeds
            the standards. We strive to provide excellent customer service
            through our online shopping experience and hope to see you soon at
            our flagship stores.
          </Paragraph>
        </Grid>
      </Grid>
    </Container>
  );
}
