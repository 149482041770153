import Page from "../../../modules/Core/Components/Page";
import templateDefault from "../../templates/default/pages/results";

export default class Results extends Page {
  id = "results";

  template = templateDefault;

  onAuth() {
    this.refresh();
  }

  onLoad(data) {
    super.onLoad(data);
    this.scrollToTop();
    this.loadCollection(true);
  }

  onUpdate(prevProps, prevState, snapshot) {
    super.onUpdate(prevProps, prevState, snapshot);

    const newPage =
      prevProps.location.pathname !== this.props.location.pathname;

    if (newPage || prevProps.location.search !== this.props.location.search) {
      this.loadCollection(newPage);
    }
  }

  loadCollection(newPage) {
    this.getComponents()
      .findById("collection")
      .forEach((c) => {
        // this.scrollToTop();
        c.loadCollection(newPage);
      });

    return this;
  }
}
