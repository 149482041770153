import React from "react";
import {
  Container,
  Typography,
  Grid,
  Box,
  CircularProgress,
} from "@mui/material";
import ProductListView from "../../../../Components/Widgets/ProductListView";

export default function Wishlist(props) {
  const { component } = props;
  const devHelper = component.getHelpers("dev");
  const products = component.getData("default.products", null);

  return (
    <Container
      sx={{
        pb: 3,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        minHeight: "600px",
      }}
      disableGutters
      maxWidth="xl"
    >
      <Box sx={{ textAlign: "center", py: 3 }}>
        <Typography
          sx={{
            fontFamily: "Poppins-Regular",
            fontSize: "34px",
          }}
        >
          {component.ucfirst("wishlist")}
        </Typography>
      </Box>
      {!products ? (
        <CircularProgress
          sx={{
            my: "80px",
            color: "#000",
          }}
        />
      ) : (
        <>
          {products.length !== 0 ? (
            <Grid
              container
              sx={{
                flexGrow: 1,
                alignItems: "flex-start",
              }}
              direction="row"
              spacing={"4px"}
            >
              {products.map((product, index) => (
                <ProductListView
                  key={devHelper.getObjectValue(product, "_id")}
                  {...{
                    ...props,
                    ...{
                      product,
                      grid: 4,
                    },
                  }}
                />
              ))}
            </Grid>
          ) : (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                "@media (max-width: 899px)": { display: "block" },
              }}
            >
              <Box
                sx={{
                  textAlign: "center",
                  mt: "50px",
                }}
              >
                <Typography>
                  {component.ucfirst("empty-wishlist-message")}
                </Typography>
              </Box>
            </Box>
          )}
        </>
      )}
    </Container>
  );
}
