import React from "react";
import { useEffect, useState } from "react";
import { useTheme } from "@mui/material/styles";
import {
  Button,
  CardMedia,
  Typography,
  Link,
  Box,
  useMediaQuery,
  Fade,
  CircularProgress,
  Skeleton,
} from "@mui/material";
import { Link as RouterLink } from "react-router-dom";

export default function SingleBanner(props) {
  const { component } = props;
  const {
    top = null,
    bottom = null,
    left = null,
    right = null,
    center = null,
    titleMaxWidthDesktop = null,
    titleMaxWidthMobile = null,
    textMaxWidthDesktop = null,
    textMaxWidthMobile = null,
    text_color = "#fff",
    top_right_text = null,
    imageMargin = null,
    texts = {},
    fscope = null,
  } = props;
  const theme = useTheme();
  const view = useMediaQuery(theme.breakpoints.up("md")) ? "desktop" : "mobile";
  const [loader, setLoader] = useState(true);

  const devHelper = component.getHelpers("dev");
  const filesUrl = component.getHelpers("env").get("files_url");
  const img_version = component.getHelpers("value").getValue("images-version");

  useEffect(() => {
    setTimeout(() => {
      setLoader(false);
    }, 1500);
  }, []);

  return (
    <Box sx={{ bgcolor: "#fff" }}>
      <Fade in={loader}>
        <Box
          sx={{
            width: "100vw",
            height: "100vh",
            position: "fixed",
            top: 0,
            left: 0,
            zIndex: 1200,
            bgcolor: "#000",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <CircularProgress sx={{ color: "#fff" }} size={100} thickness={1} />
          <Skeleton
            variant="rectangular"
            width="100%"
            height="100%"
            sx={{ position: "absolute", top: 0, left: 0 }}
          />
        </Box>
      </Fade>
      <Box>
        <Box
          sx={{
            position: "relative",
          }}
        >
          <Link
            component={RouterLink}
            to={devHelper.getObjectValue(texts, `${view}.url`)}
          >
            <CardMedia
              component="img"
              alt={`${fscope}-${view}`}
              image={`${filesUrl}/konstantino/sliders/${view}/${fscope}.webp?v=${img_version}`}
              onLoad={() => setLoader(false)}
            />
          </Link>
          {top_right_text && (
            <Button
              disabled
              sx={{
                position: "absolute",
                background: "transparent",
                zIndex: "1",
                padding: 0,
                minWidth: 0,
                color: text_color,
                textTransform: "none",
                fontFamily: "Spartan-SemiBold",
                "&:disabled": {
                  pointerEvents: "auto",
                  color: text_color,
                },
                "&:hover, &:focus": {
                  background: "transparent",
                },
                top: "3%",
                right: "3%",
                fontSize: { xs: "12px", sm: "14px" },
              }}
            >
              {top_right_text}
            </Button>
          )}
          <Box
            sx={{
              display: { xs: "none", md: "block" },
              position: "absolute",
              top: top ? top : "unset",
              bottom: bottom ? bottom : "unset",
              left: left ? left : "unset",
              right: right ? right : "unset",
              transform: center ? `translate(${center})` : "unset",
            }}
          >
            <Box
              sx={{
                backgroundColor: "transparent",
                display: "flex",
                flexDirection: "column",
                px: { xs: 2, md: 0 },
                mt: imageMargin ? 0 : { xs: 6, md: 0 },
              }}
            >
              {devHelper.getObjectValue(texts, `${view}.name`) && (
                <Button
                  sx={{
                    ...theme.custom.titles.subtitle,
                    ...{
                      color: text_color,
                      textAlign: "center",
                      mx: "auto",
                    },
                  }}
                  component={RouterLink}
                  to={devHelper.getObjectValue(texts, `${view}.url`)}
                  disableRipple
                >
                  {devHelper.getObjectValue(texts, `${view}.name`)}
                </Button>
              )}
              {devHelper.getObjectValue(texts, `${view}.title`) && (
                <Button
                  sx={{
                    ...theme.custom.titles.title,
                    ...{
                      color: text_color,
                      textAlign: "center",
                      maxWidth: {
                        xs: titleMaxWidthMobile ? titleMaxWidthMobile : "unset",
                        md: titleMaxWidthDesktop
                          ? titleMaxWidthDesktop
                          : "unset",
                      },
                      mx: "auto",
                    },
                  }}
                  component={RouterLink}
                  to={devHelper.getObjectValue(texts, `${view}.url`)}
                  disableRipple
                >
                  {devHelper.getObjectValue(texts, `${view}.title`)}
                </Button>
              )}
              {devHelper.getObjectValue(texts, `${view}.blurb`) && (
                <Typography
                  component="h2"
                  sx={{
                    ...theme.custom.titles.description,
                    ...{
                      color: text_color,
                      textAlign: "center",
                      maxWidth: {
                        xs: textMaxWidthMobile ? textMaxWidthMobile : "unset",
                        md: textMaxWidthDesktop ? textMaxWidthDesktop : "unset",
                      },
                      mx: "auto",
                    },
                  }}
                >
                  {devHelper.getObjectValue(texts, `${view}.blurb`)}
                </Typography>
              )}
              {devHelper.getObjectValue(texts, `${view}.button`) && (
                <Button
                  sx={{
                    ...theme.custom.buttons.button1,
                    ...{
                      color: text_color,
                      border: "1px solid " + text_color,
                      mx: "auto",
                      mt: 2,
                    },
                  }}
                  component={RouterLink}
                  to={devHelper.getObjectValue(texts, `${view}.url`)}
                  disableRipple
                >
                  {devHelper.getObjectValue(texts, `${view}.button`)}
                </Button>
              )}
            </Box>
          </Box>
          <Box
            sx={{
              position: "absolute",
              display: { xs: "block", md: "none" },
              bottom: "5%",
              left: "5%",
            }}
          >
            {devHelper.getObjectValue(texts, `${view}.name`) && (
              <Button
                sx={{
                  fontFamily: "Poppins-Regular",
                  color: "#fff",
                  fontSize: { xs: 18, md: 45 },
                  textTransform: "none",
                  lineHeight: 1,
                  textAlign: "center",
                  px: 0,
                  pt: 0,
                  pb: 1,
                  maxWidth: {
                    xs: titleMaxWidthMobile ? titleMaxWidthMobile : "unset",
                    md: titleMaxWidthDesktop ? titleMaxWidthDesktop : "unset",
                  },
                  mx: "auto",
                  "&:hover": {
                    backgroundColor: "transparent",
                  },
                }}
                component={RouterLink}
                to={devHelper.getObjectValue(texts, `${view}.url`)}
                disableRipple
              >
                {devHelper.getObjectValue(texts, `${view}.name`)}
              </Button>
            )}
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
