import React from "react";
import { useTheme } from "@mui/material/styles";
import {
  Typography,
  Button,
  CardMedia,
  Dialog,
  DialogContent,
  Box,
  FormControl,
  Select,
  Skeleton,
  InputLabel,
  MenuItem,
  Link,
  IconButton,
} from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import SwipeableViews from "react-swipeable-views";
import CloseIcon from "@mui/icons-material/Close";
import NotifyMe from "../../../Components/Widgets/Dialogs/NotifyMe";
import Inquire from "../../../Components/Widgets/Dialogs/Inquire";

export default function QuickView(props) {
  const { component } = props;
  const theme = useTheme();

  const devHelper = component.getHelpers("dev");
  const filesUrl = component.getHelpers("env").get("files_url");

  const product = component.getData("default.product", null);

  const [notifyMeOpen, setNotifyMeOpen] = React.useState(false);
  const [inquireOpen, setInquireOpen] = React.useState(false);

  //Filter images
  const [activeImage, setActiveImage] = React.useState(0);

  const images = component.getData("default.images", []);

  const wishlist = component.getData("default.wishlist", false);
  const in_stock = devHelper.getObjectValue(product, "in_stock");
  const gems = devHelper.getObjectValue(product, "_values.gems", []);
  const sizes = devHelper.getObjectValue(product, "_values.sizes", []);
  const slug = devHelper.getObjectValue(product, "_values.slug");
  const collections = devHelper.getObjectValue(
    product,
    "_values.collections",
    []
  );
  const genders = devHelper.getObjectValue(product, "_values.genders", []);

  var collection = collections[0] !== undefined ? collections[0] : null;
  var gender = genders[0] !== undefined ? genders[0] : null;

  //Add or Remove product from Wishlist
  const updateWishList = (boolean) => {
    boolean
      ? component.addToWishList(slug)
      : component.removeFromWishList(slug);

    component.setData("default.wishlist", true);
  };

  //Change product
  const changeProduct = (gem, number) => {
    var gemValues = gem.item._values;

    var has_size = gemValues.sizes.length ? true : false;

    var { sku, price, options, stock } = has_size
      ? gemValues.sizes[0]
      : gemValues.default;

    product._values.sku = sku;
    product._values.price = price;
    product.selected_option = has_size ? "" : options;
    product.has_size = has_size;

    product.in_stock = has_size ? true : stock;

    product.active_gem = number;

    setActiveImage(0);

    component.filterImages(product._values.images, sku);

    component.setData({
      "default.product": product,
      "default.fields.size": "",
    });
  };

  //Change size of product
  const changeSize = (size_slug) => {
    var active_gem = product.active_gem;

    component.setData({
      "default.fields.size": size_slug,
      "error.fields": {},
    });

    var size = null;

    if (active_gem !== undefined) {
      size = gems[active_gem].item._values.sizes.find(
        (size) => size.options.split(",")[1] === size_slug
      );
    } else {
      size = product._values.combinations.find(
        (combination) => combination.options === size_slug
      );
    }

    if (size) {
      product._values.price = size.price;
      product.selected_option = size.options;
    }

    product.in_stock = size ? size.stock : false;

    component.setData({ "default.product": product });
  };

  //Change slider image
  const handleImageChange = (step) => {
    setActiveImage(step);
  };

  const ready = component.getData("default.ready", false);

  return (
    <>
      {product && (
        <Dialog
          onClose={() => {
            component.toggle();
            setActiveImage(0);
          }}
          open={component.getData("default.opened", false)}
          sx={{
            display: { xs: "none", md: "block" },
            "& .MuiDialog-paperScrollPaper": {
              maxWidth: "1200px",
            },
            "& .MuiPaper-rounded": {
              borderRadius: 0,
            },
          }}
        >
          <IconButton
            onClick={() => {
              component.toggle();
              setActiveImage(0);
            }}
            sx={{
              position: "absolute",
              right: 5,
              top: 5,

              color: "#000",
            }}
          >
            <CloseIcon />
          </IconButton>
          <DialogContent
            sx={{
              backgroundColor: "#fff",
              px: { xs: 1, lg: 3 },
              py: { xs: 3 },
              alignItems: "flex-start",
              justifyContent: "center",
              display: "flex",
            }}
          >
            <Box sx={{ display: "inline-block" }}>
              {images.length !== 0 ? (
                images.map((image, index) => (
                  <Box
                    key={"menu_img-" + index}
                    sx={{
                      position: "relative",
                      width: "120px",
                      height: "120px",
                      cursor: "pointer",
                      marginBottom: "10px",
                      "&:hover, &:focus": {
                        opacity: "0.8",
                      },
                    }}
                  >
                    {!component.getData(
                      `default.ready-side-product-${index}`,
                      false
                    ) && (
                      <Skeleton
                        variant="rectangular"
                        width="100%"
                        height="100%"
                        sx={{ position: "absolute", top: 0, left: 0 }}
                      />
                    )}
                    <CardMedia
                      component="img"
                      image={
                        component.getData(
                          `default.img-error-side-product-${index}`,
                          false
                        )
                          ? filesUrl.concat(
                              `/konstantino/assets/product-not-found.jpg`
                            )
                          : filesUrl.concat(
                              `/konstantino/products/${devHelper.getObjectValue(
                                image,
                                "name"
                              )}`
                            )
                      }
                      alt={`${devHelper.getObjectValue(
                        product,
                        "_values.name"
                      )} image ${index}`}
                      style={{
                        maxWidth: "100%",
                        height: "100%",
                      }}
                      onClick={(e) => {
                        handleImageChange(index);
                      }}
                      onLoad={() => {
                        component.setData({
                          [`default.ready-side-product-${index}`]: true,
                        });
                      }}
                      onError={() => {
                        component.setData({
                          [`default.img-error-side-product-${index}`]: true,
                        });
                      }}
                    />
                  </Box>
                ))
              ) : (
                <Box
                  sx={{
                    position: "relative",
                    width: "120px",
                    height: "120px",
                    cursor: "pointer",
                  }}
                ></Box>
              )}
            </Box>
            <Box
              sx={{
                position: "relative",
                display: "inline-block",
                width: "400px",
                height: "500px",
                mx: { xs: 1, lg: 3 },
              }}
            >
              {!ready && (
                <Skeleton
                  variant="rectangular"
                  width="100%"
                  height="100%"
                  sx={{ position: "absolute", top: 0, left: 0 }}
                />
              )}
              <SwipeableViews
                axis={theme.direction === "rtl" ? "x-reverse" : "x"}
                index={activeImage}
                onChangeIndex={handleImageChange}
                enableMouseEvents
              >
                {images.length !== 0 ? (
                  images.map((image, index) => (
                    <Box key={"product-img-" + index} sx={{ height: "100%" }}>
                      <CardMedia
                        style={{
                          maxWidth: "100%",
                        }}
                        component="img"
                        image={
                          component.getData(
                            `default.img-error-product-${index}`,
                            false
                          )
                            ? filesUrl.concat(
                                `/konstantino/assets/product-not-found.jpg`
                              )
                            : filesUrl.concat(
                                `/konstantino/products/${devHelper.getObjectValue(
                                  image,
                                  "name"
                                )}`
                              )
                        }
                        alt={`${devHelper.getObjectValue(
                          product,
                          "_values.name"
                        )} image ${index}`}
                        onLoad={() => {
                          component.setData({ "default.ready": true });
                        }}
                        onError={() => {
                          component.setData({
                            [`default.img-error-product-${index}`]: true,
                          });
                        }}
                      />
                    </Box>
                  ))
                ) : (
                  <Box sx={{ height: "100%" }}>
                    <CardMedia
                      style={{
                        maxWidth: "100%",
                        cursor: "pointer",
                      }}
                      component="img"
                      image={filesUrl.concat(
                        `/konstantino/assets/product-not-found.jpg`
                      )}
                      alt="product not found image"
                      onLoad={() => {
                        component.setData({ "default.ready": true });
                      }}
                      onClick={() => component.toggleZoom(images)}
                    />
                  </Box>
                )}
              </SwipeableViews>
            </Box>
            <Box sx={{ display: "inline-block" }}>
              <Box
                sx={{
                  width: "100%",
                  textAlign: "center",
                  borderBottom: "5px solid #000",
                  px: 1,
                }}
              >
                {collection && (
                  <Button
                    sx={{
                      fontFamily: "Spartan-SemiBold",
                      fontSize: { xs: 14, md: 14 },
                      padding: 0,
                      "&:hover, &:focus": {
                        background: "transparent",
                      },
                    }}
                    component={RouterLink}
                    to={
                      gender
                        ? "/" +
                          devHelper.getObjectValue(
                            gender,
                            "item._values.slug"
                          ) +
                          "/collection/" +
                          devHelper.getObjectValue(
                            collection,
                            "item._values.slug"
                          )
                        : "/"
                    }
                    fullWidth
                    disableRipple
                    onClick={() => {
                      component.toggle();
                      setActiveImage(0);
                    }}
                  >
                    {devHelper.getObjectValue(collection, "item._values.name")}
                  </Button>
                )}
                <Button
                  sx={{
                    fontFamily: "Poppins-Regular",
                    fontSize: { xs: 22, md: 25 },
                    textTransform: "none",
                    padding: 0,
                    mb: "10px",
                    color: "#000",
                    lineHeight: { xs: "26px", md: "26px" },
                    "&:hover, &:focus": {
                      background: "transparent",
                    },
                  }}
                  component={RouterLink}
                  to={
                    "/product/" +
                    slug +
                    "/" +
                    devHelper.getObjectValue(product, "_values.sku")
                  }
                  fullWidth
                  disableRipple
                  onClick={() => {
                    component.toggle();
                    setActiveImage(0);
                  }}
                >
                  {devHelper.getObjectValue(product, "_values.name")}
                </Button>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  mt: 1,
                  px: 1,
                }}
              >
                <Typography
                  sx={{
                    fontFamily: "Poppins-Regular",
                    fontSize: { xs: 22, md: 25 },
                    color: "#000",
                  }}
                >
                  {component
                    .getApp()
                    .priceFormater(
                      devHelper.getObjectValue(product, "_values.price")
                    )}
                </Typography>
                <Button
                  disableRipple
                  id="heart"
                  onClick={(e) => {
                    updateWishList(!wishlist);
                  }}
                  sx={{
                    fontSize: { xs: "25px", md: "25px" },
                    "&:hover": {
                      background: "transparent",
                    },
                  }}
                >
                  <i
                    className={
                      wishlist ? "fa-solid fa-heart" : "fa-light fa-heart"
                    }
                  ></i>
                </Button>
              </Box>
              {/* Gemstones */}
              {gems.length !== 0 && !product.simple && (
                <Box sx={{ mt: 1, px: 1 }}>
                  <Typography
                    sx={{
                      fontFamily: "Spartan-SemiBold",
                      fontSize: { xs: 12, md: 14 },
                      mb: 1,
                      color: "#000",
                    }}
                  >
                    {devHelper.getObjectValue(product, "_values.colorized")
                      ? "COLOR"
                      : "GEMSTONE"}
                  </Typography>
                  <Box sx={{ mb: 1 }}>
                    {gems.map((gem, index) => (
                      <Box
                        key={"gem-" + slug + "-" + index}
                        sx={{
                          position: "relative",
                          cursor: "pointer",
                          width: "35px",
                          height: "35px",
                          display: "inline-block",
                          margin: "0px 3px",
                          borderRadius: "50px",
                          padding: "3px",
                          border:
                            devHelper.getObjectValue(product, "active_gem") ===
                            index
                              ? "1px solid #000"
                              : "1px solid transparent",
                        }}
                      >
                        {!component.getData(
                          `default.ready-gem-${index}`,
                          false
                        ) && (
                          <Skeleton
                            variant="rectangular"
                            width="100%"
                            height="100%"
                            sx={{
                              position: "absolute",
                              top: 0,
                              left: 0,
                              borderRadius: "50px",
                            }}
                          />
                        )}
                        <CardMedia
                          component="img"
                          image={
                            component.getData(
                              `default.img-error-gem-${index}`,
                              false
                            )
                              ? filesUrl.concat(
                                  `/konstantino/assets/gem-not-found.png`
                                )
                              : filesUrl.concat(
                                  `/konstantino/gems/${devHelper.getObjectValue(
                                    gem,
                                    "item._values.slug"
                                  )}.png`
                                )
                          }
                          title={devHelper.getObjectValue(
                            gem,
                            "item._values.name"
                          )}
                          onClick={() => {
                            changeProduct(gem, index);
                          }}
                          onLoad={() => {
                            component.setData({
                              [`default.ready-gem-${index}`]: true,
                            });
                          }}
                          onError={() => {
                            component.setData({
                              [`default.img-error-gem-${index}`]: true,
                            });
                          }}
                        />
                      </Box>
                    ))}
                  </Box>
                </Box>
              )}
              {/* Sizes */}
              {sizes.length !== 0 && product.has_size && (
                <Box sx={{ mt: 1, px: 1 }}>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      mb: 1,
                    }}
                  >
                    <Typography
                      sx={{
                        fontFamily: "Spartan-SemiBold",
                        fontSize: { xs: 12, md: 14 },
                        color: "#000",
                      }}
                    >
                      SIZE
                    </Typography>
                    <Button
                      sx={{
                        visibility: "hidden",
                        fontFamily: "Spartan-SemiBold",
                        fontSize: { xs: 12, md: 14 },
                        color: "#000",
                        "&:hover, &:focus": {
                          background: "transparent",
                        },
                      }}
                      disableRipple
                      endIcon={
                        <i
                          className="fa-regular fa-chevron-right"
                          style={{ marginTop: "-4px", fontSize: "18px" }}
                        ></i>
                      }
                    >
                      size chart
                    </Button>
                  </Box>
                  <FormControl
                    fullWidth
                    variant="standard"
                    sx={{
                      "& .MuiSelect-select": {
                        "&:focus,&:hover": {
                          backgroundColor: "transparent !important",
                        },
                      },
                    }}
                  >
                    <InputLabel
                      sx={{
                        fontFamily: "Spartan-SemiBold",
                        fontSize: { xs: 12, md: 14 },
                        color: "#000",
                      }}
                    >
                      Choose an option
                    </InputLabel>
                    <Select
                      label="Choose an option"
                      onChange={(e) => changeSize(e.target.value)}
                      value={component.getData("default.fields.size", "")}
                      error={component.dataExists("error.fields.size")}
                      IconComponent={(props) => (
                        <i
                          {...props}
                          className={`material-icons ${props.className} fa-regular fa-chevron-down`}
                          style={{
                            fontSize: "18px",
                            color: "#000",
                          }}
                        ></i>
                      )}
                    >
                      {sizes.map((size) => (
                        <MenuItem
                          key={`size-${devHelper.getObjectValue(
                            size,
                            "item._values.slug"
                          )}`}
                          value={devHelper.getObjectValue(
                            size,
                            "item._values.slug"
                          )}
                        >
                          {devHelper.getObjectValue(size, "item._values.name")}
                        </MenuItem>
                      ))}
                      <MenuItem
                        onClick={() => {
                          component.prepareInquire(product);
                          setInquireOpen(true);
                        }}
                        style={{
                          justifyContent: "center",
                        }}
                      >
                        {component.ucfirst("Can't find your size? Inquire")}
                      </MenuItem>
                    </Select>
                  </FormControl>
                </Box>
              )}

              <Box
                sx={{
                  width: "100%",
                  textAlign: "center",
                  borderBottom: "5px solid #000",
                  mt: 3,
                  px: 1,
                }}
              >
                <Button
                  sx={{
                    borderRadius: 0,
                    color: "#fff",
                    backgroundColor: "#000",
                    fontSize: { xs: 14, md: 18 },
                    py: 1,
                    mb: 2,
                    "&:hover": {
                      background: "#000",
                    },
                  }}
                  fullWidth
                  onClick={
                    in_stock
                      ? () => {
                          component.updateCart(
                            product,
                            "combinations.options",
                            product.selected_option,
                            1
                          );
                        }
                      : () => {
                          component.notifyMe(product);
                          setNotifyMeOpen(true);
                        }
                  }
                >
                  {in_stock ? "add to cart" : "notify me"}
                </Button>
                <Link
                  sx={{
                    fontFamily: "Spartan-SemiBold",
                    color: "#000",
                    fontSize: { xs: 14, md: 18 },
                    px: 4,
                    py: 1,
                    cursor: "pointer",
                  }}
                  underline="always"
                  component={RouterLink}
                  to={
                    "/product/" +
                    slug +
                    "/" +
                    devHelper.getObjectValue(product, "_values.sku")
                  }
                  onClick={() => {
                    component.toggle();
                    setActiveImage(0);
                  }}
                >
                  View Full Details
                </Link>
                <Typography
                  sx={{
                    fontFamily: "Spartan-SemiBold",
                    fontSize: { xs: 11, md: 14 },
                    color: "#000",
                    mt: 2,
                    mb: 1,
                  }}
                >
                  AVERAGE DELIVERY TIME: 1-3 WORKING DAYS
                </Typography>
              </Box>
            </Box>
          </DialogContent>
        </Dialog>
      )}
      <NotifyMe
        {...{
          ...props,
          ...{
            open: notifyMeOpen,
            setOpen: (boolean) => setNotifyMeOpen(boolean),
          },
        }}
      />
      <Inquire
        {...{
          ...props,
          ...{
            open: inquireOpen,
            setOpen: (boolean) => setInquireOpen(boolean),
          },
        }}
      />
    </>
  );
}
