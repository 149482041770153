import React from "react";
import { styled } from "@mui/material/styles";
import {
  Box,
  CardMedia,
  Grid,
  Link,
  Typography,
  Fade,
  Container,
} from "@mui/material";
import { Link as RouterLink } from "react-router-dom";

const MyLink = styled(Link)(({ theme }) => ({
  float: "left",
  fontFamily: "Spartan-Bold",
  color: "#000",
  fontSize: "16px",
  textTransform: "none",
  padding: 0,
  width: "100%",
  textDecoration: "none",
  "&:hover": {
    backgroundColor: "transparent",
  },
}));

export default function ShopByDesignMetal(props) {
  const { component } = props;
  const devHelper = component.getHelpers("dev");
  const filesUrl = component.getHelpers("env").get("files_url");
  const img_version = component.getHelpers("value").getValue("images-version");
  const {
    types = [],
    metals = [],
    sliders = [],
  } = component.getData("default", {});

  const activeSlide = component.getData("default.active_slide", 0);

  const [timeout, setTimeoutt] = React.useState(0);

  React.useEffect(() => {
    setTimeout(() => {
      setTimeoutt(1000);
    }, 400);
  }, []);

  return (
    <Box sx={{ bgcolor: "#fff", display: { xs: "none", md: "block" } }}>
      <Container
        maxWidth="xl"
        disableGutters
        sx={{
          mt: 6,
          px: 2,
        }}
      >
        <Box
          sx={{
            py: 6,
            px: { xs: 10, md: 5, lg: 10 },
            bgcolor: "#F6F6F7",
          }}
        >
          {types.length !== 0 && metals.length !== 0 && (
            <Grid
              container
              direction="row"
              sx={{ alignItems: "flex-start", justifyContent: "center" }}
            >
              <Grid
                item
                xs={12}
                md={3}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <Typography
                  component="h2"
                  sx={{
                    fontFamily: "Poppins-SemiBold",
                    fontSize: 30,
                    color: "#000",
                    mt: 5,
                  }}
                >
                  {component.trans("women-types-title")}
                </Typography>
                <ul
                  style={{
                    margin: "0px",
                    padding: "0px",
                    listStyleType: "none",
                  }}
                >
                  {types.map((slider, index) => (
                    <li key={devHelper.getObjectValue(slider, "_values.slug")}>
                      <MyLink
                        component={RouterLink}
                        to={devHelper.getObjectValue(slider, "_values.url")}
                        sx={{ mt: 3 }}
                        onMouseEnter={(e) => {
                          component.startInterval(sliders, index);
                        }}
                      >
                        {devHelper.getObjectValue(slider, "_values.name")}
                      </MyLink>
                    </li>
                  ))}
                </ul>
              </Grid>
              <Grid
                item
                xs={3}
                md={3}
                sx={{
                  display: { xs: "none", md: "flex" },
                  flexDirection: "column",
                }}
              >
                <Typography
                  component="h2"
                  sx={{
                    fontFamily: "Poppins-SemiBold",
                    fontSize: 30,
                    color: "#000",
                    mt: 5,
                  }}
                >
                  {component.trans("women-metals-title")}
                </Typography>
                <ul
                  style={{
                    margin: "0px",
                    padding: "0px",
                    listStyleType: "none",
                  }}
                >
                  {metals.map((slider, index) => (
                    <li key={devHelper.getObjectValue(slider, "_values.slug")}>
                      <MyLink
                        component={RouterLink}
                        to={devHelper.getObjectValue(slider, "_values.url")}
                        sx={{ mt: 3 }}
                        onMouseEnter={(e) => {
                          component.startInterval(
                            sliders,
                            types.length + index
                          );
                        }}
                      >
                        {devHelper.getObjectValue(slider, "_values.name")}
                      </MyLink>
                    </li>
                  ))}
                </ul>
              </Grid>
              <Grid
                item
                xs={6}
                md={6}
                sx={{ display: { xs: "none", md: "flex" } }}
              >
                <Box sx={{ position: "relative" }}>
                  {sliders.map((slider, index) => {
                    const { slug, url } = devHelper.getObjectValue(
                      slider,
                      "_values"
                    );
                    return (
                      <Fade
                        key={slug}
                        in={activeSlide === index}
                        timeout={timeout}
                      >
                        <Box>
                          <Link component={RouterLink} to={url}>
                            <CardMedia
                              component="img"
                              sx={{
                                position: index === 0 ? "relative" : "absolute",
                                top: 0,
                                left: 0,
                                zIndex: 0,
                              }}
                              image={`${filesUrl}/konstantino/sliders/desktop/${slug}.jpg?v=${img_version}`}
                              alt={devHelper.getObjectValue(
                                slider,
                                "_values.name"
                              )}
                              onLoad={() =>
                                component.setData({
                                  [`default.imageReady-${index}`]: true,
                                })
                              }
                            />
                          </Link>
                        </Box>
                      </Fade>
                    );
                  })}
                </Box>
              </Grid>
            </Grid>
          )}
        </Box>
      </Container>
    </Box>
  );
}
