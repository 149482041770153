import React from "react";
import { styled } from "@mui/material/styles";
import PropTypes from "prop-types";
import {
  Container,
  Button,
  Typography,
  Grid,
  Link,
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  Paper,
  CircularProgress,
  Box,
  CardActions,
  CardContent,
} from "@mui/material";
import AddressDialog from "../../../../Components/Widgets/Dialogs/AddressDialog";
import CloseIcon from "@mui/icons-material/Close";

const FullAddress = styled(Typography)(({ theme }) => ({
  // whiteSpace: "nowrap",
  width: "100%",
  // overflow: "hidden",
  // textOverflow: "ellipsis",
  marginBottom: 20,
}));

const MyTypography = styled(Typography)(({ theme }) => ({
  // whiteSpace: "nowrap",
  width: "100%",
  // overflow: "hidden",
  // textOverflow: "ellipsis",
}));

const ActionBtn = styled(Link)(({ theme }) => ({
  color: "#000",
  fontSize: "13px",
  fontWeight: "400",
  cursor: "pointer",
  borderBottom: "1px solid #000",
  "&:hover": {
    borderBottom: "1px solid transparent",
  },
}));

const AddAddressBtn = styled(Button)(({ theme }) => ({
  background: "transparent",
  border: "1px solid #000",
  borderRadius: 0,
  color: "#000",
  height: 48,
  textTransform: "none",
  "&:hover": {
    border: "1px solid #000",
    background: "#fff",
  },
}));

const ConfirmBtn = styled(Button)(({ theme }) => ({
  margin: theme.spacing(2, 0),
  background: "#000",
  borderRadius: 0,
  border: "1px solid #000",
  color: "#ffffff",
  "&:hover": {
    background: "#000",
  },
}));

const MyDialogTitle = (props) => {
  const { children, onClose, ...other } = props;
  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

MyDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

export default function Address(props) {
  const { component } = props;
  const devHelper = component.getHelpers("dev");
  const addresses = component.getData("default.addresses", null);

  const [newAddressDialogOpen, setNewAddressDialogOpen] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [address_id, setId] = React.useState(null);

  const handleClickOpen = (id) => {
    setOpen(true);
    setId(id);
  };

  return (
    <Container
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
      maxWidth="xl"
    >
      <Box
        sx={{
          borderTop: "2px solid #000",
          width: "100%",
          textAlign: "center",
          position: "relative",
          zIndex: -1,
          my: 6,
        }}
      >
        <Box
          sx={{
            fontFamily: "Poppins-Regular",
            color: "#000",
            lineHeight: 1,
            mx: "auto",
            textAlign: "center",
            position: "absolute",
            top: "0px",
            left: "50%",
            transform: "translate(-50%, -50%)",
            bgcolor: "#fff",
            px: 5,
            fontSize: "34px",
          }}
        >
          {component.ucfirst("addresses-title")}
        </Box>
      </Box>
      <AddAddressBtn
        variant="outlined"
        onClick={() => {
          setNewAddressDialogOpen(true);
          component.activateAutocomplete();
        }}
      >
        <i
          className="fa-light fa-plus"
          style={{ marginRight: "5px", fontSize: "18px" }}
        ></i>
        {component.trans("add-new-address-btn")}
      </AddAddressBtn>
      {!addresses ? (
        <CircularProgress
          style={{
            margin: "200px 0",
            color: "#000",
          }}
        />
      ) : (
        <>
          {addresses.length !== 0 ? (
            <Grid container direction="row" spacing={2} sx={{ mt: 1, mb: 3 }}>
              {addresses.map((address) => (
                <Grid
                  item
                  lg={3}
                  md={4}
                  sm={6}
                  xs={12}
                  key={devHelper.getObjectValue(address, "id")}
                >
                  <Paper
                    sx={{
                      border: "1px solid #dedede",
                      padding: "20px",
                      backgroundColor: "#ffffff",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "flex-start",
                      minHeight: "100%",
                    }}
                    elevation={2}
                  >
                    <CardContent sx={{ p: 0, mb: 2 }}>
                      <FullAddress variant="h6">
                        {devHelper.getObjectValue(address, "address")}
                      </FullAddress>
                      <MyTypography variant="subtitle1">
                        <b> {component.ucfirst("first_name-textfield")}:</b>{" "}
                        {devHelper.getObjectValue(address, "first_name")}
                      </MyTypography>
                      <MyTypography variant="subtitle1">
                        <b> {component.ucfirst("last_name-textfield")}:</b>{" "}
                        {devHelper.getObjectValue(address, "last_name")}
                      </MyTypography>
                      <MyTypography variant="subtitle1">
                        <b> {component.ucfirst("street_address-textfield")}:</b>{" "}
                        {devHelper.getObjectValue(address, "street")}
                      </MyTypography>
                      <MyTypography variant="subtitle1">
                        <b> {component.ucfirst("city-textfield")}:</b>{" "}
                        {devHelper.getObjectValue(address, "city")}
                      </MyTypography>
                      <MyTypography variant="subtitle1">
                        <b> {component.ucfirst("state-textfield")}:</b>{" "}
                        {devHelper.getObjectValue(address, "state")}
                      </MyTypography>
                      <MyTypography variant="subtitle1">
                        <b> {component.ucfirst("postal_code-textfield")}:</b>{" "}
                        {devHelper.getObjectValue(address, "postal_code")}
                      </MyTypography>
                      <MyTypography variant="subtitle1">
                        <b> {component.ucfirst("country-textfield")}:</b>{" "}
                        {devHelper.getObjectValue(address, "country")}
                      </MyTypography>
                      <MyTypography variant="subtitle1">
                        <b> {component.ucfirst("phone-textfield")}:</b>{" "}
                        {devHelper.getObjectValue(address, "phone")}
                      </MyTypography>
                      <MyTypography variant="subtitle1">
                        <b> {component.ucfirst("notes-textfield")}:</b>{" "}
                        {devHelper.getObjectValue(address, "notes")}
                      </MyTypography>
                    </CardContent>
                    <CardActions
                      sx={{
                        textAlign: "center",
                        display: "flex",
                        mt: "auto",
                        p: 0,
                      }}
                    >
                      <ActionBtn
                        underline="none"
                        onClick={() => {
                          component.setAddressId(
                            devHelper.getObjectValue(address, "id")
                          );
                          setNewAddressDialogOpen(true);
                          component.activateAutocomplete();
                        }}
                      >
                        {component.ucfirst("edit-btn")}
                      </ActionBtn>
                      <ActionBtn
                        underline="none"
                        onClick={() =>
                          handleClickOpen(
                            devHelper.getObjectValue(address, "id")
                          )
                        }
                        style={{ marginLeft: 10 }}
                      >
                        {component.ucfirst("remove-btn")}
                      </ActionBtn>
                    </CardActions>
                  </Paper>
                </Grid>
              ))}
            </Grid>
          ) : (
            <div
              style={{
                textAlign: "center",
                margin: "175px 0",
              }}
            >
              <Typography>{component.ucfirst("zero-addresses")}</Typography>
            </div>
          )}
        </>
      )}

      <AddressDialog
        {...{
          ...props,
          ...{
            open: newAddressDialogOpen,
            setOpen: (boolean) => setNewAddressDialogOpen(boolean),
            retrieveAddresses: () => component.retrieveAddresses(),
          },
        }}
      />
      <Dialog
        onClose={() => setOpen(false)}
        open={open}
        sx={{
          "& .MuiDialogContent-root": { padding: "16px" },
          "& .MuiDialogActions-root": {
            padding: "8px",
          },
          "& .MuiPaper-rounded": {
            borderRadius: 0,
          },
        }}
        maxWidth="xs"
      >
        <MyDialogTitle onClose={() => setOpen(false)}>
          {component.ucfirst("delete-address-title")}
        </MyDialogTitle>
        <DialogContent dividers>
          <form
            onSubmit={(e) => {
              component.deleteAddress(e, address_id);
              setOpen(false);
            }}
          >
            <Typography gutterBottom>
              {component.ucfirst("delete-address-text")}
            </Typography>
            <div style={{ textAlign: "center", width: "100%" }}>
              <ConfirmBtn
                type="submit"
                fullWidth
                variant="contained"
                sx={{ padding: "8px 12px", borderRadius: 0 }}
              >
                {component.ucfirst("confirm-btn")}
              </ConfirmBtn>
              <Button
                onClick={() => setOpen(false)}
                fullWidth
                variant="contained"
                sx={{
                  background: "transparent",
                  color: "#000",
                  padding: "8px 12px",
                  borderRadius: 0,
                  "&:hover, &:focus": {
                    background: "transparent",
                  },
                }}
              >
                {component.ucfirst("cancel-btn")}
              </Button>
            </div>
          </form>
        </DialogContent>
      </Dialog>
    </Container>
  );
}
