import React from "react";
import { Box } from "@mui/material";
import NavBar from "../../../../Components/Widgets/Navbar";
import MenuDrawer from "../../../../Components/Widgets/MenuDrawer";
import SubMenuDrawer from "../../../../Components/Widgets/SubMenuDrawer";
import Coupons from "../../../../Components/Widgets/User/Coupons";
import Footer from "../../widgets/footer";
import { useTheme } from "@mui/material/styles";

const ExportDefault = (props) => {
  const theme = useTheme();
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        minHeight: "100vh",
      }}
    >
      <NavBar
        {...{
          ...props,
          ...{
            bg_color: "#000",
          },
        }}
      />
      <Box
        sx={{
          height: {
            xs: theme.appbar_height.mobile,
            md: theme.appbar_height.desktop,
          },
        }}
      ></Box>
      <MenuDrawer {...props} />
      <SubMenuDrawer {...props} />
      <Coupons {...props} />
      <Footer {...props} />
    </Box>
  );
};

export default ExportDefault;
