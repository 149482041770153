import React from "react";
import { useTheme } from "@mui/material/styles";
import { Box, CardMedia, useMediaQuery, Link, Button } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import Slider from "react-slick";

export default function SlickSlider3(props) {
  const { component } = props;
  const devHelper = component.getHelpers("dev");
  const filesUrl = component.getHelpers("env").get("files_url");
  const img_version = component.getHelpers("value").getValue("images-version");

  const {
    textMaxWidthDesktop = null,
    textMaxWidthMobile = null,
    fscope = null,
  } = component.props;

  const theme = useTheme();

  const view = useMediaQuery(theme.breakpoints.up("md")) ? "desktop" : "mobile";

  const sliders = component.getData("default.sliders", []);

  const settings = {
    className: "center",
    centerMode: true,
    centerPadding: "20%",
    infinite: true,
    dots: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2500,
    nextArrow: <></>,
    prevArrow: <></>,
    arrows: false,
    pauseOnHover: false,
    appendDots: (dots) => (
      <div
        style={{
          height: "15px",
        }}
      >
        <ul style={{ margin: "0px" }}> {dots} </ul>
      </div>
    ),
    customPaging: (i) => (
      <div
        className="dot"
        style={{
          width: "15px",
          height: "15px",
          border: "1px solid #000",
          borderRadius: "50%",
          backgroundColor: "#000",
        }}
      ></div>
    ),
    responsive: [
      {
        breakpoint: 900,
        settings: {
          slidesToShow: 1,
          centerPadding: "18%",
          autoplay: false,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          centerPadding: "14%",
          autoplay: false,
        },
      },
      {
        breakpoint: 400,
        settings: {
          slidesToShow: 1,
          centerPadding: "12%",
          autoplay: false,
        },
      },
    ],
  };

  return (
    <Box
      sx={{
        py: 1,
        bgcolor: "#fff",
        display: { xs: "block", md: "none" },
        my: 6,
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          textAlign: "center",
          mb: 3,
        }}
      >
        {!component.trans(`${fscope}-title_${view}`).includes(`_${view}`) && (
          <Box
            sx={{
              ...theme.custom.titles.title,
              ...{
                mx: "auto",
              },
            }}
          >
            {component.trans(`${fscope}-title_${view}`)}
          </Box>
        )}
        {!component
          .trans(`${fscope}-description_${view}`)
          .includes(`_${view}`) && (
          <Box
            sx={{
              ...theme.custom.titles.description,
              ...{
                textAlign: "center",
                maxWidth: {
                  xs: textMaxWidthMobile ? textMaxWidthMobile : "unset",
                  md: textMaxWidthDesktop ? textMaxWidthDesktop : "unset",
                },
                mx: "auto",
              },
            }}
          >
            {component.trans(`${fscope}-description_${view}`)}
          </Box>
        )}
      </Box>
      {sliders.length !== 0 && (
        <Slider {...settings} className="side_slider">
          {sliders
            .filter(
              (slider) =>
                !slider._values.slug.includes("18kt-gold") || view === "desktop"
            )
            .map((slider) => (
              <Box
                key={devHelper.getObjectValue(slider, "_values.slug")}
                sx={{
                  px: 1.5,
                }}
              >
                <Link
                  component={RouterLink}
                  to={devHelper.getObjectValue(slider, "_values.url")}
                >
                  <CardMedia
                    sx={{
                      maxWidth: "100%",
                    }}
                    component="img"
                    image={`${filesUrl}/konstantino/sliders/${view}/${devHelper.getObjectValue(
                      slider,
                      "_values.slug"
                    )}.jpg?v=${img_version}`}
                    alt={`${devHelper.getObjectValue(
                      slider,
                      "_values.name"
                    )}-${view}`}
                  />
                </Link>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    mb: 2,
                  }}
                >
                  <Button
                    sx={{
                      ...theme.custom.buttons.button1,
                      ...{
                        mt: 3,
                        mx: "auto",
                      },
                    }}
                    component={RouterLink}
                    to={devHelper.getObjectValue(slider, "_values.url")}
                    disableRipple
                  >
                    {devHelper.getObjectValue(slider, "_values.name")}
                  </Button>
                </Box>
              </Box>
            ))}
        </Slider>
      )}
    </Box>
  );
}
