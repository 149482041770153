import { Component } from "../../../modules/Core/Component";
import templateDefault from "../../templates/default/widgets/order_complete";

export default class OrderComplete extends Component {
  id = "order-complete";

  template = templateDefault;

  onLoad(data) {
    super.onLoad(data);
  }
}
