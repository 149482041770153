import React from "react";
import { styled } from "@mui/material/styles";
import {
  Container,
  List,
  Button,
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
  Typography,
  CircularProgress,
  Box,
} from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import ProductCartView from "../../../../Components/Widgets/ProductCartView";

const MyTableCell = styled(TableCell)({
  padding: "8px 0px",
  borderBottom: "none",
  fontSize: 16,
  "@media (max-width: 580px )": {
    padding: "8px 10px 8px 0px",
  },
});

export default function Cart(props) {
  const { component } = props;
  const devHelper = component.getHelpers("dev");

  const order = component.getData("default.order", null);

  return (
    <Container
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        minHeight: "600px",
        px: { xs: 1, sm: 2 },
      }}
      disableGutters
      maxWidth="lg"
    >
      <Box sx={{ textAlign: "center", py: 3 }}>
        <Typography
          sx={{
            fontWeight: 400,
            fontSize: "28px",
          }}
        >
          {component.ucfirst("Shopping Bag")}
        </Typography>
      </Box>
      {!order ? (
        <CircularProgress
          sx={{
            my: "80px",
            color: "#000",
          }}
        />
      ) : (
        <>
          {order.items && order.items.length !== 0 ? (
            <>
              <List
                sx={{
                  width: { xs: "100%" },
                }}
              >
                <Table>
                  <TableHead>
                    <TableRow
                      sx={{
                        borderBottom: "1px solid rgba(0,0,0,.1)",
                        verticalAlign: "top",
                      }}
                    >
                      <MyTableCell>ITEM</MyTableCell>
                      <MyTableCell
                        sx={{
                          display: { xs: "none", sm: "table-cell" },
                        }}
                        align="center"
                      >
                        QTY
                      </MyTableCell>
                      <MyTableCell
                        sx={{
                          display: { xs: "none", sm: "table-cell" },
                        }}
                        align="right"
                      >
                        TOTAL
                      </MyTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {order.items.map((product, index) => (
                      <ProductCartView
                        key={devHelper.getObjectValue(product, "sku")}
                        {...{
                          ...props,
                          ...{
                            product,
                          },
                        }}
                      />
                    ))}
                  </TableBody>
                </Table>
                <Table>
                  <TableBody style={{ float: "right" }}>
                    <TableRow>
                      <MyTableCell sx={{ fontWeight: 500, width: "60px" }}>
                        TOTAL
                      </MyTableCell>
                      <MyTableCell>
                        {component
                          .getApp()
                          .priceFormater(
                            devHelper.getObjectValue(order, "totals.items", 0)
                          )}
                      </MyTableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </List>

              <div style={{ textAlign: "center", marginTop: "40px" }}>
                <Typography variant="subtitle1" gutterBottom>
                  Tax and shipping are calculated at checkout
                </Typography>
                <Button
                  component={RouterLink}
                  to="/checkout"
                  sx={{
                    background: "#000",
                    border: "1px solid #000",
                    borderRadius: 0,
                    color: "#ffffff",
                    width: "100%",
                    maxWidth: "400px",
                    margin: "15px 0px",
                    padding: "15px auto",
                    fontWeight: 400,
                    height: "40px",
                    "&:hover, &:focus": {
                      background: "#ffffff",
                      color: "#000",
                    },
                  }}
                  disableRipple
                >
                  Checkout
                </Button>
              </div>
            </>
          ) : (
            <Box
              sx={{
                textAlign: "center",
                mt: "50px",
              }}
            >
              <Typography>THERE ARE NO ITEMS IN YOUR SHOPPING BAG</Typography>
            </Box>
          )}
        </>
      )}
    </Container>
  );
}
