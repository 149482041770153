import { Component } from "../../../modules/Core/Component";
import templateDefault from "../../templates/default/widgets/main_message";

export default class MainMessage extends Component {
  id = "main-message";

  template = templateDefault;

  onLoad(data) {
    super.onLoad(data);
  }
}
