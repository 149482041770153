import Helpers from "../../../modules/Core/Helpers/Helpers";
import MainApi from "../../Api/Main";
import Service from "../Service";

class Menu extends Service {
  getAll(options) {
    const {
      account = Helpers.Env.getDefaultAccount(),
      repository = Helpers.Env.getDefaultRepository(),
      params = {},
    } = options || {};

    return new Promise((resolve, reject) => {
      MainApi.items(account, repository, "menu", {
        params,
      }).then((r) => {
        if (this.evaluate(r, "menu")) {
          const { rows = [] } = this.getData("menu", {}) || {};
          this.setData("menu", this.fixMainMenu(rows));

          resolve(this);
        } else {
          reject(this);
        }
      });
    });
  }

  fixMainMenu(menu) {
    menu = menu
      .map((menu) => {
        const { _values = {} } = menu;
        const subMenu = _values["sub-menu"] || [];
        const newSubMenu = {};

        subMenu.forEach((subMenu) => {
          const { type } = subMenu;

          if (!newSubMenu[type]) {
            newSubMenu[type] = [];
          }

          newSubMenu[type].push(subMenu);
        });

        for (var i in newSubMenu) {
          newSubMenu[i] = newSubMenu[i].sort((a, b) => {
            const posA = a.position * 1;
            const posB = b.position * 1;

            return posA - posB;
          });
        }

        _values["subMenu"] = newSubMenu;

        return _values;
      })
      .sort((menu1, menu2) => {
        const pos1 = menu1.position || 0;
        const pos2 = menu2.position || 0;
        return pos1 - pos2;
      });

    return menu;
  }
}

export default new Menu();
