import { Component } from "../../../../modules/Core/Component";
import templateDefault from "../../../templates/default/widgets/sliders/slick_slider_3";
import Services from "../../../Services/Services";

export default class SlickSlider3 extends Component {
  template = templateDefault;

  onLoad(data) {
    super.onLoad(data);

    this.getSliders();
  }

  getSliders() {
    Services.get("hook").then(async ([hookService]) => {
      try {
        var response = await hookService.exec("sliders", {
          params: { scope: this.props.fscope },
        });

        const sliders = response.getData() || [];

        this.setData({
          "default.sliders": sliders,
        });
      } catch (e) {
        console.log(e);
      }
    });
  }
}
