import React from "react";
import { useTheme } from "@mui/material/styles";
import {
  Button,
  Box,
  CardMedia,
  Link,
  Container,
  useMediaQuery,
  Typography,
} from "@mui/material";
import { Link as RouterLink } from "react-router-dom";

export default function Grid6Banners(props) {
  const { component } = props;
  const theme = useTheme();
  const devHelper = component.getHelpers("dev");
  const filesUrl = component.getHelpers("env").get("files_url");
  const img_version = component.getHelpers("value").getValue("images-version");
  const sliders = component.getData("default.sliders", []);
  const view = useMediaQuery(theme.breakpoints.up("lg")) ? "desktop" : "mobile";

  const {
    titleMaxWidthDesktop = null,
    titleMaxWidthMobile = null,
    textMaxWidthDesktop = null,
    textMaxWidthMobile = null,
  } = component.props;

  const text = (slider, f_color, view) => {
    const { slug = null, url = null } = devHelper.getObjectValue(
      slider,
      "_values"
    );

    return (
      <Box
        sx={{
          backgroundColor: "transparent",
          display: "flex",
          flexDirection: "column",
          px: { xs: 2, md: 0 },
        }}
      >
        {!component
          .trans(`${slug}-small-title_${view}`)
          .includes(`_${view}`) && (
          <Button
            sx={{
              ...theme.custom.titles.subtitle,
              ...{
                color: f_color,
                textAlign: "center",
                mx: "auto",
              },
            }}
            component={RouterLink}
            to={url}
            disableRipple
          >
            {component.trans(`${slug}-small-title_${view}`)}
          </Button>
        )}
        {!component.trans(`${slug}-title_${view}`).includes(`_${view}`) && (
          <Button
            sx={{
              ...theme.custom.titles.title,
              ...{
                color: f_color,
                textAlign: "center",
                maxWidth: {
                  xs: titleMaxWidthMobile ? titleMaxWidthMobile : "unset",
                  md: titleMaxWidthDesktop ? titleMaxWidthDesktop : "unset",
                },
                mx: "auto",
              },
            }}
            component={RouterLink}
            to={url}
            disableRipple
          >
            {component.trans(`${slug}-title_${view}`)}
          </Button>
        )}
        {!component
          .trans(`${slug}-description_${view}`)
          .includes(`_${view}`) && (
          <Typography
            component="h2"
            sx={{
              ...theme.custom.titles.description,
              ...{
                color: f_color,
                textAlign: "center",
                maxWidth: {
                  xs: textMaxWidthMobile ? textMaxWidthMobile : "unset",
                  md: textMaxWidthDesktop ? textMaxWidthDesktop : "unset",
                },
                mx: "auto",
              },
            }}
          >
            {component.trans(`${slug}-description_${view}`)}
          </Typography>
        )}
      </Box>
    );
  };

  return (
    <Box sx={{ bgcolor: "#fff" }}>
      {sliders.length !== 0 && (
        <Container
          maxWidth="xl"
          disableGutters
          sx={{
            px: 2,
          }}
        >
          <Box
            sx={{
              pt: 6,
              pb: { xs: 12, md: 6 },
              // bgcolor: "#F6F6F7",
            }}
          >
            <Box sx={{ px: { xs: 2, md: 5, lg: 10 }, mb: 3 }}>
              <Link component={RouterLink} to="/">
                <CardMedia
                  component="img"
                  alt={`${devHelper.getObjectValue(
                    sliders[0],
                    "_values.name"
                  )}-${view}`}
                  image={`${filesUrl}/konstantino/sliders/desktop/${devHelper.getObjectValue(
                    sliders[0],
                    "_values.slug"
                  )}.jpg?v=${img_version}`}
                  onLoad={() => {
                    component.setData({ "default.ready": true });
                  }}
                />
              </Link>
            </Box>
            {text(sliders[0], "#000", view)}
            <Box
              sx={{
                display: "flex",
                flexDirection: { xs: "column", sm: "row" },
                justifyContent: "center",
                alignItems: "center",
                mt: 2,
              }}
            >
              <Button
                component={RouterLink}
                to="/women/metal/18kt-gold"
                sx={{
                  textTransform: "none",
                  border: "1px solid #000",
                  borderRadius: 0,
                  fontFamily: "Spartan-Bold",
                  color: "#000",
                  fontSize: 15,
                  py: 2,
                  minWidth: 240,
                  m: 1,
                  lineHeight: 1.2,
                  "&:hover": {
                    backgroundColor: "transparent",
                  },
                }}
                disableRipple
              >
                Shop Women’s Gold
              </Button>
              <Button
                component={RouterLink}
                to="/men/metal/18kt-gold"
                sx={{
                  textTransform: "none",
                  border: "1px solid #000",
                  borderRadius: 0,
                  fontFamily: "Spartan-Bold",
                  color: "#000",
                  fontSize: 15,
                  py: 2,
                  minWidth: 240,
                  m: 1,
                  lineHeight: 1.2,
                  "&:hover": {
                    backgroundColor: "transparent",
                  },
                }}
                disableRipple
              >
                Shop Men’s Gold
              </Button>
            </Box>
          </Box>
        </Container>
      )}
    </Box>
  );
}
