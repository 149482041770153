import React, { forwardRef } from "react";
import { useTheme } from "@mui/material/styles";
import {
  Box,
  CardMedia,
  Button,
  Typography,
  useMediaQuery,
  Tabs,
  Tab,
  ButtonBase,
} from "@mui/material";
import { Link as RouterLink } from "react-router-dom";

import { collections } from "./collections";

const MyTabScrollButton = forwardRef((props, ref) => {
  const { direction, ...other } = props;

  return (
    <ButtonBase
      component="div"
      ref={ref}
      {...other}
      disableRipple
      aria-label="Change Slide"
    >
      {direction === "left" ? (
        <i className="fa-sharp fa-light fa-chevron-left" />
      ) : (
        <i className="fa-sharp fa-light fa-chevron-right" />
      )}
    </ButtonBase>
  );
});

export default function CollectionsSlider(props) {
  const { component, sliders = [] } = props;
  const theme = useTheme();
  const view = useMediaQuery(theme.breakpoints.up("md")) ? "desktop" : "mobile";

  const devHelper = component.getHelpers("dev");
  const filesUrl = component.getHelpers("env").get("files_url");
  const img_version = component.getHelpers("value").getValue("images-version");

  return (
    <Box sx={{ pb: 12, bgcolor: "#fff" }}>
      <Tabs
        value={0}
        variant="scrollable"
        scrollButtons={"auto"}
        ScrollButtonComponent={MyTabScrollButton}
        sx={{
          width: "100%",
          position: "relative",
          justifyContent: "center",
          "& .MuiTabs-indicator": {
            display: "none",
          },
          "& .MuiTabs-scrollButtons:nth-of-type(1)": {
            left: 20,
          },
          "& .MuiTabs-scrollButtons": {
            position: "absolute",
            color: "#000",
            backgroundColor: "#fff !important",
            height: "30px",
            width: "30px",
            zIndex: 100,
            top: "50%",
            webkitTransform: "translate(0,-50%)",
            transform: "translate(0,-50%)",
            borderRadius: "50%",
            right: 20,
          },
          "& .MuiTabs-scrollButtons.Mui-disabled": {
            display: "none",
          },
        }}
      >
        {sliders.map((slider) => {
          return (
            <Tab
              key={slider}
              disableRipple
              className="myTab"
              sx={{
                p: 0,
                maxWidth: "unset",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "flex-start",
                width: { xs: "80%", sm: "65%", md: "40%", lg: "30%" },
              }}
              component={RouterLink}
              to={devHelper.getObjectValue(collections, `${slider}.url`)}
              label={
                <Box
                  sx={{
                    position: "relative",
                    p: 1,
                  }}
                >
                  <Button
                    disabled
                    sx={{
                      position: "absolute",
                      background: "transparent",
                      zIndex: "1",
                      padding: 0,
                      minWidth: 0,
                      color: "#000",
                      textTransform: "none",
                      fontFamily: "Spartan-SemiBold",
                      "&:disabled": {
                        pointerEvents: "auto",
                        color: "#000",
                      },
                      "&:hover, &:focus": {
                        background: "transparent",
                      },
                      top: { xs: 10, sm: 12 },
                      right: { xs: 10, sm: 15 },
                      fontSize: { xs: "12px", sm: "14px" },
                    }}
                  >
                    {devHelper.getObjectValue(collections, `${slider}.tag`)}
                  </Button>
                  <CardMedia
                    sx={{ maxWidth: "100%", mx: "auto" }}
                    component="img"
                    alt={`${slider}-${view}`}
                    image={`${filesUrl}/konstantino/sliders/collections/${slider}.jpg?v=r${img_version}`}
                  />
                  <Box
                    sx={{
                      backgroundColor: "transparent",
                      display: "flex",
                      flexDirection: "column",
                      mt: 2,
                    }}
                  >
                    <Typography
                      sx={{
                        ...theme.custom.titles.title,
                        ...{},
                      }}
                    >
                      {devHelper.getObjectValue(collections, `${slider}.name`)}
                    </Typography>
                    <Typography
                      variant="body2"
                      component="h3"
                      sx={{
                        ...theme.custom.titles.description,
                        ...{
                          textAlign: "center",
                          maxWidth: { xs: "400px", md: "500px" },
                          mx: "auto",
                        },
                      }}
                    >
                      {devHelper.getObjectValue(
                        collections,
                        `${slider}.blurb.${view}`
                      )}
                    </Typography>
                    <Typography
                      sx={{
                        ...theme.custom.buttons.button1,
                        ...{
                          m: "auto",
                          mt: 2,
                        },
                      }}
                    >
                      Explore
                    </Typography>
                  </Box>
                </Box>
              }
            />
          );
        })}
      </Tabs>
    </Box>
  );
}
