import { Component } from "../../../../modules/Core/Component";
import templateDefault from "../../../templates/default/widgets/user/profile";
import Services from "../../../Services/Services";

export default class Profile extends Component {
  template = templateDefault;

  onLoad(data) {
    super.onLoad(data);
    this.retrieve();
  }

  retrieve() {
    Services.get("auth").then(([authService]) => {
      authService
        .retrieveProfile()
        .then((authService) => {
          const { name, username } = authService.getData("profile", {});
          this.setData({
            "default.fields": { name, username },
          });
        })
        .catch((authService) => {
          console.log(authService);
        });
    });
  }

  updateProfile(e) {
    if (e) e.preventDefault();

    const { name, password, verify } = this.getData("default.fields", {});
    const error = {
      fields: {},
      message: "",
    };

    if (!name) {
      error.fields["name"] = this.ucfirst("fullname-helpertext");
    }
    if (password || verify) {
      if (!password) {
        error.fields["password"] = this.ucfirst("password-helpertext");
      }

      if (!verify) {
        error.fields["verify"] = this.ucfirst("confirm-password-helpertext");
      }
    }

    if (password && verify && password !== verify) {
      this.getComponents()
        .findById("main-message")
        .first()
        .setData({
          "error-message": this.ucfirst("invalid-password-verification"),
        });
      error.message = this.ucfirst("invalid-password-verification");
    }

    this.setData({
      error,
    });

    if (Object.keys(error.fields).length === 0 && !error.message) {
      Services.get("auth").then(([authService]) => {
        authService
          .updateProfile({ name, password, verify })
          .then((authService) => {
            this.deleteData("error.message");
            this.getComponents()
              .findById("main-message")
              .first()
              .setData({
                "success-message": this.ucfirst(
                  authService.getData("_response").getMessage()
                ),
              });
            // this.setData({
            //   "success.message": this.ucfirst(
            //     authService.getData("_response").getMessage()
            //   ),
            // });
          })
          .catch((authService) => {
            this.getComponents()
              .findById("main-message")
              .first()
              .setData({
                "error-message": this.ucfirst(
                  authService.getData("_response").getMessage()
                ),
              });
            // this.setData({
            //   "success.message": "",
            //   "error.message": this.ucfirst(
            //     authService.getData("_response").getMessage()
            //   ),
            // });
          });
      });
    }
  }
}
