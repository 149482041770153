import React from "react";
import { styled } from "@mui/material/styles";
import {
  SwipeableDrawer,
  Link,
  Accordion as MuiAccordion,
  AccordionSummary as MuiAccordionSummary,
  AccordionDetails as MuiAccordionDetails,
  Box,
} from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import { useTheme } from "@mui/material/styles";

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  marginBottom: "40px",
  "&:not(:last-child)": {
    borderBottom: 0,
    m: 0,
  },
  "&:before": {
    display: "none",
  },
  "& .MuiButtonBase-root": { padding: 0 },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={
      <i
        className="fa-regular fa-chevron-down"
        style={{ fontSize: "18px", color: "#000" }}
      ></i>
    }
    {...props}
  />
))(({ theme }) => ({
  border: "none",
  p: 0,
  fontFamily: "Spartan-SemiBold",
  fontSize: "14px",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(-90deg)",
    border: "none",
    p: 0,
  },
  "& .MuiAccordionSummary-content": {
    border: "none",
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: "0 16px",
}));

const MyLink = styled(Link)(({ theme }) => ({
  color: "#000",
  padding: "0px",
  backgroundColor: "transparent",
  borderRadius: "0px",
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  fontSize: "14px",
  fontFamily: "Spartan-Medium",
  marginBottom: "40px",
}));

export default function MenuDrawer(props) {
  const { component } = props;

  // const wishlist = component.getData("default.wishlist", []);
  const isAuth = component.isAuthenticated();
  const mainMenu = component.getData("default.mainMenu", []);

  const theme = useTheme();
  return (
    <SwipeableDrawer
      anchor="left"
      open={component.getData("default.opened", false)}
      onClose={() => component.toggle()}
      onOpen={() => {}}
      sx={{
        zIndex: (theme) => theme.zIndex.drawer + 2,
        "& .MuiDrawer-paper": {
          p: 0,
          overflowY: "scroll",
          scrollbarWidth: "none",
          msOverflowStyle: "none",
          width: { xs: "90%", sm: "500px" },
        },
        "& .MuiDrawer-paper::-webkit-scrollbar": {
          display: "none",
        },
      }}
      transitionDuration={{ enter: 500, exit: 500 }}
      swipeAreaWidth={0}
    >
      <Box
        sx={{
          backgroundColor: "rgb(0, 0, 0,0.9)",
          width: "100%",
          position: "relative",
        }}
      >
        <i
          className="fa-light fa-xmark"
          onClick={() => component.toggle()}
          style={{
            position: "absolute",
            cursor: "pointer",
            right: "24px",
            top: "50%",
            transform: "translate(0,-50%)",
            fontSize: "22px",
            color: "#fff",
          }}
        ></i>
        <Box
          sx={{
            display: "flex",
            px: 3,
            justifyContent: "center",
            height: {
              xs: theme.appbar_height.mobile,
              md: theme.appbar_height.desktop,
            },
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              width: { xs: "75px", md: "85px" },
            }}
            component={RouterLink}
            to="/"
            onClick={() => component.toggle()}
          >
            <img
              alt="logo konstantino"
              src="/images/logos/navbar-logo.png"
              title="logo"
              style={{
                maxWidth: "100%",
              }}
            />
          </Box>
        </Box>
      </Box>
      <Box sx={{ p: 3 }}>
        {mainMenu.map((menu) => {
          const { slug, name, url, menu_template } = menu;

          return (
            <Box key={`mobile-main-menu-root-${slug}`}>
              {menu_template !== "none" ? (
                <MyLink
                  underline="none"
                  onClick={() => {
                    component.toggleSubMenuDrawer(menu_template);
                  }}
                >
                  {name}
                  <i
                    className="fa-light fa-chevron-right"
                    style={{ fontSize: "18px" }}
                  ></i>
                </MyLink>
              ) : (
                <MyLink
                  underline="none"
                  component={RouterLink}
                  to={url}
                  onClick={() => component.toggle()}
                >
                  {name}
                </MyLink>
              )}
            </Box>
          );
        })}

        <Box sx={{ borderBottom: "1px solid #000", mb: 5 }}></Box>

        {isAuth ? (
          <Accordion elevation={0} disableGutters>
            <AccordionSummary>My Account</AccordionSummary>
            <AccordionDetails>
              <MyLink
                underline="none"
                component={RouterLink}
                to="profile"
                onClick={() => component.toggle()}
              >
                {component.ucfirst("k-profile")}
              </MyLink>
              <MyLink
                underline="none"
                component={RouterLink}
                to="/addresses"
                onClick={() => component.toggle()}
              >
                {component.ucfirst("k-addresses")}
              </MyLink>
              <MyLink
                underline="none"
                component={RouterLink}
                to="/orders"
                onClick={() => component.toggle()}
              >
                {component.ucfirst("k-orders")}
              </MyLink>
              <MyLink
                underline="none"
                component={RouterLink}
                to="/coupons"
                onClick={() => component.toggle()}
              >
                {component.ucfirst("k-coupons")}
              </MyLink>
            </AccordionDetails>
          </Accordion>
        ) : (
          <MyLink
            underline="none"
            component={RouterLink}
            to="/signin"
            onClick={() => component.toggle()}
          >
            My Account
          </MyLink>
        )}

        <MyLink
          underline="none"
          component={RouterLink}
          to="/wishlist"
          onClick={() => component.toggle()}
        >
          My Wishlist
        </MyLink>

        {isAuth ? (
          <MyLink underline="none" onClick={() => component.getPage().logout()}>
            {component.ucfirst("logout")}
          </MyLink>
        ) : (
          <MyLink
            underline="none"
            component={RouterLink}
            to="/signin"
            onClick={() => component.toggle()}
          >
            Sign In
          </MyLink>
        )}
      </Box>
    </SwipeableDrawer>
  );
}
