import Page from "../../../modules/Core/Components/Page";
import templateDefault from "../../templates/default/pages/locations";

export default class Locations extends Page {
  template = templateDefault;

  onAuth() {
    this.refresh();
  }

  onLoad(data) {
    super.onLoad(data);
    this.scrollToTop();
  }

  onUpdate(prevProps, prevState, snapshot) {
    super.onUpdate(prevProps, prevState, snapshot);

    if (prevProps.location.pathname !== this.props.location.pathname) {
      this.getComponents().findById("locations").first().getLocations();
    }
  }
}
