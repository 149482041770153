import Helpers from "../../../modules/Core/Helpers/Helpers";
import MainApi from "../../Api/Main";
import Service from "../Service";

class Coupon extends Service {
  getCoupons(options) {
    const {
      account = Helpers.Env.getDefaultAccount(),
      repository = Helpers.Env.getDefaultRepository(),
    } = options || {};

    return new Promise((resolve, reject) => {
      MainApi.coupon.collection(account, repository).then((r) => {
        const result = this.evaluate(r, "coupons");

        if (result) {
          resolve(this);
        } else {
          reject(this);
        }
      });
    });
  }

  addCoupon(coupon, options) {
    const {
      account = Helpers.Env.getDefaultAccount(),
      repository = Helpers.Env.getDefaultRepository(),
    } = options || {};

    return new Promise((resolve, reject) => {
      MainApi.coupon
        .create(account, repository, {
          coupon,
        })
        .then((r) => {
          const result = this.evaluate(r, "coupon");

          if (result) {
            resolve(this);
          } else {
            reject(this);
          }
        });
    });
  }

  deleteCoupon(couponId, options) {
    const {
      account = Helpers.Env.getDefaultAccount(),
      repository = Helpers.Env.getDefaultRepository(),
    } = options || {};

    return new Promise((resolve, reject) => {
      MainApi.coupon.delete(account, repository, couponId).then((r) => {
        const result = this.evaluate(r);

        if (result) {
          resolve(this);
        } else {
          reject(this);
        }
      });
    });
  }
}

export default new Coupon();
