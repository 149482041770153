import React from "react";
import { AppBar, Toolbar, useScrollTrigger } from "@mui/material";
import MainAppbar from "../../../../Components/Widgets/MainAppbar";
import CartPopper from "../../../../Components/Widgets/CartPopper";
import { styled } from "@mui/system";

const MyAppBar = styled(AppBar)(({ theme }) => ({
  "&.scrolled-appbar": {
    background: "rgb(0, 0, 0,0.8)",
  },
  boxShadow: "none",
  transition: "background 1s",
  webkitTransition: "background 1s",
}));

function ScrollEvent(props) {
  const { children } = props;
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 90,
  });

  return (
    <>
      {React.Children.map(children, (child) => {
        return React.cloneElement(child, {
          className: trigger ? "scrolled-appbar" : "",
        });
      })}
    </>
  );
}

export default function NavBar(props) {
  const { component } = props;
  const { bg_color = "rgb(0, 0, 0,0.8)" } = component.props;

  return (
    <>
      <ScrollEvent {...props}>
        <MyAppBar
          position="fixed"
          sx={{
            zIndex: (theme) => theme.zIndex.drawer + 1,
            background: bg_color,
            "&:hover": {
              background: "rgb(0, 0, 0,0.8)",
            },
          }}
        >
          <MainAppbar
            {...{
              ...props,
              ...{},
            }}
          />
          <CartPopper
            {...{
              ...props,
              ...{},
            }}
          />
        </MyAppBar>
      </ScrollEvent>
      <Toolbar id="back-to-top-anchor" sx={{ minHeight: "0.1px !important" }} />
    </>
  );
}
