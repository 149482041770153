import React from "react";
import ProductListView from "../../../Components/Widgets/ProductListView";

export default function LayoutProducts(props) {
  const { products = [], from = 0, to = 0 } = props;
  const { component } = props;
  const devHelper = component.getHelpers("dev");
  const pageGender = component.getGender();
  const pageType = component.getData("default.pageType", "");
  const pageValue = component.getData("default.pageValue", "");
  const page = `${pageGender}-${pageType}-${pageValue}`;

  return (
    <>
      {products &&
        products.slice(from, to).map((product) => {
          return (
            <ProductListView
              key={`${page}-${devHelper.getObjectValue(product, "_id")}`}
              {...{
                ...props,
                ...{
                  product,
                  grid: 4,
                },
              }}
            />
          );
        })}
    </>
  );
}
