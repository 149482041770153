import Page from "../../../modules/Core/Components/Page";
import templateDefault from "../../templates/default/pages/cart";

export default class Cart extends Page {
  template = templateDefault;

  onAuth() {
    this.refresh();
  }

  onLoad(data) {
    super.onLoad(data);
    this.getApp().updateWindowTitle(
      `${this.ucfirst("Cart")} | ${this.ucfirst("window-title")}`
    );
    this.scrollToTop();
  }
}
