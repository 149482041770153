import React from "react";
import { useTheme } from "@mui/material/styles";
import {
  Button,
  Box,
  CardMedia,
  Link,
  Grid,
  Container,
  Typography,
} from "@mui/material";
import { Link as RouterLink } from "react-router-dom";

export default function Grid6Banners(props) {
  const { component } = props;
  const devHelper = component.getHelpers("dev");
  const filesUrl = component.getHelpers("env").get("files_url");
  const img_version = component.getHelpers("value").getValue("images-version");
  const sliders = component.getData("default.sliders", []);
  const theme = useTheme();

  return (
    <Box sx={{ bgcolor: "#fff" }}>
      <Container
        maxWidth="xl"
        disableGutters
        sx={{
          mt: 3,
          px: 2,
        }}
      >
        <Box
          sx={{
            py: 6,
            bgcolor: { xs: "#fff", md: "#F6F6F7" },
          }}
        >
          <Grid
            container
            justifycontent="center"
            direction="row"
            rowSpacing={{ xs: 3, md: 8 }}
            columnSpacing={{ xs: 3, md: 12 }}
            sx={{
              px: { xs: 5, md: 5, lg: 10 },
              display: { xs: "none", md: "flex" },
            }}
          >
            {sliders.map((slider) => {
              const { slug = null, url = null } = devHelper.getObjectValue(
                slider,
                "_values"
              );
              return (
                <Grid item xs={12} sm={6} key={slug}>
                  <Box
                    sx={{
                      position: "relative",
                    }}
                  >
                    <Link component={RouterLink} to={url}>
                      <CardMedia
                        component="img"
                        image={`${filesUrl}/konstantino/sliders/desktop/${devHelper.getObjectValue(
                          slider,
                          "_values.slug"
                        )}.jpg?v=${img_version}`}
                        alt={devHelper.getObjectValue(slider, "_values.name")}
                      />
                    </Link>
                    <Box
                      sx={{
                        position: "absolute",
                        textAlign: "center",
                        left: 0,
                        right: 0,
                        mx: "auto",
                        bottom: { xs: 25, sm: 50 },
                      }}
                    >
                      <Button
                        component={RouterLink}
                        to={url}
                        sx={{
                          textTransform: "none",
                          border: "1px solid #fff",
                          borderRadius: 0,
                          fontFamily: "Spartan-Bold",
                          color: "#fff",
                          // fontSize: { xs: 16, sm: 20, md: 25 },
                          fontSize: { xs: 16 },
                          // minWidth: { xs: "190px", sm: "280px", md: "330px" },
                          minWidth: { xs: "240px" },
                          py: 2,
                          "&:hover": {
                            backgroundColor: "transparent",
                          },
                        }}
                        disableRipple
                      >
                        {component.trans(`${slug}-button`)}
                      </Button>
                    </Box>
                  </Box>
                </Grid>
              );
            })}
          </Grid>
          <Box sx={{ display: { xs: "block", md: "none" } }}>
            <Typography
              component="h2"
              align="center"
              sx={{
                ...theme.custom.titles.title,
                ...{
                  color: "#000",
                  mb: 4,
                },
              }}
            >
              Shop by Category
            </Typography>
            <Box
              sx={{
                display: "flex",
                flexDirection: { xs: "column", sm: "row" },
                justifyContent: "center",
                alignItems: "center",
                mt: 2,
              }}
            >
              {sliders.map((slider) => {
                const { slug = null, url = null } = devHelper.getObjectValue(
                  slider,
                  "_values"
                );
                return (
                  <Button
                    key={slug}
                    component={RouterLink}
                    to={url}
                    sx={{
                      textTransform: "none",
                      border: "1px solid #000",
                      borderRadius: 0,
                      fontFamily: "Spartan-Bold",
                      color: "#000",
                      fontSize: 15,
                      py: 2,
                      minWidth: 240,
                      m: 1,
                      lineHeight: 1.2,
                      "&:hover": {
                        backgroundColor: "transparent",
                      },
                    }}
                    disableRipple
                  >
                    {component.trans(`${slug}-button`)}
                  </Button>
                );
              })}
            </Box>
          </Box>
        </Box>
      </Container>
    </Box>
  );
}
