import React from "react";
import { Box, Container } from "@mui/material";
import { styled } from "@mui/material/styles";

const Title = styled(Box)(({ theme }) => ({
  mx: "auto",
  color: "#000",
  fontFamily: "Poppins-Regular",
  fontSize: "34px",
  textAlign: "center",
  marginTop: "40px",
  marginBottom: "8px",
  [theme.breakpoints.down("sm")]: {
    fontSize: "28px",
  },
}));

export default function Films(props) {
  return (
    <Container
      disableGutters
      maxWidth="xl"
      sx={{ px: { xs: 1, sm: 2, md: 14 }, mb: 9 }}
    >
      <Title>Callas Collection</Title>
      <div style={{ padding: "56.25% 0 0 0", position: "relative" }}>
        <iframe
          src="https://player.vimeo.com/video/471392306?h=b44e036457&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
          frameborder="0"
          allow="autoplay; fullscreen; picture-in-picture"
          allowfullscreen
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
          }}
          title="KONSTANTINO | Callas Collection"
        ></iframe>
      </div>
      <Title>Kleos Collection</Title>
      <Box
        sx={{
          textAlign: "center",
          maxWidth: "600px",
          color: "#000",
          fontFamily: "Spartan-SemiBold",
          fontSize: "16px",
          px: 1,
          mb: 2,
          mx: "auto",
        }}
      >
        The Delos Collection represents independent women of limitless power
        motivated by their strength and willpower.
      </Box>
      <div style={{ padding: "56.25% 0 0 0", position: "relative" }}>
        <iframe
          src="https://player.vimeo.com/video/353585383?h=af1bdcabb6&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
          frameborder="0"
          allow="autoplay; fullscreen; picture-in-picture"
          allowfullscreen
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
          }}
          title="KONSTANTINO | Kleos Collection"
        ></iframe>
      </div>
      <Title>Delos Collection</Title>
      <Box
        sx={{
          textAlign: "center",
          maxWidth: "600px",
          color: "#000",
          fontFamily: "Spartan-SemiBold",
          fontSize: "16px",
          px: 1,
          mb: 2,
          mx: "auto",
        }}
      >
        The Delos Collection represents independent women of limitless power
        motivated by their strength and willpower.
      </Box>
      <div style={{ padding: "56.25% 0 0 0", position: "relative" }}>
        <iframe
          src="https://player.vimeo.com/video/355945181?h=030253fb3d&badge=0&autopause=0&player_id=0&app_id=58479/embed"
          allow="autoplay; fullscreen; picture-in-picture"
          allowfullscreen
          frameborder="0"
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
          }}
          title="KONSTANTINO | Delos Collection"
        ></iframe>
      </div>
      <Title>Astria Collection - Editorial</Title>
      <div style={{ padding: "56.25% 0 0 0", position: "relative" }}>
        <iframe
          src="https://player.vimeo.com/video/471578243?h=1d34732df1&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
          frameborder="0"
          allow="autoplay; fullscreen; picture-in-picture"
          allowfullscreen
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
          }}
          title="KONSTANTINO | Astria Collection"
        ></iframe>
      </div>
      <Title>Astria Collection - Advertorial</Title>
      <div style={{ padding: "56.25% 0 0 0", position: "relative" }}>
        <iframe
          src="https://player.vimeo.com/video/402874661?h=d43e79e864&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
          frameborder="0"
          allow="autoplay; fullscreen; picture-in-picture"
          allowfullscreen
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
          }}
          title="KONSTANTINO | Astria Collection"
        ></iframe>
      </div>
      <Title>Perseus Collection</Title>
      <div style={{ padding: "56.25% 0 0 0", position: "relative" }}>
        <iframe
          src="https://player.vimeo.com/video/472244502?h=8646f73fab&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
          frameborder="0"
          allow="autoplay; fullscreen; picture-in-picture"
          allowfullscreen
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
          }}
          title="KONSTANTINO | Perseus Collection"
        ></iframe>
      </div>
      <Title>The Art Of Etching</Title>
      <div style={{ padding: "56.25% 0 0 0", position: "relative" }}>
        <iframe
          src="https://player.vimeo.com/video/246941031?h=ef03a361e1&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
          frameborder="0"
          allow="autoplay; fullscreen; picture-in-picture"
          allowfullscreen
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
          }}
          title="KONSTANTINO | The Art Of Etching"
        ></iframe>
      </div>
      <Title>This is Konstantino</Title>
      <div style={{ padding: "56.25% 0 0 0", position: "relative" }}>
        <iframe
          src="https://player.vimeo.com/video/271084772?h=6325a69faa&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
          frameborder="0"
          allow="autoplay; fullscreen; picture-in-picture"
          allowfullscreen
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
          }}
          title="This Is Konstantino"
        ></iframe>
      </div>
      <script src="https://player.vimeo.com/api/player.js"></script>
    </Container>
  );
}
