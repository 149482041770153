import { Component } from "../../../modules/Core/Component";
import templateDefault from "../../templates/default/widgets/cart/first_section";
import Services from "../../Services/Services";

export default class Cart extends Component {
  template = templateDefault;
  group = "order";
  id = "cart";

  onLoad(data) {
    super.onLoad(data);

    Services.get("order").then(([orderService]) => {
      orderService
        .fetchOrder()
        .then((orderService) => {
          const order = orderService.getData("order", {});
          this.setOrder(order);
        })
        .catch((orderService) => {
          this.checkOrderError(orderService);
        });
    });
  }

  checkOrderError(service) {
    if (service && service.getMessage && service.getError) {
      const type = service.getMessage() || service.getError();

      switch (type) {
        case "order-missing":
        case "order-expired":
        case "order-placed": {
          this.setData({ "default.order": {} });
          break;
        }
        default:
          break;
      }
    }

    return this;
  }

  setOrder(order) {
    this.setData({ "default.order": order });
    return this;
  }

  getOrder() {
    return this.getData("default.order", {});
  }
}
