import React from "react";
import { styled } from "@mui/material/styles";
import {
  Skeleton,
  Box,
  ListItem,
  ListItemAvatar,
  CardMedia,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from "@mui/material";

const MyTableCell = styled(TableCell)({
  padding: "0px 0px 8px",
  borderBottom: "none",
  fontFamily: "Spartan-Medium",
});

const ProductName = styled(Typography)({
  fontFamily: "Poppins-Regular",
  fontSize: "18px",
});

const ProductCollection = styled(Typography)({
  fontFamily: "Spartan-SemiBold",
  fontSize: "12px",
  padding: "0px",
  minWidth: 0,
  "&:hover, &:focus": {
    background: "transparent",
  },
});

export default function ProductCheckoutView(props) {
  const { component } = props;

  const devHelper = component.getHelpers("dev");
  const filesUrl = component.getHelpers("env").get("files_url");

  const product = component.getData("default.product", null);
  const image = component.getData("default.image", null);
  const ready = component.getData("default.ready", false);

  const gem = devHelper.getObjectValue(product, "gem");
  const size = devHelper.getObjectValue(product, "size");
  const simple = devHelper.getObjectValue(product, "simple");
  const collections = devHelper.getObjectValue(product, "item.collections", []);

  return (
    <>
      {product && (
        <TableRow
          sx={{
            borderBottom: "1px solid rgba(0,0,0,.1)",
            verticalAlign: "top",
          }}
        >
          <MyTableCell component="th" scope="row">
            <ListItem sx={{ py: 1, px: 0, alignItems: "flex-start" }}>
              <ListItemAvatar
                sx={{
                  position: "relative",
                  width: { xs: "150px" },
                  height: { xs: "188px" },
                }}
              >
                {!ready && (
                  <Skeleton
                    variant="rectangular"
                    width="100%"
                    height="100%"
                    sx={{ position: "absolute", top: 0, left: 0 }}
                  />
                )}
                <CardMedia
                  component="img"
                  style={{
                    maxWidth: "100%",
                  }}
                  image={
                    component.getData(`default.img-error-product`, false)
                      ? filesUrl.concat(
                          `/konstantino/assets/product-not-found.jpg`
                        )
                      : filesUrl.concat(`/konstantino/products/${image}`)
                  }
                  onLoad={() => {
                    component.setData({ "default.ready": true });
                  }}
                  alt={image}
                  onError={() => {
                    component.setData({
                      [`default.img-error-product`]: true,
                    });
                  }}
                ></CardMedia>
              </ListItemAvatar>
              <Box sx={{ flex: 1, ml: { xs: 1, sm: 2 } }}>
                {collections.length !== 0 && (
                  <ProductCollection>
                    {devHelper.getObjectValue(
                      collections[0],
                      "item._values.name"
                    )}
                  </ProductCollection>
                )}
                <ProductName sx={{ mb: 3 }}>
                  {devHelper.getObjectValue(product, "item.name")}
                </ProductName>
                <Table sx={{ mb: 1 }}>
                  <TableBody>
                    {gem && !simple && (
                      <TableRow>
                        <MyTableCell sx={{ width: { xs: "45px", sm: "60px" } }}>
                          Gem:
                        </MyTableCell>
                        <MyTableCell>
                          <Box
                            sx={{
                              width: "20px",
                              display: "inline-block",
                              margin: "0px 0px -5px",
                              borderRadius: "50px",
                            }}
                          >
                            {!component.getData(`default.ready-gem`, false) && (
                              <Skeleton
                                variant="rectangular"
                                width="100%"
                                height="100%"
                                sx={{
                                  position: "absolute",
                                  top: 0,
                                  left: 0,
                                  borderRadius: "50px",
                                }}
                              />
                            )}
                            <CardMedia
                              component="img"
                              image={
                                component.getData(
                                  `default.img-error-gem`,
                                  false
                                )
                                  ? filesUrl.concat(
                                      `/konstantino/assets/gem-not-found.png`
                                    )
                                  : filesUrl.concat(
                                      `/konstantino/gems/${devHelper.getObjectValue(
                                        gem,
                                        "item._values.slug"
                                      )}.png`
                                    )
                              }
                              title={devHelper.getObjectValue(
                                gem,
                                "item._values.name"
                              )}
                              onLoad={() => {
                                component.setData({
                                  [`default.ready-gem`]: true,
                                });
                              }}
                              onError={() => {
                                component.setData({
                                  [`default.img-error-gem`]: true,
                                });
                              }}
                            />
                          </Box>
                        </MyTableCell>
                      </TableRow>
                    )}
                    {size && !simple && (
                      <TableRow>
                        <MyTableCell sx={{ width: { xs: "45px", sm: "60px" } }}>
                          Size:
                        </MyTableCell>
                        <MyTableCell>
                          {devHelper.getObjectValue(size, "item._values.name")}
                        </MyTableCell>
                      </TableRow>
                    )}
                    <TableRow>
                      <MyTableCell sx={{ width: { xs: "45px", sm: "60px" } }}>
                        Qty:
                      </MyTableCell>
                      <MyTableCell>
                        {devHelper.getObjectValue(product, "amount")}
                      </MyTableCell>
                    </TableRow>
                    <TableRow
                      sx={{
                        display: {
                          xs: "table-row",
                          sm: "none",
                          md: "table-row",
                          lg: "none",
                        },
                      }}
                    >
                      <MyTableCell sx={{ width: { xs: "45px", sm: "60px" } }}>
                        Price:
                      </MyTableCell>
                      <MyTableCell>
                        {component
                          .getApp()
                          .priceFormater(
                            devHelper.getObjectValue(product, "price")
                          )}
                      </MyTableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </Box>
            </ListItem>
          </MyTableCell>

          <MyTableCell
            sx={{
              display: {
                xs: "none",
                sm: "table-cell",
                md: "none",
                lg: "table-cell",
              },
            }}
            align="right"
          >
            <Box sx={{ mt: "30px" }}>
              {component
                .getApp()
                .priceFormater(devHelper.getObjectValue(product, "price"))}
            </Box>
          </MyTableCell>
        </TableRow>
      )}
    </>
  );
}
