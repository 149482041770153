import React from "react";
import { Box, CardMedia } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import Slider from "react-slick";

export default function ProductsSlider(props) {
  const { component } = props;
  const devHelper = component.getHelpers("dev");
  const filesUrl = component.getHelpers("env").get("files_url");
  const img_version = component.getHelpers("value").getValue("images-version");

  const sliders = component.getData("default.sliders", []);

  const settings = {
    className: "center",
    centerMode: true,
    infinite: true,
    dots: false,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    speed: 1500,
    autoplaySpeed: 2500,
    centerPadding: "10%",
    nextArrow: <></>,
    prevArrow: <></>,
    pauseOnHover: false,
    responsive: [
      {
        breakpoint: 1000,
        settings: {
          slidesToShow: 3,
          centerPadding: "12.5%",
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          centerPadding: "17.5%",
        },
      },
    ],
  };

  return (
    <Box sx={{ pt: 1, display: { xs: "none", lg: "block" }, bgcolor: "#fff" }}>
      {sliders.length !== 0 && (
        <Slider {...settings}>
          {sliders.map((slider, index) => (
            <Box
              key={devHelper.getObjectValue(slider, "_values.slug")}
              sx={{
                p: "3px",
              }}
              component={RouterLink}
              to={devHelper.getObjectValue(slider, "_values.url")}
            >
              <CardMedia
                sx={{
                  maxWidth: "100%",
                }}
                component="img"
                alt={devHelper.getObjectValue(slider, "_values.name")}
                image={`${filesUrl}/konstantino/sliders/desktop/${devHelper.getObjectValue(
                  slider,
                  "_values.slug"
                )}.jpg?v=${img_version}`}
              />
            </Box>
          ))}
        </Slider>
      )}
    </Box>
  );
}
