import React, { useLayoutEffect, useState, useRef } from "react";
import { useTheme } from "@mui/material/styles";
import {
  Button,
  Dialog,
  DialogContent,
  Box,
  CardMedia,
  IconButton,
} from "@mui/material";
import SwipeableViews from "react-swipeable-views";
import CloseIcon from "@mui/icons-material/Close";
import ClickAwayListener from "@mui/base/ClickAwayListener";

export default function Zoom(props) {
  const { component } = props;
  const theme = useTheme();
  const devHelper = component.getHelpers("dev");
  const filesUrl = component.getHelpers("env").get("files_url");
  const images = component.getData("default.images", []);
  const activeImage = component.getData("default.activeImage", 0);

  const handleNext = () => {
    const activeImage = component.getData("default.activeImage", 0);
    component.setData({ "default.activeImage": activeImage + 1 });
  };

  const handleBack = () => {
    const activeImage = component.getData("default.activeImage", 0);
    component.setData({ "default.activeImage": activeImage - 1 });
  };

  const handleImageChange = (step) => {
    component.setData({ "default.activeImage": step });
  };

  const targetRef = useRef();

  function SetImageHeight() {
    const [height, setHeight] = useState(0);
    useLayoutEffect(() => {
      const resizeInterval = setInterval(() => {
        updateSize();
      }, 100);

      function updateSize() {
        if (targetRef.current) {
          clearInterval(resizeInterval);
          setHeight(900 * (targetRef.current.clientWidth / 720));
        }
      }

      window.addEventListener("resize", updateSize);

      return () => window.removeEventListener("resize", updateSize);
    }, []);
    return height;
  }

  const img_height = SetImageHeight();

  return (
    <>
      {images !== 0 && (
        <Dialog
          onClose={() => component.toggle()}
          open={component.getData("default.opened", false)}
          sx={{
            padding: 0,
            "& .MuiDialog-paper": {
              backgroundColor: "transparent",
            },
          }}
          fullScreen
        >
          <DialogContent
            sx={{
              backgroundColor: "rgba(179, 179, 179, 0.5)",
              p: 0,
              outline: 0,
              position: "relative",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <ClickAwayListener onClickAway={() => component.toggle()}>
              <Box
                ref={targetRef}
                sx={{
                  maxWidth: "720px",
                  flexGrow: 1,
                  position: "relative",
                  background: "transparent",
                }}
              >
                <>
                  <Box sx={{ height: img_height }}>
                    <IconButton
                      onClick={() => component.toggle()}
                      sx={{
                        zIndex: "1400",
                        position: "absolute",
                        top: { xs: 15, md: 20 },
                        right: { xs: 15, md: 20 },
                        fontSize: { xs: "24px", sm: "28px" },
                        color: "#000",
                      }}
                    >
                      <CloseIcon />
                    </IconButton>
                    <Button
                      onClick={() => handleBack()}
                      disabled={activeImage === 0}
                      disableRipple
                      sx={{
                        position: "absolute",
                        zIndex: "1400",
                        top: "50%",
                        minWidth: 0,
                        left: { xs: 15, md: 20 },
                        fontSize: { xs: "22px", sm: "28px" },
                        transform: "translateY(-50%)",
                        display: images.length !== 0 ? "initial" : "none",
                        "&:hover": {
                          backgroundColor: "transparent",
                        },
                      }}
                    >
                      <i className="fa-light fa-chevron-left"></i>
                    </Button>
                    <Button
                      onClick={() => handleNext()}
                      disabled={activeImage === images.length - 1}
                      disableRipple
                      sx={{
                        position: "absolute",
                        zIndex: "1400",
                        top: "50%",
                        minWidth: 0,
                        right: { xs: 15, md: 20 },
                        fontSize: { xs: "22px", sm: "28px" },
                        transform: "translateY(-50%)",
                        display: images.length !== 0 ? "initial" : "none",
                        "&:hover": {
                          backgroundColor: "transparent",
                        },
                      }}
                    >
                      <i className="fa-light fa-chevron-right"></i>
                    </Button>
                    <SwipeableViews
                      axis={theme.direction === "rtl" ? "x-reverse" : "x"}
                      index={activeImage}
                      onChangeIndex={handleImageChange}
                      enableMouseEvents
                      interval={3000}
                    >
                      {images.length !== 0 ? (
                        images.map((image, index) => (
                          <Box
                            key={"product-img-" + index}
                            sx={{ height: "100%" }}
                          >
                            <CardMedia
                              style={{
                                maxWidth: "100%",
                                cursor: "pointer",
                              }}
                              component="img"
                              image={
                                component.getData(
                                  `default.img-error-product-${index}`,
                                  false
                                )
                                  ? filesUrl.concat(
                                      `/konstantino/assets/product-not-found.jpg`
                                    )
                                  : filesUrl.concat(
                                      `/konstantino/products/${devHelper.getObjectValue(
                                        image,
                                        "name"
                                      )}`
                                    )
                              }
                              alt={devHelper.getObjectValue(image, "name")}
                              onLoad={() => {
                                component.setData({ "default.ready": true });
                              }}
                              onError={() => {
                                component.setData({
                                  [`default.img-error-product-${index}`]: true,
                                });
                              }}
                            />
                          </Box>
                        ))
                      ) : (
                        <Box sx={{ height: "100%" }}>
                          <CardMedia
                            style={{
                              maxWidth: "100%",
                              cursor: "pointer",
                            }}
                            component="img"
                            image={filesUrl.concat(
                              `/konstantino/assets/product-not-found.jpg`
                            )}
                            alt="product-not-found"
                            onLoad={() => {
                              component.setData({ "default.ready": true });
                            }}
                          />
                        </Box>
                      )}
                    </SwipeableViews>
                  </Box>
                </>
              </Box>
            </ClickAwayListener>
          </DialogContent>
        </Dialog>
      )}
    </>
  );
}
