import React from "react";
import { styled } from "@mui/material/styles";
import {
  Grid,
  TextField,
  Alert,
  Typography,
  // FormControl,
  // InputLabel,
  // Select,
  // MenuItem,
  // FormHelperText,
} from "@mui/material";

const MyTextField = styled(TextField)(({ theme }) => ({
  "& label.Mui-focused": {
    color: "#000",
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "#000",
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "#000",
    },
    "&:hover fieldset": {
      borderColor: "#000",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#000",
    },
  },
}));

// const MyFormControl = styled(FormControl)(({ theme }) => ({
//   width: "100%",
//   [`& fieldset`]: {
//     borderRadius: 0,
//   },
//   "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
//     borderColor: "#000",
//   },
// }));

export default function Address(props) {
  const { component, type } = props;
  // const devHelper = component.getHelpers("dev");

  var order = "";
  if (type !== "address") {
    order = "order.";
  }

  // const states = [
  //   { name: "Alabama" },
  //   { name: "Alaska" },
  //   { name: "Arizona" },
  //   { name: "Texas" },
  // ];

  return (
    <Grid container justifycontent="center" rowSpacing={0} columnSpacing={1}>
      <Grid item xs={12}>
        <Alert severity="info" sx={{ py: "5px" }}>
          {component.ucfirst("shipping-restriction")}
        </Alert>
      </Grid>
      <Grid item sm={6} xs={12}>
        <MyTextField
          variant="outlined"
          margin="normal"
          fullWidth
          label={component.ucfirst("first_name-textfield")}
          name="first_name"
          helperText={
            component.getData("error." + type + ".first_name", [
              { message: "" },
            ])[0].message
          }
          value={component.getData(
            "default." + order + "" + type + ".first_name",
            ""
          )}
          onChange={(e) => {
            component.setData({
              ["default." + order + "" + type + ".first_name"]: e.target.value,
            });
            component.deleteData("error." + type + ".first_name");
          }}
          onFocus={(e) => component.setData({ onSubmit: true })}
          error={component.dataExists("error." + type + ".first_name")}
        />
      </Grid>
      <Grid item sm={6} xs={12}>
        <MyTextField
          variant="outlined"
          margin="normal"
          fullWidth
          label={component.ucfirst("last_name-textfield")}
          name="last_name"
          helperText={
            component.getData("error." + type + ".last_name", [
              { message: "" },
            ])[0].message
          }
          value={component.getData(
            "default." + order + "" + type + ".last_name",
            ""
          )}
          onChange={(e) => {
            component.setData({
              ["default." + order + "" + type + ".last_name"]: e.target.value,
            });
            component.deleteData("error." + type + ".last_name");
          }}
          onFocus={(e) => component.setData({ onSubmit: true })}
          error={component.dataExists("error." + type + ".last_name")}
        />
      </Grid>
      <Grid item xs={12}>
        <MyTextField
          variant="outlined"
          margin="normal"
          fullWidth
          label={component.ucfirst("phone-textfield")}
          name="phone"
          helperText={
            component.getData("error." + type + ".phone", [{ message: "" }])[0]
              .message
          }
          value={component.getData(
            "default." + order + "" + type + ".phone",
            ""
          )}
          onChange={(e) => {
            component.setData({
              ["default." + order + "" + type + ".phone"]: e.target.value,
            });
            component.deleteData("error." + type + ".phone");
          }}
          onFocus={(e) => component.setData({ onSubmit: true })}
          error={component.dataExists("error." + type + ".phone")}
        />
      </Grid>
      <Grid item xs={12}>
        <Typography sx={{ mt: 2, mb: -1, fontSize: 14 }}>
          {component.ucfirst("street_address-helper-title")}
        </Typography>
        <MyTextField
          id={type !== "address" ? type + "_autocomplete" : "autocomplete"}
          variant="outlined"
          margin="normal"
          fullWidth
          placeholder={component.ucfirst("street_address-holder")}
          label={component.ucfirst("street_address-textfield")}
          name="street"
          helperText={
            component.getData("error." + type + ".street", [{ message: "" }])[0]
              .message ||
            component.getData("error." + type + ".street_number", [
              { message: "" },
            ])[0].message
          }
          value={component.getData(
            "default." + order + "" + type + ".street",
            ""
          )}
          onChange={(e) => {
            component.setData({
              ["default." + order + "" + type + ".street"]: e.target.value,
              ["default." + order + "" + type + ".city"]: "",
              ["default." + order + "" + type + ".postal_code"]: "",
              ["default." + order + "" + type + ".country"]: "",
              ["default." + order + "" + type + ".state"]: "",
            });
            component.deleteData("error." + type + ".street");
            component.deleteData("error." + type + ".street_number");
          }}
          onFocus={(e) => component.setData({ onSubmit: false })}
          error={
            component.dataExists("error." + type + ".street") ||
            component.dataExists("error." + type + ".street_number")
          }
        />
      </Grid>
      <Grid item sm={6} xs={12}>
        <TextField
          disabled
          variant="outlined"
          margin="normal"
          fullWidth
          label={component.ucfirst("city-textfield")}
          name="city"
          value={component.getData(
            "default." + order + "" + type + ".city",
            ""
          )}
          error={component.dataExists("error." + type + ".city")}
          size="small"
        />
      </Grid>
      <Grid item sm={6} xs={12}>
        <TextField
          disabled
          sx={{ borderRadius: 0 }}
          variant="outlined"
          margin="normal"
          fullWidth
          label={component.ucfirst("textfield-state")}
          name="state"
          autoComplete="state"
          value={component.getData(
            "default." + order + "" + type + ".state",
            ""
          )}
          error={component.dataExists("error." + type + ".state")}
          size="small"
        />
      </Grid>
      <Grid item sm={6} xs={12}>
        <TextField
          disabled
          variant="outlined"
          margin="normal"
          fullWidth
          label={component.ucfirst("postal_code-textfield")}
          name="postal_code"
          value={component.getData(
            "default." + order + "" + type + ".postal_code",
            ""
          )}
          error={component.dataExists("error." + type + ".postal_code")}
          size="small"
        />
      </Grid>
      <Grid item sm={6} xs={12}>
        <TextField
          disabled
          variant="outlined"
          margin="normal"
          fullWidth
          label={component.ucfirst("country-textfield")}
          name="country"
          autoComplete="country"
          value={component.getData(
            "default." + order + "" + type + ".country",
            ""
          )}
          error={component.dataExists("error." + type + ".country")}
          size="small"
        />
      </Grid>
      <Grid item xs={12}>
        <MyTextField
          variant="outlined"
          margin="normal"
          multiline
          fullWidth
          placeholder={component.trans("notes-holder")}
          rows={2}
          label={component.ucfirst("notes-textfield")}
          InputLabelProps={{
            shrink: true,
          }}
          name="notes"
          value={component.getData(
            "default." + order + "" + type + ".notes",
            ""
          )}
          onChange={(e) => {
            component.setData({
              ["default." + order + "" + type + ".notes"]: e.target.value,
            });
          }}
        />
      </Grid>
    </Grid>
  );
}
