import React, { useEffect } from "react";
import {
  Container,
  Box,
  Grid,
  Button,
  FormControl,
  Select,
  MenuItem,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import Layouts from "../../layouts/layouts";
import Filters from "../filters";
import ProductListView from "../../../../Components/Widgets/ProductListView";
import ProductListSkeletonView from "../../widgets/product_list_skeleton_view";

export default function Collection(props) {
  const { component } = props;
  const theme = useTheme();
  const types = component.getData("default.types", []);

  const { products = null, filters = [] } = component.getData("default", {});
  const term = component.getSearchTerm();
  const devHelper = component.getHelpers("dev");

  const layouts = component.getData("default.layouts", []);
  const Products = Layouts["products"];
  const FullBanner = Layouts["full_bannner"];
  const SideBanner = Layouts["side_bannner"];

  const view = useMediaQuery(theme.breakpoints.up("md")) ? "desktop" : "mobile";

  const [filterDrawer, setFilterDrawer] = React.useState(false);
  const [activeType, setActiveType] = React.useState("all");
  const pageGender = component.getGender();
  const pageType = component.getData("default.pageType", "");
  const pageValue = component.getData("default.pageValue", "");
  const page = `${pageGender}-${pageType}-${pageValue}`;
  const title = `${page}-title_${view}`;
  const subtitle = `${page}-subtitle_${view}`;

  var index = 0;

  useEffect(() => {
    const onScroll = () => {
      const max = 0.6;

      if (
        !component.getData("default.loading") &&
        component.canFetchMore() &&
        (window.scrollY + window.innerHeight) / document.body.clientHeight > max
      ) {
        component.fetchMore();
      }

      localStorage.setItem("scrollPosition", window.scrollY);
      localStorage.setItem("scrollList", window.location.pathname);
    };

    const onBack = () => {
      localStorage.removeItem("saveScrollPosition");
      const pathname = window.location.pathname.split("/");

      pathname.shift();

      if (pathname.length) {
        const type = pathname.shift();

        if (["women", "men"].indexOf(type) !== -1) {
          component.getPage().redirect(`/${type}`);
        }
      }
    };

    window.addEventListener("scroll", onScroll);
    window.addEventListener("popstate", onBack);

    return () => {
      if (onScroll) {
        window.removeEventListener("scroll", onScroll);
      }

      if (onBack) {
        window.removeEventListener("popstate", onBack);
      }

      const scrollY = localStorage.getItem("scrollPosition");

      localStorage.setItem("saveScrollPosition", scrollY);
    };
  }, [component]);

  return (
    <Container disableGutters maxWidth="xl">
      <Box
        sx={{
          width: "100%",
          textAlign: "center",
          p: 2,
          position: "relative",
        }}
      >
        <Box
          sx={{
            width: "100%",
            position: "relative",
          }}
        >
          <Button
            sx={{
              position: "absolute",
              borderRadius: 0,
              borderBottom: "1px solid #000",
              top: 0,
              left: 0,
              fontSize: "14px",
              fontFamily: "Spartan-Medium",
              textTransform: "none",
              p: 0,
              "&:hover": {
                background: "transparent",
              },
              "&:disabled": {
                color: "#000",
              },
            }}
            disabled
            disableRipple
          >
            {component.getData("default.total", 0) + " products"}
          </Button>
          <Button
            sx={{
              position: "absolute",
              borderRadius: 0,
              top: 0,
              right: 0,
              fontSize: "14px",
              fontFamily: "Spartan-Medium",
              textTransform: "none",
              p: 0,
              "&:hover": {
                background: "transparent",
              },
              "&:disabled": {
                color: "#000",
              },
            }}
            onClick={() => setFilterDrawer((prev) => !prev)}
            disableRipple
          >
            <i
              className="fa-light fa-sliders"
              style={{ fontSize: "20px", marginRight: "5px" }}
            ></i>
            Filters &amp; Sort
          </Button>
        </Box>

        <Box sx={{ mt: 5 }}>
          {term && (
            <Typography
              component="h1"
              sx={{
                textAlign: "center",
                fontFamily: "Poppins-Regular",
                fontSize: { xs: 28, md: 34 },
              }}
            >
              Search Results For: «{decodeURIComponent(term)}»
            </Typography>
          )}
          <Box
            sx={{
              width: "100%",
              height: "25px",
              textAlign: "center",
              mb: { xs: 3, md: 1 },
            }}
          >
            {component.showTypes() && types.length !== 0 && (
              <>
                <Box
                  sx={{
                    display: { xs: "none", md: "initial" },
                  }}
                >
                  <Button
                    sx={{
                      fontSize: "15px",
                      fontFamily: "Spartan-SemiBold",
                      textTransform: "none",
                      p: 0,
                      width: "110px",
                      "&:hover,&:focus": {
                        backgroundColor: "transparent",
                        fontFamily: "Spartan-Bold",
                      },
                    }}
                    onClick={() => {
                      setFilterDrawer(false);
                      component.allTypes();
                    }}
                    disableRipple
                  >
                    {component.ucfirst("all")}
                  </Button>

                  {types
                    .filter((type) => component.typeIsVisible(type))
                    .map((type) => {
                      const { _values = {} } = type;
                      const { slug, name } = _values;
                      return (
                        <Button
                          sx={{
                            fontSize: "15px",
                            textTransform: "none",
                            p: 0,
                            width: "110px",
                            "&:hover,&:focus": {
                              backgroundColor: "transparent",
                              fontFamily: "Spartan-Bold",
                            },
                            fontFamily: component.isOnType(slug)
                              ? "Spartan-Bold"
                              : "Spartan-SemiBold",
                          }}
                          disableRipple
                          key={`type-${slug}`}
                          onClick={() => {
                            setFilterDrawer(false);
                            component.selectType(slug);
                          }}
                        >
                          {component.ucfirst(name)}
                        </Button>
                      );
                    })}
                </Box>
                <FormControl
                  fullWidth
                  sx={{
                    display: { xs: "initial", md: "none" },
                  }}
                  size="small"
                >
                  <Select
                    onChange={(e) => {
                      const value = e.target.value;
                      setActiveType(value);
                      setFilterDrawer(false);
                      value === "all"
                        ? component.allTypes()
                        : component.selectType(e.target.value);
                    }}
                    value={activeType}
                  >
                    <MenuItem value="all">{component.ucfirst("all")}</MenuItem>
                    {types.map((type) => {
                      const { _values = {} } = type;
                      const { slug, name } = _values;
                      return (
                        <MenuItem key={`type-${slug}`} value={slug}>
                          {component.ucfirst(name)}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </>
            )}
          </Box>
          {!component.trans(title).includes(`_${view}`) && (
            <Typography
              component="h1"
              sx={{
                color: "#000",
                fontFamily: "Poppins-Regular",
                fontSize: { xs: 28, md: 34 },
              }}
            >
              {component.trans(title)}
            </Typography>
          )}
          {!component.trans(subtitle).includes(`_${view}`) && (
            <Typography
              component="h3"
              sx={{
                color: "#000",
                fontFamily: "Spartan-SemiBold",
                fontSize: { xs: 12, md: 15 },
                maxWidth: { xs: "unset", md: "700px" },
                m: "auto",
              }}
            >
              {component.trans(subtitle)}
            </Typography>
          )}
        </Box>
      </Box>
      <div
        style={{
          position: "relative",
          overflowX: "hidden",
          minHeight: "600px",
        }}
      >
        <Filters
          {...{
            ...props,
            ...{
              filterDrawer,
              filters,
              setFilterDrawer: (boolean) => setFilterDrawer(boolean),
            },
          }}
        />
        <Box
          sx={{
            width: "100%",
            transition: "margin-left 0.3s",
            marginLeft: filterDrawer ? "350px" : 0,
            "@media (max-width: 500px)": {
              marginLeft: filterDrawer ? "100%" : 0,
            },
          }}
        >
          {!products ? (
            <Grid
              container
              sx={{
                alignItems: "flex-start",
              }}
              justifycontent="center"
              direction="row"
              spacing={"4px"}
            >
              {[...new Array(40)].map((product, index) => (
                <ProductListSkeletonView
                  key={`skeleton-${index}`}
                  {...{
                    ...props,
                    ...{
                      grid: 4,
                    },
                  }}
                />
              ))}
            </Grid>
          ) : (
            <>
              {products.length !== 0 ? (
                <Grid
                  container
                  sx={{
                    alignItems: "flex-start",
                  }}
                  justifycontent="center"
                  direction="row"
                  spacing={"4px"}
                >
                  {component.showBanners() ? (
                    <>
                      {layouts.map((layout, i) => {
                        if (layout.slug === "products") {
                          return (
                            <Products
                              key={"layout-" + i}
                              {...{
                                ...props,
                                ...{
                                  products,
                                  from: index,
                                  to: (index = index + layout.items),
                                },
                              }}
                            />
                          );
                        } else if (layout.slug === "full_bannner") {
                          return (
                            <FullBanner
                              key={"layout-" + i}
                              {...{
                                ...props,
                                ...{
                                  image: layout.img,
                                },
                              }}
                            />
                          );
                        } else {
                          return (
                            <SideBanner
                              key={"layout-" + i}
                              {...{
                                ...props,
                                ...{
                                  products,
                                  from: index,
                                  to: (index = index + layout.items),
                                  image: layout.img,
                                },
                              }}
                            />
                          );
                        }
                      })}
                    </>
                  ) : (
                    <>
                      {products.map((product) => (
                        <ProductListView
                          key={`${page}-${devHelper.getObjectValue(
                            product,
                            "_id"
                          )}`}
                          {...{
                            ...props,
                            ...{
                              product,
                              grid: 4,
                            },
                          }}
                        />
                      ))}
                    </>
                  )}
                </Grid>
              ) : (
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <Box
                    sx={{
                      textAlign: "center",
                      mt: "50px",
                    }}
                  >
                    <Typography
                      sx={{
                        fontFamily: "Spartan-SemiBold",
                        fontSize: "16px",
                        mb: 6,
                      }}
                    >
                      NO PRODUCTS FOUND
                    </Typography>
                  </Box>
                </Box>
              )}
            </>
          )}
        </Box>
      </div>
      <Box
        sx={{
          width: "100%",
          textAlign: "center",
          my: 6,
          position: "relative",
        }}
      >
        {component.canFetchMore() && (
          <>
            <Box
              sx={{
                width: "100%",
                textAlign: "center",
                zIndex: -1,
                position: "absolute",
                top: "50%",
                left: 0,
                px: { xs: 2, sm: 4, md: 6, lg: 8 },
              }}
            >
              <Box
                sx={{
                  borderTop: "2px solid #808080",
                  width: "100%",
                }}
              ></Box>
            </Box>
            {/* <Box
              sx={{
                backgroundColor: "#fff",
                maxWidth: {
                  xs: "180px",
                  sm: "200px",
                },
                m: "auto",
              }}
            >
              <Button
                sx={{
                  color: "#000",
                  border: "1px solid #000",
                  px: 6,
                  py: 1,
                  borderRadius: 0,
                  textTransform: "none",
                  lineHeight: 1,
                  fontWeight: 400,
                }}
                onClick={() => component.fetchMore()}
              >
                View {component.getMoreAmount()} <br />
                more
              </Button>
            </Box> */}
          </>
        )}
      </Box>
    </Container>
  );
}
