import { Component } from "../../../modules/Core/Component";
import templateDefault from "../../templates/default/widgets/drawers/menu_drawer";
import Services from "../../Services/Services";

export default class MenuDrawer extends Component {
  id = "menu-drawer";
  group = "wishlist";

  template = templateDefault;

  onLoad(data) {
    super.onLoad(data);

    this.checkWishlist();
    this.getMenu();
  }

  getMenu() {
    Services.get("menu").then(([menuService]) => {
      menuService
        .getAll()
        .then((menuService) => {
          this.setData({ "default.mainMenu": menuService.getData("menu", []) });
        })
        .catch((menuService) => {});
    });
  }

  checkWishlist() {
    Services.get("wishlist").then(([wishlistService]) => {
      var wishlist = wishlistService.getWishlist();
      this.setData({ "default.wishlist": wishlist });
    });
  }

  toggle() {
    this.toggleData("default.opened", {
      defaultValue: true,
    });
  }

  toggleSubMenuDrawer(index) {
    this.getComponents()
      .findById("sub-menu-drawer")
      .forEach((drawer) => {
        drawer.toggle(index);
      });
  }
}
