import { Component } from "../../../modules/Core/Component";
import templateDefault from "../../templates/default/widgets/cart/product_cart_popper_view";
// import Services from "../../Services/Services";

export default class ProductCartPopperView extends Component {
  id = "procut-checkout-cart-view";

  template = templateDefault;

  onLoad(data) {
    super.onLoad(data);

    this.setProducts();
  }

  setProducts() {
    var product = { ...this.props.product };

    product.simple = product.sku === "combinations.options:g-default,s-default";

    var combination = product.sku.split(":")[1];
    var options = combination.split(",");

    var comb = product.item.combinations.find((c) => c.options === combination);

    product.p_sku = comb.sku;

    if (!product.simple) {
      product.gem = product.item.gems.find(
        (gem) => gem.item._values.slug === options[0]
      );

      product.size = product.item.sizes.find((size) => {
        var opt = options[1] !== undefined ? options[1] : options[0];
        return size.item._values.slug === opt;
      });
    }

    this.filterImages(product.item.images, product.p_sku);

    this.setData({ "default.product": product });
  }

  filterImages = (images, sku) => {
    var filtered_images = images.filter((img) =>
      img.name.includes(sku.toLowerCase())
    );
    this.setData({
      "default.image":
        filtered_images.length !== 0 ? filtered_images[0].name : null,
    });

    return this;
  };
}
