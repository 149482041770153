import { Component } from "../../../../modules/Core/Component";
import templateDefault from "../../../templates/default/widgets/sliders/shop_by_design_metal";
import Services from "../../../Services/Services";

export default class ShopByDesignMetal extends Component {
  group = "slider";

  template = templateDefault;

  onLoad(data) {
    super.onLoad(data);

    this.getSliders();
  }

  getSliders() {
    Services.get("hook").then(async ([hookService]) => {
      try {
        var response = await hookService.exec("sliders", {
          params: { scope: this.props.fscope },
        });

        const types = response.getData() || [];
        response = await hookService.exec("sliders", {
          params: { scope: this.props.fscope2 },
        });

        const metals = response.getData() || [];

        const sliders = [...types, ...metals];

        this.startInterval(sliders, 0);

        this.setData({
          "default.metals": metals,
          "default.types": types,
          "default.sliders": sliders,
        });
      } catch (e) {
        console.log(e);
      }
    });
  }

  startInterval(sliders, activeSlide) {
    clearInterval(this.interval);
    this.setData({
      "default.active_slide": activeSlide,
    });
    this.interval = setInterval(() => {
      this.setData({
        "default.active_slide": ++activeSlide % sliders.length,
      });
    }, 5000);
  }
}
