import React from "react";
import { Box } from "@mui/material";
import NavBar from "../../../Components/Widgets/Navbar";
import MenuDrawer from "../../../Components/Widgets/MenuDrawer";
import SubMenuDrawer from "../../../Components/Widgets/SubMenuDrawer";
import TopBannersSlider2 from "../../../Components/Widgets/Sliders/TopBannersSlider2";
import SpecialColSlider from "../../../Components/Widgets/Sliders/SpecialColSlider";
import NewCollections from "../widgets/sliders/new_collections";
import CollectionsSlider from "../../default/widgets/sliders/collections_slider";
import AllCollections from "../../default/widgets/sliders/all_collections";
import Footer from "../widgets/footer";

const ExportDefault = (props) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        minHeight: "100vh",
      }}
    >
      <NavBar
        {...{
          ...props,
          ...{
            bg_color: "transparent",
          },
        }}
      />
      <MenuDrawer {...props} />
      <SubMenuDrawer {...props} />
      <TopBannersSlider2
        {...{
          ...props,
          ...{
            fscope: "women-collection-top-banner",
            lazyLoading: true,
          },
        }}
      />
      <NewCollections
        {...{
          ...props,
          ...{
            sliders: ["kyma", "gen-k", "karia", "1993", "zodiac", "anthos"],
          },
        }}
      />
      <SpecialColSlider
        {...{
          ...props,
          ...{
            fscope: "women-special-col-slider",
            textMaxWidthDesktop: "400px",
            textMaxWidthMobile: "350px",
          },
        }}
      />
      <CollectionsSlider
        {...{
          ...props,
          ...{
            sliders: [
              "muse",
              "pythia",
              "dome",
              "delos-2",
              "astria",
              "birthstone",
              "crosses",
              "ultra",
              "angelic",
              "paradise",
              "gen-k",
              "callas",
            ],
          },
        }}
      />
      <AllCollections
        {...{
          ...props,
          ...{
            gender: "women",
          },
        }}
      />
      <Footer {...props} />
    </Box>
  );
};

export default ExportDefault;
