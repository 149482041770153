import React from "react";
import { styled } from "@mui/material/styles";
import {
  Button,
  TextField,
  Container,
  IconButton,
  OutlinedInput,
  InputLabel,
  InputAdornment,
  FormControl,
  FormHelperText,
  Box,
} from "@mui/material";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

const SubmitBtn = styled(Button)(({ theme }) => ({
  margin: theme.spacing(2, 0),
  padding: "12px 14px",
  background: "#000",
  borderRadius: 0,
  border: "1px solid #000",
  color: "#ffffff",
  "&:hover": {
    background: "#000",
  },
}));

const MyTextField = styled(TextField)(({ theme }) => ({
  "& label.Mui-focused": {
    color: "#000",
    borderRadius: 0,
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "#000",
    borderRadius: 0,
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "#000",
      borderRadius: 0,
    },
    "&:hover fieldset": {
      borderColor: "#000",
      borderRadius: 0,
    },
    "&.Mui-focused fieldset": {
      borderColor: "#000",
      borderRadius: 0,
    },
  },
}));

const PasswordField = styled(FormControl)(({ theme }) => ({
  marginTop: "16px",
  marginBottom: "8px",
  width: "100%",
  "& label.Mui-focused": {
    color: "#000",
    borderRadius: 0,
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "#000",
    borderRadius: 0,
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "#000",
      borderRadius: 0,
    },
    "&:hover fieldset": {
      borderColor: "#000",
      borderRadius: 0,
    },
    "&.Mui-focused fieldset": {
      borderColor: "#000",
      borderRadius: 0,
    },
  },
}));

export default function Profile(props) {
  const { component } = props;

  const [values, setValues] = React.useState({
    showPassword: false,
  });

  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <Container
      maxWidth="lg"
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <Box
        sx={{
          borderTop: "2px solid #000",
          width: "100%",
          textAlign: "center",
          position: "relative",
          zIndex: -1,
          my: 6,
        }}
      >
        <Box
          sx={{
            fontFamily: "Poppins-Regular",
            color: "#000",
            lineHeight: 1,
            mx: "auto",
            textAlign: "center",
            position: "absolute",
            top: "0px",
            left: "50%",
            transform: "translate(-50%, -50%)",
            bgcolor: "#fff",
            px: 5,
            fontSize: "34px",
          }}
        >
          {component.ucfirst("profile-btn")}
        </Box>
      </Box>
      <Container
        maxWidth="xs"
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          marginBottom: "20px",
        }}
      >
        <form
          style={{ width: "100%", marginTop: "8px" }}
          onSubmit={(e) => component.updateProfile(e)}
        >
          <MyTextField
            variant="outlined"
            margin="normal"
            fullWidth
            id="name"
            label={component.ucfirst("fullname-textfield")}
            name="name"
            helperText={component.getData("error.fields.name")}
            error={component.dataExists("error.fields.name")}
            value={component.getData("default.fields.name", "")}
            onChange={(e) => {
              component.setData({ "default.fields.name": e.target.value });
              component.deleteData("error.fields.name");
            }}
          />
          <MyTextField
            disabled
            variant="outlined"
            margin="normal"
            fullWidth
            id="email"
            label={component.ucfirst("email-textfield")}
            name="email"
            value={component.getData("default.fields.username", "")}
          />

          <PasswordField
            variant="outlined"
            error={component.dataExists("error.fields.password")}
          >
            <InputLabel htmlFor="outlined-adornment-password">
              {component.ucfirst("password-textfield")}
            </InputLabel>
            <OutlinedInput
              type={values.showPassword ? "text" : "password"}
              value={component.getData("default.fields.password", "")}
              onChange={(e) => {
                component.setData({
                  "default.fields.password": e.target.value,
                });
                component.deleteData("error.fields.password");
              }}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                    disableRipple
                    sx={{
                      "&:hover, &:focus": { backgroundColor: "transparent" },
                    }}
                  >
                    {values.showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              }
              label={component.ucfirst("password-textfield")}
            />
            {component.dataExists("error.fields.password") && (
              <FormHelperText error style={{ margin: "3px 14px 0" }}>
                {component.getData("error.fields.password")}
              </FormHelperText>
            )}
          </PasswordField>

          <PasswordField
            variant="outlined"
            error={component.dataExists("error.fields.verify")}
          >
            <InputLabel htmlFor="outlined-adornment-password">
              {component.ucfirst("confirm-password-textfield")}
            </InputLabel>
            <OutlinedInput
              type={values.showPassword ? "text" : "password"}
              value={component.getData("default.fields.verify", "")}
              onChange={(e) => {
                component.setData({
                  "default.fields.verify": e.target.value,
                });
                component.deleteData("error.fields.verify");
              }}
              label={component.ucfirst("confirm-password-textfield")}
            />
            {component.dataExists("error.fields.verify") && (
              <FormHelperText error style={{ margin: "3px 14px 0" }}>
                {component.getData("error.fields.verify")}
              </FormHelperText>
            )}
          </PasswordField>

          <SubmitBtn type="submit" fullWidth variant="contained">
            {component.ucfirst("submit-btn")}
          </SubmitBtn>
        </form>
      </Container>
    </Container>
  );
}
