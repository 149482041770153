import { Component } from "../../../modules/Core/Component";
import templateDefault from "../../templates/default/widgets/drawers/sub_menu_drawer";

export default class SubMenuDrawer extends Component {
  id = "sub-menu-drawer";

  template = templateDefault;

  toggle(index) {
    if (index !== undefined) this.setData({ "default.menu": index });
    this.toggleData("default.opened", {
      defaultValue: true,
    });
  }

  toggleMenuDrawer() {
    this.getComponents()
      .findById("menu-drawer")
      .forEach((drawer) => {
        drawer.toggle();
      });
  }

  getMainMenu() {
    const menuDrawer = this.getComponents().findById("menu-drawer").first();
    return menuDrawer ? menuDrawer.getData("default.mainMenu", []) : [];
  }
}
