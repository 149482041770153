import React from "react";
import { styled } from "@mui/material/styles";
import PropTypes from "prop-types";
import {
  Button,
  TextField,
  Typography,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  Alert as MuiAlert,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

const LoginRegisterBtn = styled(Button)(({ theme }) => ({
  margin: theme.spacing(2, 0),
  padding: "12px 14px",
  background: "#000",
  borderRadius: 0,
  border: "1px solid #000",
  color: "#fff",
  "&:hover": {
    opcity: "0.8",
    background: "#000",
  },
}));

const MyTextField = styled(TextField)(({ theme }) => ({
  "& label.Mui-focused": {
    color: "#000",
    borderRadius: 0,
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "#000",
    borderRadius: 0,
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "#000",
      borderRadius: 0,
    },
    "&:hover fieldset": {
      borderColor: "#000",
      borderRadius: 0,
    },
    "&.Mui-focused fieldset": {
      borderColor: "#000",
      borderRadius: 0,
    },
  },
}));

const MyDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": { padding: theme.spacing(2) },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
  "& .MuiPaper-rounded": {
    borderRadius: 0,
  },
}));

const MyDialogTitle = (props) => {
  const { children, onClose, ...other } = props;
  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

MyDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

function Alert(props) {
  return (
    <MuiAlert
      elevation={0}
      variant="filled"
      {...props}
      style={{ borderRadius: 0 }}
    />
  );
}

export default function ForgotPasswordDialog(props) {
  const { component } = props;
  const { open = false } = component.props;

  const errorMessagePasswordRequest = component.getData(
    "error.message.password.request"
  );

  return (
    <MyDialog
      onClose={() => component.props.setOpen(false)}
      aria-labelledby="customized-dialog-title"
      open={open}
      maxWidth="sm"
      fullWidth
    >
      <MyDialogTitle
        id="customized-dialog-title"
        onClose={() => component.props.setOpen(false)}
      >
        {component.ucfirst("reset-password-title")}
      </MyDialogTitle>
      <DialogContent dividers>
        <Typography variant="body2">
          {component.trans("reset-password-text-1")}
        </Typography>
        <form onSubmit={(e) => component.requestPassword(e)}>
          {errorMessagePasswordRequest && (
            <Alert style={{ marginTop: "10px" }} severity="error">
              {component.ucfirst(errorMessagePasswordRequest)}
            </Alert>
          )}
          <MyTextField
            error={component.dataExists("error.fields.request.username")}
            value={component.getData("default.fields.request.username", "")}
            helperText={component.getData("error.fields.request.username")}
            onChange={(e) => {
              component.setData({
                "default.fields.request.username": e.target.value,
              });
              component.deleteData("error.fields.request.username");
            }}
            variant="outlined"
            margin="normal"
            fullWidth
            id="email"
            label={component.ucfirst("email-textfield")}
            name="email"
          />
          <LoginRegisterBtn type="submit" fullWidth variant="contained">
            {component.trans("submit-btn")}
          </LoginRegisterBtn>
        </form>
      </DialogContent>
    </MyDialog>
  );
}
