import React from "react";
import { styled } from "@mui/material/styles";
import {
  SwipeableDrawer,
  CardMedia,
  Link,
  Box,
  Button,
  Typography,
  Grid,
} from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import { useTheme } from "@mui/material/styles";

const MyLi = styled("li")(({ theme }) => ({}));

const MyLink = styled(Link)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  fontFamily: "Spartan-Medium",
  color: "#000",
  fontSize: "13px",
  textTransform: "none",
  padding: 0,
  textDecoration: "none",
  "&:hover": {
    backgroundColor: "transparent",
  },
}));

const MainTitle = styled(Box)(({ theme }) => ({
  fontFamily: "Spartan-SemiBold",
  color: "#fff",
  fontSize: "16px",
}));

const Title = styled("div")(({ theme }) => ({
  fontFamily: "Poppins-SemiBold",
  color: "#000",
  fontSize: "16px",
  marginTop: "24px",
}));

export default function MenuDrawer(props) {
  const { component } = props;
  const theme = useTheme();
  const menu = component.getData("default.menu", null);
  const filesUrl = component.getHelpers("env").get("files_url");
  const img_version = component.getHelpers("value").getValue("images-version");

  const mainMenu = component.getMainMenu();
  const kgifts = mainMenu.find((menu) => menu.slug === "gifts");
  const kwomens = mainMenu.find((menu) => menu.slug === "womens");
  const kmens = mainMenu.find((menu) => menu.slug === "mens");
  const kworld = mainMenu.find((menu) => menu.slug === "world");
  const klocations = mainMenu.find((menu) => menu.slug === "locations");

  const closeAllDrawers = () => {
    component.toggleMenuDrawer();
    component.toggle();
  };

  return (
    <SwipeableDrawer
      anchor="left"
      open={component.getData("default.opened", false)}
      onClose={() => closeAllDrawers()}
      onOpen={() => {}}
      sx={{
        zIndex: (theme) => theme.zIndex.drawer + 3,
        "& .MuiDrawer-paper": {
          p: 0,
          overflowY: "scroll",
          scrollbarWidth: "none",
          msOverflowStyle: "none",
          width: { xs: "90%", sm: "500px" },
        },
        "& .MuiDrawer-paper::-webkit-scrollbar": {
          display: "none",
        },
      }}
      transitionDuration={{ enter: 1000, exit: 500 }}
      swipeAreaWidth={0}
    >
      <Box
        sx={{
          backgroundColor: "rgb(0, 0, 0,0.9)",
          width: "100%",
          position: "relative",
        }}
      >
        <i
          className="fa-light fa-chevron-left"
          onClick={() => component.toggle()}
          style={{
            position: "absolute",
            cursor: "pointer",
            left: "24px",
            top: "50%",
            transform: "translate(0,-50%)",
            fontSize: "22px",
            color: "#fff",
          }}
        ></i>
        <i
          className="fa-light fa-xmark"
          onClick={() => closeAllDrawers()}
          style={{
            position: "absolute",
            cursor: "pointer",
            right: "24px",
            top: "50%",
            transform: "translate(0,-50%)",
            fontSize: "22px",
            color: "#fff",
          }}
        ></i>
        <Box
          sx={{
            display: "flex",
            px: 3,
            justifyContent: "center",
            height: {
              xs: theme.appbar_height.mobile,
              md: theme.appbar_height.desktop,
            },
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
            }}
          >
            {menu === "womens" && <MainTitle>{kwomens.name}</MainTitle>}
            {menu === "mens" && <MainTitle>{kmens.name}</MainTitle>}
            {menu === "gifts" && <MainTitle>{kgifts.name}</MainTitle>}
            {menu === "locations" && <MainTitle>{klocations.name}</MainTitle>}
            {menu === "world" && <MainTitle>{kworld.name}</MainTitle>}
          </Box>
        </Box>
      </Box>
      <Box sx={{ p: 3 }}>
        {menu === "womens" && (
          <>
            <Title sx={{ mt: 0 }}>Shop Women's</Title>
            <ul
              style={{
                margin: "0px",
                padding: "0px",
                listStyleType: "none",
              }}
            >
              {kwomens &&
                kwomens.subMenu.women &&
                kwomens.subMenu.women.map((data, index) => {
                  const { slug, title, url } = data;

                  return (
                    <MyLi key={`kwomens-women-${slug}`}>
                      <MyLink
                        component={RouterLink}
                        to={url}
                        sx={{ mt: index === 0 ? "4px" : 1 }}
                        onClick={() => closeAllDrawers()}
                      >
                        {title}
                      </MyLink>
                    </MyLi>
                  );
                })}
            </ul>

            <ul
              style={{
                margin: "0px",
                padding: "0px",
                listStyleType: "none",
                marginTop: "18px",
              }}
            >
              {kwomens &&
                kwomens.subMenu.kwomen &&
                kwomens.subMenu.kwomen.map((data, index) => {
                  const { slug, title, url } = data;

                  return (
                    <MyLi key={`kwomens-curation-${slug}`}>
                      <MyLink
                        component={RouterLink}
                        to={url}
                        sx={{ mt: index === 0 ? "4px" : 1 }}
                        onClick={() => closeAllDrawers()}
                      >
                        {title}
                      </MyLink>
                    </MyLi>
                  );
                })}
            </ul>
            {/* <Grid
              container
              justifycontent="center"
              direction="row"
              justify="center"
              columnSpacing={3}
              rowSpacing={3}
              sx={{ mt: "1px" }}
            >
              {kwomens &&
                kwomens.subMenu.kwomen &&
                kwomens.subMenu.kwomen.map((data) => {
                  const { slug, title, url } = data;

                  return (
                    <Grid
                      key={`kwomens-kwomen-${slug}`}
                      item
                      sm={4}
                      xs={6}
                      justifycontent="center"
                      sx={{ textAlign: "center" }}
                    >
                      <Box
                        key={slug}
                        sx={{
                          textAlign: "center",
                          "&:hover": {
                            "& > div > a > img": {
                              transform: "scale(1.3)",
                              webkitTransform: "scale(1.3)",
                            },
                          },
                        }}
                      >
                        <Box
                          className="box-img-zoomed"
                          sx={{
                            overflow: "hidden",
                            borderRadius: "50%",
                          }}
                        >
                          <Link
                            component={RouterLink}
                            to={url}
                            onClick={() => closeAllDrawers()}
                          >
                            <CardMedia
                              sx={{
                                maxWidth: "100%",
                                transition: "transform .5s ease",
                                webkitTransition: "transform .5s ease",
                              }}
                              component="img"
                              image={component
                                .getHelpers("url")
                                .createAccountImageUrl(
                                  account,
                                  repository,
                                  "item",
                                  "k-womens",
                                  "default",
                                  slug
                                )}
                              alt={slug + " image"}
                            />
                          </Link>
                        </Box>
                        <Button
                          variant="text"
                          sx={{
                            fontFamily: "Spartan-Bold",
                            color: "#000",
                            fontSize: "13px",
                            textTransform: "none",
                            p: 0,
                            minWidth: 0,
                            mt: 2,
                            "&:hover": {
                              backgroundColor: "transparent",
                            },
                          }}
                          component={RouterLink}
                          to={url}
                          disableRipple
                          onClick={() => closeAllDrawers()}
                        >
                          {title}
                        </Button>
                      </Box>
                    </Grid>
                  );
                })}
            </Grid> */}
            <Title>Shop By Collection</Title>
            <ul
              style={{
                margin: "0px",
                padding: "0px",
                listStyleType: "none",
              }}
            >
              {kwomens &&
                kwomens.subMenu.collections &&
                kwomens.subMenu.collections.map((data, index) => {
                  const { slug, title, url } = data;

                  return (
                    <MyLi
                      key={`kwomens-collections-${slug}`}
                      sx={{ mt: index === 0 ? "4px" : 1 }}
                    >
                      <MyLink
                        component={RouterLink}
                        to={url}
                        onClick={() => closeAllDrawers()}
                      >
                        {title}
                      </MyLink>
                    </MyLi>
                  );
                })}
            </ul>

            <Title>Shop By Curation</Title>
            <ul
              style={{
                margin: "0px",
                padding: "0px",
                listStyleType: "none",
              }}
            >
              {kwomens &&
                kwomens.subMenu.curation &&
                kwomens.subMenu.curation.map((data, index) => {
                  const { slug, title, url } = data;

                  return (
                    <MyLi key={`kwomens-curation-${slug}`}>
                      <MyLink
                        component={RouterLink}
                        to={url}
                        sx={{ mt: index === 0 ? "4px" : 1 }}
                        onClick={() => closeAllDrawers()}
                      >
                        {title}
                      </MyLink>
                    </MyLi>
                  );
                })}
            </ul>

            <Title>Shop By Metal</Title>
            <ul
              style={{
                margin: "0px",
                padding: "0px",
                listStyleType: "none",
              }}
            >
              {kwomens &&
                kwomens.subMenu.metals &&
                kwomens.subMenu.metals.map((data, index) => {
                  const { slug, title, url } = data;

                  return (
                    <MyLi key={`kwomens-metals-${slug}`}>
                      <MyLink
                        component={RouterLink}
                        to={url}
                        sx={{ mt: index === 0 ? "4px" : 1 }}
                        onClick={() => closeAllDrawers()}
                      >
                        {title}
                      </MyLink>
                    </MyLi>
                  );
                })}
            </ul>

            <Title sx={{ mb: 1 }}>Shop By Stone Color</Title>
            <Grid
              container
              justifycontent="center"
              direction="row"
              justify="center"
              columnSpacing={2}
              rowSpacing={1}
            >
              {kwomens &&
                kwomens.subMenu.colors &&
                kwomens.subMenu.colors.map((data, index) => {
                  const { slug, title, url } = data;

                  return (
                    <Grid
                      key={`kwomens-colors-${slug}`}
                      item
                      sm={4}
                      xs={6}
                      justifycontent="center"
                    >
                      <Link
                        underline="none"
                        sx={{
                          fontFamily: "Spartan-Medium",
                          color: "#000",
                          fontSize: "13px",
                          p: 0,
                          display: "flex",
                          alignItems: "center",
                          // justifyContent: "space-between",
                        }}
                        component={RouterLink}
                        to={url}
                        onClick={() => closeAllDrawers()}
                      >
                        <CardMedia
                          component="img"
                          image={`${filesUrl}/konstantino/menu/${slug}.png?v=${img_version}`}
                          alt={slug + " image"}
                          sx={{
                            width: "20px",
                            height: "20px",
                            mt: "-4px",
                            mr: 1,
                          }}
                        ></CardMedia>
                        {title}
                      </Link>
                    </Grid>
                  );
                })}
            </Grid>
          </>
        )}
        {menu === "mens" && (
          <>
            <Title sx={{ mt: 0 }}>Shop Men's</Title>
            <ul
              style={{
                margin: "0px",
                padding: "0px",
                listStyleType: "none",
              }}
            >
              {kmens &&
                kmens.subMenu.men &&
                kmens.subMenu.men.map((data, index) => {
                  const { slug, title, url } = data;

                  return (
                    <MyLi key={`kmens-men-${slug}`}>
                      <MyLink
                        component={RouterLink}
                        to={url}
                        sx={{ mt: index === 0 ? "4px" : 1 }}
                        onClick={() => closeAllDrawers()}
                      >
                        {title}
                      </MyLink>
                    </MyLi>
                  );
                })}
            </ul>

            <ul
              style={{
                margin: "0px",
                padding: "0px",
                listStyleType: "none",
                marginTop: "18px",
              }}
            >
              {kwomens &&
                kmens.subMenu.kmen &&
                kmens.subMenu.kmen.map((data, index) => {
                  const { slug, title, url } = data;

                  return (
                    <MyLi key={`kwomens-curation-${slug}`}>
                      <MyLink
                        component={RouterLink}
                        to={url}
                        sx={{ mt: index === 0 ? "4px" : 1 }}
                        onClick={() => closeAllDrawers()}
                      >
                        {title}
                      </MyLink>
                    </MyLi>
                  );
                })}
            </ul>

            {/* <Grid
              container
              justifycontent="center"
              direction="row"
              justify="center"
              columnSpacing={3}
              rowSpacing={3}
              sx={{ mt: "1px" }}
            >
              {kmens &&
                kmens.subMenu.kmen &&
                kmens.subMenu.kmen.map((data) => {
                  const { slug, title, url } = data;

                  return (
                    <Grid
                      key={`kmens-kmen-${slug}`}
                      item
                      sm={4}
                      xs={6}
                      justifycontent="center"
                      sx={{ textAlign: "center" }}
                    >
                      <Box
                        key={slug}
                        sx={{
                          textAlign: "center",
                          "&:hover": {
                            "& > div > a > img": {
                              transform: "scale(1.3)",
                              webkitTransform: "scale(1.3)",
                            },
                          },
                        }}
                      >
                        <Box
                          className="box-img-zoomed"
                          sx={{
                            overflow: "hidden",
                            borderRadius: "50%",
                          }}
                        >
                          <Link
                            component={RouterLink}
                            to={url}
                            onClick={() => closeAllDrawers()}
                          >
                            <CardMedia
                              sx={{
                                maxWidth: "100%",
                                transition: "transform .5s ease",
                                webkitTransition: "transform .5s ease",
                              }}
                              component="img"
                              image={component
                                .getHelpers("url")
                                .createAccountImageUrl(
                                  account,
                                  repository,
                                  "item",
                                  "k-mens",
                                  "default",
                                  slug
                                )}
                              alt={slug + " image"}
                            />
                          </Link>
                        </Box>
                        <Button
                          variant="text"
                          sx={{
                            fontFamily: "Spartan-Bold",
                            color: "#000",
                            fontSize: "13px",
                            textTransform: "none",
                            p: 0,
                            minWidth: 0,
                            mt: 2,
                            "&:hover": {
                              backgroundColor: "transparent",
                            },
                          }}
                          component={RouterLink}
                          to={url}
                          disableRipple
                          onClick={() => closeAllDrawers()}
                        >
                          {title}
                        </Button>
                      </Box>
                    </Grid>
                  );
                })}
            </Grid> */}

            <Title>Shop By Collection</Title>
            <ul
              style={{
                margin: "0px",
                padding: "0px",
                listStyleType: "none",
              }}
            >
              {kmens &&
                kmens.subMenu.collections &&
                kmens.subMenu.collections.map((data, index) => {
                  const { slug, title, url } = data;

                  return (
                    <MyLi
                      key={`kmens-collections-${slug}`}
                      sx={{ mt: index === 0 ? "4px" : 1 }}
                    >
                      <MyLink
                        component={RouterLink}
                        to={url}
                        onClick={() => closeAllDrawers()}
                      >
                        {title}
                      </MyLink>
                    </MyLi>
                  );
                })}
            </ul>

            <Title>Shop By Curation</Title>
            <ul
              style={{
                margin: "0px",
                padding: "0px",
                listStyleType: "none",
              }}
            >
              {kmens &&
                kmens.subMenu.curation &&
                kmens.subMenu.curation.map((data, index) => {
                  const { slug, title, url } = data;

                  return (
                    <MyLi key={`kmens-curation-${slug}`}>
                      <MyLink
                        component={RouterLink}
                        to={url}
                        sx={{ mt: index === 0 ? "4px" : 1 }}
                        onClick={() => closeAllDrawers()}
                      >
                        {title}
                      </MyLink>
                    </MyLi>
                  );
                })}
            </ul>

            <Title>Shop By Metal</Title>
            <ul
              style={{
                margin: "0px",
                padding: "0px",
                listStyleType: "none",
              }}
            >
              {kmens &&
                kmens.subMenu.metals &&
                kmens.subMenu.metals.map((data, index) => {
                  const { slug, title, url } = data;

                  return (
                    <MyLi key={`kmens-metals-${slug}`}>
                      <MyLink
                        component={RouterLink}
                        to={url}
                        sx={{ mt: index === 0 ? "4px" : 1 }}
                        onClick={() => closeAllDrawers()}
                      >
                        {title}
                      </MyLink>
                    </MyLi>
                  );
                })}
            </ul>

            <Title sx={{ mb: 1 }}>Shop By Color</Title>
            <Grid
              container
              justifycontent="center"
              direction="row"
              justify="center"
              columnSpacing={2}
              rowSpacing={1}
            >
              {kmens &&
                kmens.subMenu.colors &&
                kmens.subMenu.colors.map((data, index) => {
                  const { slug, title, url } = data;

                  return (
                    <Grid
                      key={`kmens-colors-${slug}`}
                      item
                      sm={4}
                      xs={6}
                      justifycontent="center"
                    >
                      <Link
                        underline="none"
                        sx={{
                          fontFamily: "Spartan-Medium",
                          color: "#000",
                          fontSize: "13px",
                          p: 0,
                          display: "flex",
                          alignItems: "center",
                          // justifyContent: "space-between",
                        }}
                        component={RouterLink}
                        to={url}
                        onClick={() => closeAllDrawers()}
                      >
                        <CardMedia
                          component="img"
                          image={`${filesUrl}/konstantino/menu/${slug}.png?v=${img_version}`}
                          alt={slug + " image"}
                          sx={{
                            width: "20px",
                            height: "20px",
                            mt: "-4px",
                            mr: 1,
                          }}
                        ></CardMedia>
                        {title}
                      </Link>
                    </Grid>
                  );
                })}
            </Grid>
          </>
        )}
        {menu === "gifts" && (
          <>
            <Typography
              sx={{
                fontFamily: "Spartan-Medium",
                color: "#000",
                fontSize: "13px",
                mb: 2,
              }}
            >
              Take advatage of free two day ground shipping with in the US.
            </Typography>
            <Link
              sx={{
                fontFamily: "Spartan-Bold",
                color: "#000",
                fontSize: "13px",
                textDecoration: "none",
              }}
            >
              View Details
            </Link>

            <Box sx={{ mb: 2 }}>
              <Title>Shop Women's Gifts</Title>
              <ul
                style={{
                  margin: "0px",
                  padding: "0px",
                  listStyleType: "none",
                }}
              >
                {kgifts &&
                  kgifts.subMenu.womensgifts &&
                  kgifts.subMenu.womensgifts.map((data, index) => {
                    const { slug, title, url } = data;

                    return (
                      <MyLi key={`kgifts-giftsforher-${slug}`}>
                        <MyLink
                          component={RouterLink}
                          to={url}
                          sx={{ mt: index === 0 ? "4px" : 1 }}
                          onClick={() => closeAllDrawers()}
                        >
                          {title}
                        </MyLink>
                      </MyLi>
                    );
                  })}
              </ul>
            </Box>
            {/* <Link
              component={RouterLink}
              to={giftsOfHerLink}
              onClick={() => closeAllDrawers()}
            ></Link> */}
            <CardMedia
              component="img"
              image={`${filesUrl}/konstantino/menu/womens-gifts.jpg?v=${img_version}`}
              alt={"womens gifts image"}
            />
            {/* </Link> */}
            <Box sx={{ mb: 2 }}>
              <Title>Shop Men's Gifts</Title>
              <ul
                style={{
                  margin: "0px",
                  padding: "0px",
                  listStyleType: "none",
                }}
              >
                {kgifts &&
                  kgifts.subMenu.mensgifts &&
                  kgifts.subMenu.mensgifts.map((data, index) => {
                    const { slug, title, url } = data;

                    return (
                      <MyLi key={`kgifts-giftsforhim-${slug}`}>
                        <MyLink
                          component={RouterLink}
                          to={url}
                          sx={{ mt: index === 0 ? "4px" : 1 }}
                          onClick={() => closeAllDrawers()}
                        >
                          {title}
                        </MyLink>
                      </MyLi>
                    );
                  })}
              </ul>
            </Box>

            {/* <Link component={RouterLink} to={giftsOfHimLink} onClick={() => closeAllDrawers()}> */}
            <CardMedia
              component="img"
              image={`${filesUrl}/konstantino/menu/mens-gifts.jpg?v=${img_version}`}
              alt={"mens gifts image"}
            />
            {/* </Link> */}
          </>
        )}
        {menu === "locations" && (
          <>
            <Title sx={{ fontSize: "26px", mt: 0 }}>OUR LOCATIONS</Title>
            <Title sx={{ color: "#000", lineHeight: "18px", mt: 4 }}>
              KONSTANTINO US Contact Direct
            </Title>
            {/* <span
              style={{
                color: "#000",
                fontSize: "12px",
                fontFamily: "Spartan-SemiBold",
                marginRight: "4px",
              }}
            >
              Tel:
              <Link
                href="tel:+14144532857"
                underline="none"
                sx={{
                  fontFamily: "Spartan-SemiBold",
                  color: "#000",
                  fontSize: "12px",
                  lineHeight: "14px",
                  pl: "4px",
                }}
              >
                +1 (414) 453-2857
              </Link>
            </span> */}
            <span
              style={{
                color: "#000",
                fontSize: "12px",
                fontFamily: "Spartan-SemiBold",
              }}
            >
              Email:
              <Link
                href="mailto:info@konstantino.com"
                underline="none"
                target="_blank"
                sx={{
                  fontFamily: "Spartan-SemiBold",
                  color: "#000",
                  fontSize: "12px",
                  pl: "4px",
                }}
              >
                K-U.S.A
              </Link>
            </span>
            <Title sx={{ color: "#000", mt: 4, lineHeight: "18px" }}>
              KONSTANTINO ATHENS (Flagship Store)
            </Title>
            <Link
              href="https://www.google.com/maps/place/KONSTANTINO+JEWELRY+-+ATHENS/@37.9756587,23.7276433,19.63z/data=!4m5!3m4!1s0x14a1bd3d320b57ad:0x62993108934b74eb!8m2!3d37.9756326!4d23.7278967"
              rel="noreferrer"
              target="_blank"
              underline="none"
              sx={{
                fontFamily: "Spartan-Medium",
                color: "#000",
                fontSize: "12px",
                lineHeight: "14px",
                display: "inline-block",
                mt: "6px",
              }}
            >
              20, Pandrossou Str. Plaka
              <br />
              ATHENS, GREECE
            </Link>
            <Box sx={{ mt: -1 }}>
              <span
                style={{
                  color: "#000",
                  fontSize: "12px",
                  fontFamily: "Spartan-SemiBold",
                  marginRight: "4px",
                }}
              >
                Tel:
                <Link
                  href="tel:+302103222701"
                  underline="none"
                  sx={{
                    fontFamily: "Spartan-SemiBold",
                    color: "#000",
                    fontSize: "12px",
                    pl: "4px",
                  }}
                >
                  +30 (210)322-2701
                </Link>
              </span>
              <span
                style={{
                  color: "#000",
                  fontSize: "12px",
                  fontFamily: "Spartan-SemiBold",
                }}
              >
                Email:
                <Link
                  href="mailto:konstantino.gr@gmail.com"
                  target="_blank"
                  underline="none"
                  sx={{
                    fontFamily: "Spartan-SemiBold",
                    color: "#000",
                    fontSize: "12px",
                    pl: "4px",
                  }}
                >
                  K-Athens
                </Link>
              </span>
            </Box>
            <Title sx={{ color: "#000", mt: 4, lineHeight: "18px" }}>
              KONSTANTINO MYKONOS
            </Title>
            <Link
              href="https://www.google.com/maps/place/KONSTANTINO+JEWELRY+-+MYKONOS/@37.444966,25.3255058,17z/data=!3m1!4b1!4m5!3m4!1s0x14a2bf0c301477df:0x7070d7c505b2b4a!8m2!3d37.4449618!4d25.3276945"
              rel="noreferrer"
              target="_blank"
              underline="none"
              sx={{
                fontFamily: "Spartan-Medium",
                color: "#000",
                fontSize: "12px",
                lineHeight: "14px",
                display: "inline-block",
                mt: "6px",
              }}
            >
              Enoplon Dynameon Str.
              <br />
              MYKONOS, GREECE
            </Link>
            <Box sx={{ mt: -1 }}>
              <span
                style={{
                  color: "#000",
                  fontSize: "12px",
                  fontFamily: "Spartan-SemiBold",
                  marginRight: "4px",
                }}
              >
                Tel:
                <Link
                  href="tel:+302289023762"
                  underline="none"
                  sx={{
                    fontFamily: "Spartan-SemiBold",
                    color: "#000",
                    fontSize: "12px",
                    pl: "4px",
                  }}
                >
                  +30 (228)902-3762
                </Link>
              </span>
              <span
                style={{
                  color: "#000",
                  fontSize: "12px",
                  fontFamily: "Spartan-SemiBold",
                }}
              >
                Email:
                <Link
                  href="mailto:mykonos@konstantino-jewelry.com"
                  target="_blank"
                  underline="none"
                  sx={{
                    fontFamily: "Spartan-SemiBold",
                    color: "#000",
                    fontSize: "12px",
                    pl: "4px",
                  }}
                >
                  K-Mykonos
                </Link>
              </span>
            </Box>

            <Title sx={{ fontSize: "26px", mb: 4 }}>
              WHERE YOU CAN FIND US
            </Title>
            <Grid
              container
              justifycontent="center"
              direction="row"
              justify="center"
              sx={{ px: { xs: 1, md: 2, lg: 10 } }}
              spacing={{ xs: 1, lg: 2 }}
            >
              {klocations &&
                klocations.subMenu.location &&
                klocations.subMenu.location.map((data) => {
                  const { slug, title, url } = data;

                  return (
                    <Grid
                      key={"klocations-" + slug}
                      item
                      sm={4}
                      xs={6}
                      justifycontent="center"
                    >
                      <div
                        style={{
                          position: "relative",
                          width: "100%",
                          height: 0,
                          paddingBottom: "100%",
                        }}
                      >
                        <div
                          style={{
                            position: "absolute",
                            left: 0,
                            right: 0,
                            top: 0,
                            bottom: 0,
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <Button
                            component={RouterLink}
                            to={url}
                            sx={{
                              width: "100%",
                              height: "100%",
                              color: "#000",
                              backgroundColor: "#ffffff",
                              textAlign: "center",
                              border: "2px solid #000",
                              borderRadius: "0px",
                              fontSize: "15px",
                              fontWeight: "600",
                              "&:hover,&:focus": {
                                color: "#ffffff",
                                backgroundColor: "#000",
                              },
                            }}
                            onClick={() => closeAllDrawers()}
                          >
                            {title}
                          </Button>
                        </div>
                      </div>
                    </Grid>
                  );
                })}
            </Grid>
          </>
        )}
        {menu === "world" && (
          <Grid
            container
            justifycontent="center"
            direction="row"
            justify="center"
            columnSpacing={2}
            rowSpacing={1}
          >
            {kworld &&
              kworld.subMenu.world &&
              kworld.subMenu.world.map((data) => {
                const { slug, title, url } = data;
                return (
                  <Grid
                    key={`kworld-kworld-${slug}`}
                    item
                    xs={12}
                    justifycontent="center"
                    sx={{ textAlign: "center", mx: { xs: 1, sm: 4 } }}
                  >
                    <Link
                      component={RouterLink}
                      to={url}
                      onClick={() => closeAllDrawers()}
                    >
                      <Box
                        key={slug}
                        className="image_shadow"
                        sx={{
                          position: "relative",
                          width: "100%",
                        }}
                      >
                        <Box
                          sx={{
                            position: "absolute",
                            top: "50%",
                            width: "100%",
                            textAlign: "center",
                            transform: "translate(0,-50%)",
                            zIndex: 2,
                            fontSize: "20px",
                            color: "#fff",
                            fontFamily: "Spartan-SemiBold",
                          }}
                        >
                          {title}
                        </Box>
                        <CardMedia
                          sx={{
                            maxWidth: "100%",
                          }}
                          component="img"
                          image={`${filesUrl}/konstantino/menu/${slug}.jpg?v=${img_version}`}
                          alt={slug + " image"}
                        />
                      </Box>
                    </Link>
                  </Grid>
                );
              })}
          </Grid>
        )}
      </Box>
    </SwipeableDrawer>
  );
}
