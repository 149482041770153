import { Component } from "../../../../modules/Core/Component";
import templateDefault from "../../../templates/default/widgets/user/orders";
import Services from "../../../Services/Services";

export default class Orders extends Component {
  template = templateDefault;

  onLoad(data) {
    super.onLoad(data);
    this.retrieveOrders();
  }

  retrieveOrders() {
    Services.get("order").then(([orderService]) => {
      orderService
        .getOrders()
        .then((orderService) => {
          this.setData({
            "default.orders": orderService.getData("user-orders", null),
          });
        })
        .catch((err) => {
          console.log("error", err);
        });
    });
  }
}
