import { Component } from "../../../../modules/Core/Component";
import templateDefault from "../../../templates/default/widgets/dialogs/notify_me";
import Services from "../../../Services/Services";

export default class NotifyMe extends Component {
  id = "notify-me";

  template = templateDefault;

  onAuth() {
    this.setAuthFields();
  }

  onLoad(data) {
    super.onLoad(data);
    var open = this.props.open;

    this.setData({
      "default.open": open,
      "default.fields": {
        name: "",
        email: "",
      },
    });

    this.setAuthFields();
  }

  setAuthFields() {
    const auth = this.getAuth();

    if (auth.isAuthenticated()) {
      const { name, username } = auth.getUser();

      this.setData({
        "default.fields.name": name,
        "default.fields.email": username,
      });
    } else {
      this.setData({
        "default.fields.name": "",
        "default.fields.email": "",
      });
    }
  }

  sendRequest(e) {
    e.preventDefault();

    const product = this.getPage().getData("default.notify");

    const { fields } = this.getData("default", {
      name: "",
      email: "",
    });

    const error = {
      fields: { message: "", error: false },
    };

    const { name, email } = this.getData("default.fields", {});

    if (!name) {
      error.fields["name"] = "Please enter your Name";
      error.fields.error = true;
    }

    if (!email) {
      error.fields["email"] = "Please enter your Email";
      error.fields.error = true;
    } else {
      if (!this.getHelpers("validate").email(email)) {
        error.fields["email"] = this.ucfirst("Invalid Email");
        error.fields.error = true;
      }
    }

    // if (phone && phone.length !== 10) {
    //   error.fields["phone"] = "The total numbers allowed is 10";
    //   error.fields.error = true;
    // }

    this.setData({
      error,
    });

    if (!error.fields.error) {
      Services.get("hook").then(async ([hookService]) => {
        await hookService.post("notification", { ...fields, ...product });

        this.setData({
          "default.fields.size": "",
        });

        this.props.setOpen(false);

        this.getComponents()
          .findById("main-message")
          .first()
          .setData({
            "message-duration": 2000,
            "success-message": this.trans("notification-saved"),
          });
      });

      // Services.get("product").then(([productService]) => {
      //   productService
      //     .inquiry(fields)
      //     .then((productService) => {
      //       this.props.setOpen(false);
      //       this.setData({
      //         "default.fields": {
      //           first_name: "",
      //           last_name: "",
      //           email: "",
      //           phone: "",
      //         },
      //       });
      //     })
      //     .catch((orderService) => {
      //       //
      //     });
      // });
    }
  }
}
