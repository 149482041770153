import React from "react";
import { Box } from "@mui/material";
import NavBar from "../../../../Components/Widgets/Navbar";
import MenuDrawer from "../../../../Components/Widgets/MenuDrawer";
import SubMenuDrawer from "../../../../Components/Widgets/SubMenuDrawer";
import SingleBanner from "../../widgets/sliders/single_banner";
import Footer from "../../widgets/footer";
import About from "../../widgets/about/about";

const ExportDefault = (props) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        minHeight: "100vh",
      }}
    >
      <NavBar
        {...{
          ...props,
          ...{
            bg_color: "transparent",
          },
        }}
      />
      <MenuDrawer {...props} />
      <SubMenuDrawer {...props} />
      <SingleBanner
        {...{
          ...props,
          ...{
            fscope: "about-banner",
            texts: {
              desktop: {
                url: "/about",
              },
              mobile: {
                url: "/about",
              },
            },
          },
        }}
      />
      <About {...props} />
      <Footer {...props} />
    </Box>
  );
};

export default ExportDefault;
