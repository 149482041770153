import { Component } from "../../../modules/Core/Component";
import templateDefault from "../../templates/default/widgets/product/zoom_drawer";

export default class Zoom extends Component {
  id = "zoom";

  template = templateDefault;

  toggle() {
    this.toggleData("default.opened", {
      defaultValue: true,
    });
  }
}
