import Page from "../../../modules/Core/Components/Page";
import templateDefault from "../../templates/default/pages/product";
import Services from "../../Services/Services";

export default class Product extends Page {
  title = "product";

  template = templateDefault;

  onAuth() {
    this.refresh();
  }

  onLoad(data) {
    super.onLoad(data);
    this.scrollToTop();

    Services.get("recently-viewed").then(([recentlyviewedService]) => {
      recentlyviewedService.addToRecentlyViewed(
        this.getPage().getParam("slug")
      );
    });
  }

  onUpdate(prevProps, prevState, snapshot) {
    super.onUpdate(prevProps, prevState, snapshot);

    if (prevProps.location.pathname !== this.props.location.pathname) {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
      this.setData({
        "default.fields.size": "",
      });
      this.getComponents().findById("product").first().updateProduct();
    }
  }
}
