import React from "react";
import { Snackbar, Fade, Alert as MuiAlert } from "@mui/material";

const Alert = React.forwardRef(function Alert(props, ref) {
  return (
    <MuiAlert
      elevation={1}
      ref={ref}
      variant="filled"
      {...props}
      // sx={{ borderRadius: 0 }}
    />
  );
});

export default function MainMessage(props) {
  const { component } = props;

  const successMessage = component.getData("success-message", "");
  const errorMessage = component.getData("error-message", "");
  const messageDuration = component.getData("message-duration", 3000);

  const handleClose = () => {
    component.setData({ "success-message": "" });
    component.setData({ "error-message": "" });
    component.setData({ "message-duration": 3000 });
  };

  return (
    <>
      <Snackbar
        open={successMessage !== ""}
        autoHideDuration={messageDuration}
        onClose={handleClose}
        TransitionComponent={Fade}
        anchorOrigin={{ horizontal: "center", vertical: "top" }}
        sx={{
          maxWidth: "unset",
          margin: "40px auto 0",
          // "& .MuiAlert-root": { backgroundColor: "#88c300" },
        }}
      >
        <Alert severity="success">{component.ucfirst(successMessage)}</Alert>
      </Snackbar>
      <Snackbar
        open={errorMessage !== ""}
        autoHideDuration={messageDuration}
        onClose={handleClose}
        TransitionComponent={Fade}
        anchorOrigin={{ horizontal: "center", vertical: "top" }}
        sx={{
          maxWidth: "unset",
          margin: "40px auto 0",
        }}
      >
        <Alert severity="error">{component.ucfirst(errorMessage)}</Alert>
      </Snackbar>
    </>
  );
}
