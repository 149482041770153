import { Component } from "../../../modules/Core/Component";
import templateDefault from "../../templates/default/widgets/cart/product_cart_view";
import Services from "../../Services/Services";

export default class ProductCartView extends Component {
  id = "procut-cart-view";
  group = "wishlist";

  template = templateDefault;

  onLoad(data) {
    super.onLoad(data);

    this.setProducts();
    this.checkWishlist();
  }

  onUpdate(prevProps, prevState, snapshot) {
    super.onUpdate(prevProps, prevState, snapshot);
    if (prevProps.product.amount !== this.props.product.amount) {
      this.setProducts();
      this.checkWishlist();
    }
  }

  setProducts() {
    var product = { ...this.props.product };

    product.simple = product.sku === "combinations.options:g-default,s-default";

    var combination = product.sku.split(":")[1];
    var options = combination.split(",");

    var comb = product.item.combinations.find((c) => c.options === combination);

    product.p_sku = comb.sku;

    if (!product.simple) {
      product.gem = product.item.gems.find(
        (gem) => gem.item._values.slug === options[0]
      );

      product.size = product.item.sizes.find((size) => {
        var opt = options[1] !== undefined ? options[1] : options[0];
        return size.item._values.slug === opt;
      });
    }

    this.filterImages(product.item.images, product.p_sku);

    product.item.price = product.price;
    product.item.upc = product.skuStock;

    this.setData({ "default.product": product });
  }

  filterImages = (images, sku) => {
    var filtered_images = images.filter((img) =>
      img.name.includes(sku.toLowerCase())
    );
    this.setData({
      "default.image":
        filtered_images.length !== 0 ? filtered_images[0].name : null,
    });

    return this;
  };

  checkWishlist() {
    var product = { ...this.props.product };

    Services.get("wishlist").then(([wishlistService]) => {
      var wishlist = wishlistService.getWishlist();
      this.setData({
        "default.wishlist": wishlist.some(
          (wishlist_product) => wishlist_product === product.item.slug
        ),
      });
    });
  }

  toggleQuickView(product) {
    this.getComponents()
      .findById("quick-view")
      .forEach((quick_view) => {
        quick_view.setData({ product });
        quick_view.toggle();
      });
  }

  addToWishList(slug) {
    Services.get("wishlist").then(([wishlistService]) => {
      wishlistService.addToWishList(slug, this);
    });
  }

  removeFromWishList(slug) {
    Services.get("wishlist").then(([wishlistService]) => {
      wishlistService.removeFromWishList(slug, this);
    });
  }

  updateCart(product, skuPath, sku, amount) {
    var firstAmount = amount;

    Services.get("order").then(async ([orderService]) => {
      const { id, combinations } = product;
      const { upc } = combinations.find(({ options }) => options === sku) || {};

      sku = typeof sku === "string" ? sku : sku.join(",");
      if (amount !== 0) {
        amount = this.getProductAmount(product, skuPath, sku) + amount;
      }

      await orderService.updateItem({
        itemId: id,
        itemType: "product",
        skuStock: upc,
        sku: skuPath ? `${skuPath}:${sku}` : sku,
        amount,
      });

      const order = orderService.getOrder();

      this.getComponents()
        .findByGroup("order")
        .forEach((c) => {
          c.setData({ "default.order": order });
        });

      if (firstAmount > 0) {
        if (this.getProductAmount(product, skuPath, sku) !== amount) {
          this.setData({ "default.in_stock": false });
        } else {
          this.getApp().sendToGA4(product, "add_to_cart", amount);
        }
      } else {
        this.setData({ "default.in_stock": true });
      }
    });
  }

  getProductAmount(product, skuPath, sku) {
    const { items = [] } =
      this.getComponents().findById("cart").first().getOrder() || {};

    const { amount = 0 } =
      items.find((orderItem) => {
        return (
          product.id === orderItem.item.id &&
          (!sku || orderItem.sku === `${skuPath}:${sku}`)
        );
      }) || {};

    return amount;
  }
}
