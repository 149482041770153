import React from "react";
import { styled } from "@mui/material/styles";
import PropTypes from "prop-types";
import {
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  Button,
  TextField,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

const SubmitBtn = styled(Button)(({ theme }) => ({
  margin: theme.spacing(2, 0),
  padding: "12px 14px",
  background: "#000",
  borderRadius: 0,
  border: "1px solid #000",
  color: "#ffffff",
  "&:hover": {
    background: "#000",
  },
}));

const MyTextField = styled(TextField)(({ theme }) => ({
  "& label.Mui-focused": {
    color: "#7d7d7d",
    borderRadius: 0,
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "#7d7d7d",
    borderRadius: 0,
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "#7d7d7d",
      borderRadius: 0,
    },
    "&:hover fieldset": {
      borderColor: "#7d7d7d",
      borderRadius: 0,
    },
    "&.Mui-focused fieldset": {
      borderColor: "#7d7d7d",
      borderRadius: 0,
    },
  },
}));

const MyDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": { padding: theme.spacing(2) },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
  "& .MuiPaper-rounded": {
    borderRadius: 0,
  },
}));

const MyDialogTitle = (props) => {
  const { children, onClose, ...other } = props;
  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

MyDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

export default function Address(props) {
  const { component } = props;
  const { open = false } = component.props;
  return (
    <MyDialog
      onClose={() => {
        component.props.setOpen(false);
        component.resetCoupon();
      }}
      open={open}
    >
      <MyDialogTitle
        onClose={() => {
          component.props.setOpen(false);
          component.resetCoupon();
        }}
      >
        {component.ucfirst("coupon-dialog-title")}
      </MyDialogTitle>
      <DialogContent dividers>
        <Typography variant="body2">
          {component.trans("coupon-dialog-subtitle")}
        </Typography>
        <form onSubmit={(e) => component.addCoupon(e)}>
          <MyTextField
            variant="outlined"
            margin="normal"
            fullWidth
            name="couponCode"
            label={component.trans("promo-code")}
            type="text"
            id="couponCode"
            placeholder={component.trans("promo-holder")}
            helperText={component.getData("error.couponCode")}
            value={component.getData("default.couponCode", "")}
            onChange={(e) => {
              component.setData({
                "default.couponCode": e.target.value,
              });
            }}
            error={component.dataExists("error.couponCode")}
          />
          <SubmitBtn type="submit" fullWidth>
            {component.trans("submit-btn")}
          </SubmitBtn>
        </form>
      </DialogContent>
    </MyDialog>
  );
}
