import React from "react";
import { CardMedia, Grid } from "@mui/material";
import ProductListView from "../../../Components/Widgets/ProductListView";

export default function LayoutSideBanner(props) {
  const { products = [], from = 0, to = 0, image = "" } = props;
  const { component } = props;
  const devHelper = component.getHelpers("dev");
  const pageGender = component.getGender();
  const pageType = component.getData("default.pageType", "");
  const pageValue = component.getData("default.pageValue", "");
  const page = `${pageGender}-${pageType}-${pageValue}`;

  return (
    <>
      <Grid
        item
        md={6}
        sm={12}
        xs={12}
        sx={{
          "@media (max-width: 520px )": {
            padding: "8px 12px !important",
          },
        }}
      >
        <CardMedia
          style={{ height: "100%" }}
          component="img"
          alt={"banner"}
          image={image}
        />
      </Grid>
      <Grid
        item
        md={6}
        sm={12}
        xs={12}
        container
        sx={{ flexGrow: 1, p: 0 }}
        justifycontent="center"
        direction="row"
      >
        {products &&
          products.rows.slice(from, to).map((product) => {
            return (
              <ProductListView
                key={`${page}-${devHelper.getObjectValue(product, "_id")}`}
                {...{
                  ...props,
                  ...{
                    product,
                    grid: 6,
                  },
                }}
              />
            );
          })}
      </Grid>
    </>
  );
}
