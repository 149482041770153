import { Component } from "../../../../modules/Core/Component";
import templateDefault from "../../../templates/default/widgets/user/addresses";
import Services from "../../../Services/Services";

export default class Addresses extends Component {
  template = templateDefault;

  onLoad(data) {
    super.onLoad(data);
    this.retrieveAddresses();
  }

  setAddressId(id) {
    this.getComponents()
      .findById("address-dialog")
      .forEach((comp) => {
        comp.selectAddress(id);
      });
  }

  retrieveAddresses() {
    Services.get("address").then(([addressService]) => {
      addressService
        .getAddresses()
        .then((addressService) => {
          const addresses = addressService.getData("addresses");
          this.setData({
            "default.addresses": addresses,
          });
        })
        .catch((err) => {
          console.log("error", err);
        });
    });
  }

  deleteAddress(e, id) {
    e.preventDefault();
    Services.get("address").then(([addressService]) => {
      addressService
        .deleteAddress(id)
        .then((addressService) => {
          this.setData({
            "default.addresses": addressService.getData("addresses", []),
          });
        })
        .catch((err) => {
          console.log("error", err);
        });
    });
  }

  activateAutocomplete() {
    this.getComponents()
      .findById("address-dialog")
      .forEach((comp) => {
        comp.initGoogleAutocomplete();
      });
  }
}
