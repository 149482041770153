import React from "react";
import { Button, Box, CardMedia, Link, Grid, Container } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";

export default function ImagesGallery(props) {
  const { component } = props;

  const { fscope } = component.props;
  const devHelper = component.getHelpers("dev");
  const filesUrl = component.getHelpers("env").get("files_url");
  const sliders = component.getData("default.sliders", []);

  return (
    <Container disableGutters sx={{ my: 3 }}>
      <Grid
        container
        justifycontent="center"
        direction="row"
        rowSpacing={1}
        columnSpacing={{ xs: 1, md: 1 }}
      >
        {sliders.map((slider) => {
          const {
            slug = null,
            url = null,
            button_text,
          } = devHelper.getObjectValue(slider, "_values");
          return (
            <Grid item md={3} sm={4} xs={6} key={slug}>
              <Box
                sx={{
                  position: "relative",
                }}
              >
                <Link component={RouterLink} to={url}>
                  <CardMedia
                    component="img"
                    alt={component.trans(`${fscope} image`)}
                    image={filesUrl.concat(
                      `/konstantino/${fscope}/${slug}.jpg`
                    )}
                  />
                </Link>
              </Box>
              <Box sx={{ textAlign: "center" }}>
                <Button
                  sx={{
                    fontFamily: "Poppins-Regular",
                    fontSize: { xs: 14, md: 15 },
                    textTransform: "none",
                    padding: "0px",
                    mt: 1,
                    lineHeight: { xs: "17px", sm: "unset" },
                    "&:hover, &:focus": {
                      background: "transparent",
                      color: "#000",
                    },
                  }}
                  fullWidth
                  component={RouterLink}
                  to={url}
                  disableRipple
                >
                  {button_text}
                </Button>
              </Box>
            </Grid>
          );
        })}
      </Grid>
    </Container>
  );
}
