import React from "react";
import { styled } from "@mui/material/styles";
import {
  Typography,
  Button,
  Dialog,
  Container,
  TextField,
  IconButton,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

const MyTextField = styled(TextField)(({ theme }) => ({
  "& label.Mui-focused": {
    color: "#000",
    borderRadius: 0,
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "#000",
    borderRadius: 0,
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "#000",
      borderRadius: 0,
    },
    "&:hover fieldset": {
      borderColor: "#000",
      borderRadius: 0,
    },
    "&.Mui-focused fieldset": {
      borderColor: "#000",
      borderRadius: 0,
    },
  },
}));

export default function Address(props) {
  const { component } = props;
  const { open = false } = component.props;

  return (
    <Dialog
      onClose={() => component.props.setOpen(false)}
      open={open}
      sx={{
        "& .MuiPaper-rounded": {
          borderRadius: 0,
          px: 2,
          py: 2,
        },
        "& .MuiDialog-paper": {
          "@media (max-width: 400px)": {
            margin: 0,
          },
        },
      }}
    >
      <Container
        maxWidth="xs"
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <form onSubmit={(e) => component.sendRequest(e)}>
          <IconButton
            onClick={() => component.props.setOpen(false)}
            sx={{
              position: "absolute",
              right: 5,
              top: 5,

              color: "#000",
            }}
          >
            <CloseIcon />
          </IconButton>
          <Typography
            sx={{
              fontWeight: 400,
              fontSize: "20px",
              marginBottom: "16px",
              fontFamily: "founders-grotesk,Helvetica,sans-serif",
            }}
          >
            {component.ucfirst("SEND US AN INQUIRY")}
          </Typography>
          <MyTextField
            variant="outlined"
            margin="normal"
            fullWidth
            label={component.ucfirst("name-textfield")}
            name="name"
            helperText={component.getData("error.fields.name")}
            value={component.getData("default.fields.name", "")}
            onChange={(e) => {
              component.setData({
                "default.fields.name": e.target.value,
              });
              component.deleteData("error.fields.name");
            }}
            error={component.dataExists("error.fields.name")}
          />
          <MyTextField
            variant="outlined"
            margin="normal"
            fullWidth
            label={component.ucfirst("email-textfield")}
            name="email"
            helperText={component.getData("error.fields.email")}
            value={component.getData("default.fields.email", "")}
            onChange={(e) => {
              component.setData({
                "default.fields.email": e.target.value,
              });
              component.deleteData("error.fields.email");
            }}
            error={component.dataExists("error.fields.email")}
          />
          <MyTextField
            variant="outlined"
            margin="normal"
            fullWidth
            label={component.ucfirst("size-textfield")}
            name="size"
            helperText={component.getData("error.fields.size")}
            value={component.getData("default.fields.size", "")}
            onChange={(e) => {
              component.setData({
                "default.fields.size": e.target.value,
              });
              component.deleteData("error.fields.size");
            }}
            error={component.dataExists("error.fields.size")}
          />
          <MyTextField
            variant="outlined"
            margin="normal"
            fullWidth
            label={component.ucfirst("phone-textfield") + " ( Optional )"}
            name="phone"
            helperText={component.getData("error.fields.phone")}
            value={component.getData("default.fields.phone", "")}
            onChange={(e) => {
              component.setData({
                "default.fields.phone": e.target.value,
              });
              component.deleteData("error.fields.phone");
            }}
            error={component.dataExists("error.fields.phone")}
          />
          <Button
            type="submit"
            fullWidth
            sx={{
              height: 56,
              mt: 2,
              background: "#000",
              borderRadius: 0,
              border: "1px solid #000",
              color: "#ffffff",
              "&:hover": {
                background: "#000",
              },
            }}
          >
            {component.ucfirst("submit")}
          </Button>
        </form>
      </Container>
    </Dialog>
  );
}
