import Page from "../../../../modules/Core/Components/Page";
import templateDefault from "../../../templates/default/pages/user/coupons";

export default class Coupons extends Page {
  title = "coupons";
  template = templateDefault;

  fullAuth = true;

  onAuth(data) {
    const { auth } = data;
    if (!auth.isAuthenticated()) {
      this.redirectTo("/signin");
    }
  }

  onLoad(data) {
    super.onLoad(data);
    this.getApp().updateWindowTitle(
      `${this.ucfirst("coupons-title")} | ${this.ucfirst("window-title")}`
    );
    this.scrollToTop();
  }
}
