import React from "react";
import { AppBar, Toolbar, Link, CardMedia } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";

export default function CheckoutNavBar(props) {
  // const { component } = props;

  return (
    <>
      <AppBar position="relative" sx={{ boxShadow: "none" }}>
        <Toolbar
          sx={{
            justifyContent: "center",
            display: "flex",
            backgroundColor: "#000",
            minHeight: "0 !important",
          }}
        >
          <Link
            component={RouterLink}
            sx={{
              maxWidth: "100%",
              width: { xs: "75px", md: "105px" },
            }}
            to="/"
          >
            <CardMedia
              component="img"
              image="/images/logos/navbar-logo.png"
              alt="Konstantino Logo"
              title="Konstantino Home"
              sx={{
                maxWidth: "100%",
                width: "inherit",
              }}
            />
          </Link>
        </Toolbar>
      </AppBar>
      <Toolbar
        id="back-to-top-anchor"
        style={{ minHeight: 1, backgroundColor: "#f2f2f3" }}
      />
    </>
  );
}
