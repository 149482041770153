import { Component } from "../../../modules/Core/Component";
import templateDefault from "../../templates/default/widgets/recently_viewed";
import Services from "../../Services/Services";

export default class RecentlyViewed extends Component {
  id = "recently-viewed";

  template = templateDefault;

  onLoad(data) {
    super.onLoad(data);

    this.getRecentlyViewed();
  }

  getRecentlyViewed() {
    var viewed = this.getHelpers("state").get("viewed", null);
    if (viewed) {
      Services.get("product").then(([productService]) => {
        var promises = viewed
          .split(",")
          .reverse()
          .map((slug) => {
            return productService.getOne({ slug });
          });

        Promise.all(promises).then((products) => {
          products = products
            .filter((product) => product && product._id)
            .map((product) => {
              const { response } = product;
              var temp = response.getData() || {};
              return temp;
            });

          this.setData({
            "default.viewed": products,
          });
        });
      });
    } else {
      this.setData({
        "default.viewed": [],
      });
    }
  }
}
