import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Pages from "../Components/Pages";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import MainMessage from "../Components/Widgets/MainMessage";
import QuickView from "../Components/Widgets/QuickView";

const theme = createTheme({
  components: {
    MuiUseMediaQuery: {
      defaultProps: {
        noSsr: true,
      },
    },
  },
  palette: {
    primary: {
      main: "#000",
    },
    secondary: {
      main: "#000",
    },
  },
  appbar_height: {
    desktop: "100px",
    mobile: "75px",
  },
  custom: {
    titles: {
      title: {
        fontFamily: "Poppins-Regular",
        color: "#000",
        fontSize: { xs: 26, md: 32, lg: 37 },
        textTransform: "none",
        lineHeight: 1.2,
        pt: 0,
        "&:hover": {
          bgcolor: "transparent",
        },
      },
      subtitle: {
        fontFamily: "Spartan-SemiBold",
        color: "#000",
        fontSize: { xs: 12, md: 13.5, lg: 15 },
        lineHeight: 1.2,
        p: 0,
        textTransform: "none",
        "&:hover": {
          backgroundColor: "transparent",
        },
      },
      description: {
        color: "#000",
        fontFamily: "Spartan-Medium",
        fontSize: { xs: 14, md: 15 },
        px: 1,
        lineHeight: 1.5,
        textTransform: "none",
        "&:hover": {
          backgroundColor: "transparent",
        },
      },
    },
    buttons: {
      button1: {
        fontFamily: "Spartan-Bold",
        color: "#000",
        lineHeight: 1.2,
        fontSize: { xs: 13, md: 15 },
        textTransform: "none",
        border: "1px solid #000",
        py: 2,
        px: 3,
        borderRadius: 0,
        "&:hover": {
          bgcolor: "transparent",
        },
      },
      button2: {},
      button3: {},
    },
  },
});

const ExportDefault = (props) => {
  const { component } = props;

  return (
    component.isLoaded() && (
      <ThemeProvider theme={theme}>
        <Router>
          <CssBaseline />
          <MainMessage />
          <QuickView />
          <Switch>
            <Route exact path="/" component={Pages.Home} />
            <Route exact path="/signin" component={Pages.Login} />
            <Route exact path="/register" component={Pages.Register} />
            <Route exact path="/register" component={Pages.Register} />
            <Route exact path="/men" component={Pages.Men} />
            <Route exact path="/women" component={Pages.Women} />
            <Route
              exact
              path="/assistance/:section"
              component={Pages.Assistance}
            />
            <Route
              exact
              path="/women/collections"
              component={Pages.CollectionsWomen}
            />
            <Route
              exact
              path="/men/collections"
              component={Pages.CollectionsMen}
            />
            <Route
              exact
              path="/location/:country"
              component={Pages.Locations}
            />
            <Route exact path="/trunk-shows" component={Pages.TrunkShows} />
            <Route exact path="/boutiques" component={Pages.Boutiques} />
            <Route
              exact
              path="/women/:type/:value"
              component={Pages.Collection}
            />
            <Route
              exact
              path="/men/:type/:value"
              component={Pages.Collection}
            />
            <Route
              exact
              path="/product/:slug/:sku?"
              component={Pages.Product}
            />
            <Route exact path="/wishlist" component={Pages.Wishlist} />
            <Route exact path="/cart" component={Pages.Cart} />
            <Route exact path="/search/results" component={Pages.Results} />
            <Route exact path="/checkout" component={Pages.Checkout} />
            <Route
              exact
              path="/order/complete"
              component={Pages.OrderComplete}
            />
            {/* world section */}
            <Route exact path="/about" component={Pages.About} />
            <Route exact path="/legacy" component={Pages.Legacy} />
            <Route exact path="/limelight" component={Pages.Limelight} />
            <Route exact path="/press" component={Pages.Press} />
            <Route exact path="/films" component={Pages.Films} />
            {/* user section */}
            <Route exact path="/profile" component={Pages.Profile} />
            <Route exact path="/addresses" component={Pages.Addresses} />
            <Route exact path="/orders" component={Pages.Orders} />
            <Route exact path="/order/:id" component={Pages.Order} />
            <Route exact path="/coupons" component={Pages.Coupons} />
            {/* test section */}
            {/* <Route exact path="/test/register" component={Pages.TestRegister} />
            <Route exact path="/test/login" component={Pages.TestLogin} />
            <Route exact path="/test/products" component={Pages.TestProducts} />
            <Route
              exact
              path="/test/product/:slug"
              component={Pages.TestProduct}
            />
            <Route exact path="/test/checkout" component={Pages.TestCheckout} />
            <Route exact path="/test/address" component={Pages.TestAddress} />
            {/* redirection section */}
            <Route component={Pages.Redirect} />
          </Switch>
        </Router>
      </ThemeProvider>
    )
  );
};

export default ExportDefault;
