import { Component } from "../../../modules/Core/Component";
import templateDefault from "../../templates/default/widgets/register";
import Services from "../../Services/Services";

export default class Register extends Component {
  template = templateDefault;

  onLoad(data) {
    super.onLoad(data);

    // Services.get("socialLogin", "test").then(([socialLogin]) => {
    //   socialLogin.loadFB({ window, document, page: this });
    // });

    // Services.get("socialLogin", "test").then(([socialLogin]) => {
    //   socialLogin.loadGoogle({ window, document, page: this });
    // });
  }

  signinFB() {
    Services.get("socialLogin", "test").then(([socialLogin]) => {
      socialLogin.loginFB({ window, document, page: this });
    });
  }

  // signIn(e) {
  register(e) {
    e.preventDefault();

    const { name, username, password, verify } = this.getData(
      "default.fields",
      {}
    );
    const error = {
      fields: {},
      message: "",
    };

    if (!name) {
      error.fields["name"] = this.ucfirst("fullname-helpertext");
    }
    if (!username) {
      error.fields["username"] = this.ucfirst("email-helpertext");
    } else {
      if (!this.getHelpers("validate").email(username)) {
        error.fields["username"] = this.ucfirst("invalid-email");
      }
    }

    if (!password) {
      error.fields["password"] = this.ucfirst("password-helpertext");
    }

    if (!verify) {
      error.fields["verify"] = this.ucfirst("confirm-password-helpertext");
    }

    if (password && verify && password !== verify) {
      this.getComponents()
        .findById("main-message")
        .first()
        .setData({
          "error-message": this.ucfirst("invalid-password-verification"),
        });
      error.message = this.ucfirst("invalid-password-verification");
    }

    this.setData({
      error,
    });

    if (Object.keys(error.fields).length === 0 && !error.message) {
      Services.get("auth", "test").then(([auth]) => {
        auth
          .register({ name, username, password, verify })
          .then((auth) => {
            this.deleteData("error.message");
            this.getHelpers("Auth").set(auth.getData("_response").getData());
            this.getPage().redirectToRedirect("/");
          })
          .catch((auth) => {
            this.getComponents()
              .findById("main-message")
              .first()
              .setData({
                "error-message": this.ucfirst(
                  auth.getData("_response").getMessage()
                ),
              });
          });
      });
    }
  }
}
